import { Font, StyleSheet } from '@react-pdf/renderer';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }]
});

const styles = StyleSheet.create({
  col4: { width: '25%' },
  col8: { width: '75%' },
  col6: { width: '50%' },
  underline: { textDecoration: 'underline' },
  center: { alignItems: 'center', justifyContent: 'center' },
  mt: { marginTop: -10 },
  bold: { fontWeight: 'bold' },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  mb10: { marginBottom: 10 },
  mb15: { marginBottom: 15 },
  mb20: { marginBottom: 20 },
  mb30: { marginBottom: 30 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  h3_2: { fontSize: 11, fontWeight: 700 },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  h5: { fontSize: 12, fontWeight: 700 },
  body1: { fontSize: 11 },
  subtitle2: { fontSize: 9, fontWeight: 700, textAlign: 'justify' },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff'
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 24,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8'
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  table: { display: 'flex', width: 'auto' },
  tableHeader: {},
  tableBody: { marginTop: -16 },
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row'
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: '5%', padding: 5, borderBottom: '1px solid #DFE3E8' },
  tableCell_2: {
    width: '50%',
    padding: 5,
    border: '1px solid black',
    margin: -0.5,
    textAlign: 'center'
  },
  tableCell_3: { width: '15%', padding: 5, borderBottom: '1px solid #DFE3E8' },
  tableCell_4: { width: '100%', padding: 2, borderBottom: '1px solid #DFE3E8' }
});

export default styles;
