import { Stack, Tooltip } from '@mui/material';
import { Avatar } from 'antd';
import React from 'react';
import { TaskType } from 'src/models/task_m';
import createAvatar from 'src/utils/createAvatar';
import ContactsDialog from 'src/components/ContactsDialog';
import { useTaskAssignee } from './useTaskAssignee';


/**
 *
 * @param {{task: TaskType, onUpdate: function, disabled: boolean}} props
 * @returns
 */
export default function TaskAssigne({ task, onUpdate, disabled = false }) {
  const { assignee, onAssigne } = useTaskAssignee(task, onUpdate, disabled);
  return (
    <Stack width={1} direction="row" alignItems="center" spacing={0.5}>
      {!!assignee?.length && (
        <Avatar.Group maxCount={3}>
          {assignee.map((user, idx) => (
            <Tooltip title={user?.name} arrow key={user.id + idx}>
              <Avatar src={user.avatar} alt={user.name} style={{ backgroundColor: createAvatar(user.name).color2 }}>
                {createAvatar(user.name).name}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      )}
      <Stack>
        {!disabled && (
          <ContactsDialog
            task={assignee}
            onAssigne={onAssigne}
            assigne={assignee}
          />
        )}
      </Stack>
    </Stack>
  );
}

