/**
 * Compares an old object with a new object and returns an array of changes.
 *
 * @param {Object} old - The old object to compare against.
 * @param {Object} newest - The new object to compare.
 * @returns {Object[]} - An array of changes, where each change is an object with the key that changed and the new and old values.
 */
export function changeOnObject(old, newest) {
  if (JSON.stringify(old) === JSON.stringify(newest)) {
    return [];
  }

  let changes = [];
  Object.entries(newest).forEach(([key, one]) => {
    if (old) {
      if (JSON.stringify(one) !== JSON.stringify(old[key])) {
        changes.push({ [key]: one });
      }
    } else {
      changes.push({ [key]: one });
    }
  });

  return changes;
}

/**
 * Compares an old object with a new object and returns an array of changes, including the old and new values.
 *
 * @param {Object} old - The old object to compare against.
 * @param {Object} newest - The new object to compare.
 * @returns {Object[]} - An array of changes, where each change is an object with the key that changed and the new and old values.
 */
export function changeOnObjectWithOldVal(old, newest) {
  if (JSON.stringify(old) === JSON.stringify(newest)) {
    return [];
  }

  let changes = [];
  Object.entries(newest).forEach(([key, one]) => {
    if (old) {
      if (JSON.stringify(one) !== JSON.stringify(old[key])) {
        changes.push({ [key]: { new: one, old: old[key] } });
      }
    } else {
      changes.push({ [key]: { new: one, old: null } });
    }
  });

  return changes;
}

/**
 * Compares two arrays of objects and returns the added, removed, and modified objects.
 *
 * @param {Object[]} oldArray - The old array of objects to compare against.
 * @param {Object[]} newArray - The new array of objects to compare.
 * @param {string} [proprety='id'] - The property to use for comparison between objects.
 * @returns {Object} - An object with three properties: `added`, `removed`, and `modified`, each containing an array of objects.
 */
export function compareArraysOfObjects(oldArray, newArray, proprety = 'id') {
  const added = [];
  const removed = [];
  const modified = [];

  // Recherche des objets ajoutés
  for (const newObj of newArray) {
    const found = oldArray.find((oldObj) => oldObj[proprety] === newObj[proprety]);
    if (!found) {
      added.push(newObj);
    }
  }

  // Recherche des objets supprimés
  for (const oldObj of oldArray) {
    const found = newArray.find((newObj) => newObj[proprety] === oldObj[proprety]);
    if (!found) {
      removed.push(oldObj);
    }
  }

  // Recherche des objets modifiés
  for (const newObj of newArray) {
    const oldObj = oldArray.find((obj) => obj[proprety] === newObj[proprety]);
    if (oldObj) {
      const keys = Object.keys(newObj);
      const isModified = keys.some((key) => newObj[key] !== oldObj[key]);
      if (isModified) {
        modified.push(newObj);
      }
    }
  }

  return {
    added,
    removed,
    modified
  };
}
