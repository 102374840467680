/**
 * Capitalizes the first letter of the given text.
 *
 * @param {string} [text=''] - The input text to capitalize.
 * @returns {string} The capitalized text, or an empty string if the input was empty.
 */
export const capitalize = (text = '') => {
    return text.length !== 0
        ? `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}`
        : '';
}