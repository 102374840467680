 /**
  * Converts an array of objects into an object keyed by a specified property.
  *
  * @param {Array} array - The array of objects to convert.
  * @param {string} [key='id'] - The property to use as the key in the resulting object.
  * @returns {Object} - An object keyed by the specified property, with the array elements as the values.
  */
 export default function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
      accumulator[current[key]] = current;
      return accumulator;
    }, {});
  }