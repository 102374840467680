import React from 'react'
import Label from './Label'
import useNotifications from './../hooks/useNotifications';
import { NOTIFICATION_TYPES } from 'src/constants';

export default function CommunityNotificationLabel() {
  const { combine: notifications } = useNotifications();

  const unReadNotifications = notifications.filter(item => item.isUnRead === true && (item.type === NOTIFICATION_TYPES.COMMUNICATION || item.type === NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL || item.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE || item.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE || NOTIFICATION_TYPES.COMMUNICATION_REMOVE_FROM_CHANNEL || NOTIFICATION_TYPES.COMMUNICATION_YOU_ARE_NEW_ADMIN))


  return unReadNotifications.length ? <Label color="error"> {unReadNotifications.length} </Label> : null
}
