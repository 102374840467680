/**
 * Checks if the given string represents a valid time in the format "HH:MM".
 *
 * @param {string} str - The time string to validate.
 * @returns {boolean} - True if the time string is valid, false otherwise.
 */
export function isValidTimeString(str) {
  const [hours, minutes] = str.split(':');

  if (hours === undefined || minutes === undefined) {
    return false;
  }

  if (isNaN(hours) || isNaN(minutes)) {
    return false;
  }

  const hoursNum = parseInt(hours, 10);
  const minutesNum = parseInt(minutes, 10);
  if (hoursNum < 0 || hoursNum > 23 || minutesNum < 0 || minutesNum > 59) {
    return false;
  }

  return true;
}
