import { IconButton, Input } from '@mui/joy';
import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useToggle from 'src/hooks/useToggle';
import { Box, ClickAwayListener, MenuItem, Paper } from '@mui/material';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import { isValidTimeString } from 'src/utils/valideTime';
import { isEmpty } from 'lodash';

/**
 *
 * @param {{
 * value: string,
 * onChange: (time?: string) =>{},
 * size: 'lg' | 'md' | 'sm',
 * variant: 'outlined' | 'plain' | 'solid' | 'soft'
 * }} props
 * @returns
 */
export default function OKyTimeInput({ placeholder, variant = 'outlined', size = 'lg', sx, value, onChange }) {
  const popoverRef = useRef();
  const [time, setTime] = useState(value || '');
  const { open, handleOpen, handleClose } = useToggle(false);

  const options = useMemo(() => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  }, []);

  const handleUpdateTime = (val) => {
    // console.log(val);
    setTime(val);
    if (onChange) onChange(val);
    handleClose();
  };

  const wipeTime = () => {
    setTime('');
    if (onChange) onChange(null);
  };

  const formatTime = () => {
    if (!isValidTimeString(time)) {
      setTime(options.at(0));
    }
    handleClose();
  };

  return (
    <>
      <Box ref={popoverRef} position="relative" onClick={handleOpen}>
        <ClickAwayListener onClickAway={formatTime}>
          <Input
            variant={variant}
            size={size}
            value={time}
            readOnly
            onClick={handleOpen}
            onFocus={handleOpen}
            slotProps={{ input: { onClick: handleOpen } }}
            onChange={(e) => handleUpdateTime(e.currentTarget.value)}
            placeholder={placeholder}
            sx={{ width: 150, fontSize: 14, ...sx }}
            {...(!isEmpty(time) && {
              endDecorator: (
                <IconButton size="sm" onClick={wipeTime}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              )
            })}
          ></Input>
        </ClickAwayListener>
      </Box>
      {open &&
        popoverRef.current &&
        ReactDOM.createPortal(
          <Box
            sx={{
              zIndex: (t) => t.zIndex.tooltip - 1,
              position: 'absolute',
              top: 50,
              right: 0,

              overflow: 'hidden'
            }}
          >
            <Paper variant="outlined" sx={{ width: 150, height: 200 }}>
              <Scrollbar>
                {options &&
                  options.map((option) => (
                    <MenuItem
                      selected={time === option}
                      key={option}
                      sx={{
                        fontSize: 14,
                        fontWeight: '500',
                        borderLeftWidth: '4px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: 'transparent',
                        '&.Mui-selected': { borderLeftColor: `black` }
                      }}
                      onClick={() => handleUpdateTime(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
              </Scrollbar>
            </Paper>
          </Box>,
          popoverRef.current
        )}
    </>
  );
}
