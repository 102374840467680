import { groupBy, capitalize } from 'lodash';


/**
 * Comparison function used to sort an array of strings in alphabetical order.
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {number} - A negative value if `a` should come before `b`, a positive value if `a` should come after `b`, and zero if they are equal.
 */
const sortFunction = (a, b) =>{
    if (a < b)
        return -1
    if (a > b)
        return 1
    return 0
  };
  

/**
 * Sorts the keys of an object in alphabetical order and returns a new object with the keys sorted.
 * @param {Object} obj - The object to sort.
 * @returns {Object} - A new object with the keys sorted in alphabetical order.
 */
function sortObj(obj) {
return Object.keys(obj).sort(sortFunction).reduce(function (result, key) {
    result[key] = obj[key];
    return result;
}, {});
}


/**
 * Gets the first character of a name, capitalizing it if it exists.
 * @param {string} name - The name to get the first character of.
 * @returns {string} - The first character of the name, capitalized if it exists.
 */
function getFirstCharacter(name) {
    return capitalize(name && name.charAt(0));
}

/**
 * Converts a list of contacts into an object where the keys are the first character of the contact's last name, and the values are arrays of contacts with that first character.
 * @param {Object[]} contacts - An array of contact objects, each with a `lastName` property.
 * @returns {Object} - An object where the keys are the first character of the contact's last name, and the values are arrays of contacts with that first character.
 */
export function contactToAphabetList (contacts){
    let data = groupBy(contacts, (contact) => getFirstCharacter(contact.lastName));
    return sortObj(data);
}

/**
 * Converts a collection of items into an object where the keys are the unique values of the `title` property, and the values are arrays of items with that title.
 * @param {Object[]} collection - An array of objects, each with a `title` property.
 * @returns {Object} - An object where the keys are the unique values of the `title` property, and the values are arrays of items with that title.
 */
export const goupToAlphabet = collection=>{
    let data = groupBy(collection, (contact) => contact.title);
    return sortObj(data);
}