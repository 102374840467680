import { Icon } from '@iconify/react';
import { Dialog, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AffectPDF from '../../section/affectation_v4/AffectPDF';

export default function AffectationPdf() {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <Stack>
      <Stack width={1} direction="row" justifyContent="flex-end">
        <Stack>
          <IconButton color="error" onClick={() => navigate(-1)}>
            <Icon icon="eva:close-fill" height={40} width={40} />
          </IconButton>
        </Stack>
      </Stack>
      <AffectPDF affect={state} />
    </Stack>
  );
}

export function AffectationPdfModal({ open, onClose, affect }) {
  return (
    <Dialog fullScreen fullWidth open={open} onClose={onClose}>
      <Stack width={1} direction="row" justifyContent="flex-end" position="relative">
        <AffectPDF affect={affect} />
        <Stack position="absolute" top={-5} right={0}>
          <IconButton size="small" color="error" onClick={onClose}>
            <Icon icon="eva:close-fill" height={25} width={25} />
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
