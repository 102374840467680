import PropTypes from 'prop-types';
import { forwardRef, useCallback, useMemo } from 'react';
// @mui
import { Typography, Link, Tooltip, Stack } from '@mui/material';
// utils
import GetFontValue from 'src/utils/getFontValue';

// ----------------------------------------------------------------------

/**
 * @type {Typography}
 * @param {number} maxLength Maximum length of the text
 */
const TextMaxLine = forwardRef(
  (
    { asLink, variant = 'body1', line = 1, persistent = false, children, sx, getLine, maxLength, noTooltip, ...other },
    ref
  ) => {
    const { lineHeight } = GetFontValue(variant);

    const style = {
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: line,
      WebkitBoxOrient: 'vertical',
      ...(persistent && {
        height: lineHeight * line
      }),
      ...sx
    };

    const handleRef = useCallback(
      (node) => {
        if (node) {
          const computedStyle = window.getComputedStyle(node);
          const _lineHeight = parseFloat(computedStyle.getPropertyValue('line-height'));
          const maxHeight = parseFloat(computedStyle.getPropertyValue('height'));
          const actualLines = Math.round(maxHeight / _lineHeight);
          if (getLine) {
            getLine(actualLines);
          }
        }
      },
      [getLine]
    );

    const content = useMemo(() => {
      return typeof children === 'string' && maxLength && children.length > maxLength
        ? `${children.substring(0, maxLength)}...`
        : children;
    }, [children, maxLength]);

    if (asLink) {
      return (
        <Tooltip title={children} arrow>
          <Stack ref={ref}>
            <Link color="inherit" variant={variant} sx={{ ...style }} {...other} ref={handleRef}>
              {content}
            </Link>
          </Stack>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={children}
        arrow
        {...(noTooltip && { disableHoverListener: true, disableFocusListener: true, disableTouchListener: true })}
      >
        <Stack ref={ref}>
          <Typography variant={variant} sx={{ ...style }} {...other} ref={handleRef}>
            {content}
          </Typography>
        </Stack>
      </Tooltip>
    );
  }
);

TextMaxLine.propTypes = {
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  line: PropTypes.number,
  persistent: PropTypes.bool,
  sx: PropTypes.object,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  maxLength: PropTypes.number // Add maxLength prop
};

export default TextMaxLine;
