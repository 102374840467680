// src/components/ProjectExtendModal.js
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ActionModal from 'src/components/ActionModal';
import { convertToTimestamp, convertTimestampToHumanReadable } from 'src/utils/dateUtils';

/**
 * Renders a modal dialog for extending the due date of a project.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.openExtend - Indicates whether the modal should be open.
 * @param {function} props.handleCloseExtend - Function to close the modal.
 * @param {Object} props.tempDate - The temporary date to be extended.
 * @param {Object} props.enabledDataInterval - The enabled data interval for the project.
 * @param {function} props.extendDate - Function to extend the project due date.
 * @param {boolean} props.isLoading - Indicates whether the extend date action is loading.
 * @param {boolean} props.disabled - Indicates whether the extend date action is disabled.
 * @returns {JSX.Element} - The rendered modal dialog.
 */
const ProjectExtendModal = ({
  openExtend,
  handleCloseExtend,
  tempDate,
  enabledDataInterval,
  extendDate,
  isLoading,
  disabled
}) => (
  <ActionModal
    title="Prolonger l'échéance du projet"
    desc={
      <Stack spacing={2}>
        <Typography>
          {`Voulez-vous prolonger la date de ${
            convertToTimestamp(tempDate?.date) < convertToTimestamp(enabledDataInterval?.start)
              ? 'début'
              : convertToTimestamp(tempDate?.date) > convertToTimestamp(enabledDataInterval?.end)
              ? 'fin'
              : tempDate?.isStartTimeSelected
              ? 'début'
              : 'fin'
          } du projet `}
          {enabledDataInterval?.start && enabledDataInterval?.end && (
            <>
              du{' '}
              <Typography component="span" color="#000" fontWeight={700}>
                {tempDate?.isStartTimeSelected
                  ? convertTimestampToHumanReadable(convertToTimestamp(enabledDataInterval?.start))
                  : convertTimestampToHumanReadable(convertToTimestamp(enabledDataInterval?.end))}
              </Typography>
            </>
          )}
          au{' '}
          <Typography component="span" color="#000" fontWeight={700}>
            {convertTimestampToHumanReadable(convertToTimestamp(tempDate?.date))}
          </Typography>{' '}
          ?
        </Typography>
      </Stack>
    }
    open={openExtend}
    onClose={handleCloseExtend}
    action={
      <LoadingButton color="warning" variant="contained" disabled={disabled} loading={isLoading} onClick={extendDate}>
        Continuer
      </LoadingButton>
    }
  />
);

export default ProjectExtendModal;
