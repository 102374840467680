import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { DocumentEditor } from '@onlyoffice/document-editor-react';
import useAuth from 'src/hooks/useAuth';
import storage from 'src/utils/storage';
import { dispatch } from 'src/redux/store';
import { IOnlyOfficeDoc } from 'src/models/IOnlyOfficeDoc';
import { DOCUMENT_READER_SERVER_URL, DOC_READER_URL } from 'src/config';
import { onlyOfficeDocumentType, onlyOfficeFileType } from './helper';
import { getOnlyOfficeFileLastHistories, saveOnlyOfficeFileCopy } from 'src/redux/slices/driver/driver';
import useToggle from 'src/hooks/useToggle';
import DocumentOutDateVersion from './DocumentOutDateVersion';

/**
 *
 * @param {object} props
 * @param {object} props.params
 * @param {string} props.params.docKey
 * @param {string} [props.params.module]
 * @param {string} [props.params.parentId]
 * @param {string} props.params.uploadName
 * @param {IOnlyOfficeDoc} props.params.document
 * @param {'edit' | 'view'} props.params.openMode
 * @param {()=> {}} [props.onReady] onReady() => void
 * @param {(key:string)=> {}} [props.refrechDocKey] refrechDocKey(newKey) => void
 */
export default function DocumentOnlineEdit({ params, refrechDocKey, onReady }) {
  const editorId = 'oky_doc_reader';

  const { document, uploadName, openMode = 'view', parentId = null, module = null, docKey } = params;

  const { user } = useAuth();
  const { open, handleOpen, handleClose } = useToggle();
  const { enqueueSnackbar } = useSnackbar();
  const callbackUrl = DOCUMENT_READER_SERVER_URL;

  const docType = useMemo(() => onlyOfficeDocumentType(document.fileType), [document]);
  const fileType = useMemo(() => onlyOfficeFileType(uploadName), [uploadName]);
  const docUser = useMemo(
    () => ({ name: user.displayName, id: user.uid, group: '', image: '' }),
    [user.displayName, user.uid]
  );

  const handleCloseOutDateDialog = (key) => {
    if (refrechDocKey) refrechDocKey(key);
    handleClose();
  };

  const onDocumentReady = (event) => {
    const docRef = storage.refFromURL(document.url);
    const path = docRef.fullPath;
    dispatch(
      saveOnlyOfficeFileCopy({
        name: uploadName,
        id: document.key,
        url: document.url,
        doc_path: path,
        parentId,
        module,
        isEditingDocKey: docKey,
        isEditing: true,
        callback: () => {}
      })
    );
  };

  const onAppReady = (event) => {
    if (onReady) onReady();
  };
  const onCreateDocument = (event) => {};

  const onRequestHistory = (event) => {
    const docEditor = window.DocEditor?.instances;

    if (docEditor) {
      let versions = [];
      const callback = (_versions = []) => {
        versions = _versions;

        const histories = versions.map((one, index) => {
          const { savedAt, ...rest } = one;
          const changes = [{ created: rest?.created, user: rest?.user }];
          return { ...rest, changes, version: index + 1 };
        });

        const editorApi = docEditor[editorId];

        editorApi.refreshHistory({
          currentVersion: histories.length,
          history: histories
        });
      };

      dispatch(getOnlyOfficeFileLastHistories({ id: document.key, callback, onError: () => {} }));
    }
  };

  const onRequestHistoryData = (event) => {
    const docEditor = window.DocEditor?.instances;
    let version = event.data;

    let versions = [];
    const callback = (_versions = []) => {
      versions = _versions;
      const histories = versions.map((one, index) => {
        const _key = versions[index + 1]?.key || docKey;
        const _prev = versions[index - 1];

        return {
          fileType: fileType,
          key: _key,
          url: one?.changesurl,
          changesUrl: one?.changesurl,
          version: index + 1,
          ...(_prev && {
            previous: {
              fileType: fileType,
              key: one?.key,
              url: _prev?.changesurl
            }
          })
        };
      });

      const editorApi = docEditor[editorId];

      const currentSelection = histories[version - 1];

      editorApi.setHistoryData({ ...currentSelection });
    };

    dispatch(getOnlyOfficeFileLastHistories({ id: document.key, callback, onError: () => {} }));
  };

  const onRequestCloseHistory = () => {
    location.reload(true);
  };

  const onRequestRestore = (event) => {
    let currentVersion = event.data.version;
    const docEditor = window.DocEditor?.instances;

    if (docEditor) {
      let versions = [];
      const callback = (_versions = []) => {
        versions = _versions;

        const histories = versions.map((one, index) => {
          const { savedAt, ...rest } = one;
          const changes = [{ created: rest?.created, user: rest?.user }];
          return { ...rest, changes, version: index + 1 };
        });

        const editorApi = docEditor[editorId];

        // console.log({ currentVersion, histories });

        editorApi.refreshHistory({
          currentVersion: currentVersion,
          history: histories
        });
      };

      dispatch(getOnlyOfficeFileLastHistories({ id: document.key, callback, onError: () => {} }));
    }
  };

  const onLoadComponentError = (errorCode, errorDescription) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        enqueueSnackbar(errorDescription, { variant: 'error' });
        break;

      case -2: // Error load DocsAPI from http://documentserver/
        enqueueSnackbar(errorDescription, { variant: 'error' });
        break;

      case -3: // DocsAPI is not defined
        enqueueSnackbar(errorDescription, { variant: 'error' });
        break;
    }
  };

  const onOutDateVersion = () => {
    handleOpen();
  };

  return (
    <>
      <DocumentEditor
        id={editorId}
        documentServerUrl={DOC_READER_URL}
        config={{
          document: { ...document, key: docKey, fileType: fileType },

          documentType: docType,
          editorConfig: {
            lang: 'fr',
            mode: openMode,
            user: docUser,
            callbackUrl: callbackUrl,
            customization: {
              zoom: 90,
              uiTheme: 'theme-classic-light'
            }
          },
          events: {
            onAppReady: onAppReady,
            onDocumentReady: onDocumentReady,
            onRequestRestore: onRequestRestore,
            onRequestHistory: onRequestHistory,
            onOutdatedVersion: onOutDateVersion,
            onRequestCreateNew: onCreateDocument,
            onRequestHistoryData: onRequestHistoryData,
            onRequestHistoryClose: onRequestCloseHistory
          }
        }}
        events_onDocumentReady={onDocumentReady}
        onLoadComponentError={onLoadComponentError}
      />

      {open && (
        <DocumentOutDateVersion
          open={Boolean(open)}
          docId={document.key}
          currentDocKey={docKey}
          onClose={handleCloseOutDateDialog}
        />
      )}
    </>
  );
}
