export const mailHoverColor = 'rgba(249, 162, 31, 0.15)';
export const mailUnSeenColor = '#E6F0FF';
export const mailSelectedColor = 'rgba(249, 162, 31, 0.10)';

export const CUSTOM_MAIL_BOX = {
  INBOX: 'INBOX',
  DRAFTS: 'DRAFTS',
  SENT: 'Sent',
  Junk: 'Junk',
  Trash: 'Trash'
};
export const CUSTOM_MAIL_FLAG = {
  UNSEEN: '\\UNSEEN',
  SEEN: '\\SEEN',
  DRAFTS: '\\DRAFT',
  SENT: '\\SENT',
  TRASH: '\\TRASH',
  JUNK: '\\JUNK'
};

export const MAIL_PLATFORM = {
  MICROSOFT: 'microsoft',
  CUSTOM: 'custom'
};

export const MAIL_SIGNATURE_CLASS = 'mail-signature-custom-component';

export const DEFAULT_MAIL_FOLDERS = {
  Trash: {
    attribs: ['\\HasNoChildren', '\\Trash'],
    delimiter: '/',
    children: null,
    parent: null,
    special_use_attrib: '\\Trash'
  },
  Spam: {
    attribs: ['\\HasNoChildren', '\\UnMarked', '\\Junk'],
    delimiter: '/',
    children: null,
    parent: null,
    special_use_attrib: '\\Junk'
  },
  Sent: {
    attribs: ['\\HasNoChildren', '\\Sent'],
    delimiter: '/',
    children: null,
    parent: null,
    special_use_attrib: '\\Sent'
  },
  Drafts: {
    attribs: ['\\HasNoChildren', '\\Drafts'],
    delimiter: '/',
    children: null,
    parent: null,
    special_use_attrib: '\\Drafts'
  },
  INBOX: {
    attribs: ['\\HasNoChildren'],
    delimiter: '/',
    children: null,
    parent: null
  }
};

export const KEYS_FOLDERS = {
  INBOX: ['INBOX', 'Boîte de réception'],
  SENT: ['Sent', 'Outbox', 'Boîte de réception', 'Sent Items', 'Sent Messages', 'Sent Mail'],
  TRASH: ['Deleted Items', 'Trash', 'Bin', 'Recycle Bin'],
  SPAM: ['Junk', 'Spam', 'Bulk Mail', 'Junk E-mail'],
  DRAFTS: ['Drafts', 'Draft', 'Brouillons', 'Drafts Folder']
};

/**
 * Checks the given folders list against a set of key folders and returns a dictionary of matches.
 *
 * @param {Object.<string, string[]>} myFoldersList - The list of folders to check against the key folders.
 * @param {Object.<string, string[]>} keysFolders - The dictionary of key folders to check against the folders list.
 * @return {Object.<string, string[]>} - A dictionary of matches where the keys are the key folders and the values are arrays of matching folders.
 */
export function checkFolders(myFoldersList, keysFolders) {
  const matches = {};

  for (const myFolderKey in myFoldersList) {
    for (const [key, values] of Object.entries(keysFolders)) {
      if (values.some((value) => myFolderKey.includes(value) || value.includes(myFolderKey))) {
        if (!matches[key]) {
          matches[key] = [];
        }
        matches[key].push(myFolderKey);
      }
    }
  }

  return matches;
}

export const MiscrosoftMeetingMessageType = {
    meetingRequest: 'Réunion planifiée',
    meetingResponse: 'Réponse à une réunion',
    meetingCanceled: 'Réunion annulée',
    meetingAccepted: 'Réunion acceptée',
    meetingDeclined: 'Réunion refusée',
    meetingTenativelyAccepted: 'Réunion acceptée provisoirement'
};
  
export const MAIL_ATTACHMENT_SLICE = 'mailAttachment';

export const MEDIA_TYPES = ['video', 'audio', 'image'];

export const DOCUMENT_TYPES = ['word', 'excel', 'pdf', 'powerpoint'];

export const SUPPORTED_FORMATS = [...MEDIA_TYPES, ...DOCUMENT_TYPES];