import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar } from 'antd';
import { format } from 'date-fns';
import { fr as LocalFr } from 'date-fns/locale';
import { isEmpty, isEqual } from 'lodash';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ActionModal from 'src/components/ActionModal';
import CIconButton from 'src/components/CIconButton';
import ContactsDialog from 'src/components/ContactsDialog';
import { MuiCustomInput } from 'src/components/CustomInput';
import Iconify from 'src/components/Iconify';
import { MenuLine } from 'src/components/MenuLine';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import TextMaxLine from 'src/components/TextMaxLine';
import { baseColors } from 'src/constants/color';
import { useTaskV4Context } from 'src/contexts/TaskContext';
import { useWorldTime } from 'src/contexts/WorldTimeContext';
import { dateConverter } from 'src/helpers/dueDateConverter';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';
import { addTask, deleteTask } from 'src/redux/slices/kanban';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import { dispatch } from 'src/redux/store';
import { useDatePicker } from 'src/section/tasks/kanban/KanbanTaskAdd';
import createAvatar from 'src/utils/createAvatar';
import { useV4ProjectContext } from '..';
import { useProjectContext } from 'src/contexts/ProjectContext';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { gDate } from 'src/utils/formatTime';
import { Priority, getDueDateColor, useCompletedAllSubTasksByTaskId } from '../list/V4ProjectContentListTask';
import { priorityColor } from 'src/helpers/priorityColor';
import PriorityFlags from 'src/section/tasks/PriorityFlags';
import { Visibility } from 'src/section/project/v4/content/project_content/list/V4ProjectContentListTask';
import { TASK_VISIBILITY } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import V4TaskJointe from 'src/section/tasks/v4/V4TaskJointe';
import OkyCalendar from 'src/components/calendar/OkyCalendar';
import { useTaskDueDate } from 'src/hooks/_tasks/useTaskDueDate';
import { useProjectData } from 'src/hooks/useProject';
import { useSelector } from 'react-redux';
import { V4_DEFAULT_TASK_RIGTH } from 'src/constants/task';

/**
 * Renders an icon indicating whether a task is not empty.
 *
 * @param {Object} props - The component props.
 * @param {Object} [props.task={}] - The task object.
 * @param {boolean} [props.hover=false] - Whether the task card is being hovered over.
 * @returns {React.ReactElement} - The ProjectIsNotEmptyIcon component.
 */
export const ProjectIsNotEmptyIcon = ({ task = {}, hover = false }) => {
  const isEmpty = useMemo(() => {
    return !task?.description?.length && !task?.comments?.length && !task?.files?.length;
  }, [task?.comments, task?.description, task?.files]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {!isEmpty && hover && (
        <Tooltip title="Cette tâche contient des fichiers, des commentaires ou une description" arrow>
          <Icon icon="eva:alert-circle-fill" color="gray" height={15} width={15} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default function V4ProjectCotentKanbanCard({ task, column, index }) {
  const moreRef = useRef(null);
  const [completed, setCompleted] = useState(task?.completed);
  const [__hover, onHover, endHover] = useToggleV2();
  const [more, openMore, closeMore] = useToggleV2();
  const [edit, openEdit, closeEdit] = useToggleV2();
  const { openTaskDetails } = useTaskV4Context();
  const [deleteOK, openDelete, closeDelete] = useToggleV2();
  const [canDelete, _, __, handleSwitchDelete] = useToggleV2();
  const [maxHeight, setMaxHeight] = useState(false);
  const { user } = useAuth();
  const { projectId, taskRights } = useV4ProjectContext();
  const { project } = useProjectData(projectId);
  const completedAllSubTasksByTaskId = useCompletedAllSubTasksByTaskId(task.id);

  const disableEdit = !taskRights?.edit;
  const disableDelete = !taskRights?.delete;

  const hover = useMemo(() => {
    if (disableEdit) return __hover;
    return false;
  }, [disableEdit, __hover]);

  const handleCompleted = () => {
    setCompleted(!completed);
    dispatch(
      updateTaskWithoutProject(task, { completed: !completed }, () => {
        completedAllSubTasksByTaskId();
      })
    );
  };

  const getlastImageAttachment = () => {
    const attachments = task?.attachments || [];
    const images = attachments.filter((att) => att?.type?.includes('image'));
    return images[images.length - 1];
  };

  const handleEdit = () => {
    closeMore();
    openEdit();
  };

  const handleOpenTaskDetails = () => {
    closeMore();
    openTaskDetails(task, taskRights?.edit, taskRights?.delete, taskRights?.create);
  };

  const handleOpenImages = () => {
    closeMore();
    openTaskDetails(task, taskRights?.edit, taskRights?.delete, taskRights?.create, true);
  };

  const handleDelete = () => {
    closeDelete();
    dispatch(deleteTask({ cardId: task?.id, columnId: column?.id, card: task, parentId: projectId }));
  };

  const handlePriorityChange = (priority) => {
    dispatch(updateTaskWithoutProject(task, { priority }, () => {}));
  };

  const onAddPrioritie = (priority) => {
    handlePriorityChange(priority);
  };

  const handleVisibilityChange = (visibility) => {
    dispatch(updateTaskWithoutProject(task, { visibility }, () => {}));
    closeMore();
  };

  return (
    <Draggable draggableId={task.id} index={index} isDragDisabled={disableEdit}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Stack
            component={Paper}
            variant="outlined"
            width={1}
            minHeight={maxHeight ? 125 : 105}
            onMouseEnter={!snapshot.isDragging ? onHover : undefined}
            onMouseLeave={endHover}
            sx={{
              border: `1px solid #CCD1DD`,
              ...(disableEdit && {
                ':hover': {
                  outline: () => `1px solid #CDCECE`
                }
              })
            }}
          >
            {getlastImageAttachment() && (
              <Box
                component="img"
                onClick={handleOpenImages}
                sx={{
                  aspectRatio: 3 / 2,
                  objectFit: 'contain',
                  width: '100%',
                  borderRadius: '4px 4px 0 0'
                }}
                alt="The house from the offer."
                src={getlastImageAttachment()?.url}
              />
            )}
            <Stack position="relative" justifyContent="space-between" minHeight={105} maxHeight={130}>
              <Box position="absolute" top={80} left={0} height={1} width={1} onClick={handleOpenTaskDetails} />

              <Stack top={2} left={0} width={1} justifyContent="space-between" px={edit ? 0.5 : 1.5} pt={1}>
                <Stack direction="row" spacing={0.5} alignItems="start">
                  {(!edit || disableEdit) && (
                    <IconButton size="small" sx={{ p: 0.2 }} onClick={!disableEdit ? handleCompleted : undefined}>
                      <Iconify
                        icon={!completed ? 'mingcute:check-circle-line' : 'ph:check-circle-fill'}
                        color={completed ? 'green' : 'inherit'}
                        sx={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                  )}

                  <Stack width={1} spacing={0.5} pr={3}>
                    <Name
                      disabled={disableEdit}
                      task={task}
                      hover={hover}
                      edit={edit}
                      onEdit={handleOpenTaskDetails}
                      closeEdit={closeEdit}
                      getLine={(line) => setMaxHeight(line >= 3)}
                    />
                  </Stack>
                </Stack>
              </Stack>

              {!edit && <Box bottom={2} left={0} height={50} width={1} />}

              <Stack
                position="absolute"
                bottom={32}
                right={2}
                width="max-content"
                bgcolor={(t) => t.palette.background.default}
              >
                {!edit && <V4TaskJointe task={task} handleClick={handleOpenTaskDetails} />}
              </Stack>

              <Stack width={1} position="absolute" bottom={2} left={0} px={2.5}>
                {!edit && <Divider sx={{ mb: 1 }} />}

                {!edit && (
                  <Stack
                    width={1}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justifyContent="space-between"
                    py={1}
                  >
                    <Assigne task={task} hover disabled={disableEdit} />
                    <Box height={25} width={1} onClick={handleOpenTaskDetails} />
                    {task.priority && (
                      <Priority task={task} handlePriorityChange={handlePriorityChange} hover isNew hideTitle />
                    )}
                    {task?.visibility === TASK_VISIBILITY.PRIVATE && <Visibility task={task} hover hideTitle />}

                    <ProjectIsNotEmptyIcon task={task} hover={disableEdit ? false : hover} />
                    <DateDue task={task} hover disabled={disableEdit} />
                  </Stack>
                )}
              </Stack>

              {!edit && __hover && (
                <Stack position="absolute" top={2} right={2} ref={moreRef}>
                  <CIconButton
                    title="Plus d'action"
                    onClick={openMore}
                    sx={{ bgcolor: 'white', ':hover': { bgcolor: 'white' } }}
                  >
                    <Icon icon="eva:more-horizontal-fill" height={22} width={22} />
                  </CIconButton>
                </Stack>
              )}

              {more && (
                <More
                  task={task}
                  open={more}
                  onClose={closeMore}
                  anchorEl={moreRef.current}
                  onEdit={disableEdit ? undefined : handleEdit}
                  onDone={disableEdit ? undefined : handleCompleted}
                  onOpen={handleOpenTaskDetails}
                  onDelete={disableDelete ? undefined : openDelete}
                  onChangeVisibility={disableEdit ? undefined : handleVisibilityChange}
                  onAddPrioritie={disableEdit ? undefined : (priority) => onAddPrioritie(priority)}
                />
              )}

              {deleteOK && (
                <ActionModal
                  open={deleteOK}
                  onClose={closeDelete}
                  title="Supprimer cette tâche"
                  moreAction={
                    <Stack alignItems="center" direction="row" sx={{ cursor: 'pointer' }} onClick={handleSwitchDelete}>
                      <Checkbox checked={canDelete} />
                      <Typography>Vous êtes certains de vouloir supprimer la tâche : {task?.name} ? </Typography>
                    </Stack>
                  }
                  action={
                    <Button variant="contained" onClick={handleDelete} disabled={!canDelete}>
                      Supprimer
                    </Button>
                  }
                />
              )}
            </Stack>
          </Stack>
        </div>
      )}
    </Draggable>
  );
}

const Name = ({ task, edit, onEdit, closeEdit, getLine, disabled = false }) => {
  const [name, setName] = useState(task?.name);

  useEffect(() => setName(task?.name), [task?.name]);

  const handleChangeName = (e) => {
    const val = e.currentTarget.value;
    setName(val);
  };

  const onClickAway = () => {
    closeEdit();
    if (!isEmpty(name)) {
      dispatch(updateTaskWithoutProject(task, { name }));
    }
  };

  let counter = 0;
  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      if (counter < 1) {
        onClickAway();
        counter += 1;
        return;
      }
    }
  };

  return (
    <>
      {!edit && (
        <Stack onClick={!disabled ? onEdit : undefined} width={1}>
          <TextMaxLine
            fontSize={13}
            fontWeight={600}
            line={3}
            getLine={getLine}
            sx={{ cursor: disabled ? 'default' : 'pointer', whiteSpace: 'pre-line' }}
          >
            {name}
          </TextMaxLine>
        </Stack>
      )}
      {edit && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Stack p={0.5}>
            <MuiCustomInput
              multiline
              maxRows={3.5}
              minRows={3.5}
              value={name}
              disabled={disabled}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleChangeName}
              fullWidth
              size="small"
              sx={{
                py: 0.2,
                fontSize: 13,
                border: 'none'
              }}
            />
          </Stack>
        </ClickAwayListener>
      )}
    </>
  );
};

const useAddToProject = ({ task, disabled = false, isNew = false, onAssigneChange }) => {
  const [assigne, setAssigne] = useState(task?.assignee);
  const [oldAssignee, setOldAssignee] = useState(task?.assignee);
  const { handleOpenAddToProjectConfirmation, currentProject } = useProjectContext();
  const { endTime } = useDatePicker({
    date: dateConverter(task?.due)
  });

  useEffect(() => setAssigne(task?.assignee), [task]);

  const onAssigne = React.useCallback(
    (_assigne) => {
      if (isEqual(_assigne, assigne)) return;
      if (disabled) return; // Ajouté pour désactiver les modifications
      //TODO : ouvrir la confirmation uniquement si l'utilisateur n'est pas dans le projet ou dans la tache
      const isNotInTheProject = [];
      _assigne.forEach((_user) => {
        if (!currentProject?.canAccessId.find((_id) => _id === _user.id)) {
          isNotInTheProject.push({
            ..._user,
            taskRights: { ...V4_DEFAULT_TASK_RIGTH },
            rights: { ...V4_DEFAULT_TASK_RIGTH }
          });
        }
      });

      if (isNotInTheProject.length) {
        handleOpenAddToProjectConfirmation({
          task,
          usersToAdd: isNotInTheProject,
          values: { assignee: _assigne },
          callback: () => {
            if (isNew && onAssigneChange) {
              onAssigneChange(_assigne);
              return;
            }

            dispatch(updateTaskWithoutProject(task, { assignee: _assigne }));
          }
        });
        return;
      }

      //console.log('onAssigne', _assigne);

      setAssigne(_assigne);
      dispatch(updateTaskWithoutProject(task, { assignee: _assigne }));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [task?.id, disabled, assigne]
  );

  return { onAssigne, assigne, endTime };
};
const Assigne = ({ task, hover, onAssigneChange, isNew, disabled = false }) => {
  const { onAssigne, assigne, endTime } = useAddToProject({ task, disabled, isNew, onAssigneChange });

  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ cursor: disabled ? 'default' : 'pointer' }}>
        <ContactsDialog
          action={(popoverRef, onOpen) =>
            !!assigne?.length ? (
              <Stack ref={popoverRef} onClick={disabled ? null : onOpen}>
                <Avatar.Group maxPopoverPlacement="top" size="small" maxCount={assigne?.length > 2 ? 1 : 2}>
                  {assigne?.map((part) => (
                    <Tooltip key={part?.id} title={part?.name} arrow>
                      <Avatar
                        size="small"
                        srcSet={part?.avatar}
                        src={part?.avatar}
                        style={{ backgroundColor: createAvatar(part?.name).color2 }}
                      >
                        {createAvatar(part?.name).name}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Stack>
            ) : (
              <Stack ref={popoverRef}>
                {(hover || endTime) && !disabled && (
                  <CIconButton noBorder title="Ajouter des intervenants" onClick={onOpen}>
                    <Icon icon="fluent:person-add-20-regular" height={15} width={15} />
                  </CIconButton>
                )}
              </Stack>
            )
          }
          assigne={assigne}
          onAssigne={onAssigne}
          task={task}
        />
      </Stack>
    </Stack>
  );
};

const DateDue = ({ task, hover, onDueChange, isNew = false, disabled: _disabled = false }) => {
  const anchorEl = useRef();
  const { now } = useWorldTime();
  const { open, handleOpen, handleClose } = useToggle();
  const [_hover, onHover, endHover] = useToggleV2();
  const { taskRights } = useV4ProjectContext();
  const { handleOPenExtendProjectTime } = useProjectContext();
  const { currentProject: project } = useSelector((state) => state.kanban);
  const auth = useAuth();

  const isAllowed = useMemo(() => {
    if (auth?.user?.id === project?.createdBy?.id) return true;
    if (project?.managers?.map((user) => user?.id).includes(auth?.user?.id)) return true;
    return false;
  }, [auth, project]);

  const disabled = !taskRights?.edit && !isAllowed;
  const dataInterval = useMemo(() => project?.dataInterval, [project?.dataInterval]);

  const handleUpdate = (values) => {
    if (isNew && onDueChange) {
      onDueChange(values);
      return;
    }
    // console.log('values', values);
    dispatch(updateTaskWithoutProject(task, { due: values }));
  };

  const onRemove = () => {
    handleClose();
    handleDueDateChange([null, null]);
    handleUpdate([null, null]);
  };

  const { startTime, endTime, handleDueDateChange, handleCloseExtend, openExtend, tempDate, isLoading, extendDate } =
    useTaskDueDate(task, dataInterval, updateTaskWithoutProject);

  useEffect(() => {
    if (openExtend && !disabled && tempDate && extendDate && !isLoading) {
      handleOPenExtendProjectTime({
        extendOption: {
          tempDate,
          extendDate,
          dataInterval,
          disabled,
          isLoading
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openExtend]);

  return (
    <>
      <Stack sx={{ cursor: disabled ? 'default' : 'pointer' }} ref={anchorEl}>
        {task.due[1] ? (
          <Stack
            direction="row"
            spacing={0.5}
            onMouseEnter={disabled ? null : onHover}
            onMouseLeave={disabled ? null : endHover}
          >
            {_hover && !disabled && (
              <IconButton size="small" sx={{ p: 0.1 }} onClick={onRemove}>
                <Icon height={13} width={13} icon="eva:close-fill" color="red" />
              </IconButton>
            )}
            <Typography
              onClick={disabled ? null : handleOpen}
              color={task.completed ? 'text.disabled' : getDueDateColor(new Date(endTime), now)}
              fontSize={11}
              fontWeight="600"
              sx={{
                ':hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {format(gDate(task.due[1]) || new Date(), 'dd/MM/yy', { locale: LocalFr })}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" width={1} onClick={disabled ? null : handleOpen}>
            {(_hover || hover) && !disabled && (
              <CIconButton noBorder size="small" title="Ajouter une échéance">
                <SvgIconStyle src="/static/icons/calendar.svg" sx={{ height: 13, width: 13 }} />
              </CIconButton>
            )}
          </Stack>
        )}
      </Stack>

      {open && !disabled && (
        <OkyCalendar
          dates={task?.due}
          open={open}
          onClose={handleClose}
          onChange={handleDueDateChange}
          anchorEl={anchorEl.current}
          startTime={startTime}
          endTime={endTime}
          dataInterval={dataInterval}
          disabled={disabled}
        />
      )}

      {/* <ProjectExtendModal
        openExtend={openExtend}
        handleCloseExtend={handleCloseExtend}
        tempDate={tempDate}
        enabledDataInterval={dataInterval}
        extendDate={extendDate}
        isLoading={isLoading}
        disabled={disabled}
      /> */}
    </>
  );
};

const More = ({
  task,
  open,
  onClose,
  anchorEl,
  onEdit,
  onDelete,
  onOpen,
  onDone,
  onAddPrioritie,
  onChangeVisibility
}) => {
  const flagReg = useRef();
  const [openPriority, onOpenPriority, onClosePriority] = useToggleV2();

  const handlePriorityChange = (val) => {
    onAddPrioritie(val);
    onClosePriority();
    onClose();
  };

  return (
    <CustomMenuPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disabledArrow
    >
      <MenuLine
        onClick={onEdit}
        icon="eva:edit-outline"
        title="Modifer le titre de la tâche"
        disabled={onEdit ? false : true}
      />
      <MenuLine
        onClick={onDone}
        icon={!task?.completed ? 'mingcute:check-circle-line' : 'ph:check-circle-fill'}
        title={task?.completed ? 'Marquer comme non terminée' : 'Marquer comme terminée'}
        iconColor={task?.completed ? 'green' : 'inherit'}
        disabled={onDone ? false : true}
      />
      <MenuLine onClick={onOpen} icon="eva:eye-outline" title="Ouvrir les détails" disabled={onOpen ? false : true} />
      <Box ref={flagReg}>
        <MenuLine
          onClick={onOpenPriority}
          icon={isEmpty(task?.priority) ? 'eva:flag-outline' : 'eva:flag-fill'}
          iconColor={priorityColor(task?.priority)}
          title={isEmpty(task?.priority) ? 'Ajouter une priorité' : 'Changer de priorité'}
          disabled={onAddPrioritie ? false : true}
        />
      </Box>
      <MenuLine
        onClick={() =>
          onChangeVisibility(
            task?.visibility === TASK_VISIBILITY.PUBLIC ? TASK_VISIBILITY.PRIVATE : TASK_VISIBILITY.PUBLIC
          )
        }
        icon={task?.visibility === TASK_VISIBILITY.PUBLIC ? 'eva:eye-off-outline' : 'eva:eye-outline'}
        title={task?.visibility === TASK_VISIBILITY.PUBLIC ? 'Rendre privée' : 'Rendre publique'}
        disabled={onChangeVisibility ? false : true}
      />

      <Divider />
      <MenuLine
        onClick={onDelete}
        color={baseColors.REDD}
        icon="eva:trash-outline"
        title="Supprimer la tâche"
        disabled={onDelete ? false : true}
      />
      {openPriority && (
        <PriorityFlags
          value={task?.priority}
          onChange={handlePriorityChange}
          open={openPriority}
          onClose={onClosePriority}
          anchorRef={flagReg}
        />
      )}
    </CustomMenuPopover>
  );
};

export const V4ProjectContentKanbanNewCard = ({ onClose, operation }) => {
  const [task, setTask] = useState(TaskType);
  const { projectId } = useV4ProjectContext();

  const handleChangeName = (e) => {
    const val = e.currentTarget.value;
    setTask((prev) => ({ ...prev, name: val }));
  };

  const onAssigneChange = (val) => {
    setTask((prev) => ({ ...prev, assignee: val }));
  };
  const onDueChange = (val) => {
    setTask((prev) => ({ ...prev, due: val }));
  };

  const handleCreateNewTask = () => {
    const onReset = () => {
      onClose();
    };
    if (!isEmpty(task?.name)) {
      dispatch(
        addTask(
          { card: { ...task, projectKey: projectId, id: nanoid() }, columnId: operation?.id },
          onReset,
          onReset,
          projectId
        )
      );
    }

    onClose();
  };

  let counter = 0;
  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      if (counter < 1) {
        handleCreateNewTask();
        counter += 1;
        return;
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Stack width={1} spacing={0.5}>
        <Stack component={Paper} variant="outlined" width={1} height={105} p={0.5} spacing={1}>
          <MuiCustomInput
            multiline
            maxRows={3}
            minRows={3}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            value={task.name}
            onChange={handleChangeName}
            autoFocus
            fullWidth
            size="small"
            sx={{
              py: 0.2,
              fontSize: 13,
              border: 'none'
            }}
          />
          <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
            <Assigne task={task} hover onAssigneChange={onAssigneChange} isNew />
            <DateDue task={task} hover onDueChange={onDueChange} isNew />
          </Stack>
        </Stack>
        <Button fullWidth size="small" variant="contained" color="info" onClick={handleCreateNewTask}>
          Enregistrer
        </Button>
      </Stack>
    </ClickAwayListener>
  );
};
