import React from 'react';
import { Icon } from '@iconify/react';
import { IconButtonAnimate } from 'src/components/animate';
import { themeColor } from 'src/constants/color';
import { TaskType } from 'src/models/task_m';
import DisplayTime from 'src/components/DisplayTime';
import OkyCalendar from 'src/components/calendar/OkyCalendar';
import ProjectExtendModal from 'src/components/ProjectExtendModal';
import { useTaskDueDate } from 'src/hooks/_tasks/useTaskDueDate';


/**
 * Renders the TaskDueDate component, which displays the due date and time for a task, and provides functionality to open a calendar picker and extend the due date.
 *
 * @param {Object} props - The component props.
 * @param {TaskType} props.task - The task object.
 * @param {function} props.onUpdate - A callback function to update the task.
 * @param {boolean} [props.disabled=false] - Whether the component is disabled.
 * @param {string} [props.enabledDataInterval] - The data interval for which the calendar is enabled.
 * @param {React.ReactNode} [props.customIcon] - A custom icon to be displayed.
 * @param {function} [props.renderCustom] - A custom render function.
 * @returns {React.ReactElement} - The TaskDueDate component.
 */
export default function TaskDueDate({ task, onUpdate, disabled = false, enabledDataInterval = null, customIcon, renderCustom, ...props }) {
  const {
    startTime,
    endTime,
    openPicker,
    onOpenPicker,
    onClosePicker,
    handleDueDateChange,
    handleClosePicker,
    handleCloseExtend,
    isSameDays,
    isSameMonths,
    openExtend,
    tempDate,
    isLoading,
    extendDate,
    onOpenPickerDisabled,
    anchorEl,
  } = useTaskDueDate(task, enabledDataInterval, onUpdate);

  const CalendarIcon = () => (
    <IconButtonAnimate onClick={() => onOpenPickerDisabled(disabled)} sx={{ p: 0.5 }} ref={anchorEl}>
      <Icon icon="clarity:calendar-line" color={themeColor.CARROT_ORANGE} />
    </IconButtonAnimate>
  );

  return (
    <>
      {startTime || endTime ? (
        <DisplayTime
          startTime={startTime}
          endTime={endTime}
          isSameDays={isSameDays}
          isSameMonths={isSameMonths}
          onOpenPicker={() => onOpenPickerDisabled(disabled)}
          sx={{ typography: 'body2' }}
          anchor={anchorEl}
        />
      ) : (
        <CalendarIcon />
      )}

      {!disabled && openPicker && (
        <OkyCalendar
          dates={[startTime, endTime]}
          open={openPicker}
          onClose={handleClosePicker}
          onChange={handleDueDateChange}
          anchorEl={anchorEl.current}
          dataInterval={enabledDataInterval}
          disabled={disabled}
        />
      )}

      <ProjectExtendModal
        openExtend={openExtend}
        handleCloseExtend={handleCloseExtend}
        tempDate={tempDate}
        enabledDataInterval={enabledDataInterval}
        extendDate={extendDate}
        isLoading={isLoading}
        disabled={disabled}
      />
    </>
  );
}
