import { useCallback, useMemo } from 'react';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import useAuth from 'src/hooks/useAuth';
import { Affectation_Setting_model } from 'src/models/Affectation_v4_type';

/**
 * Hook personnalisé pour obtenir le niveau de l'utilisateur et la date de son niveau dans l'organigramme.
 *
 * @returns {{ getMyLevel: (params: { due: Array<any>, setDuedates?: (dates: Array<any>) => void }) => { due: Array<any>, position: number }, myLevelDate: (due: Array<any>, setDuedates?: (dates: Array<any>) => void, defaultPosition?: number) => Date | null }}
 */
export const useGetMyLevel = () => {
  const { user } = useAuth();
  const { settings } = useAffectation_v4Context();

  const organigramme = useMemo(() => settings?.organigramme || [], [settings]);

  /**
   * Obtient le niveau de l'utilisateur dans l'organigramme.
   *
   * @param {{ due: Array<any>, setDuedates?: (dates: Array<any>) => void }} params - Les paramètres pour obtenir le niveau.
   * @returns {{ due: Array<any>, position: number }} Un objet contenant la liste des dates et la position de l'utilisateur.
   */
  const getMyLevel = useCallback(
    ({ due, setDuedates }) => {
      const position = organigramme.findIndex((org) => org.users.includes(user.uid));
      if (!due.length) {
        const initialAssignments = organigramme.map((org) => ({
          label: org.label,
          assigneBy: null,
          assigneTo: null,
          date: null
        }));

        if (setDuedates) {
          setDuedates(initialAssignments);
        }

        return { due: initialAssignments, position: position !== -1 ? position : 0 };
      }

      return { due, position: position !== -1 ? position : 0 };
    },
    [organigramme, user.uid]
  );

  /**
   * Obtient la date du niveau de l'utilisateur.
   *
   * @param {Array<any>} due - Liste des dates dues.
   * @param {(dates: Array<any>) => void} [setDuedates] - Fonction pour définir les dates dues.
   * @param {number} [defaultPosition] - Position par défaut.
   * @returns {Date | null} La date du niveau de l'utilisateur, ou null si aucune date n'est trouvée.
   */
  const myLevelDate = useCallback(
    (due, setDuedates, defaultPosition) => {
      const { due: updatedDue, position } = getMyLevel({ due, setDuedates });

      if (defaultPosition != null) {
        return updatedDue[position + defaultPosition]?.date || null;
      }

      if (updatedDue[position]?.date) {
        return updatedDue[position].date;
      }

      for (let i = position - 1; i == 0; i--) {
        if (due.some((one) => one?.date)) {
          console.log({ i });
        }
        if (updatedDue[i]?.date) {
          return updatedDue[i].date;
        }
      }

      return null;
    },
    [getMyLevel]
  );

  return { getMyLevel, myLevelDate };
};
