import { defaultDb } from 'src/contexts/FirebaseContext';

/**
 * Returns the current server timestamp.
 *
 * @param {boolean} [db=false] - If true, returns the server timestamp for the default Firebase database. Otherwise, returns the server timestamp for the default Firebase Firestore.
 * @returns {number|firebase.firestore.Timestamp} - The current server timestamp.
 */
export const serverTime = (db = false) => {
  if (db) return defaultDb.database.ServerValue.TIMESTAMP;
  return defaultDb.firestore.Timestamp.now();
};
