import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { MAX_TITLE_LENGTH } from 'src/constants/community';

/**
 * Provides a custom validation hook that uses the Yup library to validate form values.
 * The hook includes a `handleValidate` function that validates the form values and displays success or error messages using the `useSnackbar` hook.
 *
 * @returns {object} - An object containing the `handleValidate` function.
 */
const useComunityInfoValidator = () => {
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    title: yup
      .string()
      .required('Le titre ne doit pas être vide.')
      .max(MAX_TITLE_LENGTH, `Le titre doit avoir moins de ${MAX_TITLE_LENGTH} caractères.`)
      .min(1, 'Le titre doit avoir au moins un caractère.'),
    description: yup.string()
  });

  /**
   * Validates the provided `title` and `description` values using the Yup schema defined in the `useComunityInfoValidator` hook.
   * If the validation passes, a success message is displayed using the `useSnackbar` hook.
   * If the validation fails, the first error message is displayed using the `useSnackbar` hook.
   *
   * @param {object} - An object containing the `title` and `description` properties to be validated.
   * @returns {Promise<object>} - A Promise that resolves with the validated data if the validation passes, or rejects with the validation error.
   */
  const handleValidate = async ({ title, description }) => {
    return new Promise((resolve, reject) => {
      schema
        .validate({ title, description }, { abortEarly: false })
        .then((data) => {
          resolve(data);
          enqueueSnackbar('Les modifications ont bien été sauvegardées', { variant: 'success' });
        })
        .catch((err) => {
          enqueueSnackbar(err.errors[0], { variant: 'error' });
          reject(err);
        });
    });
  };

  return { handleValidate };
};

export default useComunityInfoValidator;
