import { IconButton } from '@mui/joy';
import { Box, Button, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import { Avatar } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import ContactsDialog from 'src/components/ContactsDialog';
import { MuiCustomInput } from 'src/components/CustomInput';
import Iconify from 'src/components/Iconify';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useSelector } from 'src/redux/store';
import createAvatar, { getAvatarUrl } from 'src/utils/createAvatar';


/**
 * Renders an individual item in an organigramme (organizational chart) component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The data for the organigramme item.
 * @param {boolean} props.isLast - Indicates if the item is the last one in the organigramme.
 * @param {boolean} props.isFirst - Indicates if the item is the first one in the organigramme.
 * @param {Object[]} props.allUsers - An array of all users in the system.
 * @param {number} props.index - The index of the item in the organigramme.
 * @param {()=>void} props.addLevel - A function to add a new level to the organigramme.
 * @param {(index:number,levelItem:{label:string,users:Array})} props.updateLevel - A function to update the level of the organigramme item.
 * @param {(index:number)=>void|undefined} props.removeLevel - A function to remove a level from the organigramme.
 * @param {boolean} [props.assingation=false] - Indicates if the component is being used for assigning users to the organigramme item.
 * @param {number} [props.defaultPosition=null] - The default position of the organigramme item.
 * @param {boolean} [props.disabled=false] - Indicates if the organigramme item is disabled.
 * @returns {JSX.Element} - The rendered organigramme item.
 */
export default function OrganigrammeItem({
  item,
  isLast,
  isFirst,
  allUsers,
  index,
  addLevel,
  updateLevel,
  removeLevel,
  assingation = false,
  defaultPosition = null,
  disabled = false
}) {
  const [levelItem, setlevelItem] = useState(item);
  const [edit, onEdit, endEdit] = useToggleV2();
  const [hover, onHover, endHover] = useToggleV2();
  const { users } = useSelector((state) => state.user);
  const { settings } = useAffectation_v4Context();

  useEffect(() => setlevelItem(item), [item]);

  const toCompleteUser = useMemo(
    () => levelItem?.users?.map((userId) => users.find((user) => user?.id === userId)),
    [users, levelItem]
  );

  const customList = useMemo(() => {
    if (defaultPosition !== null) {
      const levelUsers = settings?.organigramme[index];
      return levelUsers?.users?.map((userId) => users?.find((user) => user?.id === userId));
    }
    return [];
  }, [defaultPosition, index, settings, users]);

  const handleClick = () => {
    addLevel(index);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setlevelItem((old) => ({ ...old, label: value }));
  };

  const handleKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      updateLevel(index, levelItem);
      endEdit();
    }
  };

  const onClickAway = () => {
    updateLevel(index, levelItem);
    endEdit();
  };

  const handleRemove = () => {
    removeLevel(index);
  };

  const handleAssigne = (users) => {
    const userIds = users?.map((user) => user?.id);
    setlevelItem((old) => ({ ...old, users: userIds }));
    updateLevel(index, { ...levelItem, users: userIds });
  };

  return (
    <>
      <Stack position="relative">
        <Stack
          direction="row"
          borderLeft={(t) => `1px solid ${t.palette.divider}`}
          pt={2}
          sx={{
            ...(isLast && {
              ':before': {
                content: "''",
                position: 'absolute',
                top: 36,
                left: 0,
                bottom: 0,
                width: 2,
                bgcolor: '#FFF'
              }
            }),
            ...(isFirst && {
              ':before': {
                content: "''",
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 48,
                width: 2,
                bgcolor: '#FFF'
              }
            })
          }}
        >
          <Box height={20} width={20} borderBottom={(t) => `1px solid ${t.palette.divider}`} />

          <Stack alignItems="start" spacing={0.5}>
            {item?.label && !edit ? (
              <Stack direction="row" spacing={3} alignItems="center">
                <Stack position="relative" onMouseEnter={onHover} onMouseLeave={endHover}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    disabled={disabled}
                    sx={{ fontSize: 13, width: 200, display: 'flex', justifyContent: 'start' }}
                  >
                    {levelItem.label}
                  </Button>
                  {hover && addLevel && (
                    <Stack direction="row" spacing={1} position="absolute" width={1} justifyContent="flex-end" p={0.5}>
                      <CIconButton
                        size="small"
                        color="info"
                        sx={{ p: 0.2, borderRadius: 0.5, ':hover': { bgcolor: '#FFF' } }}
                        onClick={onEdit}
                        disabled={disabled}
                      >
                        <Iconify icon="eva:edit-fill" sx={{ height: 12, width: 15, color: 'info.main' }} />
                      </CIconButton>
                      <CIconButton
                        size="small"
                        color="error"
                        onClick={handleRemove}
                        sx={{ p: 0.2, borderRadius: 0.5, ':hover': { bgcolor: '#FFF' } }}
                        disabled={disabled}
                      >
                        <Iconify icon="eva:trash-fill" sx={{ height: 12, width: 15, color: 'error.main' }} />
                      </CIconButton>
                    </Stack>
                  )}
                </Stack>

                {!assingation && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    {!!levelItem?.users?.length && (
                      <Avatar.Group maxCount={5}>
                        {levelItem?.users?.map((userId) => {
                          const user = users.find((one) => one?.id === userId);
                          return (
                            <Tooltip key={userId} title={user?.displayName} arrow>
                              <Avatar
                                key={userId}
                                src={getAvatarUrl(user)}
                                style={{
                                  backgroundColor: createAvatar(user?.displayName).color2,
                                  fontWeight: 'bold',
                                  borderRadius: 10,
                                  marginRight: 8
                                }}
                              >
                                {createAvatar(user?.displayName).name}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    )}

                    <ContactsDialog
                      assigne={toCompleteUser}
                      onAssigne={handleAssigne}
                      // CustomList={customList}
                      action={(popoverRef, onOpen) => (
                        <Stack ref={popoverRef}>
                          <CIconButton title={`Ajouter un ${levelItem.label}`} onClick={onOpen}>
                            <Iconify icon="eva:plus-fill" />
                          </CIconButton>
                        </Stack>
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            ) : (
              <ClickAwayListener onClickAway={onClickAway}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <MuiCustomInput
                    size="small"
                    value={levelItem?.label || ''}
                    onKeyUp={handleKeyUp}

                    onChange={handleInputChange}
                    sx={{ p: 0, px: 1, pt: 0.5, fontSize: 13, fontWeight: 'bold' }}
                  />
                  <Tooltip title="Supprimer" arrow>
                    <IconButton size="small" sx={{ p: 0, m: 0, ml: 0.5 }} color="error" onClick={handleRemove}>
                      {!edit && <Iconify icon="eva:close-fill" sx={{ height: 12, width: 12 }} />}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </ClickAwayListener>
            )}
            {!assingation ? (
              <Button
                size="small"
                color="inherit"
                onClick={handleClick}
                startIcon={<Iconify icon="eva:plus-fill" sx={{ height: 10, width: 10 }} />}
                sx={{ fontSize: 10, fontWeight: 600 }}
              >
                Ajouter
              </Button>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                {!!levelItem?.users?.length && (
                  <Avatar.Group maxCount={5}>
                    {levelItem?.users?.map((userId) => {
                      const user = users.find((one) => one?.id === userId);
                      return (
                        <Tooltip key={userId} title={user?.displayName} arrow>
                          <Avatar
                            key={userId}
                            src={getAvatarUrl(user)}
                            style={{
                              backgroundColor: createAvatar(user?.displayName).color2,
                              fontWeight: 'bold',
                              borderRadius: 10,
                              marginRight: 8
                            }}
                          >
                            {createAvatar(user?.displayName).name}
                          </Avatar>
                        </Tooltip>
                      );
                    })}
                  </Avatar.Group>
                )}
                <ContactsDialog
                  assigne={toCompleteUser}
                  onAssigne={handleAssigne}
                  {...(assingation && { CustomList: customList })}
                  action={(popoverRef, onOpen) => (
                    <Stack ref={popoverRef}>
                      <CIconButton title={`Assigner à un ${levelItem.label}`} onClick={onOpen} disabled={disabled}>
                        <Iconify icon="eva:plus-fill" />
                      </CIconButton>
                    </Stack>
                  )}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
