/**
 * Opens a new popup window with the specified URL, window name, and dimensions.
 *
 * @param {string} url - The URL to open in the popup window.
 * @param {string} windowName - The name of the popup window.
 * @param {Window} win - The current window object.
 * @param {number} w - The width of the popup window.
 * @param {number} h - The height of the popup window.
 * @returns {Window} The opened popup window object.
 */
export function popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}