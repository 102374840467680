import { gDate } from 'src/utils/formatTime';
import { DateTime } from 'luxon';

export const PROJECT_COLOR = {
  BLEU1: '#85C4ED',
  BLEU2: '#58ACE3',
  BLEU3: '#3498DB',
  BLEU4: '#0F85D1',
  BLEU5: '#0665A2',

  VERT1: '#65D7C1',
  VERT2: '#3CC7AC',
  VERT3: '#1ABB9B',
  VERT4: '#00A887',
  VERT5: '#008268',

  ORANGE1: '#FFC675',
  ORANGE2: '#F0AD4E',
  ORANGE3: '#DE9226',
  ORANGE4: '#AD6D11',

  ROUGE1: '#FF988E',
  ROUGE2: '#FF7364',
  ROUGE3: '#E74C3C',
  ROUGE4: '#C92918',
  ROUGE5: '#A01607',

  NOIR: '#000000',

  GRIS1: '#464646',
  GRIS2: '#7F7E7E',

  ROSE: '#f9b1f5',

  VIOLET: '#c13ac1',

  JAUNE1: '#f7e438',
  JAUNE2: '#DEC90E',
  JAUNE3: '#AD9C03'
};

export const PROJECT_STATE_FR = {
  IN_PROGRESS: 'En cours',
  SUSPENDED: 'Suspendus',
  CLOSED: 'Clôturés'
};
export const PROJECT_STATE_CONVERT = {
  open: 'En cours',
  suspended: 'Suspendus',
  closed: 'Clôturés'
};

export const PROJECT_STATE = {
  OPEN: 'open',
  SUSPENDED: 'suspended',
  CLOSED: 'closed'
};

export const WORKSPACE_VISIBILITY = {
  PRIVATE: 'Privé',
  PUBLIC: 'Public'
};

export const PROJECT_ADVANCEMENT_STATE = {
  STARTED: 'À démarrer',
  IN_PROGRESS: 'En cours',
  DONE: 'Prêt'
};

export const PROJECT_ADVANCEMENT_STATE_COLOR = {
  [PROJECT_ADVANCEMENT_STATE.STARTED]: 'info',
  [PROJECT_ADVANCEMENT_STATE.IN_PROGRESS]: 'warning',
  [PROJECT_ADVANCEMENT_STATE.DONE]: 'success'
};

export const PROJECT_ADVANCEMENT_STATE_ICON = {
  [PROJECT_ADVANCEMENT_STATE.STARTED]: '/static/icons/project/ic_advancement_play.svg',
  [PROJECT_ADVANCEMENT_STATE.IN_PROGRESS]: '/static/icons/project/ic_advancement_in_progress.svg',
  [PROJECT_ADVANCEMENT_STATE.DONE]: '/static/icons/project/ic_advancement_done.svg'
};

export const PROJECT_STATUS = {
  DISORGANIZED: 'Brouillon',
  CREATED: 'Créé',
  LAUNCH_REQUEST: 'Demande de lancement',
  IN_THE_INITIALIZATION_PHASE: 'In the initialization phase',
  IN_PROGRESS: 'En cours de réalisation',
  UNDER_VALIDATION: 'En cours de validation',
  SUBMITTED_FOR_CLOSURE: 'Soumis pour clôture',
  CLOSED: 'Clôturé',
  SUSPENDED: 'Suspendu',
  CANCELED: 'Annulé'
};

export const PROJECT_STATUS_COLOR = {
  [PROJECT_STATUS.DISORGANIZED]: 'info',
  [PROJECT_STATUS.CREATED]: 'info',
  [PROJECT_STATUS.LAUNCH_REQUEST]: 'warning',
  [PROJECT_STATUS.IN_THE_INITIALIZATION_PHASE]: 'warning',
  [PROJECT_STATUS.IN_PROGRESS]: 'success',
  [PROJECT_STATUS.UNDER_VALIDATION]: 'success',
  [PROJECT_STATUS.SUBMITTED_FOR_CLOSURE]: 'info',
  [PROJECT_STATUS.CLOSED]: 'error',
  [PROJECT_STATUS.SUSPENDED]: 'error',
  [PROJECT_STATUS.CANCELED]: 'error'
};

export const PROJECT_STATUS_COLOR_HEX = {
  [PROJECT_STATUS.DISORGANIZED]: '#dcdcdc',
  [PROJECT_STATUS.CREATED]: '#dcdcdc',
  [PROJECT_STATUS.LAUNCH_REQUEST]: '#f0ad4e',
  [PROJECT_STATUS.IN_THE_INITIALIZATION_PHASE]: '#34b99a',
  [PROJECT_STATUS.IN_PROGRESS]: '#337ab7',
  [PROJECT_STATUS.UNDER_VALIDATION]: '#337ab7',
  [PROJECT_STATUS.SUBMITTED_FOR_CLOSURE]: '#34b99a',
  [PROJECT_STATUS.CLOSED]: '#34b99a',
  [PROJECT_STATUS.SUSPENDED]: '#f0ad4e',
  [PROJECT_STATUS.CANCELED]: '#d9534f'
};

export const CALENDAR_TYPE = {
  FIVE_DAYS_A_WEEK: '5/7',
  SEVEN_DAYS_A_WEEK: '7/7'
};

export const GOAL_STATE = {
  DEFINED: 'Défini',
  REACHED: 'Atteint',
  SUSPENDED: 'Suspendu'
};

export const GOAL_STATE_COLOR = {
  [GOAL_STATE.DEFINED]: 'info',
  [GOAL_STATE.REACHED]: 'success',
  [GOAL_STATE.SUSPENDED]: 'error'
};

export const Delivery_STATE = {
  TO_DELIVER: 'À livrer',
  DELIVERED: 'Livré',
  VALIDATED: 'Validé',
  IN_PROOFREADING: 'En relecture',
  IN_VALIDATION: 'En validation',
  CANCELED: 'Annulé'
};

export const Delivery_STATE_COLOR = {
  [Delivery_STATE.TO_DELIVER]: 'info',
  [Delivery_STATE.DELIVERED]: 'success',
  [Delivery_STATE.VALIDATED]: 'success',
  [Delivery_STATE.IN_PROOFREADING]: 'warning',
  [Delivery_STATE.IN_VALIDATION]: 'warning',
  [Delivery_STATE.CANCELED]: 'error'
};
export const Delivery_STATE_COLOR_HEX = {
  [Delivery_STATE.TO_DELIVER]: '#337ab7',
  [Delivery_STATE.DELIVERED]: '#34b99a',
  [Delivery_STATE.VALIDATED]: '#34b99a',
  [Delivery_STATE.IN_PROOFREADING]: '#f0ad4e',
  [Delivery_STATE.IN_VALIDATION]: '#FFFF00',
  [Delivery_STATE.CANCELED]: '#FF0000'
};

export const Milestone_STATE = {
  TO_DO: 'A faire',
  IN_WAITING: 'En attente',
  IN_PROGRESS: 'En cours',
  FINISHED: 'Terminée',
  CANCELED: 'Annulé'
};

export const Milestone_STATE_COLOR = {
  [Milestone_STATE.TO_DO]: 'info',
  [Milestone_STATE.IN_WAITING]: 'warning',
  [Milestone_STATE.IN_PROGRESS]: 'warning',
  [Milestone_STATE.FINISHED]: 'success',
  [Milestone_STATE.CANCELED]: 'error'
};

export const RISKS_CLASSIFICATION = {
  PLANNING: 'Planning',
  COST: 'Coût',
  TECHNOLOGY: 'Technologie',
  ORGANIZATION: 'Organisation'
};

export const RISKS_REPORT = {
  IN_CHARTER: 'Affichage dans la charte projet',
  IN_FLASH_REPORT: 'Affichage dans les rapports flash'
};

export const RISKS_STATUS = {
  ACTIVE: 'Actif',
  SETS_OFF: 'Déclenché',
  CLOSED: 'Clôturé',
  DELETED: 'Supprimé'
};

export const RISKS_STATUS_COLOR = {
  [RISKS_STATUS.ACTIVE]: 'info',
  [RISKS_STATUS.SETS_OFF]: 'warning',
  [RISKS_STATUS.CLOSED]: 'success',
  [RISKS_STATUS.DELETED]: 'error'
};

export const RISKS_STATUS_COLOR_HEX = {
  [RISKS_STATUS.ACTIVE]: '#337ab7',
  [RISKS_STATUS.SETS_OFF]: '#f0ad4e',
  [RISKS_STATUS.CLOSED]: '#34b99a',
  [RISKS_STATUS.DELETED]: '#d9534f'
};

export const RISKS_PROBABILITY = {
  WEAK: 'Faible',
  AVERAGE: 'Moyen',
  STRONG: 'Fort',
  VERY_STRONG: 'Très fort'
};

export const RISKS_PROBABILITY_COLOR = {
  [RISKS_PROBABILITY.WEAK]: 'success',
  [RISKS_PROBABILITY.AVERAGE]: 'info',
  [RISKS_PROBABILITY.STRONG]: 'error',
  [RISKS_PROBABILITY.VERY_STRONG]: 'error'
};

export const RISKS_PROBABILITY_COLOR_HEX = {
  [RISKS_PROBABILITY.WEAK]: '#34b99a',
  [RISKS_PROBABILITY.AVERAGE]: '#337ab7',
  [RISKS_PROBABILITY.STRONG]: '#d9534f',
  [RISKS_PROBABILITY.VERY_STRONG]: '#FF0000'
};

export const RISKS_IMPACT = {
  ...RISKS_PROBABILITY
};

export const FLASH_REPORT_FORM_SEARCH_RANGE = {
  WEEK: '7 prochains jours',
  HALF_MONTH: '15 prochains jours',
  MONTH: '30 prochains jours',
  CUSTOM: 'Personnalise'
};

export const FLASH_REPORT_FORM_SEARCH_RANGE_DAYS_COUNT = {
  [FLASH_REPORT_FORM_SEARCH_RANGE.WEEK]: 7,
  [FLASH_REPORT_FORM_SEARCH_RANGE.HALF_MONTH]: 15,
  [FLASH_REPORT_FORM_SEARCH_RANGE.MONTH]: 30
};

export const PROJECT_TREND = {
  HIGH: 'Haute',
  MEDIUM: 'Moyene',
  LOW: 'Faible'
};

export const PROJECT_TREND_ICON = {
  [PROJECT_TREND.HIGH]: '/static/icons/project/ic_trend_high.png',
  [PROJECT_TREND.MEDIUM]: '/static/icons/project/ic_trend_medium.png',
  [PROJECT_TREND.LOW]: '/static/icons/project/ic_trend_low.png'
};

export const PROJECT_WEATHER = {
  SUNNY: 'Ensoleillée',
  COVERED_SKY: 'Ciel couver',
  CLOUDY: 'Nuageux',
  RAINY: 'Pluvieux',
  STORMY: 'Orageux'
};

export const PROJECT_WEATHER_HEX = {
  [PROJECT_WEATHER.SUNNY]: '#FFFF00',
  [PROJECT_WEATHER.COVERED_SKY]: '#FFA500',
  [PROJECT_WEATHER.CLOUDY]: '#708090',
  [PROJECT_WEATHER.RAINY]: '#00BFFF',
  [PROJECT_WEATHER.STORMY]: '#696969'
};

export const PROJECT_WEATHER_ICON = {
  [PROJECT_WEATHER.SUNNY]: '/static/icons/project/ic_meteo_sunny.png',
  [PROJECT_WEATHER.COVERED_SKY]: '/static/icons/project/ic_meteo_covered_sky.png',
  [PROJECT_WEATHER.CLOUDY]: '/static/icons/project/ic_meteo_cloudy.png',
  [PROJECT_WEATHER.RAINY]: '/static/icons/project/ic_meteo_rainy.png',
  [PROJECT_WEATHER.STORMY]: '/static/icons/project/ic_meteo_stormy.png'
};

export const ACTION_STATE = {
  OPEN: 'Ouverte',
  IN_PROGRESS: 'En cours',
  SUSPENDED: 'Suspendue',
  CLOSED: 'Cloture',
  CANCELED: 'Annulee'
};

export const ACTION_STATE_COLOR = {
  [ACTION_STATE.OPEN]: 'info',
  [ACTION_STATE.IN_PROGRESS]: 'warning',
  [ACTION_STATE.SUSPENDED]: 'warning',
  [ACTION_STATE.CLOSED]: 'success',
  [ACTION_STATE.CANCELED]: 'error'
};

export const ACTION_STATE_COLOR_HEX = {
  [ACTION_STATE.OPEN]: '#337ab7',
  [ACTION_STATE.IN_PROGRESS]: '#f0ad4e',
  [ACTION_STATE.SUSPENDED]: '#696969',
  [ACTION_STATE.CLOSED]: '#34b99a',
  [ACTION_STATE.CANCELED]: '#FF0000'
};

export const TASK_STATE = {
  ...Milestone_STATE
};

export const TASK_STATE_COLOR = {
  ...Milestone_STATE_COLOR
};

export const PROBLEMS_STATE = {
  OPEN: 'Ouverte',
  IN_PROGRESS: 'En cours',
  SUSPENDED: 'Suspendue',
  RESOLVED: 'Resolue',
  CANCELED: 'Annulee'
};

export const PROBLEMS_STATE_COLOR = {
  [PROBLEMS_STATE.OPEN]: 'info',
  [PROBLEMS_STATE.IN_PROGRESS]: 'warning',
  [PROBLEMS_STATE.SUSPENDED]: 'default',
  [PROBLEMS_STATE.RESOLVED]: 'success',
  [PROBLEMS_STATE.CANCELED]: 'error'
};

export const PROBLEMS_STATE_COLOR_HEX = {
  [PROBLEMS_STATE.OPEN]: '#00BFFF',
  [PROBLEMS_STATE.IN_PROGRESS]: '#FFFF00',
  [PROBLEMS_STATE.SUSPENDED]: '#696969',
  [PROBLEMS_STATE.RESOLVED]: '#34b99a',
  [PROBLEMS_STATE.CANCELED]: '#FF0000'
};

export const CHANGE_STATE = {
  SUBMITTED: 'Soumis',
  IN_EVALUATION: 'En Evaluation',
  IN_ANALYSIS: 'En Analyse',
  REJECTED: 'Rejeté',
  ACCEPTED: 'Accepté',
  DEFERRED: 'Différé'
};

export const WEFT_TYPE = {
  FR_FOLLOW_UP: 'Rapport de suivit (template francais)',
  EN_FOLLOW_UP: 'Follow-up report (template anglais)',
  EMPTY: 'Vide/ empty'
};

export const PROJECT_ACCESS = {
  LEVEL_1: 'Niveau 1 (lecture)',
  LEVEL_2: 'Niveau 2 (lecture)',
  LEVEL_3: 'Niveau 3 (lecture)',
  LEVEL_4: 'Niveau 4 (lecture)',
  LEVEL_5: 'Niveau 5 (Mise a jour)',
  LEVEL_6: 'Niveau 6 (Mise a jour)'
};

export const PROJECT_CHANGE_REQUEST_STATUS = {
  SUBMITTED: 'Soumis',
  ASSESSMENT: 'Evaluation',
  ANALYZE: 'Analyse',
  REJECTED: 'Rejeté',
  APPROVE: 'Accepté',
  DEFERRED: 'Différé'
};

export const PROJECT_CHANGE_REQUEST_STATUS_COLOR = {
  [PROJECT_CHANGE_REQUEST_STATUS.SUBMITTED]: 'info',
  [PROJECT_CHANGE_REQUEST_STATUS.ASSESSMENT]: 'warning',
  [PROJECT_CHANGE_REQUEST_STATUS.ANALYZE]: 'warning',
  [PROJECT_CHANGE_REQUEST_STATUS.REJECTED]: 'error',
  [PROJECT_CHANGE_REQUEST_STATUS.APPROVE]: 'success',
  [PROJECT_CHANGE_REQUEST_STATUS.DEFERRED]: 'error'
};

export const CRITERION_STATES = {
  MUST_HAVE: 'Doit avoir',
  SHOULD_HAVE: 'Devrait avoir',
  MAY_HAVE: 'Peut avoir',
  DOES_NOT_HAVE: "N'aura pas"
};

export const CRITERION_STATES_COLOR = {
  [CRITERION_STATES.MUST_HAVE]: 'default',
  [CRITERION_STATES.SHOULD_HAVE]: 'default',
  [CRITERION_STATES.MAY_HAVE]: 'default',
  [CRITERION_STATES.DOES_NOT_HAVE]: 'default'
};

export const ICON_SIZE = 30;

export const PROJECT_PART_SELECTOR = {
  USER: 'organization',
  TASKS: 'tasks',
  DELIVERABLES: 'deliverables',
  ACTIONS: 'actions',
  PROBLEMS: 'problems'
};

export const TASK_LOAD_HOUR_EQUIVALENT_DAY = 7;

export const TASK_LOAD_FORMAT = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY'
};

//#region print

const formatDate = (date) => {
  return date ? DateTime.fromJSDate(gDate(date)).toFormat('dd/MM/yyyy') : '';
};

export const tasksColumns = [
  { id: 'ref', label: 'Ref', width: '10%' },
  {
    id: 'name',
    label: 'Tâches',
    width: '40%'
    // paragraphs:['description']
  },
  { id: 'startDate', label: 'Debut', width: '10%', render: (date) => formatDate(date) },
  { id: 'endDate', label: 'Fin', width: '10%', render: (date) => formatDate(date) },
  { id: 'state', label: 'Statut', width: '15%' },
  { id: 'lastComment', label: 'Commentaire', width: '15%' }
];

export const orgTasksColumns = [
  { id: 'ref', label: 'Ref', width: '10%' },
  { id: 'name', label: 'Tâches', width: '40%' },
  { id: 'startDate', label: 'Debut', width: '10%', render: (date) => formatDate(date) },
  { id: 'endDate', label: 'Fin', width: '10%', render: (date) => formatDate(date) },
  { id: 'progress', label: '%', width: '15%', render: (progress) => `${progress || 0}%` },
  { id: 'state', label: 'Statut', width: '15%' }
];

export const actionsColumns = [
  { id: 'name', label: 'Nom', width: '20%' },
  { id: 'description', label: 'Description', width: '30%' },
  { id: 'createdAt', label: 'Créé le', width: '10%', render: (date) => formatDate(date) },
  { id: 'targetDate', label: 'Date cible', width: '10%', render: (date) => formatDate(date) },
  { id: 'state', label: 'Statut', width: '10%' },
  { id: 'assigned', label: 'Resp', width: '10%', render: (person) => person?.displayName },
  { id: 'lastComment', label: 'Commentaire', width: '10%' }
];

export const problemsColumns = [
  { id: 'name', label: 'Sujet', width: '25%' },
  { id: 'description', label: 'Description', width: '25%' },
  { id: 'createdAt', label: 'Créé le', width: '10%', render: (date) => formatDate(date) },
  { id: 'state', label: 'Statut', width: '10%' },
  { id: 'assigned', label: 'Resp', width: '10%', render: (person) => person?.displayName },
  { id: 'lastComment', label: 'Commentaire', width: '10%' }
];

export const deliverablesColumns = [
  { id: 'type', label: 'Type' },
  { id: 'name', label: 'Nom' },
  { id: 'lastDateChange', label: 'Date du statut', render: (date) => formatDate(date) },
  { id: 'validationDate', label: 'Date de validation ', render: (date) => formatDate(date) },
  {
    id: 'criterion',
    label: "Critères d'acceptation",
    render: (list) => {
      const checked = list?.filter((one) => one?.isValid)?.length;
      const total = list?.length;
      return `${checked} / ${total}`;
    }
  },
  { id: 'lastComment', label: 'Commentaire' }
];

export const changesColumns = [
  { id: 'name', label: 'Nom' },
  { id: 'description', label: 'Description' },
  { id: 'createdBy', label: 'Resp', render: (person) => person?.displayName },
  { id: 'createdAt', label: 'Créé le', render: (date) => formatDate(date) },
  { id: 'lastStatusChange', label: 'Date cible', render: (date) => formatDate(date) },
  { id: 'state', label: 'Statut' }
];

export const risksColumns = [
  { id: 'name', label: 'Nom' },
  { id: 'description', label: 'Description' },
  { id: 'probability', label: 'Probabilite' },
  { id: 'impact', label: 'Impact' },
  { id: 'response', label: 'Reponse' }
];

//#endregion

export const DAYS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday'
};

export const BILLING_STATE = {
  NOT_INVOICED: 'Non facturée',
  INVOICED: 'Facturée',
  PAYED: 'Payée',
  CANCELLED: 'Annulée'
};
