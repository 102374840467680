import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuLine } from 'src/components/MenuLine';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { addProjectToWorkspace, deleteProject, duplicateProject, selectProject, updateProject, updateProjectFavorite } from 'src/redux/slices/kanban';
import { useToggleV2 } from 'src/hooks/useToggle';
import { PATH_DASHBOARD } from 'src/routes/paths';
import ActionModal from 'src/components/ActionModal';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { getCurrentUserAccess } from 'src/helpers/user';
import ProjectV4Form from './v4/content/ProjectV4Form';
import { useDispatch } from 'react-redux';
import { PROJECT_STATE_VAL } from 'src/pages/dashboard/TaskProjectMyProjects';
import useAuth from 'src/hooks/useAuth';
import { uniq } from 'lodash';


/**
 * Renders a component that provides additional options for a project workspace, including the ability to open, edit, add to favorites, suspend, close, or delete the project.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the menu popover is open.
 * @param {function} props.onClose - A function to close the menu popover.
 * @param {HTMLElement} props.anchorEl - The HTML element to which the menu popover is anchored.
 * @param {Object} props.project - The project object.
 * @param {string} props.userId - The ID of the current user.
 * @param {Object} props.space - The workspace object.
 * @returns {JSX.Element} - The ProjectWorkspaceMoreOption component.
 */
const ProjectWorkspaceMoreOption = ({ open, onClose, anchorEl, project, userId, space }) => {
  //TODO disable delete if not allowed
  const navigate = useNavigate();
  const [edit, onEdit, endEdit] = useToggleV2();
  const [openD, openDelete, closeDelete] = useToggleV2();
  const [deleteOk, setDeleteOK] = useState(false);
  const [pending, setPending] = useState(false);
  const [favorite, setFavorite] = useState(project?.favorite ? project?.favorite[userId] : false);
  const { enqueueSnackbar } = useSnackbar();
  const [question, openQuestion, closeQuestion] = useToggleV2();
  const [notDelete, openNotDelete, closeNotDelete] = useToggleV2();
  const [visibility, openR, closeR] = useToggleV2();
  const [currentState, setCurrentState] = useState(project?.state);
  const [duplicate, setDuplicate] = useState({ gestionaire: false, intervenant: false });
  const dispatch = useDispatch();
  const [visible, display, hide] = useToggleV2();
  const [confirmArchive, setConfirmArchive] = useState(false);
  const user = useAuth();

  const createEmail = useMemo(() => {
    return project?.createBy?.userEmail || project?.createBy?.email;
  }, [project]);

  const modalMessageTitle = useMemo(() => {
    if (currentState === PROJECT_STATE_VAL.CLOSED) {
      return ' voulez-vous cloturer ce projet ?';
    }
    if (currentState === PROJECT_STATE_VAL.IN_PROGRESS) {
      return ' voulez-vous re-ouvrir ce projet ?';
    }

    if (currentState === PROJECT_STATE_VAL.SUSPENDED) {
      return ' voulez-vous suspendre ce projet ?';
    }

    return ' voulez-vous archiver ce projet ?';

  }, [currentState]);

  const modalMessage = useMemo(() => {
    if (currentState === PROJECT_STATE_VAL.CLOSED) {
      return 'Je comprens que une fois cloturer, le projet ne sera plus accessible.';
    }
    if (currentState === PROJECT_STATE_VAL.IN_PROGRESS) {
      return 'Je comprens que une fois ouvert, le projet sera accessible.';
    }

    if (currentState === PROJECT_STATE_VAL.SUSPENDED) {
      return 'Je comprens que une fois suspendu, le projet ne sera plus accessible.';
    }

    return 'Je comprens que une fois archive, le projet ne sera plus accessible.';

  }, [currentState]);

  const onDuplicate = () => {
    openQuestion();
    onClose();
  };

  const addFavorite = () => {
    dispatch(updateProjectFavorite(project.id, !favorite));
    setFavorite((fav) => !fav);
  };

  const handlechange = (e, field) => {
    setDuplicate({ ...duplicate, [field]: e.target.checked });
  };

  const handleDuplicate = () => {
    onClose();
    setPending(true);

    const callback = () => {
      enqueueSnackbar('Projet dupliqué avec succès', { variant: 'info' });
    };

    const stop = () => {
      closeQuestion();
      setPending(false);
    };

    dispatch(duplicateProject({ projectId: project?.id, duplicate, user, callback, stop }));
  };
  const handleRemoveProject = (project) => {
    const projectIds = uniq([...space?.projectIds]?.filter((id) => id !== project?.id));
    dispatch(addProjectToWorkspace({ spaceId: space?.id, projectIds }));
  };
  const onRemove = () => {
    handleRemoveProject(project);
    openNotDelete();
    onClose();
    enqueueSnackbar('Projet retiré avec succès', { variant: 'info' });

  };

  const isClosed = useMemo(() => {
    return project?.state === PROJECT_STATE_VAL.CLOSED || project?.state === PROJECT_STATE_VAL.CLOSED;
  }, [project?.state]);
  const nextClosedSate = useState(() => {
    const { progress = 0 } = project;

    if (progress > 100) return 'suspendu';
    if (progress <= 100) return 'clos';
  }, [project?.progress]);

  const handleArchiveChange = () => {
    let state = currentState
    // let text = state === PROJECT_STATE_VAL.CLOSED ? 'Projet suspendu avec succès' : 'Projet cloturé avec succès'
    let text = ""
    if (currentState === PROJECT_STATE_VAL.CLOSED)
      text = 'Projet cloturé avec succès'
    else if (currentState === PROJECT_STATE_VAL.SUSPENDED)
      text = 'Projet suspendu avec succès'
    else if (currentState === PROJECT_STATE_VAL.IN_PROGRESS)
      text = 'Projet re-ouvert avec succès'
    else
      text = 'Projet archive avec succès'

    dispatch(
      updateProject({ ...project, state }, project, () => {
        enqueueSnackbar(text, { variant: 'info' });
      })
    );
  };
  const handleToggleArchiveState = (state) => {
    setCurrentState(state);
    display();
  }

  const currentUser = getCurrentUserAccess();
  const getCurrentUserRights = () => {
    if (project?.createBy?.id === currentUser?.id) return { delete: true, edit: true, create: true };
    if (project?.canAccessId?.includes(currentUser?.id)) {
      return project?.canAccess?.find((user) => user?.id === currentUser?.id)?.rights ||
        project?.managers?.find((user) => user?.id === currentUser?.id)?.rights
    }
    return { delete: false, edit: false, create: true };
  }

  const currentUserRights = getCurrentUserRights();


  const _userCanDelete = () => {
    if (currentUserRights?.delete) return true;
    return false;
  }

  const userCanDelete = _userCanDelete()

  const openProject = () => {
    dispatch(selectProject(project));
    navigate(`${PATH_DASHBOARD.general.tasks}/${project?.id}`);
    onClose();
  };

  const onEditProject = () => {
    onEdit();
    onClose();
  };

  const onAddToFavorite = () => {
    dispatch(updateProjectFavorite(project.id, !favorite));
    setFavorite((fav) => !fav);
    onClose();
  };

  const handleDelete = () => {
    const callback = () => {
      setPending(false);
      closeDelete();
      enqueueSnackbar('Projet supprimé avec succès', { variant: 'warning' });
      navigate(PATH_DASHBOARD.general.accueil);
    };
    setPending(true);
    dispatch(deleteProject(project.id, callback));
  };

  const onClickDelete = () => {
    openDelete();
    onClose();
  };



  return (
    <>
      <CustomMenuPopover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}

      >
        <MenuLine icon="fluent:open-16-regular" color="black" title="Ouvrir" onClick={openProject} />
        <MenuLine icon="akar-icons:edit" color="black" title="Editer" onClick={onEditProject} />
        <MenuLine
          icon={!favorite ? 'clarity:favorite-line' : 'mdi:favorite-off-outline'}
          iconColor={!favorite ? 'orange' : 'black'}
          color="black"
          title={favorite ? 'Retirer des favoris' : 'Ajouter aux favoris'}
          onClick={addFavorite}
        />


        <Divider light />

        <MenuLine
          hide={project?.state === PROJECT_STATE_VAL.SUSPENDED || project?.state === PROJECT_STATE_VAL.CLOSED}
          title={'Suspendre'}
          icon={'fluent:pause-16-regular'}
          onClick={() => handleToggleArchiveState(PROJECT_STATE_VAL.SUSPENDED)}
        />
        <MenuLine
          hide={project?.state === PROJECT_STATE_VAL.CLOSED}
          title={'Cloturer'}
          icon={'fluent:stop-16-regular'}
          onClick={() => handleToggleArchiveState(PROJECT_STATE_VAL.CLOSED)}
        />
        <MenuLine
          hide={project?.state === PROJECT_STATE_VAL.IN_PROGRESS}
          title={'Réactiver'}
          icon={'fluent:play-16-regular'}
          onClick={() => handleToggleArchiveState(PROJECT_STATE_VAL.IN_PROGRESS)}
        />

        <Divider light />
        <Divider />
        {onRemove && <MenuLine title="Retirer" fontWeight="bold" icon="ep:remove-filled" color="red" onClick={openR} />}
        <MenuLine icon="tabler:trash" color="red" title="Supprimer" onClick={onClickDelete} disabled={!userCanDelete} />

        {/* <MenuLine
          title="Supprimer"
          fontWeight="bold"
          icon="eva:trash-fill"
          color="red"
          onClick={canDelete ? handleDelete : openNotDelete}
        /> */}
      </CustomMenuPopover>
      {edit && <ProjectV4Form open={edit} onClose={endEdit} edit={project} />}
      {openD && (
        <ActionModal
          title="Supprimer ce projet ?"
          desc="Cette action ne peut pas être annulée"
          color="error"
          open={openD}
          onClose={closeDelete}
          moreAction={
            <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
              <Checkbox
                size="small"
                color="error"
                checked={deleteOk}
                onChange={(e) => setDeleteOK(e.currentTarget.checked)}
              />
              <Typography fontSize={14} fontWeight={120} onClick={() => setDeleteOK(!deleteOk)}>
                Je comprends qu'une fois supprimé, ce projet ne peut pas être récupéré.
              </Typography>
            </Stack>
          }
          action={
            <LoadingButton
              loading={pending}
              color="error"
              variant="contained"
              disabled={!deleteOk}
              onClick={handleDelete}
            >
              Supprimer
            </LoadingButton>
          }
        />
      )}
      {visible && (
        <ActionModal
          title={modalMessageTitle}
          color="error"
          open={visible}
          onClose={hide}
          moreAction={
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                size="small"
                color="error"
                checked={confirmArchive}
                onChange={(e) => setConfirmArchive(e.target.checked)}
              />
              <Typography fontSize={13} fontWeight={120}>
                {modalMessage}
              </Typography>
            </Stack>
          }
          action={
            <Button color="error" variant="contained" disabled={!confirmArchive} onClick={handleArchiveChange}>
              Appliquer
            </Button>
          }
        />
      )}

      {visibility && (
        <ActionModal
          title="Retier ce projet?"
          color="error"
          open
          onClose={closeR}
          desc="Êtes vous, certain de vouloir retirer le projet de cet espace ?"
          action={
            <Button color="error" variant="contained" onClick={onRemove}>
              Appliquer
            </Button>
          }
        />
      )}
      {question && (
        <ActionModal
          title="Duplication de projet"
          open={question}
          onClose={closeQuestion}
          color="info"
          desc="Voulez-vous dupliquer ce projet avec ces gestionnaires et ou intervenants ?"
          moreAction={
            <Stack pl={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={duplicate.gestionaire} onChange={(e) => handlechange(e, 'gestionaire')} />
                  }
                  label="Avec les gestionnaires"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={duplicate.intervenant} onChange={(e) => handlechange(e, 'intervenant')} />
                  }
                  label="Avec les intervenants"
                />
              </FormGroup>
            </Stack>
          }
          action={
            <LoadingButton loading={pending} variant="contained" color="info" onClick={handleDuplicate}>
              Dupliquer
            </LoadingButton>
          }
        />
      )}
    </>
  );
}


export default ProjectWorkspaceMoreOption;