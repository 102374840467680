import { createSlice, nanoid } from '@reduxjs/toolkit';
import { firestore, auth } from 'src/contexts/FirebaseContext';
import { hasAccountExpired } from 'src/contexts/MicrosoftMailContext';
import axios from 'axios';
import { MAIL_FLAG, MAIL_FOLDER, useMailContext } from 'src/section/mail/MailContext';
import { keys, uniq, isFunction, isEmpty } from 'lodash';
import { CLOUD_MAIL_API_KEY, CLOUD_MAIL_CUSTOM_API_KEY } from 'src/config';
import Timer from 'src/helpers/timer';
import Mail from 'src/section/mail_v2/models/Mail';
import { refreshLinkedAccount } from 'src/redux/slices/microsoftMail';
import axiosRequest from 'src/utils/axiosRequest';
import { MAIL_TIMER } from 'src/helpers/mailTimer';
import { DEFAULT_MAIL_FOLDERS, KEYS_FOLDERS, MAIL_PLATFORM, checkFolders } from 'src/constants/mail';

const initialState = {
  accounts: {},
  folders: {},
  mails: {},
  loading: [],

  pagination: {
    total: 0,
    per_page: 50
  },
  mailByUID: {},

  refresher: {
    callback: null,
    lastUpdated: null,
    count: 0
  },
  notify: (mail) => {}
};

const slice = createSlice({
  name: 'custom-mail',
  initialState,
  reducers: {
    loadingByUID: (state, action) => {
      const { folder, messageUID, loading, mail } = action.payload;

      if (state.mailByUID[folder]) {
        state.mailByUID[folder][messageUID] = { loading, mail };
      } else {
        state.mailByUID[folder] = { [messageUID]: { loading, mail } };
      }
    },

    updatePagination: (state, action) => {
      const { folder, totals, mailLoaded } = action.payload;
      state.pagination[folder] = mailLoaded;
      state.pagination.total = totals;
    },

    setNotifyCallback: (state, action) => {
      state.notify = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = {
        ...state.accounts,
        ...action.payload
      };
    },
    setFolders: (state, action) => {
      state.folders = {
        ...state.folders,
        ...action.payload
      };
    },
    setMailsInFolders: (state, action) => {
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mails = action.payload.mails;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [folder]: {
            ...(state.mails[email]?.[folder] || {}),
            ...mails
          }
        }
      };
    },
    removeMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;

      delete ((copy[email] || {})[folder] || {})[mailId];

      state.mails = copy;
    },

    updateMailFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const nextFolder = action.payload.nextFolder;

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      console.log(
        'updateMailFolder',
        mail,
        'copy',
        copy,
        'email',
        email,
        'folder',
        folder,
        'mailId',
        mailId,
        'nextFolder',
        nextFolder
      );

      if (!mail) return;

      delete ((copy[email] || {})[folder] || {})[mailId];

      // push to next folder and update folder property
      //uodate folder property
      mail.folder = nextFolder;

      copy[email][nextFolder] = {
        ...copy[email][nextFolder],
        [mailId]: mail
      };

      state.mails = copy;
    },

    setDrafts: (state, action) => {
      const email = action.payload.email;
      const drafts = action.payload.drafts;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [MAIL_FOLDER.DRAFTS]: {
            ...((state.mails[email] || {})[MAIL_FOLDER.DRAFTS] || {}),
            ...drafts
          }
        }
      };
    },

    deleteDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const copy = { ...state.mails };

      delete ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      state.mails = copy;
    },

    updateDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const values = action.payload.values;

      const copy = { ...state.mails };

      const draft = ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      if (!draft) return;

      Object.assign(draft, { ...values });

      state.mails = copy;
    },

    updateMailFlag: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const oldFlag = action.payload.oldFlag;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const flag = action.payload.flag;

      //email.flags= ['unseen', 'flagged', 'answered', 'draft', 'recent']

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      // add flag to mail.flags but if it already exist remove it
      if (mail.flags.includes(flag)) {
        mail.flags = mail.flags.filter((el) => el !== flag);
      } else if (flag.length === 0 && oldFlag.length !== 0) {
        mail.flags = mail.flags.filter((el) => el !== oldFlag);
      } else if (flag.length !== 0 && oldFlag.length === 0) {
        mail.flags = [...mail.flags, flag];
      } else {
        mail.flags = [...mail.flags, flag];
      }

      state.mails = copy;
    },

    updateMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const values = action.payload.values;

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      Object.assign(mail, { ...values });

      state.mails = copy;
    },
    setLoading: (state, action) => {
      state.loading = uniq([...state.loading, ...action.payload]);
    },
    revokeLoading: (state, action) => {
      state.loading = state.loading?.filter((key) => !action.payload.includes(key));
    },
    removeAccount: (state, action) => {
      delete state.accounts[action.payload];
      delete state.folders[action.payload];
      delete state.mails[action.payload];
    },
    reduceUnreadMailCount: (state, action) => {
      const email = action.payload;
      const cursor = (state.folders[email] || {})[MAIL_FOLDER.INBOX];
      const prev = cursor?.unreadItemCount || 0;
      const unreadItemCount = prev - 1 < 0 ? 0 : prev - 1;
      const result = {
        ...state.folders,
        [email]: {
          ...state.folders[email],
          [MAIL_FOLDER.INBOX]: {
            ...cursor,
            unreadItemCount
          }
        }
      };

      state.folders = result;
    },
    updateMailRefresher: (state, action) => {
      if (isFunction(state.refresher.callback)) {
        state.refresher.callback();
      }

      state.refresher = {
        callback: action.payload,
        lastUpdated: new Date(),
        count: state.refresher.count + 1
      };
    }
  }
});

export const {
  setAccounts,
  setFolders,
  setMailsInFolders,
  setLoading,
  revokeLoading,
  removeMailsInFolder,
  updateMailsInFolder,
  removeAccount,
  reduceUnreadMailCount,
  updateMailRefresher,
  setNotifyCallback,
  updateMailFolder,
  setDrafts,
  deleteDraft,
  updateDraft,
  updateMailFlag
} = slice.actions;

const customMailReducer = slice.reducer;

export default customMailReducer;

/**
 * Authenticates a custom email account and dispatches actions to set the account data.
 *
 * @param {Object} data - The data required for authentication, including the user's email.
 * @param {Function} [onResolve] - An optional callback function to be called on successful authentication.
 * @param {Function} [onReject] - An optional callback function to be called on authentication failure.
 * @returns {Function} - A thunk function that can be dispatched to perform the authentication.
 */
export const authCustomMail = (data, onResolve, onReject) => {
  return async (dispacth) => {
    try {
      const snap = await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/signin`, data);

      const result = {
        [data?.user?.email]: {
          ...snap.data,
          email: data?.user?.email
        }
      };
      // console.log({ data });
      dispacth(igniteCustomAccount({ accountSnap: result }));

      onResolve && onResolve(snap.data);
    } catch (e) {
      onReject && onReject(e?.error);
    }
  };
};
/**
 * Persists a token for a Microsoft Mail account.
 *
 * @param {Object} params - The parameters for persisting the token.
 * @param {string} params.email - The email address of the account.
 * @param {Object} params.result - The token result object.
 * @param {string} params.userId - The ID of the user.
 * @returns {Function} A thunk function that dispatches actions to update the account data.
 */
export const persistToken = ({ email, result, userId }) => {
  return async (dispatch, getStore) => {
    try {
      const accounts = getStore()?.microsoftMail?.accounts || {};
      const isExpired = hasAccountExpired(accounts[email]);
      // console.log("accounts", accounts)
      // console.log("isExpired", isExpired)

      if (isExpired) {
        await firestore.runTransaction(async (transaction) => {
          const docRef = firestore.collection('users').doc(userId);
          const snap = await transaction.get(docRef);
          const linkedAccount = {
            ...(snap?.data()?.linkedAccount || {}),
            ...result
          };
          // console.log('result save', linkedAccount)
          transaction.update(docRef, { linkedAccount });
          dispatch(setAccounts(result));
          dispatch(
            getAccountFolders({
              account: result[email],
              onResolve: () => {
                const account = result[email];
                // console.log({ account });
                dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
              }
            })
          );
        });
      }
    } catch (error) {}
  };
};

/**
 * Ignites a custom account by dispatching actions to set the account data and refresh the token.
 * It also dispatches actions to get the account folders and retrieve the inbox mails.
 *
 * @param {Object} accountSnap - The snapshot of the account data.
 * @returns {Function} A thunk function that dispatches the necessary actions.
 */
export const igniteCustomAccount = ({ accountSnap }) => {
  return (dispatch, getStore) => {
    try {
      const snap = getStore()?.customMail?.accounts || {};

      dispatch(setAccounts(accountSnap));

      keys(accountSnap).forEach((email) => {
        if (snap[email]) return;

        const account = accountSnap[email];
        dispatch(
          refreshToken({
            account,
            callback: (account) => {
              dispatch(
                getAccountFolders({
                  account,
                  onResolve: () => {
                    setTimeout(() => {
                      dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
                    }, [2000]);
                  }
                })
              );
            }
          })
        );
      });
    } catch (error) {}
  };
};

/**
 * Updates the account data for the specified email address.
 *
 * @param {Object} params - The parameters for updating the account data.
 * @param {string} params.email - The email address of the account to update.
 * @param {Object} params.data - The new data to update the account with.
 * @param {string} params.userId - The user ID associated with the account.
 * @returns {Function} - A thunk function that dispatches actions to update the account data.
 */
export const updateAccountData = ({ email, data, userId }) => {
  return async (dispatch) => {
    try {
      await axios.patch(
        `${CLOUD_MAIL_API_KEY}/account`,
        {
          ...data
        },
        {
          headers: {
            userid: auth.currentUser.uid
          }
        }
      );

      const account = { [email]: data };

      dispatch(setAccounts(account));
      dispatch(refreshLinkedAccount({}));
    } catch (e) {}
  };
};

/**
 * Updates the folder of a custom mail message.
 *
 * @param {Object} account - The account object containing the token and user information.
 * @param {string} messageUID - The unique identifier of the mail message.
 * @param {string} mailUID - The unique identifier of the mail.
 * @param {string} currentFolder - The current folder of the mail message.
 * @param {string} nextFolder - The new folder to move the mail message to.
 * @param {function} [callback] - An optional callback function to be executed after the update is successful.
 * @param {function} [onError] - An optional error callback function to be executed if the update fails.
 * @returns {function} - A thunk function that dispatches actions to update the mail folder.
 */
export const updateCustomMailsOnFolder =
  ({ account = {}, messageUID, mailUID, currentFolder, nextFolder, callback, onError }) =>
  async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      await axiosRequest.put('mail/webmail/update', {
        messageUID: mailUID,
        currentFolder,
        nextFolder,
        token,
        platform: 'custom'
      });
      if (callback) callback();
    } catch (error) {
      onError && onError();
    }
  };

/**
 * Updates the folder of a custom mail message.
 *
 * @param {Object} account - The account object containing the token and user information.
 * @param {string} messageUID - The unique identifier of the mail message.
 * @param {string} mailUID - The unique identifier of the mail.
 * @param {string} currentFolder - The current folder of the mail message.
 * @param {string} nextFolder - The new folder to move the mail message to.
 * @param {function} [callback] - An optional callback function to be executed after the update is successful.
 * @param {function} [onError] - An optional error callback function to be executed if the update fails.
 * @returns {function} - A thunk function that dispatches actions to update the mail folder.
 */
export const updateCustomMailFolder = ({
  account = {},
  messageUID,
  mailUID,
  currentFolder,
  nextFolder,
  callback,
  onError
}) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      // console.log({ account, messageUID, mailUID, currentFolder, nextFolder });

      dispatch(
        updateMailFolder({
          email: user?.email,
          folder: currentFolder,
          mailId: messageUID,
          nextFolder
        })
      );


      if (callback) callback();
    } catch (error) {
      onError && onError();
      const { token, user } = account;
      dispatch(
        updateMailFolder({
          email: user?.email,
          folder: nextFolder,
          mailId: messageUID,
          nextFolder: currentFolder
        })
      );
    }
  };
};

//TODO: updateCustomMailsOnFolder on local

/**
 * Generates a unique identifier with a minimum length of 30 characters using the nanoid library.
 *
 * @returns {string} A unique identifier with a minimum length of 30 characters.
 */
const generatId = () => {
  //minimum 30 with nanoid
  const id = nanoid(30);
  return id;
};

/**
 * Formats the email data from the email composer into a mail object.
 *
 * @param {Object} account - The account information, including the token and user details.
 * @param {Object} email - The email object containing the draft message details.
 * @returns {Promise<Object>} - A formatted mail object.
 */
const buildMessage = async ({ account, email }) => {
  const { token, user } = account;
  const to = email?.to?.split(',') || [];
  const cc = email?.cc?.split(',') || [];
  const cci = email?.cci?.split(',') || [];
  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const transformFileToBase64 = async (file) => {
    const base64 = await convertFileToBase64(file);
    const contentBytes = base64.split(',')[1];
    // console.log( {contentBytes});
    return contentBytes;
  };

  const formatFiles = async (files) => {
    const promises = files.map(async (file) => {
      //si le fichier n'est pas un blob on retourne un vide
      if (!(file instanceof Blob)) {
        return;
      }
      return {
        filename: file.name,
        content: await transformFileToBase64(file),
        contentType: file.type
      };
    });
    return Promise.all(promises);
  };

  const formatedFiles = await formatFiles(email?.files || []);

  //TODO : adapter le model de mail
  const message = {
    from: { email: user?.email, name: user?.displayName },
    to: to,
    id: generatId(),
    cc: cc,
    cci: cci,
    subject: email?.subject,
    sendDate: new Date().toISOString(),
    customFlag: 'MonDrapeauPersonnalise',
    text: email?.body || "Corps de l'e-mail au format texte.\nCela peut être un message multiligne.",
    textAsHtml: `<p>${email?.body || "Corps de l'e-mail au format texte.\nCela peut être un message multiligne"}</p>`,
    attachments: formatedFiles
  };

  // const formatedMail = new Mail({});
  const formatedMail = new Mail({
    id: message?.id,
    from: message?.from,
    to: message?.to,
    cc: message?.cc,
    cci: message?.cci,
    subject: message?.subject,
    body: message?.text,
    sentDate: new Date(),
    isRead: false,
    text: message?.text,
    textAsHtml: message?.textAsHtml,
    isImportant: false,
    hasAttachments: false,
    orginalMail: {},
    meetingMessageType: null,
    startDateTime: null,
    endDateTime: null
  });

  return Promise.resolve(formatedMail);
};

/**
 * Saves a draft email message to the server.
 *
 * @param {Object} account - The account information, including the token and user details.
 * @param {Object} email - The email object containing the draft message details.
 * @param {function} onResolve - A callback function to be called on successful save.
 * @param {function} onReject - A callback function to be called on failed save.
 * @returns {function} - A thunk function that dispatches the save draft action.
 */
export const saveToDraft = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      // console.log('Save to draft', account, email);
      const { token, user } = account;

      const message = await buildMessage({ account, email });

      // console.log('Save to draft', message);
      

      if (!token) return account;

      dispatch(
        setDrafts({
          email: user?.email,
          drafts: {
            [message?.id]: {
              ...message
            }
          }
        })
      );

      await axiosRequest.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/save-draft`, {
        ...message,
        token,
        platform: MAIL_PLATFORM.CUSTOM,
        account: account
      });
      // console.log('Save to draft');
      if (onResolve) onResolve();
    } catch (error) {
      // console.log(error);
      if (onReject) onReject();
    }
  };
};

/**
 * Formats the data from the email composer into a mail object.
 *
 * @param {Object} data - The data from the email composer.
 * @param {Array<string>} data.to - The email addresses in the "To" field.
 * @param {Array<string>} data.cc - The email addresses in the "Cc" field.
 * @param {Array<string>} data.cci - The email addresses in the "Bcc" field.
 * @param {string} data.subject - The subject of the email.
 * @param {string} data.body - The body of the email.
 * @param {Array<Object>} data.files - The files attached to the email.
 * @param {string} data.createdAt - The creation timestamp of the email.
 * @param {string} data.id - The ID of the email.
 * @returns {Object} - The formatted mail object.
 */
const fomatComposerDataToMail = (data) => {
  const { to, cc, cci, subject, body, files, createdAt, id } = data;

  const mail = {
    to:
      to?.map((el) => {
        return { name: el, email: el };
      }) || [],
    cc:
      cc?.map((el) => {
        return { name: el, email: el };
      }) || [],
    cci:
      cci?.map((el) => {
        return { name: el, email: el };
      }) || [],
    subject,
    body,
    files,
    createdAt,
    id
  };

  return mail;
};

{
  /* </k58R4sih29C9YTS-HrCZXHeU8hIBWA>
  << k58R4sih29C9YTS - HrCZXHeU8hIBWA >> */
}

/**
 * Formats an email ID by removing the surrounding angle brackets.
 *
 * @param {string} id - The email ID to be formatted.
 * @returns {string} - The formatted email ID without the angle brackets.
 */
const formatIdToSent = (id) => {
  // chnage <k58R4sih29C9YTS-HrCZXHeU8hIBWA> to k58R4sih29C9YTS-HrCZXHeU8hIBWA
  const result = id.replace('<', '').replace('>', '');
  return result;
};

/**
 * Updates a draft email message.
 *
 * @param {Object} account - The account information, including the token and user details.
 * @param {Object} email - The email object containing the draft message details.
 * @param {function} onResolve - A callback function to be called on successful update.
 * @param {function} onReject - A callback function to be called on update failure.
 * @returns {Promise} - A promise that resolves when the draft email is updated.
 */
export const updateDraftMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      const _message = await buildMessage({ account, email });

      const message = { ..._message, id: formatIdToSent(email?.id) };

      if (!token) return account;

      dispatch(
        updateDraft({
          email: user?.email,
          draftId: email?.id,
          values: {
            ...fomatComposerDataToMail(email)
          }
        })
      );

      await axiosRequest.post('mail/webmail/draft', { message, token, platform: 'custom', account: account });
      if (onResolve) onResolve();
    } catch (error) {
      if (onReject) onReject();
    }
  };
};

/**
 * Deletes a draft email message.
 *
 * @param {Object} account - The account information, including the token and user details.
 * @param {string} email - The email object containing the draft message details.
 * @param {function} onResolve - A callback function to be called on successful deletion.
 * @param {function} onReject - A callback function to be called on deletion failure.
 * @returns {Promise} - A promise that resolves when the draft email is deleted.
 */
export const deleteDraftMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      const message = buildMessage({ account, email });

      if (!token) return account;

      dispatch(
        deleteDraft({
          email: user?.email,
          draftId: email?.id
        })
      );

      //await axiosRequest.post('mail/webmail/draft', { message, token, platform: 'custom' });
      if (onResolve) onResolve();
    } catch (error) {
      if (onReject) onReject();
    }
  };
};

/**
 * Formats a flag by removing the backslash character from it.
 *
 * @param {string} flag - The flag to be formatted.
 * @returns {string} The formatted flag with the backslash character removed.
 */
const formatFlagForSent = (flag) => {
  // remove \ from flag
  const result = flag.replace('\\', '');
  return result;
};

/**
 * Updates the flag for a mail message.
 *
 * @param {Object} account - The account information, including the token and user details.
 * @param {string} mailId - The ID of the mail message.
 * @param {string} mailUID - The unique identifier of the mail message.
 * @param {string} currentFlag - The current flag for the mail message.
 * @param {string} currentFolder - The current folder of the mail message.
 * @param {string} nextFlag - The new flag to be set for the mail message.
 * @param {function} onResolve - A callback function to be called on successful update.
 * @param {function} onReject - A callback function to be called on update failure.
 * @returns {Promise} - A promise that resolves when the mail flag is updated.
 */
export const updateFlag = ({
  account = {},
  mailId,
  mailUID,
  currentFlag,
  currentFolder,
  nextFlag,
  onResolve,
  onReject
}) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;
      dispatch(
        updateMailFlag({
          email: account?.user?.email,
          folder: currentFolder,
          mailId: mailId,
          flag: nextFlag,
          oldFlag: currentFlag
        })
      );

      const params = {
        messageUID: mailUID,
        token,
        currentFlag: formatFlagForSent(currentFlag),
        nextFlag: formatFlagForSent(nextFlag),
        platform: MAIL_PLATFORM.CUSTOM,
        currentFolder
      };

      axiosRequest.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/update-flag`, params);

      if (onResolve) onResolve();
    } catch (error) {
      onReject && onReject();

      dispatch(
        updateMailFlag({
          email: account?.user?.email,
          folder: currentFolder,
          mailId: mailId,
          flag: nextFlag,
          oldFlag: currentFlag
        })
      );
    }
  };
};

/**
 * Sends an email using the custom mail platform.
 *
 * @param {Object} account - The account object containing the user's email and token.
 * @param {Object} email - The email object containing the message details.
 * @param {Function} onResolve - A callback function to be called on successful completion.
 * @param {Function} onReject - A callback function to be called on error.
 * @returns {Function} - A thunk function that can be dispatched to send the email.
 */
export const sendMsMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      const { customMail } = getStore();

      const folders = customMail?.folders || {};
      const folderId = folders?.[account?.user?.email]?.[MAIL_FOLDER.SENT]?.id;

      console.log({ folderId });

      const files = [...(email?.files || [])];
      const formated = [];

      // console.log({files});

      const inReplyTo = account?.userId;

      for (const file of files) {
        const contentBytes = await transformFileToBase64(file);
        // console.log(file);
        formated.push({
          filename: file?.name,
          content: contentBytes,
          mime_type: file?.type
        });
      }

      const SENT_BOX = folderId || 'Sent';

      let result = {
        ...email,
        files: formated,
        platform: 'custom',
        token: account?.token,
        id: generatId(),
        sentBox: SENT_BOX
      };

      // console.log({result});

      // if is not reply remove replyTo but if is reply add replyTo and  inReplyTo
      if (email?.replyTo?.length > 0) {
        result = {
          ...result,
          replyTo: email?.replyTo,
          inReplyTo
        };
      } else {
        delete result.replyTo;
      }

      // console.log({result,formatedMail: formatMailToSent(result)});

      await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/send-mail`, result);

      const formatMailToSent = (result) => {
        const message = {
          from: { email: account?.user?.email, name: account?.user?.displayName },
          // to: [{ email: email?.to, name: email?.to }],
          to: result?.to?.map((el) => {
            return { email: el, name: el };
          }),
          id: generatId(),
          cc: result?.cci?.map((el) => {
            return { email: el, name: el };
          }),
          cci: result?.cci?.map((el) => {
            return { email: el, name: el };
          }),
          subject: result?.subject,
          // customFlag: 'MonDrapeauPersonnalise',
          text: result?.body,
          textAsHtml: `<p>${result?.body || ''}</p>`,
          attachments: formated,
          body: result?.body,
          sentDate: new Date()
        };

        return message;
      };

      //const mail = await buildMessage({ account, email });

      // dispatch(
      //   setMailsInFolders({
      //     account: account,
      //     mails: [formatMailToSent(result)],
      //     folder: MAIL_FOLDER.SENT
      //   })
      // );

      dispatch(getMails({ account, folderType: folderId,refreshDate:createRefrecheDate() }));
      // console.log({ result, account, email, formatMailToSent: formatMailToSent(result) });
      onResolve && onResolve();
    } catch (error) {
      onReject && onReject();
      console.log({error});
    }
  };
};

/**
 * Retrieves the email folders for the given account, including the inbox, sent, trash, and spam folders.
 *
 * @param {Object} account - The account object containing the user's email and token.
 * @param {Function} onResolve - A callback function to be called on successful completion.
 * @param {Function} onReject - A callback function to be called on error.
 * @returns {Function} - A thunk function that can be dispatched to retrieve the email folders.
 */
export const getAccountFolders = ({ account, onResolve, onReject }) => {
  return async (disptach) => {
    const utils = new LoadingUtil(disptach, `getAccountFolders-${account?.user?.email} `);

    try {
      disptach(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;
            utils.start();

            const snap = await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/folders`, {
              token,
              platform: 'custom'
            });

            const value = snap.data || DEFAULT_MAIL_FOLDERS;

            let data = {};

            const result = checkFolders(value, KEYS_FOLDERS);

            Object.entries(MAIL_FOLDER).map(([key, val]) => {
              const id = result[key]?.at(0);

              if (!id) return;

              data = {
                ...data,
                [val]: { ...value[id], id: id }
              };
            });

            disptach(
              setFolders({
                [user?.email]: data
              })
            );

            utils.end();
            onResolve && onResolve(data);
          }
        })
      );
    } catch (error) {
      onReject && onReject();
      utils.end();
    }
  };
};

/**
 * Retrieves a specific email message by its unique identifier (UID) for the given account and folder.
 *
 * @param {Object} account - The account object containing the user's email and token.
 * @param {string} folderType - The type of email folder to retrieve the message from (e.g. 'inbox', 'sent', 'drafts').
 * @param {string} messageUID - The unique identifier of the email message to retrieve.
 * @param {Function} onReject - A callback function to be called on error.
 * @param {Function} onResolve - A callback function to be called on successful completion.
 * @returns {Function} - A thunk function that can be dispatched to retrieve the email message.
 */
export const getMailByUId = ({ account = {}, folderType, messageUID, onReject, onResolve }) => {
  return async (dispatch, getStore) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      const { customMail } = getStore();

      const folders = customMail?.folders || {};
      const folderId = folders[account?.user?.email][folderType]?.id;

      const getApiFolderType = (folderType) => {
        if (folderType === MAIL_FOLDER.ARCHIVE) return MAIL_FOLDER.INBOX;
        return folderType;
      };

      const currentFolder = getApiFolderType(folderType) || folderId;

      dispatch(slice.actions.loadingByUID({ folder: currentFolder, messageUID, loading: true, mail: null }));

      const { data } = await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/mail-id`, {
        token,
        platform: 'custom',
        folder: currentFolder,
        mail_UID: messageUID
      });

      // console.log({ data });

      dispatch(slice.actions.loadingByUID({ folder: currentFolder, messageUID, loading: false, mail: data[0] }));
    } catch (error) {
      console.error(error);
      if (onReject) onReject(error);
    }
  };
};

/**
 * Retrieves emails from the specified folder for the given account.
 *
 * @param {Object} account - The account object containing the user's email and token.
 * @param {string} folderType - The type of email folder to retrieve (e.g. 'inbox', 'sent', 'drafts').
 * @param {?Date} refreshDate - An optional date to retrieve only unread emails since that date.
 * @param {Function} onResolve - A callback function to be called on successful completion.
 * @param {Function} onReject - A callback function to be called on error.
 * @returns {Function} - A thunk function that can be dispatched to retrieve the emails.
 */
export const getMails = ({ account = {}, folderType, refreshDate = null, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    const utils = new LoadingUtil(dispatch, `getMails-${account?.user?.email}-${folderType}`);

    try {
      const { token } = account;

      if (!token) return account;

      utils.start();

      const { customMail, microsoftMail } = getStore();

      const notify = microsoftMail?.notify;
      const mails = customMail?.mails[account?.user?.email] || {};
      const folders = customMail?.folders || {};
      const folderId = folders?.[account?.user?.email]?.[folderType]?.id;

      const getApiFolderType = (folderType) => {
        if (folderType === MAIL_FOLDER.ARCHIVE) return MAIL_FOLDER.INBOX;
        return folderType;
      };

      const currentFolder = folderId || getApiFolderType(folderType);

      let snap = null;

      if (!refreshDate) {
        const { data } = await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/mail-list`, {
          token,
          platform: MAIL_PLATFORM?.CUSTOM,
          folder: currentFolder
        });

        snap = data;
      } else {
        const { data } = await axios.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/mail-list`, {
          token,
          platform: MAIL_PLATFORM?.CUSTOM,
          folder: currentFolder,
          un_read: true
        });

        snap = data;
      }

      // console.log({ snap });

      const messages = [...(snap?.data || [])];
      const totals = snap?.total || 0;

      //update mail pagination
      dispatch(slice.actions.updatePagination({ folder: currentFolder, totals, mailLoaded: messages.length }));

      const res = [];

      for (const message of messages) {
        const messageFiles = message?.hasAttachments ? message?.orginalMail?.attachments : [];
        const files = [];

        messageFiles?.forEach((el) => {
          const attachmentData = new Uint8Array(el?.content?.data);
          const blob = new Blob([attachmentData], { type: el?.contentType });
          const file = new File([blob], el?.filename, { type: el?.contentType });
          files.push(file);
        });
        res.push({ ...message, files });
      }

      const result = {
        folder: folderType,
        email: account?.user?.email,
        mails: listToObject(res)
      };

      dispatch(setMailsInFolders(result));
      onResolve && onResolve();
      utils.end();

      if (MAIL_FOLDER.INBOX === folderType && notify) {
        const newMail = [];

        const prev = mails[MAIL_FOLDER.INBOX];
        const current = result.mails;

        keys(current).map((id) => {
          if (prev && !prev[id]) {
            newMail.push(current[id]);
          }
        });

        newMail.forEach((mail) => {
          notify({ ...mail, account });
        });
      }
    } catch (error) {
      onReject && onReject();
      // console.log(error);
      utils.end();
    }
  };
};

/**
 * Toggles the importance of a mail message in the user's account.
 *
 * This function is an asynchronous action creator that updates the importance of the specified
 * mail message in the Microsoft Graph API. It also updates the mail flag for the message in the
 * application state.
 *
 * @param {Object} account - The account object containing the user's account information.
 * @param {string} folderType - The type of folder the mail message is in.
 * @param {string} mailId - The ID of the mail message.
 * @param {string} mailUID - The UID of the mail message.
 * @param {boolean} isImportant - Whether the mail message should be marked as important.
 * @param {function} onResolve - A callback function to be called on successful update.
 * @param {function} onReject - A callback function to be called on failed update.
 * @returns {Function} An asynchronous action creator that toggles the importance of the specified mail message.
 */
export const toggleMsMailImportance = ({ account, folderType, mailId, mailUID, isImportant, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;
            // use updateFlag

            dispatch(
              updateMailFlag({
                email: account?.user?.email,
                folder: folderType,
                mailId: mailId,
                flag: isImportant ? MAIL_FLAG.IMPORTANT : '',
                oldFlag: formatFlagForSent(isImportant ? '' : MAIL_FLAG.IMPORTANT)
              })
            );

            console.log({
              messageUID: mailUID
            });
            await axiosRequest.post(`${CLOUD_MAIL_CUSTOM_API_KEY}/update-flag`, {
              messageUID: mailUID,
              token,
              currentFlag: formatFlagForSent(isImportant ? MAIL_FLAG.IMPORTANT : ''),
              nextFlag: formatFlagForSent(isImportant ? '' : MAIL_FLAG.IMPORTANT),
              platform: MAIL_PLATFORM.CUSTOM,
              currentFolder: folderType
            });

            dispatch(getMails({ account, folderType }));

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};

/**
 * Retrieves the attachments for a specified mail message in the user's account.
 *
 * This function is an asynchronous action creator that fetches the attachments for the specified
 * mail message from the Microsoft Graph API and returns them as an array of objects with the
 * attachment metadata and a URL for the attachment content.
 *
 * @param {Object} account - The account object containing the user's account information.
 * @param {string} folderType - The type of folder the mail message is in.
 * @param {string} mailId - The ID of the mail message.
 * @param {function} onResolve - A callback function to be called on successful fetch.
 * @param {function} onReject - A callback function to be called on failed fetch.
 * @returns {Function} An asynchronous action creator that fetches the attachments for the specified mail message.
 */
export const getMsMailAttachment = ({ account, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.get(`https://graph.microsoft.com/v1.0/me/messages/${mailId}/attachments`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            const result = (snap.data.value || [])?.map((file) => {
              const raw = window.atob(file.contentBytes);
              const rawLength = raw.length;
              let array = new Uint8Array(new ArrayBuffer(rawLength)); // pass your byte response to this constructor

              for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
              }

              const blob = new Blob([array], { type: file.contentType });

              const url = window.URL.createObjectURL(blob);
              return {
                ...file,
                type: file?.contentType,
                url
              };
            });

            onResolve && onResolve(result);
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};
/**
 * Marks a mail message as read in the specified account and folder.
 *
 * This function is an asynchronous action creator that updates the specified mail message to be
 * marked as read in the user's account and updates the custom mail slice with the updated mail
 * information.
 *
 * @param {Object} account - The account object containing the user's account information.
 * @param {string} folderType - The type of folder the mail message is in.
 * @param {string} mailId - The ID of the mail message.
 * @param {function} onResolve - A callback function to be called on successful update.
 * @param {function} onReject - A callback function to be called on failed update.
 * @returns {Function} An asynchronous action creator that marks the specified mail message as read.
 */
export const markMailAsRead = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    const { customMail } = getStore();
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: true
                }
              })
            );

            // await axios.patch(
            //   `https://graph.microsoft.com/v1.0/me/messages/${mailId}`,
            //   {
            //     isRead: true
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       'Content-type': 'application/json'
            //     }
            //   }
            // );

            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {}
  };
};

/**
 * Marks a mail message as unread in the specified account and folder.
 *
 * This function is an asynchronous action creator that updates the specified mail message to be
 * marked as unread in the user's account and updates the custom mail slice with the updated mail
 * information.
 *
 * @param {Object} account - The account object containing the user's account information.
 * @param {string} folderType - The type of folder the mail message is in.
 * @param {string} mailId - The ID of the mail message.
 * @param {function} onResolve - A callback function to be called on successful update.
 * @param {function} onReject - A callback function to be called on failed update.
 * @returns {Function} An asynchronous action creator that marks the specified mail message as unread.
 */
export const markMailAsUnread = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: false
                }
              })
            );
            // await axios.patch(
            //   `https://graph.microsoft.com/v1.0/me/messages/${mailId}`,
            //   {
            //     isRead: false
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       'Content-type': 'application/json'
            //     }
            //   }
            // );
            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {
    } finally {
      onResolve && onResolve();
    }
  };
};

/**
 * Deletes a mail message from the specified account and folder.
 *
 * This function is an asynchronous action creator that removes the specified mail message from the
 * user's account and updates the custom mail slice with the updated mail information.
 *
 * @param {Object} account - The account object containing the user's account information.
 * @param {string} folderType - The type of folder the mail message is in.
 * @param {string} mailUID - The unique identifier of the mail message.
 * @param {string} mailId - The ID of the mail message.
 * @param {function} onResolve - A callback function to be called on successful deletion.
 * @param {function} onReject - A callback function to be called on failed deletion.
 * @returns {Function} An asynchronous action creator that deletes the specified mail message.
 */
export const deleteMsMail = ({ account = {}, folderType, mailUID, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              removeMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId
              })
            );

            dispatch(
              updateFlag({
                account,
                mailId,
                mailUID,
                currentFlag: '',
                currentFolder: folderType,
                nextFlag: MAIL_FLAG.DELETED,
                onResolve,
                onReject
              })
            );

            //dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }));

            const result = { mailId: mailUID, folderId, platform: 'custom', token: account?.token };

            await axios.post(`${CLOUD_MAIL_API_KEY}/delete_mail`, result);

            // Implement update of the mail

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};

/**
 * Disconnects the specified account from the user's linked accounts.
 *
 * This function is an asynchronous action creator that removes the specified account from the user's
 * linked accounts in the cloud mail API, and dispatches an action to update the custom mail slice
 * with the updated account information.
 *
 * @param {Object} account - The account object to be disconnected.
 * @returns {Function} An asynchronous action creator that disconnects the specified account.
 */
export const disconnectAccount = (account) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);
    try {
      utils.start();

      dispatch(removeAccount(account?.user?.email));

      await axios.delete(
        `${CLOUD_MAIL_API_KEY}/account?accountId=${account?.id}&token=${account?.token}&plateform=custom`,
        {
          headers: {
            userid: auth.currentUser.uid,
            accountId: account?.id
          }
        }
      );

      utils.end();
    } catch (error) {
      utils.end();
    }
  };
};

const refreshToken = ({ account, callback }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);

    try {
      return callback(account);
    } catch (error) {
      utils.end();
    }
  };
};

/**
 * Refreshes the linked accounts for the current user.
 *
 * This function is an asynchronous action creator that fetches the latest account information for the
 * current user from the cloud mail API, and dispatches an action to update the custom mail slice
 * with the new account information.
 *
 * @param {Object} [emmitNotification] - An optional callback function to be dispatched after the account information is updated.
 * @returns {Function} An asynchronous action creator that refreshes the linked accounts.
 */
export const refreshrrrLinkedAccount = ({ emmitNotification }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshLinkedAccount`);

    try {
      utils.start();
      const snap = await axios.get(`${CLOUD_MAIL_API_KEY}/account`, {
        headers: {
          userid: auth.currentUser.uid
        }
      });

      let accountSnap = {};

      keys(snap.data)?.forEach((id) => {
        const email = snap.data[id]?.user?.email;

        accountSnap[email] = { ...snap.data[id], email };
      });

      dispatch(igniteCustomAccount({ accountSnap }));
      utils.end();

      if (emmitNotification) {
        dispatch(setNotifyCallback(emmitNotification));
      }
      // console.log(accountSnap)
    } catch (error) {
      utils.end();
    }
  };
};

/**
 * Creates a formatted date string representing the time `MAIL_TIMER` milliseconds before the current time.
 *
 * This function is used to generate a date string that can be used to fetch emails from a mail server. It takes the current time, subtracts `MAIL_TIMER` milliseconds, and formats the resulting date as a string in the format "MMM D, YYYY HH:MM".
 *
 * @returns {string} A formatted date string representing the time `MAIL_TIMER` milliseconds before the current time.
 */
const createRefrecheDate = () => {
  const dateActuelle = new Date();

  const nouvelleDate = new Date(dateActuelle.getTime() - MAIL_TIMER);

  var month = nouvelleDate.toLocaleString('en-us', { month: 'short' });
  var day = nouvelleDate.getDate();
  var year = nouvelleDate.getFullYear();
  var hour = nouvelleDate.getHours();
  var minute = nouvelleDate.getMinutes();

  var formattedDate =
    month + ' ' + day + ', ' + year + ' ' + (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;

  return formattedDate;
};

/**
 * Refreshes the custom mails for all the user's accounts.
 *
 * This function is an asynchronous action creator that dispatches other actions to fetch the latest
 * mails for each of the user's accounts. It uses the `Timer.repeat` function to periodically
 * refresh the mails, and dispatches the `refreshToken` and `getMails` actions for each account.
 *
 * @returns {Function} An asynchronous action creator that refreshes the custom mails.
 */
export const refreshCustomMails = () => {
  return async (dispatch, getStore) => {
    try {
      let count = 0;

      const canceler = Timer.repeat(MAIL_TIMER, () => {
        const accountSnap = getStore()?.customMail?.accounts || {};

        Object.entries(accountSnap).forEach(([key, account]) => {
          if (!isEmpty(key)) {
            dispatch(
              refreshToken({
                account,
                callback: (account) => {
                  dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX, refreshDate: createRefrecheDate() }));
                  count++;
                }
              })
            );
          }
        });
      });

      dispatch(updateMailRefresher(canceler));
    } catch (error) {}
  };
};

/**
 * Transforms a Microsoft Outlook email object into a custom email object.
 *
 * This function takes a Microsoft Outlook email object as input and returns a custom email object
 * that can be used in the application. The transformation is performed using the `Mail.fromOutlook`
 * method, which is likely a utility function defined elsewhere in the codebase.
 *
 * @param {any} msMail - A Microsoft Outlook email object to be transformed.
 * @returns {any} A custom email object that can be used in the application.
 */
const transformMsMailToCustom = (msMail) => {
  return Mail.fromOutlook(msMail);
};

/**
 * Transforms a comma-separated string of email addresses into an array of objects, where each object
 * has an `emailAddress` property containing the email address.
 *
 * The function first splits the input string on commas, then trims and removes any whitespace from
 * each email address. It then filters out any empty strings, and maps the remaining email addresses
 * into an array of objects with the `emailAddress` property.
 *
 * @param {string} data - A comma-separated string of email addresses.
 * @returns {Object[]} An array of objects, where each object has an `emailAddress` property containing an email address.
 */
export const transformStringRecipientsToArray = (data = '') => {
  return data
    .split(',')
    .map((mail) => mail.trim().replaceAll(' ', ''))
    .filter((el) => el.length !== 0)
    .map((address) => ({
      emailAddress: {
        address
      }
    }));
};

/**
 * Transforms an array of email addresses into a comma-separated string.
 *
 * Each email address is included in the resulting string, separated by a comma.
 *
 * @param {any[]} data - An array of objects, each containing an `emailAddress.address` property.
 * @returns {string} A comma-separated string of the email addresses.
 */
export const transformArrayRecipientsToString = (data = []) => {
  let result = '';

  data.forEach((el) => {
    result += `${el?.emailAddress?.address},`;
  });

  return result;
};

/**
 * Transforms an array of user data objects into a comma-separated string representation.
 *
 * Each user is represented as "Name <email@example.com>", and the resulting string
 * contains all users separated by a comma. If the name and email are the same, only
 * the email is included.
 *
 * @param {any[]} data - An array of user data objects, each containing `emailAddress.name`
 *                      and `emailAddress.address` properties.
 * @returns {string} A comma-separated string representation of the input user data.
 */
const transformUsersArrayToString = (data = []) => {
  let result = '';

  data.forEach((el, index) => {
    const name = el?.emailAddress?.name;
    const email = el?.emailAddress?.address;
    const isLast = data.length - 1 === index;

    if (name !== email) result += `${name} <${email}>`;
    else {
      result += `<${email}>`;
    }

    if (isLast) return;

    result += ` , `;
  });

  return result;
};

/**
 * Transforms a list of objects into a dictionary-like object, where the keys are the IDs of the objects.
 *
 * @param {any[]} list - The list of objects to transform.
 * @returns {object} An object where the keys are the IDs of the objects in the input list, and the values are the objects themselves.
 */
export const listToObject = (list = []) => {
  let result = {};

  list.forEach((el) => {
    result[el?.id] = el;
  });

  return result;
};

/**
 * Transforms the provided data into a Microsoft Exchange email format.
 *
 * @param {any} data - The data to be transformed into a Microsoft Exchange email format.
 * @returns {object} An object representing the transformed email data.
 */
export const transformToMsEmail = (data) => {
  return {};
};

/**
 * Provides utility methods for managing loading state in a Redux application.
 *
 * The `LoadingUtil` class is responsible for dispatching actions to set and revoke the loading state for a specific key.
 * It can be used to track the loading state of various asynchronous operations in the application.
 */
class LoadingUtil {
  key = null;
  dispatch = null;

  constructor(dispatch, key) {
    this.dispatch = dispatch;
    this.key = key;
  }

  start() {
    this.dispatch(setLoading([this.key]));
  }

  end() {
    this.dispatch(revokeLoading([this.key]));
  }
}

/**
 * Transforms a file to a base64-encoded string.
 *
 * @param {File} file - The file to be transformed.
 * @returns {Promise<string>} A promise that resolves to the base64-encoded string of the file.
 */
export const transformFileToBase64 = (file) => {
  // console.log({ file });
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      return reject(new TypeError('The provided value is not a Blob.'));
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};


// dispatch(refreshToken({
//     account, callback: async (account) => {
//
//     }
// }))
