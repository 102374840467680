import { useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from '../../redux/store';
import { transformMessageBody, useConverseProvider } from './ConverseProvider';
import useAuth from 'src/hooks/useAuth';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import useMessages from '../../hooks/useMessages';
import useUserList from 'src/hooks/useUserList';
import { fr as LocalFr } from 'date-fns/locale';
import { getFileThumb, getFileThumbUrl, getFileType } from '../../utils/getFileFormat';
import { gDate, gfTime } from '../../utils/formatTime';
import { getAvatarColor2 } from '../../utils/createAvatar';
import paginateChatMessage from '../../helpers/PaginateChatMessage';
import { CONTENT_TYPE } from 'src/constants';
import { baseColors } from '../../constants/color';
import { uniq } from 'lodash';

import { nanoid } from '@reduxjs/toolkit';

import { Icon } from '@iconify/react';

import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Badge,
  Stack,
  Grid,
  Typography,
  alpha,
  ListItemText,
  MenuItem,
  useTheme
} from '@mui/material';
import { Close, ArrowBackIos, Send, Attachment } from '@mui/icons-material';

import AudioRecorder from '../../components/AudioRecorder';
import Scrollbar from '../../components/Scrollbar';
import EmojiPicker from '../../components/EmojiPicker';
import LoadingView from '../../components/LoadingView';
import AttachmentReader, { isFileHasReader } from '../../components/AttachmentReader';
import { formatDistanceToNowStrict, format } from 'date-fns';
import { auth } from '../../contexts/FirebaseContext';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import ConverseMessageResponseProvider, { useConverseMessageResponse } from './ConverseMessageResponse';
import { capitalize } from '../../utils/format_text';
import { useAnimateColorTransition } from '../../hooks/useAnimateColorTransition';
import { downloadFile } from 'src/helpers/downloadFile';
import Center from 'src/components/Center';
import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel';
import { EditorMentionEnter } from 'src/components/CustomInput';
import MusicPlayerSlider from 'src/components/MusicPlayerSlider';
import { useDriverContext } from '../doc/okydriver/context/DriverContext';
import VideoAndAudioPlayerModal from 'src/components/VideoAndAudioPlayerModal';
import DocumentReader from 'src/components/only-office/DocumentReader';
import { onlyOfficeDocumentType } from 'src/components/only-office/helper';

export const truncate = (val = '', max = 30) => (val.length > 30 ? `${val.substring(0, max)} ...` : val);

export default function ConverseChatRoom({ onClose }) {
  const { user } = useAuth();
  const { users } = useUserList();

  const { currentConversation, goToHome, goToDetails } = useConverseProvider();
  const { list, loading } = useMessages(currentConversation?.id);

  const meta = {
    ...currentConversation,
    messages: list
  };

  const { participants = [] } = currentConversation;
  const isGroup = participants.length > 2;

  const userState = useMemo(() => {
    if (isGroup) {
      return { active: false };
    }

    const other = participants?.find((el) => el?.id !== user?.id);
    const person = users?.find((el) => el?.id === other?.id);

    return { active: person?.state === 'online', last_online: person?.last_changed };
  }, [isGroup, user?.id, users, participants]);

  const displayDetails = () => {
    goToDetails(currentConversation);
  };

  const getSub = () => {
    if (isGroup) return;

    return (
      <>
        {!userState?.active
          ? userState?.last_online
            ? formatDistanceToNowStrict(gDate(userState.last_online), {
              addSuffix: true,
              locale: LocalFr
            })
            : 'hors ligne'
          : 'En ligne'}
      </>
    );
  };

  const participantsNameList = participants?.map((el) => el?.name).toString();

  return (
    <Box height={1}>
      <ConverseMessageResponseProvider>
        <Stack direction={'row'} px={2} py={1} justifyContent={'space-between'}>
          <Stack direction={'row'}>
            <IconButton onClick={goToHome}>
              <ArrowBackIos />
            </IconButton>

            <Stack direction={'row'} spacing={0.5} sx={{ cursor: 'pointer' }} onClick={displayDetails}>
              <Stack justifyContent={'center'} height={1}>
                <ListItemText
                  primary={
                    <Tooltip title={participantsNameList}>
                      <div>{truncate(currentConversation?.name)}</div>
                    </Tooltip>
                  }
                  secondary={getSub()}
                  primaryTypographyProps={{ fontWeight: 'Bold', justifyContent: 'center' }}
                  secondaryTypographyProps={{
                    fontSize: 10,
                    color: userState?.active ? 'green' : 'CaptionText'
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>

        <Divider />

        <Box flexGrow={1} style={{ height: '87%', display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Scrollbar sx={{ flexGrow: 1, height: 1 }}>
            {/*<ChatMessageList conversation={meta}/>*/}
            <ChatList loading={loading} conversation={meta} userId={user?.id} />

            {loading && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0
                }}
              >
                <LoadingView />
              </Stack>
            )}
          </Scrollbar>

          <Box flexGrow={5}>
            <MessageInput conversationId={currentConversation?.id} />
          </Box>
        </Box>
      </ConverseMessageResponseProvider>
    </Box>
  );
}

const ChatList = ({ conversation, userId, loading }) => {

  const ref = useRef();

  const { messages = [], participants = [] } = conversation;

  const { open, handleOpen, handleClose } = useToggle();
  const [selectedFile, setSelectedFile] = useState(null);

  const { sentMessage } = useSelector((state) => state?.conversation);

  const persistedMessages = useMemo(() => {

    const ids = messages?.map((el) => el?.id);

    return (sentMessage[conversation?.id] || [])?.filter((el) => !ids.includes(el?.id));

  }, [sentMessage, messages, conversation?.id]);



  const sections = useMemo(() => paginateChatMessage(messages), [messages]);

  const handleReadFile = (file) => {
    setSelectedFile(file);
    handleOpen();
  };

  const handleClosing = () => {
    handleClose();
    setSelectedFile(null);
  };

  useEffect(() => {
    const { current } = ref;

    if (current) current?.scrollIntoView({ behavior: 'instant', block: 'end' });
  }, [loading, messages, persistedMessages]);

  return (
    <Stack spacing={2} p={1}>
      {sections?.map(({ messages, title }) => (
        <Stack spacing={1} mb={5} pb={2} key={title}>
          <Stack justifyContent={'center'}>
            <Typography align={'center'} fontSize={14}>
              {title}
            </Typography>
          </Stack>

          {messages?.map((el) => {
            const sender = participants.find((e) => e?.id === el?.senderId);
            const isMine = el?.senderId === userId;
            const isGroup = participants?.length > 2;

            return (
              <Bulle
                key={el?.id}
                message={el}
                isMine={isMine}
                userId={userId}
                sender={sender}
                onReadFile={handleReadFile}
                isGroup={isGroup}
                conversation={conversation}
              />
            );
          })}

          {persistedMessages?.map((el) => {
            const sender = participants.find((e) => e?.id === el?.senderId);
            const isMine = el?.senderId === userId;
            const isGroup = participants?.length > 2;

            return (
              <Bulle
                key={`${el?.id}_persisted`}
                message={el}
                isMine={isMine}
                userId={userId}
                sender={sender}
                onReadFile={handleReadFile}
                isGroup={isGroup}
                conversation={conversation}
                isPersisted
              />
            );
          })}
        </Stack>
      ))}


      <div ref={ref} />
      {/* <DocumentReader
        onClose={handleClose}
        open={Boolean(open)}
        document={{
          key: selectedFile?.id,
          url: selectedFile?.url,
          title: selectedFile?.name,
          fileType: selectedFile?.type

        }}
        uploadName={selectedFile?.name}
        docKey={selectedFile?.docKey || selectedFile?.id}
        openMode={'view'}
        loading={selectedFile}
        editable={false}
      /> */}
      <AttachmentReader file={selectedFile} open={open} onClose={handleClosing} />
    </Stack>
  );
};

const Bulle = ({ message, sender, isMine, userId, onReadFile, isGroup = false, conversation, isPersisted = false }) => {
  const ref = useRef();
  const [open, show, hide] = useToggleV2();
  const [display, onOpen, onHide] = useToggleV2();
  const { setMessageToRespond } = useConverseMessageResponse();
  const { attachments = [] } = message;
  const shouldDisplayName = !isMine && Boolean(sender) && Boolean(sender?.name) && isGroup;
  const nameColor = getAvatarColor2(sender?.name || 'A');
  const { registerAnchor, cleanLookup, lookedUpMessageId } = useConverseMessageResponse();
  const isSelected = lookedUpMessageId === message?.id;
  const { selected, forward } = useAnimateColorTransition({ start: '#D6E4FF', timeout: 3000 });
  const [songDuration, setSongDuration] = useState(0);

  const body = transformMessageBody(message)?.message;

  const getSongDuration = (url) => {
    const audio = new Audio(url);
    audio.onloadedmetadata = () => {
      setSongDuration(audio.duration);
    };
  };

  useEffect(() => {
    if (message?.audio) {
      getSongDuration(message?.audio);
    }
  }, [message?.audio]);

  useEffect(() => {
    registerAnchor(message?.id, ref);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, message?.id]);

  useEffect(() => {
    if (isSelected) {
      forward();
      setTimeout(() => {
        if (isSelected) {
          cleanLookup();
        }
      }, 3000);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookedUpMessageId]);

  const handleResponse = () => {
    onHide();
    setMessageToRespond(message);
  };
  return (
    <Stack direction={'row'} justifyContent={isMine ? 'end' : 'start'} bgcolor={isSelected && selected} py={0.5}>
      <Box
        px={1}
        py={0.5}
        maxWidth={'70%'}
        minWidth={'25%'}
        onMouseEnter={show}
        onMouseLeave={hide}
        sx={{
          opacity: 1,
          borderRadius: 1.5,
          ...(!message?.audio && {
            bgcolor: (t) => t.palette.action.selected,
            ...(isMine && {
              bgcolor: (t) => alpha(t.palette.primary.light, 0.3)
            })
          })
        }}
      >
        <Stack direction={'row'} justifyContent={'end'} pr={message?.audio && 1.5}>
          <Stack
            onClick={onOpen}
            direction={'row'}
            justifyContent={'end'}
            sx={{ position: 'absolute', zIndex: 1000, cursor: 'pointer' }}
          >
            <div ref={ref}>
              <Iconify icon={'dashicons:arrow-down-alt2'} hidden={!open || display} />
            </div>
          </Stack>
        </Stack>

        {shouldDisplayName && (
          <Typography fontSize={11} color={nameColor}>
            {sender?.name}
          </Typography>
        )}

        {message?.response && <MessageRef response={message?.response} />}

        <Typography
          sx={{ whiteSpace: 'pre-line', flexWrap: 'wrap', wordBreak: 'break-word' }}
          fontSize={14}
          dangerouslySetInnerHTML={{
            __html: body
          }}
        >
          {/*{body || message?.body}*/}
        </Typography>
        {message?.audio && (
          <Stack justifyContent={'end'} width={250}>
            <MusicPlayerSlider
              song={message?.audio}

            />
          </Stack>
          // <audio src={message?.audio} controls style={{ width: 250 }}>
          //   <track default kind="captions" srcLang="fr" src="" />
          // </audio>
        )}

        {attachments?.length !== 0 && (
          <Grid container spacing={0.5}>
            {attachments?.map((el) => (
              <Grid item key={el?.id}>
                <MessageAttachmentBox file={el} onRead={onReadFile} />
              </Grid>
            ))}
          </Grid>
        )}

        <Stack justifyContent={'end'}>
          <Stack direction={'row'} justifyContent={'end'}>
            <Typography align={'right'} fontSize={9}>
              {gfTime(message?.createdAt)}
            </Typography>
            {isMine && (
              <Stack justifyContent={'center'} ml={0.5}>
                <BulleStatus userId={userId} message={message} conversation={conversation} isPersisted={isPersisted} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>

      <MenuPopover
        className="this-is-the-probleme"
        disablePortal
        open={display}
        onClose={onHide}
        width="max-content"
        anchorEl={ref.current}
        disabledArrow
        anchorOrigin="bottom"
      >
        <MenuItem onClick={handleResponse}>Répondre</MenuItem>
      </MenuPopover>
    </Stack>
  );
};

const BulleStatus = ({ conversation, message, userId, isPersisted = false }) => {
  const size = 14;
  const params = { width: size, height: size };
  // console.log(conversation)

  if (isPersisted) {
    return (
      <Tooltip title={'Message en attente'}>
        <Icon icon={'la:dot-circle'} {...params} />
      </Tooltip>
    );
  }
  const Default = () => {
    return (
      <Tooltip title={'Message Envoyé'}>
        <Icon icon={'akar-icons:check'} {...params} />
      </Tooltip>
    );
  };

  if (message?.timeLine?.length !== 0 || message?.receivedTimeLine?.length !== 0) {
    const userID = auth?.currentUser?.uid;
    const others = conversation?.participants?.filter((e) => e?.id !== userID)?.map((el) => el?.id);
    const received = message?.receivedTimeLine?.filter((e) => e?.userId !== userID)?.map((el) => el?.userId);
    const allReceived = uniq(others)?.length === uniq(received)?.length;

    const onlyMe =
      message?.timeLine?.length === 1 && message?.timeLine.filter((e) => e?.userId === userId)?.length === 1;
    const allReached = conversation?.participants?.length === message?.timeLine?.length || !onlyMe;


    if (allReached) {
      return (
        <Tooltip title={`Message reçu par ${allReached ? 'tous' : 'partiellement'}`}>
          <Icon icon={'bi:check-all'} {...params} color={allReached ? baseColors.BLUE : null} />
        </Tooltip>
      );
    }

    if (allReceived) {
      return (
        <Tooltip title={`Message lu`}>
          <Icon icon={'bi:check-all'} {...params} />
        </Tooltip>
      );
    }

    if (onlyMe) {
      return <Default />;
    }
  }

  return <Default />;
};

const MessageRef = ({ response, onRemove }) => {
  const { user } = useAuth();
  const {
    currentConversation: { participants = [] }
  } = useConverseProvider();
  const { scrollToMessage } = useConverseMessageResponse();
  const owner = useMemo(() => {
    if (response?.senderId === user?.id) {
      return 'Vous';
    }
    const person = participants?.find((el) => el?.id === response?.senderId);

    if (person) {
      return capitalize(person?.name);
    }

    return 'Utilisateur';
  }, [participants, response?.senderId, user?.id]);

  const boxSize = onRemove ? 40 : 20;
  const isPreviewValid = !(response?.preview || '').startsWith('/');

  const handleFindMessage = () => {
    scrollToMessage(response?.messageId);
  };

  return (
    <Stack
      spacing={1}
      direction={'row'}
      justifyContent={'space-between'}
      width={1}
      px={1}
      my={0.5}
      bgcolor={!onRemove && 'white'}
      borderRadius={!onRemove && 1}
      sx={{ cursor: 'pointer' }}
      onClick={handleFindMessage}
    >
      <Stack>
        <Typography fontSize={14}>{truncate(owner)}</Typography>

        <Typography fontSize={12}>{truncate(response?.resume)}</Typography>
      </Stack>

      <Stack direction={'row'} spacing={1}>
        {response?.preview && isPreviewValid && (
          <Stack justifyContent={'center'} height={boxSize} width={boxSize}>
            {getFileThumb(response?.preview)}
          </Stack>
        )}

        {onRemove && (
          <Stack justifyContent={'center'}>
            <IconButton size={'small'} onClick={onRemove}>
              <Iconify icon={'eva:trash-fill'} />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const MessageInput = ({ conversationId }) => {
  const ref = useRef();
  let messageRef = useRef();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [mentionOpen, setMentionOpen] = useState(false);

  const [attachments, setFiles] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [message, setMessage] = useState('');

  const [rawMessage, setRawMessage] = useState('');

  const [recording, setRecording] = useState(false);
  const [sending, setSending] = useState(false);

  const { response, cleanup: clearResponse } = useConverseMessageResponse();
  const { sendMessage } = useConverseProvider();

  const handleAttach = () => {
    ref.current.click();
  };

  const handleFileSelection = (event) => {
    const files = event.target.files ?? [];
    const list = [];

    for (const file of files) {
      if (!attachments.find((one) => one.name === file.name)) {
        list.push(file);
      }
    }

    setFiles([...attachments, ...list]);
  };

  const handleFileDeSelection = (index) => {
    setFiles(attachments.filter((file, idx) => idx !== index));
  };

  const handleSend = () => {
    const shouldSend = Boolean(message.trim().length !== 0 || attachments?.length !== 0);
    const contentType = attachments?.length === 0 ? CONTENT_TYPE.TEXT : CONTENT_TYPE.FILES;

    if (!shouldSend) {
      return '';
    }

    const data = {
      id: nanoid(),
      conversationId,
      message,
      contentType,
      attachments,
      senderId: user?.id,
      response,
      mentions
    };
    setLoading(true);
    // dispatch(
    //     onNewMessage(data, 0, user?.id, () => {
    //         setLoading(false);
    //     })
    // );

    sendMessage(data, { onResolve: () => setLoading(false) });

    setFiles([]);
    setMessage('');
    setRawMessage('');
    clearResponse();
  };

  const handleAudioRecord = (file, { cleanup }, seconds) => {
    setLoading(true);

    const data = {
      id: nanoid(),
      conversationId,
      message,
      audio: file?.url,
      contentType: CONTENT_TYPE.AUDIO,
      attachments,
      senderId: user?.id,
      response,
      mentions
    };


    sendMessage(data, {
      onResolve: () => {
        setLoading(false);
        if (cleanup) cleanup();
      }
    });

    clearResponse();
  };

  const handleKeyUp = (event) => {
    const hasJustCloseMentionPopup = rawMessage[rawMessage.length - 2] === ')';

    if (hasJustCloseMentionPopup) return;

    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      handleSend();
    }

    refocus();
  };

  const refocus = () => messageRef.current?.focus();

  const handleMessageChange = ({ mentions = [], newPlainTextValue, newValue }) => {
    setMessage(newPlainTextValue);
    setMentions(mentions);
    setRawMessage(newValue);
  };

  useEffect(() => {
    refocus();
  }, []);

  // console.log(messageRef)

  return (
    <Stack>
      {response && (
        <Stack width={1}>
          <Divider />

          <MessageRef response={response} onRemove={clearResponse} />
        </Stack>
      )}

      <Divider />
      <Stack direction={'row'} justifyContent={'space-between'} spacing={1} px={1.7} pt={1}>
        {
          !recording && (
            <Stack width={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} position={'relative'}>
              <Stack height={1} mt={0.6} justifyContent={'center'} >
                <EmojiPicker value={rawMessage} setValue={(value) => setRawMessage(value)} />
              </Stack>

              <Stack height={1} width={1} onClick={refocus}>
                <Stack width={1}>
                  <EditorMentionEnter
                    value={rawMessage}
                    onChange={handleMessageChange}
                    onKeyUp={handleKeyUp}
                    id={'chat-input'}
                    inputRef={messageRef}
                    onMentionPopupStateChange={setMentionOpen}
                    style={{
                      maxwWidth: 500,
                      wordBreak: 'break-word'
                    }}
                  />
                </Stack>

                {message?.length === 0 && (
                  <Stack
                    fontSize={15}
                    onClick={refocus}
                    sx={{
                      position: 'absolute',
                      mt: 1.3,
                      ml: 0.9,
                      zIndex: 0,
                      color: (theme) => {
                        return theme.palette.action.disabled;
                      }
                    }}
                  >
                    Message...
                  </Stack>
                )}
              </Stack>
            </Stack>
          )
        }


        <Stack height={1} justifyContent={'center'}
          sx={
            {
              width: recording ? 1 : 'auto'
            }
          }
        >
          <Stack direction={'row'} justifyContent={'center'} height={1} width={1}>
            <AudioRecorder
              size={20}
              dispatcher={handleAudioRecord}
              setRecording={setRecording}
              setLoading={setLoading}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              sending={sending}

            />
            {!recording && (
              <IconButton size={'small'} onClick={handleAttach}>
                <Attachment />
              </IconButton>
            )}
            {!recording && (
              <IconButton size={'small'} onClick={handleSend}>
                <Send />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>

      {attachments?.length !== 0 && (
        <Stack direction={'row'} spacing={2} flexWrap={'wrap'} px={2} pt={1}>
          {attachments?.map((e, idx) => (
            <AttachmentBox key={idx} file={e} onRemove={() => handleFileDeSelection(idx)} />
          ))}
        </Stack>
      )}

      <input type="file" multiple ref={ref} style={{ display: 'none' }} onChange={handleFileSelection} />
    </Stack>
  );
};

const MessageAttachmentBox = ({ file, onRead }) => {
  const size = 122;
  const { open, handleOpen, handleClose } = useToggle();
  const { uploadingFiles = [] } = useConverseProvider();
  const { loadingEdting, editingDoc } = useDriverContext();
  const [show, setShow] = useState(false);
  const { user } = useAuth();

  const { isBeingUploaded, progress } = useMemo(() => {
    const current = uploadingFiles?.find((el) => el?.id === file?.id);
    const progress = (current?.progress || 0) * 100;
    return { isBeingUploaded: Boolean(current), progress };
  }, [uploadingFiles, file]);

  const type = getFileType(file?.type);

  if (isBeingUploaded) {
    //console.log(file?.name, progress);
  }


  const isFileHasPreviewer = (file) => {
    return ['document', 'pdf', 'presentation', 'ms-excel', 'spreadsheet'].includes(onlyOfficeDocumentType(file?.type)) || ['video', 'audio', 'image'].includes(type);
  };

  const documentViewItem = useMemo(
    () => ({
      key: file.id,
      url: file.url,
      title: file?.name,
      fileType: file?.type,
      owner: user?.id,
      uploaded: format(gDate(new Date()), 'yyyy-MM-dd h:mm aa')
    }),
    [file.id, file?.name, file?.type, file.url, user?.id]
  );

  const canPreviewFileType = isFileHasPreviewer(file);

  //console.log(canPreviewFileType);

  const canRead = isFileHasReader(file);

  const handleRead = () => {
    if (type === 'document' || type === 'pdf' || type === 'presentation' || type === 'ms-excel' || type === 'spreadsheet') {
      setShow(true);
    } else {
      onRead(file)
    }
  };

  const handleDownload = () => downloadFile(file?.url, file?.name);

  return (
    <Tooltip title={file?.name}>
      <Box
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        sx={{
          height: size,
          width: size,
          borderRadius: 1,
          p: 0.5,
          borderColor: (t) => t.palette.divider
        }}
        justifyContent={'center'}
      >
        <Stack
          hidden={!open}
          direction={'row'}
          justifyContent={'end'}
          spacing={1}
          sx={{ position: 'absolute', zIndex: 100000 }}
        >
          {<AttachmentButton icon={'akar-icons:eye'} onTap={handleRead} />}
          <AttachmentButton icon={'akar-icons:download'} onTap={handleDownload} />
        </Stack>
        {
          <DocumentReader
            onClose={() => setShow(false)}
            open={Boolean(show)}
            document={documentViewItem}
            uploadName={file?.name}
            docKey={editingDoc?.docKey || file.id}
            openMode={'view'}
            loading={false}
            editable={false}
          />
        }
        <Stack
          hidden={!isBeingUploaded}
          direction={'row'}
          justifyContent={'end'}
          justifySelf={'center'}
          spacing={1}
          // bgcolor={'red'}
          sx={{ position: 'absolute', zIndex: (t) => t.zIndex.appBar + 10 }}
          height={size - 10}
          width={size - 8}
        >
          <Center>
            <CircularProgressWithLabel value={progress} customcolor="inherit" />
          </Center>
        </Stack>

        {type?.includes('video') || type?.includes('audio') ? (
          !isBeingUploaded && <VideoAndAudioPlayerModal {...file} height={size - 10} width={size - 10} />
        ) : (
          <Box component="img" src={getFileThumbUrl(file?.type, file?.url)} height={size - 10} width={size - 8} />
        )}
      </Box>
    </Tooltip>
  );
};

const VideoPreview = ({ url, type, height, width }) => {
  const theme = useTheme();
  const props = { height, width };

  return (
    <Stack {...props}>
      <video controls={false} {...props}>
        <source src={url} type={type} />
        <track default kind="captions" srcLang="fr" src="" />
      </video>
      <Stack {...props} sx={{ position: 'absolute', cursor: 'pointer' }}>
        <Center>
          <Icon icon={'ci:play-circle-outline'} height={30} width={30} color={theme.palette.success.main} />
        </Center>
      </Stack>
    </Stack>
  );
};

const AttachmentButton = ({ icon, onTap }) => {
  const size = 30;
  const iconSize = 20;
  const { open, handleOpen, handleClose } = useToggle();

  return (
    <Stack
      onClick={onTap}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      direction={'row'}
      justifyContent={'center'}
      bgcolor={'#4b4444'}
      height={size}
      width={size}
      borderRadius={1}
      sx={{
        opacity: open ? 1 : 0.7,
        cursor: 'pointer'
      }}
    >
      <Stack justifyContent={'center'}>
        <Icon icon={icon} height={iconSize} width={iconSize} color={'white'} />
      </Stack>
    </Stack>
  );
};

const AttachmentBox = ({ file, onRemove }) => {
  return (
    <Tooltip title={file?.name}>
      <Badge badgeContent={'X'} color={'primary'} onClick={onRemove} sx={{ cursor: 'pointer' }}>
        <Box
          sx={{
            height: 58,
            width: 58,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 1,
            p: 0.5,
            borderColor: (t) => t.palette.divider
          }}
          justifyContent={'center'}
        >
          <Box component="img" src={getFileThumbUrl(file?.type, URL.createObjectURL(file))} height={50} width={50} />
        </Box>
      </Badge>
    </Tooltip>
  );
};
