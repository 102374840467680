export const V4_TASK_STATE = {
  ALL: 'ALL',
  NOT_END: 'NOT_END',
  END: 'END'
};

export const V4_TASK_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
  SUSPENDED: 'suspended'
};

export const V4_TASK_STATUS_CONVERT = {
  [V4_TASK_STATUS.OPEN]: 'En cours',
  [V4_TASK_STATUS.CLOSED]: 'Terminé',
  [V4_TASK_STATUS.SUSPENDED]: 'Suspendus'
};

export const V4_ONLY_MY_TASK = {
  ALL: 'ALL',
  CREATED: 'CREATED',
  ASSIGNEE: 'ASSIGNEE',
};

export const V4_FILTER_DATE_STATE = {
  dueDate: 'dueDate',
  createdDate: 'createdAt',
}

export const V4_DEFAULT_TASK_RIGTH = {
  create: false,
  edit: false,
  delete: false
}


export const V4_TASK_STATE_CONVERT = {
  [V4_TASK_STATE.ALL]: 'Toutes les tâches',
  [V4_TASK_STATE.NOT_END]: 'Tâches en cours',
  [V4_TASK_STATE.END]: 'Tâches terminées'
};

export const V4_DATE_STATE = {
  ALL : 'Toutes',
  LATE: 'Ancienne',
  TODAY: "Aujourd'hui",
  THIS_WEEK: 'Cette semaine',
  NEXT_WEEK: 'La semaine prochaine'
};

export const V4_ONLY_MY_TASK_SORT = {
  PRIORITY: 'PRIORITY',
  CREATED_DATE: 'CREATED_DATE',
  DEADLINE: 'DEADLINE',
  PROJECT: 'PROJECT'
};

export const V4_ONLY_MY_TASK_SORT_CONVERT = {
  [V4_ONLY_MY_TASK_SORT.PRIORITY]: 'Priorité',
  [V4_ONLY_MY_TASK_SORT.CREATED_DATE]: 'Date de création',
  [V4_ONLY_MY_TASK_SORT.DEADLINE]: 'Date limite',
  [V4_ONLY_MY_TASK_SORT.PROJECT]: 'Projet'

};

export const V4_ONLY_MY_TASK_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const V4_ONLY_MY_TASK_ORDER_CONVERT = {
  [V4_ONLY_MY_TASK_ORDER.ASC]: 'Croissant',
  [V4_ONLY_MY_TASK_ORDER.DESC]: 'Décroissant'
};

export const V4_TASK_HISTORY_ACTION = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  ASSIGNED: 'ASSIGNED',
  DONE: 'DONE',
  UNDONE: 'UNDONE',
  DUE_DATE_CHANGED: 'DUE_DATE_CHANGED',
  UNASSIGNED: 'UNASSIGNED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  CREATED_COMMENT: 'CREATED_COMMENT',
  UPDATED_COMMENT: 'UPDATED_COMMENT',
  DELETED_COMMENT: 'DELETED_COMMENT',
  CREATED_ATTACHMENT: 'CREATED_ATTACHMENT',
  UPDATED_ATTACHMENT: 'UPDATED_ATTACHMENT',
  DELETED_ATTACHMENT: 'DELETED_ATTACHMENT',
  CREATED_SUBTASK: 'CREATED_SUBTASK',
  UPDATED_SUBTASK: 'UPDATED_SUBTASK',
  DELETED_SUBTASK: 'DELETED_SUBTASK',
  NAME_CHANGED: 'NAME_CHANGED',
  PRIORITY_CHANGED: 'PRIORITY_CHANGED',
  DESC_CHANGED: 'DESC_CHANGED',
  STATE_CHANGED: 'STATE_CHANGED',
}

export const V4_TASK_HISTORY_ACTION_CONVERT = {
  [V4_TASK_HISTORY_ACTION.CREATED]: 'a créé la tâche',
  [V4_TASK_HISTORY_ACTION.UPDATED]: 'a modifié la tâche',
  [V4_TASK_HISTORY_ACTION.DELETED]: 'a supprimé la tâche',
  [V4_TASK_HISTORY_ACTION.ASSIGNED]: 'a assigné',
  [V4_TASK_HISTORY_ACTION.DONE]: 'a marqué comme terminée',
  [V4_TASK_HISTORY_ACTION.UNDONE]: 'a marqué comme non terminée',
  [V4_TASK_HISTORY_ACTION.DUE_DATE_CHANGED]: 'a modifié la date limite',
  [V4_TASK_HISTORY_ACTION.UNASSIGNED]: 'a désassigné',
  [V4_TASK_HISTORY_ACTION.STARTED]: 'a commencé',
  [V4_TASK_HISTORY_ACTION.FINISHED]: 'a terminé',
  [V4_TASK_HISTORY_ACTION.REJECTED]: 'a rejeté',
  [V4_TASK_HISTORY_ACTION.ACCEPTED]: 'a accepté',
  [V4_TASK_HISTORY_ACTION.CREATED_COMMENT]: 'a ajouté un commentaire',
  [V4_TASK_HISTORY_ACTION.UPDATED_COMMENT]: 'a modifié un commentaire',
  [V4_TASK_HISTORY_ACTION.DELETED_COMMENT]: 'a supprimé un commentaire',
  [V4_TASK_HISTORY_ACTION.CREATED_ATTACHMENT]: 'a ajouté une pièce jointe',
  [V4_TASK_HISTORY_ACTION.UPDATED_ATTACHMENT]: 'a modifié une pièce jointe',
  [V4_TASK_HISTORY_ACTION.DELETED_ATTACHMENT]: 'a supprimé une pièce jointe',
  [V4_TASK_HISTORY_ACTION.CREATED_SUBTASK]: 'a ajouté une sous-tâche',
  [V4_TASK_HISTORY_ACTION.UPDATED_SUBTASK]: 'a modifié une sous-tâche',
  [V4_TASK_HISTORY_ACTION.DELETED_SUBTASK]: 'a supprimé une sous-tâche',
  [V4_TASK_HISTORY_ACTION.NAME_CHANGED]: 'a modifié le titre de la tâche',
  [V4_TASK_HISTORY_ACTION.PRIORITY_CHANGED]: 'a modifié la priorité de la tâche',
  [V4_TASK_HISTORY_ACTION.DESC_CHANGED]: 'a modifié la description de la tâche',
  [V4_TASK_HISTORY_ACTION.STATE_CHANGED]: 'a modifié l\'état de la tâche',
}

export const COL_WIDTH = 110;
