import { Icon } from '@iconify/react';
import { ButtonGroup, IconButton, Sheet } from '@mui/joy';
import { useSpring, animated } from 'react-spring';
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { Box, Checkbox, Typography, Autocomplete, Paper, Stack, TextField, Tooltip } from '@mui/material';
import { Avatar } from 'antd';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { fr as LocalFr } from 'date-fns/locale';
import { findIndex, isArray, isEmpty, isString, uniq } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ActionModal from 'src/components/ActionModal';
import { GridStack, TableBaseLine } from 'src/components/AffectationLineComponent';
import CIconButton from 'src/components/CIconButton';
import ContactsDialog from 'src/components/ContactsDialog';
import Iconify from 'src/components/Iconify';
import { MenuLine } from 'src/components/MenuLine';
import MenuPopover, { CustomMenuPopover } from 'src/components/MenuPopover';
import TextMaxLine from 'src/components/TextMaxLine';
import { AFFECT_STATE_VALIDATION } from 'src/constants';
import { AFFECT_PRIORITY, AFFECT_TYPE } from 'src/constants/affectation';
import { baseColors } from 'src/constants/color';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import { priorityColor } from 'src/helpers/priorityColor';
import useAuth from 'src/hooks/useAuth';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { Affectation_Setting_model, Affectation_v4_Type } from 'src/models/Affectation_v4_type';
import {
  addCourielToHistory,
  addCourielToTrash,
  updateAffectation,
  archiveCouriel
} from 'src/redux/slices/affectation_v4';
import { dispatch, useSelector } from 'src/redux/store';
import PriorityFlags from 'src/section/tasks/PriorityFlags';
import createAvatar, { getAvatarUrl } from 'src/utils/createAvatar';
import { gDate } from 'src/utils/formatTime';
import { serverTime } from './../../../utils/serverTime';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';
import { getAffectationById } from './../../../redux/slices/affectations';
import { transformUserToAccess } from 'src/helpers/user';

import LightboxModal from 'src/components/LightboxModal';
import { getFileThumbSecond } from 'src/utils/getFileFormat';
import FilesReader from 'src/components/FilesReader';
import { RejectMessage } from 'src/components/OnRejectComponent';
import OkyCalendar from 'src/components/calendar/OkyCalendar';
import AffectationNoteFileView from 'src/components/AffectationNoteFileView';
import useDocument from '../hook/useDocument';
import useRappel from 'src/hooks/useRappel';
import { AffectationPdfModal } from 'src/pages/dashboard/AffectationPdf';
import { useGetMyLevel } from '../hook/useAffectation_v4';

/**
 * Renders an AffectationLine component that displays information about an affectation.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.affectation - The affectation object.
 * @param {Function} props.onOpen - A function to call when the affectation is clicked.
 * @param {Function} props.handleClick - A function to call when the affectation checkbox is clicked.
 * @param {Array} props.selected - An array of selected affectation IDs.
 * @param {number} props.index - The index of the affectation in the list.
 * @param {boolean} props.disabled - Whether the affectation is disabled.
 * @param {boolean} props.canOpenDetails - Whether the affectation details can be opened.
 * @returns {JSX.Element} - The AffectationLine component.
 */
export default function AffectationLine({
  affectation,
  onOpen,
  handleClick,
  selected,
  index,
  disabled,
  canOpenDetails
}) {
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(affectation.id);
  const { rappels, remove, loading: rl } = useRappel({ docId: affectation?.id });
  const { user } = useAuth();
  const {
    _saveWithoutDocument,
    _setSaveWithoutDocument,
    saveWithoutUpdate,
    displaySaveWithoutUpdate,
    hidesaveWithoutUpdate,
    saveWithoutDocument,
    displaySaveWithoutDocument,
    hideSaveWithoutDocument
  } = useDocument();
  const [tempField, setTempField] = useState(null);
  const { assignationOrganigramme } = affectation;

  const [assigne, setAssigne] = useState(assignationOrganigramme || []);

  const handleSaveWithoutDocument = () => {
    _setSaveWithoutDocument(true);
  };

  const handleSaveWithoutUpdate = () => {
    _setSaveWithoutDocument(false);
    hidesaveWithoutUpdate();
  };

  const labelId = `enhanced-table-checkbox-${index}`;
  //animation de zoom leger
  const bellAnimation = useSpring({
    loop: true,
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.2)' },
    config: { duration: 1000 }
  });

  //console.log('affectation', affectation);

  const hasRappel = useMemo(() => {
    return rappels?.length > 0;
  }, [rappels]);

  //console.log('hasRappel', hasRappel);

  const handleUpdateAffectation = (field, value) => {
    // console.log({ field, value });
    let affect = { ...affectation };
    if (isArray(field)) {
      field.map((_val, index) => {
        affect = { ...affect, [field[index]]: value[index] };
      });
    }

    if (isString(field)) {
      affect = { ...affect, [field]: value };
    }

    setTempField(affect);

    dispatch(
      updateAffectation({
        newAffectation: { ...affect },
        oldAffectation: affectation,
        callback: () => { },
        onError: () => { }
      })
    );
  };

  const _hideSaveWithoutDocument = () => {
    //reset the affectation
    setTempField(null);
    hideSaveWithoutDocument();
  };

  const _handleSaveWithoutDocument = () => {
    //save the affectation without document
    dispatch(
      updateAffectation({
        newAffectation: { ...affectation, ...tempField },
        oldAffectation: affectation,
        callback: () => { },
        onError: () => { }
      })
    );
    handleSaveWithoutDocument();
    hideSaveWithoutDocument();
    setTempField(null);
  };

  const _handleSaveWithoutUpdate = () => {
    //save the affectation without document
    dispatch(
      updateAffectation({
        newAffectation: { ...affectation, ...tempField },
        oldAffectation: affectation,
        callback: () => { },
        onError: () => { }
      })
    );
    handleSaveWithoutUpdate();
    hidesaveWithoutUpdate();
    setTempField(null);
  };


  const rapelTooltip = useMemo(() => {
    return rappels
      ?.map((r) => {
        return `${r.title} - ${r.description} - ${format(r.initialDate.toDate(), 'dd/MM/yyyy', { locale: LocalFr })}`;
      })
      .join('\n');
  }, [rappels]);


  return (
    <Stack
      width={1}
      component={Paper}
      sx={{ cursor: 'pointer', opacity: !canOpenDetails ? 0.6 : 1 }}
      disabled={disabled}
    >
      <TableBaseLine
        height={55}
        cols={[
          {
            element: (
              <GridStack alignItems="center">
                <Checkbox
                  onClick={(e) => handleClick(e, affectation?.id)}
                  color="primary"
                  checked={isItemSelected}
                  size="small"
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                />
              </GridStack>
            ),
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            borderBottom: `1px solid #D9D9D9`,
            borderLeft: `3px solid ${(affectation?.courriel_type || affectation?.types) === AFFECT_TYPE.Out
              ? baseColors.BLUE
              : baseColors.GREEN
              }`
          },
          {
            element: (
              <GridStack pl={1} alignItems="flex-start">
                <Stack direction="row" alignItems="flex-start">
                  <TextMaxLine maxLength={6} fontSize={13}>
                    {affectation.save_reference.replace('AFF_', '') || ''}
                  </TextMaxLine>
                </Stack>
              </GridStack>
            ),
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack pl={1} alignItems="center">
                <Stack direction="row" alignItems="center">
                  <TextMaxLine fontSize={13} maxLength={6}>
                    {affectation?.courriel_reference || ''}
                  </TextMaxLine>
                </Stack>
              </GridStack>
            ),
            width: 100,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack pl={1} alignItems="center" direction="row" justifyContent="space-between">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width={{ md: 0.9, lg: 0.95, xl: 1 }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack width={1} onClick={!canOpenDetails ? undefined : onOpen}>
                    <TextMaxLine fontSize={13}>{affectation.courriel_object || ''}</TextMaxLine>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {hasRappel && (
                      <Tooltip title={rapelTooltip} arrow>
                        <IconButton size="small" sx={{ p: 0.1 }}>
                          <animated.div style={bellAnimation}>
                            <Icon icon="eva:bell-fill" color={baseColors.ORANGE} height={15} width={15} />
                          </animated.div>
                        </IconButton>
                      </Tooltip>
                    )}
                    <AffectAction affect={affectation} disabled={disabled} canOpenDetails={canOpenDetails} />
                  </Stack>
                </Stack>
              </GridStack>
            ),
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack alignItems="start" pl={1} maxWidth={150}>
                <TextMaxLine fontSize={13}>{affectation.correspondant.title || ''}</TextMaxLine>
              </GridStack>
            ),
            width: 500,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack
                pl={1}
                onClick={onOpen}
                alignItems="start"
                sx={{
                  cursor: 'pointer',
                  ...(isEmpty(affectation.annotation) && {
                    my: 3,
                    mx: 2,
                    width: '80%',
                    border: `2px solid ${baseColors.RED}`,
                    borderRadius: 0.3,
                    transform: 'scale(0.9)',
                    animation: 'pulse 2s infinite'
                  })
                }}
                maxWidth={200}
              >
                <TextMaxLine fontSize={13}>
                  {isArray(affectation.annotation) ? affectation.annotation?.join(', ') : affectation.annotation}
                </TextMaxLine>
              </GridStack>
            ),
            width: 500,
            pulse: isEmpty(affectation.annotation),
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack>
                <AffetLinePriority affect={affectation} onChange={handleUpdateAffectation} disabled={disabled} />
              </GridStack>
            ),
            width: 250,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack>
                <AffectLineDue affect={affectation} onChange={handleUpdateAffectation} disabled={disabled} />
              </GridStack>
            ),
            width: 300,
            minWidth: 120,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack>
                <AffectLineAssigne affect={affectation} onChange={handleUpdateAffectation} disabled={disabled} />
              </GridStack>
            ),
            width: 200,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          },
          {
            element: (
              <GridStack>
                <AffectLineValidation affect={affectation} onChange={handleUpdateAffectation} disabled={disabled} />
              </GridStack>
            ),
            width: 200,
            borderLeft: `1px solid #D9D9D9`,
            borderBottom: `1px solid #D9D9D9`
          }
        ]}
      />
      {saveWithoutDocument && (
        <ActionModal
          open={Boolean(saveWithoutDocument)}
          title="Enregistrer sans document ?"
          desc="Voulez-vous enregistrer cette affectation sans document ?"
          onClose={_hideSaveWithoutDocument}
          onAccept={_handleSaveWithoutDocument}
          action={
            <LoadingButton color="primary" variant="contained" onClick={_handleSaveWithoutDocument} disabled={rl}>
              Enregistrer
            </LoadingButton>
          }
        />
      )}
      {saveWithoutUpdate && (
        <ActionModal
          open={Boolean(saveWithoutUpdate)}
          onClose={hidesaveWithoutUpdate}
          onAccept={_handleSaveWithoutUpdate}
          title="Enregistrer sans annotation ?"
          desc="Voulez-vous enregistrer cette affectation sans annotation ?"
          action={
            <LoadingButton color="primary" variant="contained" onClick={_handleSaveWithoutUpdate} disabled={rl}>
              Enregistrer
            </LoadingButton>
          }
        />
      )}
    </Stack>
  );
}

/**
 *
 * @param {{affect: Affectation_v4_Type}} props
 * @returns
 */
const AffectAction = ({ affect, disabled, canOpenDetails }) => {
  const anchorEl = useRef();
  const [deleteOk, setDeleteOK] = useState(false);
  const [details, openDetails] = useState(null);
  const [open, onOpen, onClose] = useToggleV2();
  const [openD, onOpenD, onCloseD] = useToggleV2();
  const [openI, onOpenI, onCloseI] = useToggleV2();
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const [record, setRecord] = useState(null);
  const [canPrint, setCanPrint] = useState(null);
  const {
    handleOpenDetails,
    settings: { deleteIDs, printerIDs, archiveIDs }
  } = useAffectation_v4Context();
  const { enqueueSnackbar } = useSnackbar();
  const { user: { uid: currentUserId } = {} } = useAuth();
  const { users } = useSelector((state) => state.user);

  const canPrintUsers = useMemo(() => {
    return printerIDs?.map((_id) => users.find((user) => user?.id === _id));
  }, [printerIDs, users]);

  const iCanPrint = useMemo(() => {
    return printerIDs?.find((_id) => _id === currentUserId);
  }, [printerIDs, currentUserId]);

  const onOpenDetails = () => {
    handleOpenDetails(affect, disabled);
    onClose();
  };

  const disableDeleteButton = useMemo(() => {
    //utilise le deleteIds pour savoir si l'utilisateur peut supprimer
    if (affect.createdBy === currentUserId || deleteIDs?.find((_id) => _id === currentUserId)) {
      return false;
    }
    return true;
  }, [affect, currentUserId, deleteIDs]);

  const disablePrintButton = useMemo(() => {
    if (printerIDs?.find((_id) => _id === currentUserId)) {
      return false;
    }
    return true;
  }, [currentUserId, printerIDs]);

  const disableArchiveButton = useMemo(() => {
    if (archiveIDs?.find((_id) => _id === currentUserId)) {
      return false;
    }
    return true;
  }, [currentUserId, archiveIDs]);

  const handleDeletion = () => {
    onCloseD();
    if (disableDeleteButton) {
      enqueueSnackbar("Vous n'avez pas le droit de supprimer cette affectation", { variant: 'error' });
      return;
    }
    dispatch(
      addCourielToTrash({
        affect: [affect],
        callback: () => {
          enqueueSnackbar('Affectation supprimée avec succès', { variant: 'success' });
        }
      })
    );
  };

  const onDirectPrint = (_details = null) => {
    //console.log('onDirectPrint', _details);
    const res = _details || record;
    setRecord(res);

  };

  const addPrinter = () => {
    //onCloseI();

    const res = details || record;
    setRecord(res);
    onOpenP();
    dispatch(
      updateAffectation({
        affect: { ...res, state: AFFECT_STATE_VALIDATION.PENDING, canPrint: transformUserToAccess(canPrint) },
        oldAffect: details,
        isPrint: true,
        callback: () => {
          enqueueSnackbar('Demande envoyée avec succès', { variant: 'success' });
        }
      })
    );
  };

  const toPrint = (val) => {
    onCloseI();
    setRecord(val);
    onOpenP();
  };

  const openDetailsById = (id, type) => {
    dispatch(
      getAffectationById(
        id,
        (value) => openDetails(value),
        () => {
          enqueueSnackbar(`Cette affectation n'existe plus`, { variant: 'warning' });
        }
      )
    );
  };

  const clickClosr = () => {
    onClose();
    onOpenD();
  };

  const archive = () => {
    dispatch(
      archiveCouriel({
        affect: [affect],
        callback: () => {
          enqueueSnackbar('Élément(s) archivé(s) avec succès', { variant: 'success' });
        }
      })
    );
  };

  const onHandleClosePrint = () => {
    onCloseP();
    setRecord(null);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Stack ref={anchorEl}>
        <CIconButton noBorder onClick={onOpen} size="small" disabled={!canOpenDetails}>
          <Icon icon="eva:more-vertical-fill" />
        </CIconButton>
      </Stack>
      {open && (
        <MenuPopover open={open} onClose={onClose} width={200} disabledArrow anchorEl={anchorEl.current}>
          {canOpenDetails && disabled ? (
            <MenuLine
              title="Ouvrir"
              color={baseColors.BLACK}
              onClick={onOpenDetails}
              icon="clarity:play-solid"
              disabled={!canOpenDetails}
            />
          ) : (
            <MenuLine
              title="Editer"
              color={baseColors.BLACK}
              onClick={onOpenDetails}
              icon="material-symbols:edit-square-outline-rounded"
              disabled={!canOpenDetails}
            />
          )}

          <MenuLine
            title="Imprimer"
            color={baseColors.BLACK}
            onClick={onOpenI}
            icon="ion:print"
            disabled={disablePrintButton || disabled}
          />
          {affect?.state === AFFECT_STATE_VALIDATION.ACCEPTED && (
            <MenuLine
              title="Archiver"
              color={baseColors.BLACK}
              onClick={archive}
              icon="ic:round-archive"
              disabled={disableArchiveButton || disabled}
            />
          )}
          <MenuLine
            title="Supprimer"
            color={baseColors.RED}
            onClick={clickClosr}
            icon="eva:trash-fill"
            disabled={disableDeleteButton || disabled}
          />
        </MenuPopover>
      )}
      {openD && (
        <ActionModal
          title="Supprimer cette affectation ?"
          desc="Cette action ne peut pas être annulée"
          color="error"
          open={openD}
          onClose={onCloseD}
          moreAction={
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              onClick={() => setDeleteOK(!deleteOk)}
              sx={{ cursor: 'pointer' }}
            >
              <Checkbox size="small" color="error" checked={deleteOk} />
              <Typography fontSize={13} fontWeight={120} sx={{ textAlign: 'center' }}>
                Je comprends qu'une fois supprimé, cette affectation ne peut pas être récupérée.
              </Typography>
            </Stack>
          }
          action={
            <LoadingButton color="error" variant="contained" disabled={!deleteOk || disabled} onClick={handleDeletion}>
              Supprimer
            </LoadingButton>
          }
        />
      )}
      {openI && !iCanPrint && (
        <ActionModal
          title="Envoyer la demande d'impression ?"
          desc="Vous n'avez pas le droit d'imprimer cette affectation , vous pouvez envoyer une demande d'impression"
          color="info"
          open={openI}
          onClose={onCloseI}
          removeZIndex
          moreAction={
            <Autocomplete
              size="small"
              sx={{ minWidth: 200 }}
              multiple
              options={canPrintUsers || []}
              getOptionLabel={(option) => option.displayName}
              onChange={(_, value) => setCanPrint(value)}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          }
          action={
            <LoadingButton
              color="info"
              variant="contained"
              disabled={!canPrint?.length || disabled}
              onClick={addPrinter}
            >
              Envoyer
            </LoadingButton>
          }
        />
      )}
      {openI && iCanPrint && (
        <ActionModal
          title="Imprimer cette affectation ?"
          desc={"Voulez vous  imprimer l'affectation N°" + affect?.save_reference}
          color="info"
          open={openI}
          onClose={onCloseI}
          removeZIndex
          action={
            <LoadingButton color="info" variant="contained" disabled={disabled} onClick={() => toPrint(affect)}>
              Imprimer
            </LoadingButton>
          }
        />
      )}

      {openP && <AffectationPdfModal open={openP} onClose={onHandleClosePrint} affect={affect} disabled={disabled} />}
    </Stack>
  );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field:string, value: any) }} */
export const AffetLinePriority = ({ affect, disabled, onChange }) => {
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const priorityRef = useRef();
  const [priority, setPriority] = useState(affect.priority);

  React.useEffect(() => setPriority(affect.priority), [affect]);

  const handleClose = (val) => {
    onCloseP();
    onChange('priority', val);
  };

  return (
    <>
      {!isEmpty(priority) ? (
        <Stack>
          <Stack width={35}>
            <Tooltip title={priority}>
              <IconButton
                ref={priorityRef}
                onClick={disabled ? undefined : onOpenP}
                size="small"
                variant="outlined"
                color="neutral"
                sx={{ ml: 1, p: 0.65 }}
              >
                <Icon icon="eva:flag-fill" color={priorityColor(priority)} height={15} width={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      ) : (
        <Stack ref={priorityRef} width={1} alignItems="center">
          <Stack width={35}>
            <Tooltip title="Ajouter une priorité" arrow>
              <IconButton
                variant="outlined"
                color="neutral"
                onClick={onOpenP}
                size="small"
                sx={{ ml: 1, p: 0.65 }}
                disabled={disabled}
              >
                <Icon icon="eva:flag-outline" height={15} width={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}
      {openP && (
        <PriorityFlags
          value={priority}
          open={openP}
          onClose={handleClose}
          onChange={setPriority}
          anchorRef={priorityRef}
          list={AFFECT_PRIORITY}
        />
      )}
    </>
  );
};

/** @param {{ vue: 'input' | 'default', affect: Affectation_v4_Type, onChange: (field:string, value) ,disabled: boolean, defaultPosition: number| null}} */
export const AffectLineDue = ({ affect, onChange, vue, disabled, defaultPosition = null }) => {
  const { settings: { organigramme } = Affectation_Setting_model } = useAffectation_v4Context();
  const { user } = useAuth();
  const { due } = affect;
  const [dueDates, setDuedates] = useState(due || []);
  const [hover, onHover, endHover] = useToggleV2();
  const [open, onOpen, onClose] = useToggleV2();
  const anchorEl = useRef();
  const { myLevelDate: getMyLvelDate, getMyLevel } = useGetMyLevel()

  useEffect(() => {
    if (dueDates !== affect.due && affect.due) {
      setDuedates(affect.due);
    }
    ;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affect]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const myLevel = useMemo(() => getMyLevel({ due: dueDates, setDuedates }), [dueDates]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const myLevelDate = useMemo(() => getMyLvelDate(dueDates, setDuedates, defaultPosition), [dueDates, defaultPosition]);

  const handleChangeDate = (datestr) => {
    const jsDate = new Date(datestr);

    let newDates = [...dueDates];

    newDates = newDates.map((_d, index) => {
      if (index === myLevel.position + 1) {
        return {
          ..._d,
          date: jsDate
        };
      }
      return _d;
    });
    setDuedates(newDates);
    onChange('due', newDates);
  };

  const onRemoveDate = () => {
    let newDates = [...dueDates];
    newDates = newDates.map((_d, index) => {
      if (index === myLevel.position + 1) {
        return {
          ..._d,
          date: null
        };
      }
      return _d;
    });

    setDuedates(newDates);
    onChange('due', newDates);
  };

  return myLevelDate ? (
    <Sheet
      component={Stack}
      direction="row"
      spacing={0.5}
      width={1}
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer', px: 1, py: 1, borderRadius: 'sm' }}
      onMouseEnter={onHover}
      onMouseLeave={endHover}
      variant={vue === 'input' ? 'soft' : 'plain'}
    >
      <Stack width={1} fontWeight="bold" fontSize={12}>
        {format(gDate(myLevelDate), 'dd/MM/yy', { locale: LocalFr })}
      </Stack>
      {hover && (
        <CIconButton
          title="Supprimer"
          noBorder
          size="small"
          sx={{ p: 0.1 }}
          onClick={onRemoveDate}
          disabled={disabled}
          ref={anchorEl}
        >
          <Icon height={15} width={15} icon="eva:close-fill" color="red" />
        </CIconButton>
      )}
    </Sheet>
  ) : (
    <Sheet
      component={Stack}
      position="relative"
      variant={vue === 'input' ? 'soft' : 'plain'}
      width={1}
      alignItems={vue === 'input' ? 'flex-end' : 'center'}
      sx={{ cursor: 'pointer', px: 1, py: 0.5, borderRadius: 'sm' }}
    >
      <CIconButton
        title="ajouter une échéance"
        {...(vue === 'input' && { noBorder: true })}
        disabled={disabled}
        onClick={onOpen}
        ref={anchorEl}
      >
        <Iconify icon="bx:calendar" />
      </CIconButton>
      <Stack width={1} position="absolute" sx={{ cursor: 'pointer' }}>
        <OkyCalendar
          open={open}
          onChange={(date) => {
            handleChangeDate(date[1]);
            onClose();
          }}
          singleDate={true}
          anchorEl={anchorEl.current}
          dates={[]}
          openStart={true}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        />
      </Stack>
    </Sheet>
  );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field, value) }} */
export const AffectLineAssigne = ({ affect, onChange, disabled }) => {
  const { settings: { organigramme } = Affectation_Setting_model } = useAffectation_v4Context();
  const { users } = useSelector((state) => state.user);
  const { user } = useAuth();
  const { assignationOrganigramme } = affect;
  const [assigne, setAssigne] = useState(assignationOrganigramme || []);

  useEffect(() => setAssigne(affect?.assignationOrganigramme || []), [affect]);

  //console.log('assigne', assignationOrganigramme);

  const isLastLevel = useMemo(() => {
    const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    return position === organigramme.length - 1;
  }, [organigramme, user.uid]);

  const myLevel = useMemo(() => {
    const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    if (!assigne.length) {
      const _ass = organigramme.map((org) => ({ ...org, users: [] }));
      if (position !== -1) {
        return {
          assigne: _ass,
          position
        };
      }
      return { assigne: _ass, position: 0 };
    }
    return { assigne: assigne, position };
  }, [assigne, organigramme, user.uid]);

  const toCompleteUser = useMemo(
    () =>
      myLevel?.assigne?.at(myLevel.position + 1)?.users?.map((userId) => users.find((user) => user?.id === userId)) ||
      [],
    [users, myLevel]
  );

  const handleAssigne = (users) => {
    const userIds = users?.map((user) => user?.id);
    let newOrg = [...assigne];

    newOrg = newOrg.map((item, index) => {
      if (index === myLevel.position + 1) {
        return { ...item, users: userIds };
      }
      return item;
    });

    setAssigne(newOrg);

    const oldAssigne = affect.assignationOrganigramme;
    const newOrganigramme = newOrg;

    let toDoIds = affect.toDoIds;
    let toReviewIds = affect.toReviewIds;

    if (userIds?.length) {
      toReviewIds = uniq([...affect.toReviewIds, user.uid]);

      newOrganigramme.forEach((level, index) => {
        const oldLevel = oldAssigne[index];
        const { added } = compareArraysOfObjects(oldLevel?.users || [], level.users);

        toReviewIds = toReviewIds.filter((oneId) => !added.some((one) => one === oneId));

        toDoIds = uniq([...toDoIds, ...added]);
      });
      toReviewIds.forEach((_id) => (toDoIds = toDoIds.filter((_oneId) => _oneId !== _id)));
    }

    onChange(['assignationOrganigramme', 'toDoIds', 'toReviewIds'], [newOrg, toDoIds, toReviewIds]);
  };

  const userList = useMemo(
    () => {
      const levelusers = myLevel?.assigne?.at(myLevel.position + 1)?.users;

      if (levelusers?.length === 0) {
        const levelDownUsers = myLevel?.assigne?.find((item) => item.users?.length > 0);
        const res = levelDownUsers?.users?.map((userId) => userId);
        return res;
      }

      return [...(levelusers || [])];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastLevel, myLevel?.assigne, myLevel.position]
  );

  const allUsers = useMemo(() => {
    //merge all postion users
    return organigramme
      ?.map((level, index) => {
        return level?.users
          ?.map((userId) => {
            return users.find((user) => user?.id === userId);
          })
          .filter(Boolean)
          .map((user) => {
            return { ...user, level: index };
          });
      })
      .flat();
  }, [organigramme, users]);


  return (
    <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
      <ContactsDialog
        assigne={toCompleteUser}
        onAssigne={handleAssigne}
        disabled={disabled || isLastLevel}
        CustomList={allUsers}
        action={(popoverRef, onClick) => (
          <Box ref={popoverRef} onClick={onClick}>
            {userList?.length ? (
              <Avatar.Group maxCount={1}>
                {userList?.map((userId) => {
                  const user = users.find((one) => one?.id === userId);
                  return (
                    <Tooltip key={userId} title={user?.displayName || ''} arrow>
                      <Avatar
                        key={userId}
                        src={getAvatarUrl(user)}
                        style={{
                          backgroundColor: createAvatar(user?.displayName).color2,
                          fontWeight: 'bold',
                          borderRadius: 10,
                          marginRight: 8
                        }}
                      >
                        {createAvatar(user?.displayName).name}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            ) : (
              <CIconButton
                noBorder
                title={`ajouter ou retirer un ${myLevel?.assigne?.at(myLevel.position + 1)?.label || ''}`}
              >
                <Iconify icon="humbleicons:user-add" />
              </CIconButton>
            )}
          </Box>
        )}
      />
    </Stack>
  );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field, value) ,disabled: boolean}} */
export const AffectLineValidation = ({ affect, onChange, disabled }) => {
  const [completed, setCompleted] = useState(affect.completed);
  const [validated, setValidated] = useState(affect.validation);
  const { settings: { organigramme, archiveIDs } = { organigramme: [] } } = useAffectation_v4Context();
  const { open, handleOpen, handleClose } = useToggle();
  const [hover, onHover, endHover] = useToggleV2();
  const [openNote, onOpenNote, onCloseNote] = useToggleV2();
  const [openAttachements, onOpenAttachements, onCloseAttachements] = useToggleV2();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const noteViewRef = useRef();

  useEffect(() => {
    setValidated(affect.validation);
    setCompleted(affect.completed);
  }, [affect.validation, affect.completed]);

  const handleChangeDone = async () => {
    //TODO : revoir cette partie
    setCompleted(true);
    let val = { ...validated, state: false, by: null, isReject: false };
    onChange(['completed', 'completedBy', 'validation'], [true, user.uid, val]);
    await dispatch(
      updateAffectation({
        newAffectation: {
          ...affect,
          toReviewIds: uniq([...affect.toReviewIds, user.uid]),
          toDoIds: affect.toDoIds.filter((oneId) => oneId !== user.uid),
          completed: true,
          completedBy: user.uid,
          validation: val
        },
        oldAffectation: affect,
        callback: () => {
          enqueueSnackbar('Affectation marquée comme terminée avec succès', { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar("Erreur lors de l'enregistrement de l'affectation", { variant: 'error' });
        }
      })
    );
  };

  const handleArchive = async () => {
    dispatch(
      archiveCouriel({
        affect: [affect],
        callback: () => {
          enqueueSnackbar('Élément(s) archivé(s) avec succès', { variant: 'success' });
        }
      })
    );
  };

  const disableArchiveButton = useMemo(() => {
    if (!archiveIDs?.find((_id) => _id === user.uid)) return true;
    return false;
  }, [archiveIDs, user.uid]);

  const handleChangeNotDone = () => {
    setCompleted(false);
    onChange(['completed', 'completedBy'], [false, null]);
  };

  const handleChangeNotDoneWithNote = (note) => {
    setCompleted(false);
    let val = { ...validated, state: false, by: null, isReject: true };
    if (note) {
      val = { ...val, rejectNote: [...(validated?.rejectNote || []), note] };
    }
    setValidated(val);
    onChange(['completed', 'completedBy', 'validation'], [false, null, val]);
  };

  const handleReject = (value, attachement) => {
    const note = value;
    if (note) {
      handleChangeNotDoneWithNote({ by: user.uid, note, at: serverTime(), attachement });
      enqueueSnackbar('Affectation rejetée avec succès', { variant: 'success' });
      handleClose();
    } else {

      handleChangeNotDone();
      enqueueSnackbar('Affectation rejetée avec succès', { variant: 'success' });
      handleClose();
    }
  };

  const handleValidate = async () => {
    const val = { ...validated, state: true, by: user.uid, isReject: false };
    //setValidated(val);
    onChange('validation', val);
    dispatch(
      await addCourielToHistory({
        affect: affect,
        callback: () => {
          enqueueSnackbar('Affectation validée avec succès', { variant: 'success' });
        }
      })
    );
  };

  //console.log(validated);

  const DoneButton = () => {
    return (
      <>
        <Stack direction="row">
          <Stack width={35}>
            <Tooltip title="Marquer comme non terminée" arrow>
              <IconButton
                variant="solid"
                color="success"
                size="small"
                sx={{ ml: 1, p: 0.65 }}
                onClick={handleChangeNotDone}
                disabled={disabled}
              >
                <Icon icon="eva:checkmark-outline" height={15} width={15} />
              </IconButton>
            </Tooltip>
          </Stack>

          <Stack width={35}>
            <Tooltip title="Archiver" arrow>
              <IconButton
                variant="outlined"
                color="neutral"
                size="small"
                sx={{ ml: 1, p: 0.65 }}
                onClick={handleArchive}
                disabled={disableArchiveButton || disabled}
              >
                <Icon icon="ic:round-archive" height={15} width={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </>
    );
  };

  const levelDown = useMemo(() => {
    const myposition = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    const completedByposition = organigramme.findIndex((one) =>
      one.users.find((_userId) => _userId === affect.completedBy)
    );
    if (completedByposition !== -1 && myposition !== -1) {
      return myposition > completedByposition;
    }

    return false;
  }, [affect.completedBy, organigramme, user.uid]);

  return (
    <Stack>
      {completed ? (
        affect.completedBy === user.uid || levelDown ? (
          <Stack direction="row" spacing={0.5}>
            <DoneButton />
          </Stack>
        ) : !validated.state ? (
          <ButtonGroup size="sm">
            <Tooltip title="rejeter" arrow>
              <IconButton color="danger" variant="solid" onClick={handleOpen} disabled={disabled}>
                <Iconify icon="uiw:dislike-o" />
              </IconButton>
            </Tooltip>
            <Tooltip title="accepter" arrow>
              <IconButton color="success" variant="solid" onClick={handleValidate} disabled={disabled}>
                <Iconify icon="uiw:like-o" />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        ) : (
          <Stack direction="row" spacing={0.5}>
            <DoneButton />
          </Stack>
        )
      ) : (
        <Stack direction="row" spacing={0.5}>
          <CIconButton title="Marquer comme terminée" onClick={handleChangeDone} disabled={disabled}>
            <Iconify icon="icon-park-outline:done-all" />
          </CIconButton>
          {affect.validation.isReject && (
            <Stack ref={noteViewRef}>
              <CIconButton
                title="Note de reject"
                sx={{ borderColor: baseColors.RED }}
                onClick={onOpenNote}
                disabled={disabled}
              >
                <Iconify icon="ph:note-light" className="clignotant" sx={{ color: baseColors.RED }} />
              </CIconButton>
            </Stack>
          )}
        </Stack>
      )}

      {open && <RejectMessage open={open} onClose={handleClose} onReject={handleReject} />}
      {openNote && (
        <NoteViewer
          open={openNote}
          onClose={onCloseNote}
          note={validated?.rejectNote}
          anchor={noteViewRef?.current}
          openAttachements={openAttachements}
          onCloseAttachements={onCloseAttachements}
          onOpenAttachements={onOpenAttachements}
        />
      )}
    </Stack>
  );
};

/**
 *
 * @param {{vue: 'input' | 'default',date:Date|null, onChange:(value:Date|null)}}
 */
export const AffectLineDate = ({ vue, date, onChange, error, helperText, disabled = false }) => {
  const [value, setValue] = useState(date);
  const [hover, onHover, endHover] = useToggleV2();

  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    const parsedDate = new Date(year, month - 1, day, hours, minutes);
    return parsedDate;
  };

  const handleChange = (val) => {
    if (disabled) return;

    const change = (val) => {
      setValue(val);
      if (onChange) onChange(val);
    };

    if (!val) return change(null);

    change(parseDateString(val));
  };

  return (
    <>
      {value ? (
        <Sheet
          component={Stack}
          direction="row"
          spacing={0.5}
          width={1}
          justifyContent="center"
          alignItems="center"
          sx={{ cursor: 'pointer', px: 1, py: 1, borderRadius: 'sm' }}
          onMouseEnter={onHover}
          onMouseLeave={endHover}
          variant={vue === 'input' ? 'soft' : 'plain'}
        >
          <Stack width={1} fontWeight="bold" fontSize={12}>
            {format(gDate(value), 'dd/MM/yy, HH:mm', { locale: LocalFr })}
          </Stack>
          {hover && (
            <CIconButton title="Supprimer" noBorder size="small" sx={{ p: 0.1 }} onClick={(env) => handleChange(null)}>
              <Icon height={15} width={15} icon="eva:close-fill" color="red" />
            </CIconButton>
          )}
        </Sheet>
      ) : (
        <Sheet
          component={Stack}
          position="relative"
          variant={vue === 'input' ? 'soft' : 'plain'}
          width={1}
          alignItems={vue === 'input' ? 'flex-end' : 'center'}
          sx={{ cursor: 'pointer', px: 1, py: 0.5, borderRadius: 'sm' }}
        >
          <CIconButton title="ajouter une échéance" {...(vue === 'input' && { noBorder: true })}>
            <Iconify icon="bx:calendar" />
          </CIconButton>
          <Stack width={1} position="absolute" sx={{ cursor: 'pointer' }}>
          </Stack>
        </Sheet>
      )}
      {error && (
        <Typography fontSize={11} pl={1} color={'error'}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

/**
 * A React component that displays a timeline of notes, with each note containing information about the user who created it, the note text, and any attachments.
 *
 * @param {Object} props - The component props.
 * @param {Array<{ by: string, note: string, at: Date, attachement: Array<Object> }>} props.note - An array of note objects, each containing information about the note.
 * @param {boolean} props.open - A boolean indicating whether the note viewer popover is open.
 * @param {function} props.onClose - A callback function to be called when the note viewer popover is closed.
 * @param {HTMLElement} props.anchor - The HTML element that the note viewer popover is anchored to.
 * @param {function} props.onCloseAttachements - A callback function to be called when the attachments popover is closed.
 * @param {function} props.onOpenAttachements - A callback function to be called when the attachments popover is opened.
 * @param {boolean} props.openAttachements - A boolean indicating whether the attachments popover is open.
 * @returns {JSX.Element} - The rendered NoteViewer component.
 */
export const NoteViewer = ({
  note,
  open,
  onClose,
  anchor,
  onCloseAttachements,
  onOpenAttachements,
  openAttachements
}) => {
  const { users } = useSelector((state) => state.user);
  return (
    <>
      <CustomMenuPopover
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        width={{ xs: 1, md: 'max-content' }}
        sx={{ px: 2, py: 3 }}
      >
        <Timeline>
          {note?.map((_note, index) => (
            <Fragment key={index}>
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  align="right"
                  variant="overline"
                  color="text.secondary"
                  sx={{ fontSize: 9, width: 110, m: 'auto 0' }}
                >
                  {formatDistanceToNowStrict(gDate(_note?.at), { locale: LocalFr })}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <NoteInfo
                    users={users}
                    userId={_note?.by}
                    note={_note?.note}
                    attachement={_note?.attachement}
                    onOpenAttachements={onOpenAttachements}
                    openAttachements={openAttachements}
                    onCloseAttachements={onCloseAttachements}
                  />
                </TimelineContent>
              </TimelineItem>
            </Fragment>
          ))}
        </Timeline>
      </CustomMenuPopover>
    </>
  );
};

/**
 * A React component that displays a popover with attachments.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.attachement - An array of file attachments.
 * @param {boolean} props.open - A boolean indicating whether the popover is open.
 * @param {function} props.onOpen - A callback function to be called when the popover is opened.
 * @param {function} props.onClose - A callback function to be called when the popover is closed.
 * @param {HTMLElement} props.anchor - The HTML element that the popover is anchored to.
 * @param {boolean} props.defaultOpenLightbox - A boolean indicating whether the lightbox should be open by default.
 * @returns {JSX.Element} - The rendered AttachementViewer component.
 */
const AttachementViewer = ({ attachement, open, onOpen, onClose, anchor, defaultOpenLightbox }) => {
  if (!attachement?.length) return null;
  return (
    <>
      <CustomMenuPopover
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        width={{ xs: 1, md: 'max-content' }}
        sx={{ px: 2, py: 3 }}
      >
        <AffectationNoteFileView attachement={attachement} disabled={true} defaultOpenLightbox={defaultOpenLightbox} />
      </CustomMenuPopover>
    </>
  );
};

/**
 * A React component that displays information about a note, including the user who created it, the note text, and any attachments.
 *
 * @param {Object} props - The component props.
 * @param {string} props.userId - The ID of the user who created the note.
 * @param {Array<Object>} props.users - An array of user objects.
 * @param {string} props.note - The text of the note.
 * @param {Array<Object>} props.attachement - An array of file attachments.
 * @param {function} props.onOpenAttachements - A callback function to be called when the attachments popover is opened.
 * @param {function} props.onCloseAttachements - A callback function to be called when the attachments popover is closed.
 * @param {boolean} props.openAttachements - A boolean indicating whether the attachments popover is open.
 * @param {Object} props.sx - The custom styles.
 * @param {boolean} props.hideUser - A boolean indicating whether the user information should be hidden.
 * @returns {JSX.Element} - The rendered NoteInfo component.
 */
export const NoteInfo = ({ userId, users, note, attachement, sx = {}, hideUser = false }) => {
  const anchorEl = useRef();
  const [openAttachements, onOpenAttachements, onCloseAttachements] = useToggleV2();
  const user = useMemo(() => users.find((one) => one?.id === userId), [users, userId]);
  const [onpenPdf, onOpenPdf, onClosePdf] = useToggleV2();
  const [openLightbox, handleOpenLightbox, handleCloseLightbox] = useToggleV2();

  const handleOpenImageOnFullScreen = () => {
    handleOpenLightbox();
  };

  const handleOpenPdfOnFullScreen = () => {
    onOpenPdf();
  };

  const handleOpenPopover = () => {
    onOpenAttachements();
  };

  const AttachementIcon = () => {
    if (attachement?.length === 1) {
      const file = attachement[0];
      if (file.type === 'application/pdf') {
        return (
          <IconButton onClick={() => handleOpenPdfOnFullScreen()}>
            {getFileThumbSecond(file.type, file.url, { width: 16, height: 16 })}
          </IconButton>
        );
      } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return (
          <IconButton onClick={() => handleOpenImageOnFullScreen()}>
            <Iconify icon="mdi:file-image" fontSize={16} sx={{ color: 'grey.500' }} />
          </IconButton>
        );
      } else {
        return (
          <IconButton onMouseEnter={handleOpenPopover}>
            {getFileThumbSecond(file.type, file.url, { width: 16, height: 16 })}
          </IconButton>
        );
      }
    } else if (attachement?.length > 1) {
      const allIsImage = attachement.every((file) => file.type === 'image/jpeg' || file.type === 'image/png');
      if (allIsImage) {
        return (
          <IconButton onClick={() => handleOpenImageOnFullScreen()}>
            <Iconify icon="mdi:file-image" fontSize={16} sx={{ color: 'grey.500' }} />
          </IconButton>
        );
      } else {
        return (
          <IconButton onMouseEnter={handleOpenPopover}>
            <Iconify icon="entypo:attachment" fontSize={16} sx={{ color: 'grey.500' }} />
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Stack width={1} minWidth={200} sx={{ ...sx }}>
        <Stack direction="row" spacing={1} alignItems="center" ref={anchorEl}>
          {
            !hideUser &&
            <Typography variant="subtitle1" fontSize={14} fontWeight="bold" component="span">
              {user?.displayName}
            </Typography>
          }
          <AttachementIcon />
        </Stack>
        <Typography variant="caption" fontSize={13} color="grey.500">
          {note}
        </Typography>
        <AttachementViewer
          open={openAttachements}
          onClose={onCloseAttachements}
          anchor={anchorEl.current}
          attachement={attachement}
          defaultOpenLightbox={false}
        />
        {attachement?.length > 0 && (
          <LightboxModalCombo
            attachement={attachement}
            onOpen={handleOpenLightbox}
            onClose={handleCloseLightbox}
            isOpen={openLightbox}
          />
        )}
        <FilesReaderCombo attachement={attachement} onOpen={onOpenPdf} onClose={onClosePdf} isOpen={onpenPdf} />
      </Stack>
    </>
  );
};

/**
 * A React component that renders a lightbox modal combo box.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.attachement - An array of file attachments.
 * @param {function} props.onOpen - A callback function to be called when the lightbox is opened.
 * @param {function} props.onClose - A callback function to be called when the lightbox is closed.
 * @param {boolean} props.isOpen - A boolean indicating whether the lightbox is open.
 * @returns {JSX.Element} - The rendered lightbox modal combo box.
 */
const LightboxModalCombo = ({ attachement, onOpen, onClose, isOpen }) => {
  const imagesLightbox = useMemo(
    () =>
      attachement?.filter((file) => file.type === 'image/jpeg' || file.type === 'image/png').map((file) => file.url),
    [attachement]
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultOpenLightbox, setDefaultOpenLightbox] = useState(true);

  const handleOpenLightbox = useCallback(
    (url) => {
      const selectedImage = findIndex(imagesLightbox, (index) => index === url);
      //onOpen();
      setSelectedImage(selectedImage);
    },
    [imagesLightbox]
  );

  useEffect(() => {
    if (defaultOpenLightbox) {
      handleOpenLightbox(attachement[0]?.url);
      setDefaultOpenLightbox(false);
    }
  }, [defaultOpenLightbox, handleOpenLightbox, attachement]);
  const handleCloseLightbox = () => {
    onClose();
  };

  if (attachement?.length === 0) return null;
  return (
    <>
      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={isOpen}
        onClose={handleCloseLightbox}
      />
    </>
  );
};

/**
 * A React component that renders a file reader combo box.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.attachement - An array of file attachments.
 * @param {function} props.onOpen - A callback function to be called when the file reader is opened.
 * @param {function} props.onClose - A callback function to be called when the file reader is closed.
 * @param {boolean} props.isOpen - A boolean indicating whether the file reader is open.
 * @returns {JSX.Element} - The rendered file reader combo box.
 */
const FilesReaderCombo = ({ attachement, onOpen, onClose, isOpen }) => {
  const selectedFile = useMemo(() => (attachement?.length > 0 ? attachement[0] : null), [attachement]);

  const handleCloseFile = () => {
    onClose();
  };

  if (attachement?.length === 0) return null;
  return (
    <>
      <FilesReader
        open={isOpen}
        onClose={handleCloseFile}
        docUrl={selectedFile?.url}
        fileName={selectedFile?.name}
        docType={selectedFile?.type}
      />
    </>
  );
};
