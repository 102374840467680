import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import CIconButton from 'src/components/CIconButton';
import Iconify from 'src/components/Iconify';
import { baseColors, themeColor } from 'src/constants/color';
import { checkIsOne } from 'src/helpers/checkIsOne';
import { TaskType } from 'src/models/task_m';
import extraireTexteHTML from 'src/utils/formatHTMlstring';

const ICON_SIZE = 14;

/**
 *
 * @param {{task: TaskType, handleClick: Function}} props
 * @returns
 */
export default function V4TaskJointe({ task, handleClick }) {
  //console.log('task?.description', task?.description);
  return (
    <Stack direction="row" spacing={0.5} alignItems="center" onClick={handleClick}>
      {Boolean(task?.attachments?.length) ? (
        <CIconButton
          noBorder
          size="small"
          title={`${task?.attachments?.length} Fichier${checkIsOne(task?.attachments) ? '' : 's'}`}
          arrow
          sx={{ bgcolor: 'transparent', fontSize: 12, fontWeight: '400', p: 0.1 }}
        >
          {task?.attachments?.length}
          <Iconify
            icon="fluent:note-20-regular"
            color={baseColors.BLUE_GREEN}
            sx={{ height: ICON_SIZE, width: ICON_SIZE, ml: 0.2 }}
          />
        </CIconButton>
      ) : null}
      {!isEmpty(task?.description?.trim()) && extraireTexteHTML(task?.description?.trim())?.length !== 0 ? (
        <CIconButton
          noBorder
          size="small"
          title="Description"
          arrow
          sx={{ bgcolor: 'transparent', fontSize: 12, fontWeight: '600', p: 0.1 }}
        >
          <Iconify
            icon="mdi-light:comment"
            color={baseColors.BLUE_GREEN}
            sx={{ height: ICON_SIZE, width: ICON_SIZE, ml: 0.2 }}
          />
        </CIconButton>
      ) : null}
      {Boolean(task?.subTasksCount > 0) ? (
        <CIconButton
          noBorder
          size="small"
          title={`${task?.subTasksCount} Sous-tâche${checkIsOne(task?.subTasksCount) ? '' : 's'}`}
          arrow
          sx={{ bgcolor: 'transparent', color: baseColors.BLUE_GREEN, fontSize: 12, fontWeight: '400', p: 0.1 }}
        >
          {task?.subTasksCount}
          <Iconify
            icon="tabler:subtask"
            color={baseColors.BLUE_GREEN}
            sx={{ height: ICON_SIZE, width: ICON_SIZE, ml: 0.2 }}
          />
        </CIconButton>
      ) : null}
    </Stack>
  );
}
