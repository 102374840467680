// material
import { Box } from '@mui/material';
//
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';

// ----------------------------------------------------------------------

export const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp', 'ico', 'tiff', 'tif', 'psd'];
export const FORMAT_VIDEO = [
  'm4v',
  'avi',
  'mpg',
  'mp4',
  'webm',
  'mov',
  'mkv',
  'wmv',
  'flv',
  '3gp',
  'RMVB',
  'quicktime',
  'Matroska',
  'Flash Video',
  'RealVideo'
];
export const FORMAT_AUDIO = ['mp3', 'wav', 'm4a', 'aac', 'flac', 'ogg', 'wma', 'aiff', 'opus', 'm4r', 'mpeg'];
export const FORMAT_WORD = [
  'docx',
  'msword',
  'dotx',
  'docm',
  'dot',
  'doc',
  'wps-office.doc',
  '.docxf',
  '.dotm',
  '.dotx',
  '.epub',
  '.fb2',
  '.fodt',
  '.htm',
  '.html',
  '.mht',
  '.mhtml',
  '.odt',
  '.oform',
  '.ott',
  '.rtf',
  '.stw',
  '.sxw',
  '.txt',
  '.wps',
  '.wpt',
  '.xml'
];
export const FORMAT_TXT = ['.txt'];
export const FORMAT_HTML = ['html', 'mhtml', '.htm', '.html', '.mht', '.mhtml'];
export const FORMAT_EXCEL = [
  'xls',
  'xlsx',
  'sheet',
  'spreadsheetml',
  '.csv',
  '.et',
  '.ett',
  '.fods',
  '.ods',
  '.ots',
  '.sxc',
  '.xlsb',
  '.xlsm',
  '.xlt',
  '.xltm',
  '.xltx',
  '.xml'
];
export const FORMAT_POWERPOINT = [
  'ppt',
  'pptx',
  'presentationml',
  'presentation',
  '.dps',
  '.dpt',
  '.fodp',
  '.odp',
  '.otp',
  '.pot',
  '.potm',
  '.potx',
  '.pps',
  '.ppsm',
  '.ppsx',
  '.pptm',
  '.sxi'
];
export const FORMAT_PDF = ['pdf', 'application/pdf', '.djvu', '.oxps', '.xps'];
export const FORMAT_PHOTOSHOP = ['psd'];
export const FORMAT_ILLUSTRATOR = ['ai', 'esp'];
export const FORMA_ZIP = ['zip', '.zip', 'rar', '.rar'];

export function getFileType(fileUrl = '') {
  const filename = getFilename(fileUrl);
  return filename?.split('.').pop();
}

export function getFileName(fileUrl = '') {
  return fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, '');
}

export function getFileFullName(fileUrl = '') {
  return fileUrl?.split('/').pop();
}

/**
 *
 * @param {string} fileUrl  Peut-être URL, Type, Nom du fichier
 * @returns {'image'| 'video' | 'word' | 'txt' | 'html' | 'excel' | 'powerpoint'| 'pdf' | 'photoshop' | 'illustrator' | 'plain'}
 */
export function getFileFormat(fileUrl = '') {
  let format;

  switch (fileUrl?.includes(getFileType(fileUrl))) {
    case FORMAT_IMG.includes(getFileType(fileUrl)):
      format = 'image';
      break;
    case FORMAT_VIDEO.includes(getFileType(fileUrl)):
      format = 'video';
      break;
    case FORMAT_AUDIO.includes(getFileType(fileUrl)):
      format = 'audio';
      break;
    case fileUrl?.includes('word'):
      format = 'word';
      break;
    case FORMAT_WORD.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case fileUrl?.includes('.txt'):
      format = 'txt';
      break;
    case FORMAT_TXT.includes(getFileType(fileUrl)):
      format = 'txt';
      break;
    case fileUrl?.includes('.html'):
      format = 'html';
      break;
    case FORMAT_HTML.includes(getFileType(fileUrl)):
      format = 'html';
      break;
    case fileUrl?.includes('excel'):
      format = 'excel';
      break;
    case FORMAT_EXCEL.includes(getFileType(fileUrl)):
      format = 'excel';
      break;
    case fileUrl?.includes('powerpoint'):
      format = 'powerpoint';
      break;
    case FORMAT_POWERPOINT.includes(getFileType(fileUrl)):
      format = 'powerpoint';
      break;
    case FORMAT_PDF.includes(getFileType(fileUrl)):
      format = 'pdf';
      break;
    case FORMAT_PHOTOSHOP.includes(getFileType(fileUrl)):
      format = 'photoshop';
      break;
    case FORMAT_ILLUSTRATOR.includes(getFileType(fileUrl)):
      format = 'illustrator';
      break;
    case FORMA_ZIP.includes(getFileType(fileUrl)):
      format = 'zip';
      break;
    default:
      format = getFileType(fileUrl);
  }

  return format;
}

export const getFileExtensionByType = (type) => {
  if (type === 'image') return 'png';
  if (type === 'video') return 'mp4';
  if (type === 'audio') return 'mp3';
  if (type === 'word') return 'docx';
  if (type === 'txt') return 'txt';
  if (type === 'html') return 'html';
  if (type === 'excel') return 'xlsx';
  if (type === 'powerpoint') return 'pptx';
  if (type === 'pdf') return 'pdf';
  if (type === 'photoshop') return 'psd';
  if (type === 'illustrator') return 'ai';
  if (type === 'zip') return 'zip';

  return type;
};

const getIcon = (name, size) => (
  <Box component="img" src={`/static/icons/file/${name}.svg`} alt={name} sx={size ? size : { width: 28, height: 28 }} />
);

export function getFileThumb(fileUrl = '') {
  let thumb;

  switch (getFileFormat(fileUrl)) {
    case 'image':
      thumb = <Box component="img" src={fileUrl} alt={fileUrl} sx={{ width: 1, height: 1 }} />;
      break;
    case 'video':
      thumb = getIcon('file_type_video');
      break;
    case 'audio':
      thumb = getIcon('file_type_audio');
      break;
    case 'word':
      thumb = getIcon('file_type_word');
      break;
    case 'txt':
      thumb = getIcon('file_type_txt');
      break;
    case 'excel':
      thumb = getIcon('file_type_excel');
      break;
    case 'powerpoint':
      thumb = getIcon('file_type_powerpoint');
      break;
    case 'pdf':
      thumb = getIcon('file_type_pdf');
      break;
    case 'html':
      thumb = getIcon('file_type_html');
      break;
    case 'photoshop':
      thumb = getIcon('file_type_photoshop');
      break;
    case 'illustrator':
      thumb = getIcon('file_type_ai');
      break;
    case 'plain':
      thumb = getIcon('file_type_txt');
      break;
    default:
      thumb = <Box component={Icon} icon={fileFill} sx={{ width: 28, height: 28 }} />;
  }
  return thumb;
}

export function getFileThumbSecond(type = '', url, size = null) {
  type = type?.replace('/', '.');

  switch (getFileFormat(type)) {
    case 'image':
      return <Box component="img" src={url} alt={'...'} sx={size ? size : { width: 28, height: 28 }} />;

    case 'video':
      return getIcon('file_type_video', size);
    case 'audio':
      return getIcon('file_type_audio', size);

    case 'word':
      return getIcon('file_type_word', size);
    case 'txt':
      return getIcon('file_type_txt', size);

    case 'excel':
      return getIcon('file_type_excel', size);

    case 'powerpoint':
      return getIcon('file_type_powerpoint', size);

    case 'pdf':
      return getIcon('file_type_pdf', size);
    case 'html':
      return getIcon('file_type_html', size);

    case 'photoshop':
      return getIcon('file_type_photoshop', size);

    case 'illustrator':
      return getIcon('file_type_ai', size);
    case 'zip':
      return getIcon('file_type_zip', size);

    default:
      return getIcon('file_type_uknown', size);
  }
}

export function getFileThumbUrl(type, url) {
  type = type?.replace('/', '.');

  const getIcon = (name) => `/static/icons/file/${name}.svg`;

  switch (getFileFormat(type)) {
    case 'image':
      return url;

    case 'video':
      return getIcon('file_type_video');
    case 'audio':
      return getIcon('file_type_audio');

    case 'zip':
      return getIcon('file_type_zip');

    case 'word':
      return getIcon('file_type_word');
    case 'txt':
      return getIcon('file_type_txt');

    case 'excel':
      return getIcon('file_type_excel');

    case 'powerpoint':
      return getIcon('file_type_powerpoint');

    case 'pdf':
      return getIcon('file_type_pdf');
    case 'html':
      return getIcon('file_type_html');

    case 'photoshop':
      return getIcon('file_type_photoshop');

    case 'illustrator':
      return getIcon('file_type_ai');
    case 'plain':
      return getIcon('file_type_txt');

    default:
      return getIcon('file_type_uknown');
  }
}

export function getFileThumbUrlCustom(type, url) {
  type = type.replace('/', '.');

  const getIcon = (name) => `/static/icons/file/${name}.svg`;

  switch (getFileFormat(type)) {
    case 'image':
      return url;

    case 'video':
      return getIcon('file_type_video');
    case 'audio':
      return getIcon('file_type_audio');

    case 'word':
      return getIcon('file_type_word');
    case 'txt':
      return getIcon('file_type_txt');

    case 'excel':
      return getIcon('file_type_excel');

    case 'powerpoint':
      return getIcon('file_type_powerpoint');

    case 'pdf':
      return getIcon('file_type_pdf');
    case 'html':
      return getIcon('file_type_html');

    case 'photoshop':
      return getIcon('file_type_photoshop');

    case 'illustrator':
      return getIcon('file_type_ai');
    case 'plain':
      return getIcon('file_type_txt');

    default:
      return null;
  }
}

export const getFilename = (url = '') => {
  const removeSlash = url.substring(url.lastIndexOf('/') + 1);
  const removeQuery = removeSlash.includes('?') ? removeSlash.substring(0, removeSlash.lastIndexOf('?')) : removeSlash;
  return removeQuery;
};
