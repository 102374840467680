import { capitalize } from 'lodash';
import { baseColors } from 'src/constants/color';

// ----------------------------------------------------------------------

const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8', 'O'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function cleanName(name) {
  return name?.replace(/[<>]/g, '');
}

/**
 * Retrieves the first character or first two characters of a name.
 *
 * @param {string} name - The name to extract the first character(s) from.
 * @param {boolean} [two=false] - Whether to return the first two characters instead of just the first.
 * @returns {string} The first character or first two characters of the name, capitalized.
 */
function getFirstCharacter(name, two = false) {
  if (!name) return '';

  const checkSecondName = name.split(' ');

  if (two && checkSecondName.length > 0) {
    return `${capitalize(checkSecondName.at(0).charAt(0))} ${capitalize(checkSecondName.at(1).charAt(0))}`;
  }

  return capitalize(name.charAt(0));
}

/**
 * Determines the avatar color based on the first character of the provided name.
 *
 * @param {string} name - The name to use for determining the avatar color.
 * @returns {string} The avatar color, which can be 'primary', 'info', 'success', 'warning', 'error', or 'default'.
 */
export function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'error';
  return 'default';
}

/**
 * Determines the secondary avatar color based on the first character of the provided name.
 *
 * @param {string} name - The name to use for determining the secondary avatar color.
 * @returns {string} The secondary avatar color, which can be a specific color from the `baseColors` object or 'default'.
 */
export function getAvatarColor2(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return baseColors.GREEN;
  if (INFO_NAME.includes(getFirstCharacter(name))) return baseColors.BLEU_N;
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return baseColors.GREEN_N;
  if (WARNING_NAME.includes(getFirstCharacter(name))) return baseColors.WARINIG;
  if (ERROR_NAME.includes(getFirstCharacter(name))) return baseColors.RED;
  return 'default';
}

/**
 * Retrieves the avatar URL from the provided object.
 *
 * @param {object} object - The object containing the avatar URL.
 * @returns {string|null} The avatar URL, or null if not found.
 */
export function getAvatarUrl(object) {
  return object?.photoUrl || object?.photoURL || object?.avatar || null;
}

/**
 * Creates an avatar object with a name, primary color, and secondary color based on the provided name.
 *
 * @param {string} name - The name to use for generating the avatar.
 * @param {boolean} [two=false] - Whether to use the first two characters of the name for the avatar.
 * @returns {object} An object with the following properties:
 *   - name: The avatar name, either the first character or the first two characters of the name.
 *   - color: The primary avatar color, determined by the first character of the name.
 *   - color2: The secondary avatar color, determined by the first character of the name.
 */
export default function createAvatar(name, two = false) {
  const cleanedName = cleanName(name);
  return {
    name: getFirstCharacter(cleanedName, two),
    color: getAvatarColor(cleanedName),
    color2: getAvatarColor2(cleanedName)
  };
}
