import { createAsyncThunk } from '@reduxjs/toolkit';
import firestore from 'src/utils/firestore';
import storage from 'src/utils/storage';
import CryptoJS from 'crypto-js';
import { setProgress } from './mailsDocument';
import { MAIL_ATTACHMENT_SLICE } from 'src/constants/mail';

/**
 * Thunk to upload a file to storage or get an existing URL from Firestore.
 * @function
 * @param {Object} payload - The payload containing the data, fileName, fileType, and mailId.
 * @param {string} payload.data - The file data in base64 format.
 * @param {string} payload.fileName - The name of the file to be uploaded.
 * @param {string} payload.fileType - The MIME type of the file.
 * @param {string} payload.mailId - The ID of the mail.
 * @param {Object} thunkAPI - The thunkAPI object provided by Redux Toolkit.
 * @param {function} thunkAPI.rejectWithValue - Function to reject with a value.
 * @returns {Promise<Object>} A promise that resolves with the upload progress and URL or rejects with an error.
 */
export const uploadMailAttachment = createAsyncThunk(
  'mailAttachment/uploadMailAttachment',
  async ({ data, fileName, fileType, mailId }, { dispatch, rejectWithValue }) => {
    try {
      const hash = CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
      const docRef = firestore.collection(MAIL_ATTACHMENT_SLICE).doc(hash);
      const doc = await docRef.get();

      // console.log({ hash, mailId });

      if (doc.exists) {
        const docData = doc.data();
        if (docData.mailId === mailId) {
          await docRef.update({
            lastAccessed: new Date(),
            // updatedAt: new Date()
          });
          dispatch(setProgress(100));
          return { progress: 100, url: docData.url };
        }
      }

      return new Promise((resolve, reject) => {
        const uploadTask = storage.ref(`${MAIL_ATTACHMENT_SLICE}/${hash}`).putString(data, 'base64', { contentType: fileType });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch(setProgress(progress));
          },
          (error) => {
            reject(rejectWithValue(error));
          },
          async () => {
            try {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
              await docRef.set({
                url: downloadURL,
                mailId: mailId,
                createdAt: new Date(),
                updatedAt: new Date(),
                lastAccessed: new Date()
              });
              resolve({ progress: 100, url: downloadURL });
              dispatch(setProgress(100));
            } catch (error) {
              reject(rejectWithValue(error));
            }
          }
        );
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
