import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { setToAffectTask } from 'src/redux/slices/mail';
import { newOnArrayWithEmail } from 'src/utils/newOnArray';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  ProjectAssignedUpdate,
  addTask,
  createColumn,
  deleteColumn,
  deleteTask,
  getTaskById,
  projectActuSelected,
  selectProject,
  updateCard,
  updateColumn
} from 'src/redux/slices/kanban';
import KanbanSendInvitationConfimation from 'src/section/tasks/kanban/KanbanSendInvitationConfimation';
import { auth } from './FirebaseContext';
import { isEmpty, isEqual, keys } from 'lodash';
import useTaskDetails from 'src/hooks/useTaskDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { difference } from 'src/utils/difference';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AddUserToProjectDialog from 'src/section/project/v4/content/project_content/AddUserToProjectDialog';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import { useTaskV4Context } from './TaskContext';
import ProjectExtendModal from 'src/components/ProjectExtendModal';
import { subTaskAssignNotification, subTaskNewAssigneNotificationForTaskMember } from 'src/redux/slices/notifications';

const ProjectContext = createContext({
  isOpen: false,
  users: [],
  currentProject: null,
  projectChanging: false,
  openById: (taskId) => { },
  setTaskId: (taskId) => { },
  deleteCol: (taskId) => { },
  setProjetChanging: () => { },
  onOpenSub: (parent, sub) => { },
  updateColumn: (name, color) => { },
  setCurrentProject: (porject) => { },
  onOpen: (record, currentTab = 0) => { },
  addColumn: (title, color, callback) => { },
  addTask: (task, columnId, callback) => { },
  deleteTask: (taskId, parentId = null) => { },
  handleCloseAddToProjectConfirmation: () => { },
  addSubTask: (subTask, parent, callback) => { },
  handleOnValidateAddUserToProject: (shouldAdd) => { },
  updateTask: (updateTask, oldTask = null, canClose = true) => { },
  handleOpenAddToProjectConfirmation: ({ task, usersToAdd, values, callback, options = { disable: false } }) => { },
  handleOPenExtendProjectTime: ({
    extendOption: { tempDate, disabled, isLoading, extendDate, dataInterval, handleCloseExtend }
  }) => { }
});

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

export default function ProjectProvider({ children }) {
  const { openTaskDetails } = useTaskV4Context();
  //#region STATE
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { open, handleClose, handleOpen } = useToggle();

  const [addToproject, onAddtoProject, onCloseAddToProject] = useToggleV2();
  const [updatTaskInfos, setUpdatetaskInfo] = useState({ task: null, usersToAdd: [], values: null, callback: null });

  const [record, setRecord] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [prev, setPrev] = useState([]);
  const [canOpenSub, setCanOpenSub] = useState(null);
  const [projectChanging, setProjetChanging] = useState(false);

  const { task } = useTaskDetails({ taskId: taskId || '' });

  const [currentTab, setCurrentTab] = useState(null);

  const { users } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [pending, setPending] = useState(false);
  const [newGuest, setNewGuest] = useState([]);
  const [affect, setAffect] = useState([]);
  const [upTask, setUpTask] = useState([]);

  const { currentProject, sProject } = useSelector((state) => state.kanban);

  const [showInvit, handleOpenInvite, handleCloseInvite] = useToggleV2();

  const [extendDateOptions, setExtendDateOptions] = useState({
    tempDate: null,
    disabled: false,
    isLoading: false,
    dataInterval: null,
    extendDate: () => { }
  });

  const [extendTProjectTime, onOPenExtendProjectTime, onCloseExtendProjectTime] = useToggleV2();

  //#endregion

  //#region HANDLERS
  const handleOPenExtendProjectTime = ({ extendOption }) => {
    console.log(extendOption);
    setExtendDateOptions(extendOption);
    onOPenExtendProjectTime();
  };

  const handleCloseExtendProjectTime = () => {
    onCloseExtendProjectTime();
    setExtendDateOptions({
      tempDate: null,
      disabled: false,
      isLoading: false,
      extendDate: null,
      dataInterval: null
    });
  };
  //#endregion

  //#region EFFECT
  useEffect(() => {
    if (taskId && !open) {
      handleOpen();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    if (task) {
      setRecord(task);
    }
  }, [task]);

  useEffect(() => {
    if (canOpenSub) {
      setRecord(canOpenSub);
      setCanOpenSub(null);
      setTimeout(() => handleOpen(), 100);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canOpenSub]);

  //#endregion

  //#region FUNCTIONS

  const setCurrentProject = (project) => {
    dispatch(selectProject(project?.id));
    dispatch(projectActuSelected(project));
  };

  const onOpen = (_record, _currentTab) => {
    setRecord(_record);
    setCurrentTab(_currentTab);
    handleOpen();
  };

  const openById = (taskId) => {
    dispatch(
      getTaskById({
        taskId,
        resolve: (task) => {
          const projectKey = task?.projectKey || task?.idProject;
          if (projectKey)
            navigate(`${PATH_DASHBOARD.general.tasks}/${projectKey}`, {
              state: {
                projectName: ' ',
                detailId: taskId
              }
            });

          openTaskDetails(task);
        },
        reject: () => enqueueSnackbar(`Cette tâche n'existe plus`, { variant: 'warning' })
      })
    );
  };

  const handleAddSubTask = (_task, parent, callBack) => {
    // console.log({ _task, parent });
    const guest = [];
    const affectations = [];
    const user = auth.currentUser;

    const callback = (id, accepted) => {
      enqueueSnackbar('Sous Tâche créée avec succès', { variant: 'success' });
      dispatch(
        subTaskAssignNotification({
          projectId: currentProject?.id || sProject?.id,
          taskId: parent.id,
          subTaskId: id,
          projectName: currentProject?.name || sProject?.name || '',
          subTask: _task,
          taskName: parent.name,
          assignee: _task.assignee
        })
      )
      dispatch(
        subTaskNewAssigneNotificationForTaskMember({
          projectId: currentProject?.id || sProject?.id,
          task: parent,
          subTaskId: id,
          projectName: currentProject?.name || sProject?.name || '',
          subTask: _task,
          taskName: parent.name,
          oldSubTask: parent.subTasks
        })
      )
      dispatch(
        setToAffectTask(
          affectations,
          accepted,
          currentProject?.name || sProject?.name || '',
          _task,
          currentProject?.id || sProject?.id,
          id,
          () => {
            setNewGuest([]);
            setAffect([]);
            setUpTask(null);
          }
        )
      );
    };

    const newest = newOnArrayWithEmail([], _task.assignee);
    newest.map(async (pers) => {
      const exist = users.find((u) => u.email === pers.email);
      if (exist) {
        affectations.push(pers);
      } else {
        guest.push(pers);
      }
    });

    if (guest.length > 0) {
      setNewGuest(guest);
      setAffect(affectations);
      setUpTask(_task);
      handleOpen();
      return;
    }

    dispatch(
      addTask(
        {
          card: {
            priority: '',
            ..._task,
            parentId: parent?.id,
            parentName: parent?.name,
            project: currentProject?.name || sProject?.name || '',
            idProject: currentProject?.id || sProject?.id || parent?.projectKey,
            createdBy: {
              name: user?.displayName || '',
              id: user.uid,
              avatar: user?.photoURL || '',
              email: user?.email
            }
          },
          columnId: null
        },
        (_id) => callback(_id, []),
        () => callBack && callBack(),
        parent?.id
      )
    );
  };

  const handleAddTask = (_task, columnId, callBack) => {
    const guest = [];
    const affectations = [];
    const user = auth.currentUser;

    const callback = (id, accepted) => {
      enqueueSnackbar('Tâche créée avec succès', { variant: 'success' });
      dispatch(
        setToAffectTask(
          affectations,
          accepted,
          currentProject?.name || sProject?.name || '',
          _task,
          currentProject?.id || sProject?.id,
          id,
          () => {
            setNewGuest([]);
            setAffect([]);
            setUpTask(null);
          }
        )
      );
    };

    const newest = newOnArrayWithEmail([], _task?.assignee || []);
    newest.map(async (pers) => {
      const exist = users.find((u) => u.email === pers.email);
      if (exist) {
        affectations.push(pers);
      } else {
        guest.push(pers);
      }
    });

    if (guest.length > 0) {
      setNewGuest(guest);
      setAffect(affectations);
      setUpTask(_task);
      handleOpen();
      return;
    }

    dispatch(
      addTask(
        {
          card: {
            priority: '',
            ..._task,
            project: currentProject?.name || sProject?.name || '',
            projectKey: currentProject?.id || sProject?.id,
            createdBy: {
              name: user?.displayName || '',
              id: user.uid,
              avatar: user?.photoURL || '',
              email: user?.email
            }
          },
          columnId: columnId
        },
        (_id) => callback(_id, []),
        () => callBack && callBack()
      )
    );
  };

  const handleUpdateOnClose = (card, prevCard = null) => {
    if ((card?.name || '').trim().length === 0) return;

    const oldCard = record;
    const guest = [];
    const affectations = [];

    const callback = (accepted) => {
      setPending(false);
      enqueueSnackbar('Tâche mise à jour avec succès', { variant: 'info' });

      dispatch(
        setToAffectTask(
          affectations,
          accepted,
          currentProject?.name || sProject?.name || '',
          card || '',
          currentProject?.id || sProject?.id,
          card.id,
          () => {
            setNewGuest([]);
            setAffect([]);
            setUpTask(null);
          }
        )
      );
    };

    const newest = newOnArrayWithEmail(oldCard?.assignee || [], card?.assignee || []);

    newest.map(async (pers) => {
      const exist = users.find((u) => u.email === pers.email);
      if (exist) {
        affectations.push(pers);
      } else {
        guest.push(pers);
      }
    });

    if (guest.length > 0) {
      setPending(true);
      setNewGuest(guest);
      setAffect(affectations);
      setUpTask(card);
      handleOpenInvite();
      return;
    }

    const removeUnecesary = (task) => {
      const { titleMentions, mentions, ...rest } = task;
      return { ...rest };
    };
    const current = { ...removeUnecesary(card), name: card?.name?.replace(/<[^>]+>/g, '') };
    const previous = removeUnecesary(prevCard || record);
    const diff = difference(current, previous);
    const hasChanged = !isEmpty(keys(diff));

    if (hasChanged) dispatch(updateCard(card, () => callback([]), prevCard || record));
  };

  const validationUpdateCallBack = (accepted) => {
    const callback = (accepted) => {
      setPending(false);
      enqueueSnackbar('Tâche mise à jour avec succès', { variant: 'info' });
      dispatch(
        setToAffectTask(
          affect,
          accepted,
          currentProject?.name || sProject?.name || '',
          upTask?.name || '',
          currentProject?.id || sProject?.id,
          upTask.id
        )
      );
    };

    let guestClone = [...newGuest];
    let finalAssign = [...upTask.assignee];

    accepted.forEach((g) => {
      guestClone = guestClone.filter((_g) => _g.email !== g.email);
    });

    guestClone.forEach((r) => {
      finalAssign = finalAssign.filter((v) => v.email !== r.email);
    });

    dispatch(updateCard({ ...upTask, assignee: finalAssign }, () => callback(accepted)));
  };

  const handleCloseTask = (updatedCard, oldCard = null, canClose = true, usePrev = true) => {
    console.log({ updatedCard, oldCard, canClose, usePrev });
    let _rec = { ...record };
    canClose && handleClose();
    canClose && setRecord(null);

    if ((updatedCard?.name || '').trim().length === 0) return;

    if (taskId) {
      navigate(pathname.replace(taskId, ''));
      setTaskId('');
    }

    if (usePrev && !isEmpty(prev)) {
      setCanOpenSub(prev.at(prev.length - 1));
      const p = [...prev];
      p.pop();
      setPrev([...p]);
    }

    if (oldCard) {
      if (!isEqual(updatedCard, oldCard)) {
        handleUpdateOnClose(updatedCard, oldCard);
      }
    } else {
      if (!isEqual(updatedCard, _rec)) {
        handleUpdateOnClose(updatedCard);
      }
    }
  };

  const onOpenSub = (parent, _sub) => {
    handleClose();
    setPrev((p) => [...p, parent]);
    handleCloseTask(parent, null, true, false);
    setCanOpenSub(_sub);
  };

  const onDelete = (taskId = null, parentId = null, card = null) => {
    const callback = () => {
      enqueueSnackbar('Supprimé avec succès', { variant: 'warning' });
    };
    dispatch(deleteTask({ cardId: taskId || record?.id, parentId, card }, callback));
  };

  const handleDeleteColumn = async (colId) => {
    try {
      dispatch(deleteColumn(colId, currentProject?.id || sProject?.id));
      enqueueSnackbar('Supprimé avec succès', { variant: 'warning' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateColumn = async (name, color, callBack) => {
    try {
      if (!isEmpty(name)) {
        const callback = () => {
          enqueueSnackbar('Opération créé avec succès', { variant: 'success' });
        };

        dispatch(createColumn({ name, color, projectKey: currentProject?.id || sProject?.id }, callback));
      }
      callBack && callBack();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateColumn = async (colId, newName, color) => {
    try {
      if (!isEmpty(newName)) {
        dispatch(updateColumn(colId, { name: newName, color }));
        enqueueSnackbar('Mise à jour réussie', { variant: 'success' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseAddToProjectConfirmation = () => {
    setUpdatetaskInfo(null);
    onCloseAddToProject();
  };

  const handleOnValidateAddUserToProject = (shouldAdd) => {
    if (shouldAdd) {
      dispatch(updateTaskWithoutProject(updatTaskInfos.task, updatTaskInfos.values, updatTaskInfos.callback));
      dispatch(
        ProjectAssignedUpdate(
          currentProject?.id,
          [...updatTaskInfos.usersToAdd, ...currentProject?.canAccess, ...currentProject?.managers],
          auth.currentUser.uid,
          () => {
            enqueueSnackbar('Utilisateur ajouté avec succès', { variant: 'success' });
          },
          () => {
            enqueueSnackbar("Erreur lors de l'ajout de l'utilisateur", { variant: 'error' });
          }
        )
      );
      handleCloseAddToProjectConfirmation();
    }
  };

  const handleOpenAddToProjectConfirmation = ({ task, usersToAdd, values, callback, options = { disable: false } }) => {
    setUpdatetaskInfo({ task, usersToAdd, values, callback });
    if (options.disable) {
      dispatch(updateTaskWithoutProject(updatTaskInfos.task, updatTaskInfos.values, updatTaskInfos.callback));
    } else {
      onAddtoProject();
    }
  };



  //#endregion

  const store = {
    users,
    onOpen,
    openById,
    onOpenSub,
    setTaskId,
    isOpen: open,
    setCurrentProject,
    projectChanging,
    setProjetChanging,
    deleteTask: onDelete,
    addTask: handleAddTask,
    updateTask: handleCloseTask,
    handleOPenExtendProjectTime,
    handleCloseExtendProjectTime,
    addSubTask: handleAddSubTask,
    deleteCol: handleDeleteColumn,
    addColumn: handleCreateColumn,
    updateColumn: handleUpdateColumn,
    handleOnValidateAddUserToProject,
    handleOpenAddToProjectConfirmation,
    handleCloseAddToProjectConfirmation,
    currentProject: currentProject || sProject
  };

  return (
    <ProjectContext.Provider value={store}>
      {children}
      {Boolean(showInvit) && (
        <KanbanSendInvitationConfimation
          open={showInvit}
          onClose={handleCloseInvite}
          invite={newGuest}
          validation={validationUpdateCallBack}
          onCancel={() => setPending(false)}
        />
      )}
      {Boolean(addToproject) && (
        <AddUserToProjectDialog
          open={addToproject}
          users={updatTaskInfos.usersToAdd}
          onClose={handleCloseAddToProjectConfirmation}
          onValidate={handleOnValidateAddUserToProject}
        />
      )}
      {Boolean(extendTProjectTime) && (
        <ProjectExtendModal
          tempDate={extendDateOptions?.tempDate}
          disabled={extendDateOptions?.disabled}
          openExtend={Boolean(extendTProjectTime)}
          isLoading={extendDateOptions?.isLoading}
          handleCloseExtend={handleCloseExtendProjectTime}
          enabledDataInterval={extendDateOptions?.dataInterval}
          extendDate={() => extendDateOptions?.extendDate(onCloseExtendProjectTime)}
        />
      )}
    </ProjectContext.Provider>
  );
}
