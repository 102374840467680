import React from 'react';
import { MenuLine } from 'src/components/MenuLine';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { baseColors } from 'src/constants/color';

/**
 * Renders a custom menu popover with options to edit or delete an operation.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the popover should be open.
 * @param {function} props.onClose - Callback function to close the popover.
 * @param {HTMLElement} props.anchorEl - The HTML element to anchor the popover to.
 * @param {function} [props.onEdit] - Callback function to handle editing the operation.
 * @param {function} [props.onDelete] - Callback function to handle deleting the operation.
 * @returns {JSX.Element} - The rendered custom menu popover.
 */
export default function V4OperationMore({ open, onClose, anchorEl, onEdit, onDelete }) {
  const handleEdit = () => {
    if (onEdit) {
      onEdit();
      onClose();
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
      onClose();
    }
  };

  return (
    <CustomMenuPopover
      open={open}
      disabledArrow
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuLine icon="eva:edit-outline" title="Renommer l'opération" onClick={handleEdit} disabled={!onEdit} />
      <MenuLine icon="eva:trash-outline" title="Supprimer l'opération" color={baseColors.REDD} onClick={handleDelete} disabled={!onDelete} />
    </CustomMenuPopover>
  );
}