import gp from 'generate-password';


/**
 * Generates a random password with the following characteristics:
 * - Length of 10 characters
 * - Includes numbers
 * - Excludes similar characters (e.g. 0 and O, 1 and l)
 * - Excludes the characters ", <, and >
 * @returns {string} A randomly generated password
 */
export const generatePass= () => {
    return gp.generate({ length: 10, numbers: true,  excludeSimilarCharacters: true, exclude: '", <, >, ' });
}

