import { ClickAwayListener, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { MuiCustomInput } from 'src/components/CustomInput';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { createColumn } from 'src/redux/slices/kanban';
import { dispatch } from 'src/redux/store';

/**
 * Renders a new section for the project context list, allowing the user to create a new column.
 *
 * @param {object} props - The component props.
 * @param {string} props.projectKey - The key of the project.
 * @param {function} props.onClose - A callback function to close the section.
 * @returns {JSX.Element} The rendered component.
 */
export default function V4ProjectContextListSectionNew({ projectKey, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');

  const handleCreateColumn = async () => {
    try {
      if (!isEmpty(name)) {
        const callback = () => {
          enqueueSnackbar('Opération créé avec succès', { variant: 'success' });
          setName('');
        };

        dispatch(createColumn({ name, color: '', projectKey: projectKey }, callback));
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  let counter = 0;
  const handleKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (counter < 1) {
        handleCreateColumn();
        counter += 1;
        return;
      }
    }
  };

  return (
    <Stack width={1} pl={4} pt={1}>
      <ClickAwayListener onClickAway={handleCreateColumn}>
        <MuiCustomInput
          size="small"
          label=" "
          value={name}
          autoFocus
          placeholder="titre de l'opération"
          onChange={(e) => setName(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          sx={{
            border: 'none',
            typography: 'overline',
            fontSize: 14,
            fontWeight: 'fontWeightBold',
            py: 0.3,
            minWidth: 300
          }}
        />
      </ClickAwayListener>
    </Stack>
  );
}
