/**
 * Plays a notification sound and returns a function to stop the sound.
 *
 * @param {boolean} [loop=false] - Whether the sound should loop continuously.
 * @returns {function} - A function that can be called to stop the notification sound.
 */
export function playNotificationSound(loop=false){
    if(document.getElementById('sound')){
        document.getElementById('sound').play();
        if(loop){
            document.getElementById('sound').loop = true;
        }
    }

    const onStop = () =>{
        document.getElementById('sound').pause();
    }

    return onStop;
}

/**
 * Displays a web notification with the given title and description.
 *
 * @param {string} title - The title of the notification.
 * @param {string} description - The description of the notification.
 * @returns {void}
 */
function showNotification (title, description){
    const logo = localStorage.getItem('s_logo');
    //eslint-disable-next-line
    const notification = new Notification(title, {
        body: description,
        icon: logo,
        silence:false,
        sound: '/static/alarm/alarm_message.mp3',
        vibrate: [200, 100, 200, 100, 200, 100, 200],
        lang:'fr',
        tag: title
    });

    notification.onclick = () =>{
        if(document.getElementById('sound')){
            document.getElementById('sound').pause();
        }
    }
    notification.onclose = () =>{
        if(document.getElementById('sound')){
            document.getElementById('sound').pause();
        }
    }
}

/**
 * Requests permission to display web notifications and shows a notification if permission is granted.
 *
 * @param {string} titre - The title of the notification.
 * @param {string} description - The description of the notification.
 * @returns {void}
 */
export const navigatorNotif = (titre, description ) => {
   
    if(Notification.permission === "granted"){
        showNotification(titre, description);
    } else if ( Notification.permission !== 'denied'){
        Notification.requestPermission().then(permission =>{
            if(permission === "granted"){
                showNotification(titre, description);
            }
        });
    }
}