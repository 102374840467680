import { Sheet } from '@mui/joy';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  Tooltip,
  ButtonGroup,
  IconButton
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AttachReceivedSvg from 'src/components/AttachReceivedSvg';
import AttachSendSvg from 'src/components/AttachSendSvg';
import CIconButton from 'src/components/CIconButton';
import InputLine from 'src/components/CustomInputLine';
import Iconify from 'src/components/Iconify';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import useAuth from 'src/hooks/useAuth';
import { Affectation_Setting_model, Affectation_v4_Type } from 'src/models/Affectation_v4_type';
import OrganigrammeItem from './OrganigrammeItem';
import { AFFECT_ANNOTATION, AFFECT_OPERATION_TYPE, AFFECT_PRIORITY } from 'src/constants/affectation';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';
import { uniq } from 'lodash';
import { serverTime } from 'src/utils/serverTime';
import { AffectLineDue, NoteViewer } from './liste/AffectationLine';
import ActionModal from 'src/components/ActionModal';
import { useToggleV2 } from 'src/hooks/useToggle';
import CustomFileView from 'src/components/CustomFileView';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { NOTIFICATION_TYPES } from 'src/constants';
import { useMarkAsReadNotificationById } from 'src/hooks/useNotifications';
import { RejectMessage } from 'src/components/OnRejectComponent';
import { LoadingButton } from '@mui/lab'
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';

/**
 *
 * @param {{open: boolean, affectation: Affectation_v4_Type, onClose: ()=>void,handleMinimize: ()=>void, disabled: boolean}} props
 * @returns
 */
export default function AffectationDetails({ open, affectation, onClose, disabled: _disabled, handleMinimize }) {
  const { user } = useAuth();
  const { settings: { organigramme = [] } = Affectation_Setting_model, getLoading, handleSetIsUploading, handleUpdateAffectation } = useAffectation_v4Context();
  const [saving, setSaving] = useState(false);
  const [_saveWithoutDocument, _setSaveWithoutDocument] = useState(false);
  const [saveWithoutUpdate, displaySaveWithoutUpdate, hidesaveWithoutUpdate] = useToggleV2();
  const annotationRef = useRef();
  const markAsReadNotification = useMarkAsReadNotificationById();
  // console.log({ affectation });
  //isPriority

  const [visible, display, hide] = useToggleV2();
  const [noteModal, displayNote, hideNote] = useToggleV2();
  const [saveWithoutDocument, displaySaveWithoutDocument, hideSaveWithoutDocument] = useToggleV2();
  const [notSavedFiles, setNotSavedFiles] = useState([]);
  const assigne = useMemo(() => {
    return affectation?.assignationOrganigramme;
  }, [affectation?.assignationOrganigramme]);

  const isUploading = useMemo(() => {
    return getLoading(affectation?.id);
  }, [affectation?.id, getLoading]);


  const disabled = useMemo(() => {
    return _disabled || saving || isUploading;
  }, [_disabled, saving, isUploading]);

  useEffect(() => {
    markAsReadNotification(affectation?.id, [
      NOTIFICATION_TYPES.AFFECTATION,
      NOTIFICATION_TYPES.AFFECTATION_ASSIGN,
      NOTIFICATION_TYPES.AFFECTATION_ASSIGN_USER,
      NOTIFICATION_TYPES.AFFECTATION_OTHER_CHANGE,
      NOTIFICATION_TYPES.AFFECT_ADD,
      NOTIFICATION_TYPES.AFFECT_DONE,
      NOTIFICATION_TYPES.AFFECT_UPDATE,
      NOTIFICATION_TYPES.AFFECT_VALIDATE,
      NOTIFICATION_TYPES.AFFECT_REJECT,
      NOTIFICATION_TYPES.AFFECT_PRINT,
      NOTIFICATION_TYPES.AFFECT_COMMENT,
      NOTIFICATION_TYPES.COURIEL_ADD,
      NOTIFICATION_TYPES.COURIEL_ASSIGN,
      NOTIFICATION_TYPES.COURIEL_VALIDATE,
      NOTIFICATION_TYPES.COURIEL_TO_DO,
      NOTIFICATION_TYPES.COURIEL_DELETE,
      NOTIFICATION_TYPES.COURIEL_CREATION,
      NOTIFICATION_TYPES.COURIEL_ADD_ATTACHMENT,
      NOTIFICATION_TYPES.COURIEL_TO_REVIEW,
      NOTIFICATION_TYPES.COURIEL_CO_RECEIVER,
      NOTIFICATION_TYPES.COURIEL_DEFAULT_RECEIVER,
      NOTIFICATION_TYPES.COURIEL_HISTORY,
      NOTIFICATION_TYPES.COURIEL_ARCHIVE,
      NOTIFICATION_TYPES.COURIEL_UN_ARCHIVE,
      NOTIFICATION_TYPES.COURIEL_TRASH,
      NOTIFICATION_TYPES.COURIEL_UN_TRASH,
      NOTIFICATION_TYPES.COURIEL_ASSIGNED
    ]);
  }, [affectation?.id, markAsReadNotification]);

  const myLevel = useMemo(() => {
    const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    if (!assigne.length) {
      const _ass = organigramme.map((org) => ({ ...org, users: [] }));
      if (position !== -1) {
        return {
          assigne: _ass,
          position
        };
      }
      return { assigne: _ass, position: 0 };
    }
    return { assigne, position };
  }, [assigne, organigramme, user.uid]);

  const noteViewRef = useRef();

  //console.log(disabled)

  const formik = useFormik({
    initialValues: affectation,
    onSubmit: async (values) => {
      handleSetIsUploading(affectation?.id, true)
      const oldAssigne = affectation.assignationOrganigramme;
      const newOrganigramme = values.assignationOrganigramme;

      const modifiedInput = () => {
        return Object.keys(values).reduce((acc, key) => {
          if (values[key] !== affectation[key]) {
            acc[key] = values[key];
          }
          return acc;
        }, {});
      };

      //console.log(modifiedInput());

      let toDoIds = affectation.toDoIds;

      let toReviewIds = uniq([...affectation.toReviewIds, user.uid]);

      //check change user on assigne organigramme and add to toDoIds
      newOrganigramme.forEach((level, index) => {
        const oldLevel = oldAssigne[index];

        const { added } = compareArraysOfObjects(oldLevel?.users || [], level.users);

        toReviewIds = toReviewIds.filter((oneId) => !added.some((one) => one === oneId));

        toDoIds = uniq([...toDoIds, ...added]);
      });

      toReviewIds.forEach((_id) => (toDoIds = toDoIds.filter((_oneId) => _oneId !== _id)));
      // au moin un niveau est assigné
      const atlastOneLevelIsAssigned =
        values.assignationOrganigramme.map((one) => one.users.length).reduce((a, b) => a + b, 0) > 0;
      let toSave = {
        ...values,
        updated: {
          by: user.uid,
          at: serverTime()
        }
      };
      // deplacer si
      // une annotation est ajouté et une assignation est faite sur au moins un niveau
      // une affectation est marqué comme terminé
      // les propriétés d'une affectation sont modifiés et au moins un niveau est assigné et l'annotation est ajouté
      if (
        (values.annotation && values.annotation.length > 0 && atlastOneLevelIsAssigned) ||
        values.completed === true ||
        (modifiedInput().length > 0 && values.annotation && values.annotation.length > 0 && atlastOneLevelIsAssigned)
      ) {
        toSave = {
          ...toSave,
          toDoIds,
          toReviewIds
        };
      }

      handleUpdateAffectation({ newAffect: toSave, oldAffect: affectation });
      _setSaveWithoutDocument(false);

    }
  });

  const { handleSubmit, setFieldValue, values } = formik;

  const handleSave = () => {
    //map affectation propreties
    const isUnchanged = Object.keys(values).every((key) => {
      // compare all properties except attachments
      //console.log({ key, value: values[key], affectation: affectation[key] });
      if (values[key] === affectation[key] || key === 'priority') {
        return true;
      }
    });
    //console.log({ isUnchanged, newAff, affectation })
    if (isUnchanged) {
      displaySaveWithoutUpdate();
      return;
    }
    //si la personne est dans le dernier niveau et qu'il n'a pas de document
    else if (values.attachments === affectation.attachments && !_saveWithoutDocument) {
      displaySaveWithoutDocument();
      return;
    }
    handleSubmit();
  };

  const handleSaveWithoutDocument = () => {
    _setSaveWithoutDocument(true);
    handleSubmit();
  };

  const handleSaveWithoutUpdate = () => {
    _setSaveWithoutDocument(false);
    handleSubmit();
    hidesaveWithoutUpdate();
  };


  //console.log(values?.attachments?.received.length, values?.attachments?.send.length);

  const handleChangeDone = () => {
    setFieldValue('completed', true);
    setFieldValue('completedBy', user.uid);
    let val = { ...values.validation, state: false, by: null, isReject: false };
    setFieldValue('validation', val);
    //
    handleSubmit();
  };

  const handleChangeNotDone = () => {
    setFieldValue('completed', false);
    setFieldValue('completedBy', user.uid);
    handleSubmit();
  };

  const handleValidate = () => {
    setFieldValue('validation', { ...values?.validation, state: true, by: user.uid });
    handleSubmit();
  };

  const canDelete = useMemo(() => {
    // seul le créateur peut supprimer
    return user.uid === affectation.createdBy;
  }, [affectation.createdBy, user.uid]);

  const handleReject = (note) => {
    let val = { ...values.validation, state: false, by: null, isReject: true };
    if (note) {
      val = {
        ...val,
        rejectNote: [...(values?.validation?.rejectNote || []), { by: user.uid, note, at: serverTime() }]
      };
    }
    setFieldValue('validation', val);
    setFieldValue('completed', false);
    setFieldValue('completedBy', null);
    handleChangeNotDone();
  };

  const levelDown = useMemo(() => {
    const myposition = organigramme?.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    const completedByposition = organigramme?.findIndex((one) =>
      one.users.find((_userId) => _userId === values.completedBy)
    );
    if (completedByposition !== -1 && myposition !== -1) {
      return myposition > completedByposition;
    }

    return false;
  }, [values.completedBy, organigramme, user.uid]);



  //console.log(values.attachments.send)

  return (
    <>
      <FormikProvider value={formik}>
        <Dialog
          maxWidth="lg"
          fullWidth
          open={Boolean(open)}
          onClose={onClose}
          PaperProps={{ sx: { border: 'none', boxShadow: 'none', bgcolor: 'transparent', p: 2 } }}
          scroll="paper"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'center',

              pb: 2,
              bgcolor: 'background.paper',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20
            }}
          >
            <Stack width={1} direction="row" justifyContent='space-between'>

              <Stack direction='row' spacing={2} justifyContent="start" alignItems='center'>
                <Typography fontWeight="bold" variant="h4">
                  Assignation
                </Typography>
                <Label color='success'>
                  <Typography component='span' fontSize={11} fontWeight="bold" pr={0.5}>Nº: </Typography> {affectation.save_reference}
                </Label>
                <Label color='secondary'>
                  <Typography component='span' fontSize={11} fontWeight="bold" pr={0.5}>Ref: </Typography> {affectation.courriel_reference}
                </Label>
              </Stack>

              <Stack spacing={3} direction="row" justifyContent="end">
                <Stack direction={'row'} spacing={2}>
                  {values.completed ? (
                    values.completedBy === user.uid || levelDown ? (
                      <Tooltip title="Marquer comme non terminée" arrow>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ py: 1.5 }}
                          onClick={handleChangeNotDone}
                          disabled={disabled}
                        >
                          Terminée
                        </Button>
                      </Tooltip>
                    ) : !values.validation.state ? (
                      <ButtonGroup>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ py: 1.5 }}
                          endIcon={<Iconify icon="uiw:dislike-o" />}
                          onClick={display}
                          disabled={disabled}
                        >
                          Rejeter
                        </Button>

                        <Button
                          variant="contained"
                          color="success"
                          sx={{ py: 1.5 }}
                          endIcon={<Iconify icon="uiw:like-o" />}
                          onClick={handleValidate}
                          disabled={disabled}
                        >
                          Accepter
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Tooltip title="Marquer comme non terminée" arrow>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ py: 1.5 }}
                          onClick={handleChangeNotDone}
                          disabled={disabled}
                        >
                          Terminée
                        </Button>
                      </Tooltip>
                    )
                  ) : (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ py: 1.5 }}
                        endIcon={<Iconify icon="ph:check-bold" />}
                        onClick={handleChangeDone}
                        disabled={disabled}
                      >
                        Marquer comme terminée
                      </Button>
                      {affectation.validation.isReject && (
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ py: 1.5 }}
                          startIcon={<Iconify icon="ph:note-light" className="clignotant" />}
                          onClick={displayNote}
                          ref={noteViewRef}
                          disabled={disabled}
                        >
                          Note de rejet
                        </Button>
                      )}
                    </Stack>
                  )}
                </Stack>

                <LoadingButton variant="contained" color="primary" sx={{ py: 1.5 }} onClick={handleSave} disabled={disabled} loading={isUploading}>
                  ENREGISTRER
                </LoadingButton>
              </Stack>

            </Stack>

          </DialogTitle>
          <DialogContent
            sx={{
              p: 3,
              position: 'relative',
              bgcolor: 'background.paper',
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Stack width={1} direction="row" justifyContent="space-between" spacing={3}>
                <Stack width={0.4}>
                  <Sheet variant="soft" sx={{ p: 2, borderRadius: 'md' }}>
                    <Stack spacing={2}>
                      <AssigneView
                        user={user}
                        organigramme={organigramme}
                        assigne={affectation.assignationOrganigramme}
                        toDoIds={affectation.toDoIds}
                        onChange={setFieldValue}
                        disabled={disabled}
                      />
                    </Stack>
                  </Sheet>
                </Stack>

                <Stack width={0.6}>
                  <Sheet>
                    <Stack width={1} spacing={5}>
                      <Divider />

                      <Stack position="relative">
                        <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
                          <Stack width={1} direction="row" spacing={3}>
                            <InputLine
                              noWritable={user.uid !== affectation.createdBy}
                              type="text"
                              label="Référence"
                              value={values.courriel_reference}
                              field="courriel_reference"
                              onChange={setFieldValue}
                              disabled={disabled}
                              sx={{ fontSize: 14 }}
                            />
                            <InputLine
                              disabled={user.uid !== affectation.createdBy || disabled}
                              type="date"
                              label="Date"
                              value={values.courriel_date}
                              field="courriel_date"
                              onChange={setFieldValue}
                              sx={{ fontSize: 14 }}
                            />

                            <InputLine
                              noWritable={user.uid !== affectation.createdBy || disabled}
                              type="text"
                              disabled={user.uid !== affectation.createdBy || disabled}
                              label="Expéditeur"
                              value={values.correspondant.title}
                              field="correspondant.title"
                              onChange={setFieldValue}
                              sx={{ fontSize: 14 }}
                            />
                          </Stack>
                          <Stack width={1} direction="row" spacing={3}>
                            <InputLine
                              type="text"
                              label="Objet"
                              disabled={user.uid !== affectation.createdBy || disabled}
                              value={values.courriel_object}
                              field="courriel_object"
                              onChange={setFieldValue}
                              sx={{ fontSize: 12 }}
                            />
                          </Stack>
                        </Sheet>
                        <Box position="absolute" top={-10} left={20} bgcolor="#FFF" px={1}>
                          <Typography fontWeight="bold" fontSize={15}>
                            COURRIER
                          </Typography>
                        </Box>
                      </Stack>

                      <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm', mt: 3 }}>
                        <Stack spacing={3}>
                          <Stack width={1} direction="row" spacing={3}>
                            <InputLine
                              type="Autocomplete"
                              options={AFFECT_ANNOTATION}
                              label="Annotation"
                              value={affectation.annotation}
                              field="annotation"
                              required
                              ref={annotationRef}
                              onChange={setFieldValue}
                              disabled={disabled}
                              labelStyle={{ fontSize: 15, fontWeight: 'bold' }}
                              sx={{
                                border: '1px solid #DBDBDC',
                                borderRadius: 1,
                                fontSize: 14
                              }}
                              sxSheet={{
                                height: 55
                              }}
                            />
                          </Stack>

                          <Stack width={1} direction="row" spacing={3}>
                            <InputLine
                              type="select"
                              options={Object.entries(AFFECT_PRIORITY).map(([_, val]) => val)}
                              label="Priorité"
                              value={values.priority}
                              field="priority"
                              onChange={setFieldValue}
                              disabled={disabled}
                              sx={{
                                fontSize: 14, ...affectation?.isPriority && {
                                  border: '1px solid #ff7979', transform: 'scale(0.9)',
                                  animation: 'pulse 2s infinite'
                                }
                              }}
                            />

                            <InputLine
                              type="select"
                              options={AFFECT_OPERATION_TYPE}
                              label="Type d'opération"
                              value={values.operation_type}
                              field="operation_type"
                              onChange={setFieldValue}
                              disabled={disabled}
                              sx={{ fontSize: 14 }}
                            />
                          </Stack>

                          <Stack width={1} direction="row" spacing={3}>
                            <InputLine
                              type="date"
                              label="Date d'enrégistrement"
                              value={values.save_date}
                              field="save_date"
                              onChange={setFieldValue}
                              disabled={user.uid !== affectation.createdBy || disabled}
                              sx={{ fontSize: 14 }}
                            />
                            <Stack width={1} spacing={0.5}>
                              <Typography fontSize={12}>Échéance Envoyée</Typography>
                              <AffectLineDue
                                vue="input"
                                affect={affectation}
                                onChange={setFieldValue}
                                defaultPosition={1}
                                disabled={disabled}
                              />
                            </Stack>
                            <Stack width={1} spacing={0.5}>
                              <Typography fontSize={12}>Échéance Reçu</Typography>
                              <AffectLineDue
                                vue="input"
                                affect={affectation}
                                onChange={setFieldValue}
                                defaultPosition={0}
                                disabled={true}
                              />
                            </Stack>
                          </Stack>

                          {/* <AffectationRappel affectation={affectation} disabled={disabled} /> */}

                          <Stack width={1} alignItems="center" spacing={3}>
                            <Typography fontSize={13}>Pièces jointes</Typography>
                            <Stack width={1} direction="row" spacing={3} justifyContent="center" style={{ overflowX: 'auto' }}>
                              <Stack direction="row" alignItems="center">
                                <CustomFileView
                                  attachments={values?.attachments?.received}
                                  isUploading={false}
                                  onRemove={(attachment) => {
                                    setFieldValue(
                                      'attachments.received',
                                      values.attachments.received.filter((one) => one.url !== attachment.url)
                                    );
                                  }}
                                  onUpdateFile={null}
                                  hideDelete={!canDelete}
                                  hideAdd={true}
                                  onChange={null}
                                  disabled={true}
                                  editable={true}
                                />
                              </Stack>
                              <Sheet
                                variant="soft"
                                sx={{
                                  backgroundColor: 'skyblue',
                                  height: 100,
                                  width: 100,
                                  minWidth: 100,
                                  cursor: '',
                                  overflow: 'hidden',
                                  borderRadius: 'sm',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Stack alignItems="center">
                                  <Tooltip
                                    title="Pieces jointes initialement attachées au courrier lors de sa création , elles ne peuvent pas etre modifiées"
                                    arrow
                                  >
                                    <IconButton
                                      style={{
                                        position: 'absolute',
                                        top: -3,
                                        right: -3,
                                        color: '#2292fd',
                                        width: 30,
                                        height: 30,
                                        zIndex: 1
                                      }}
                                      aria-label="info"
                                      color="#2292fd"
                                    >
                                      <Iconify icon="eva:info-outline" />
                                    </IconButton>
                                  </Tooltip>
                                  <InputLine
                                    type="file"
                                    label=""
                                    disabled={true}
                                    value={values.attachments.received}
                                    field="attachments.received"
                                    sx={{ fontSize: 14 }}
                                    onChange={(field, value) => {
                                      setFieldValue(field, uniq([...values.attachments.received, ...value]));
                                    }}
                                    fileView={
                                      <Stack sx={{ cursor: 'not-allowed' }} disabled>
                                        <AttachReceivedSvg />
                                        <Typography color="info.main" fontSize={10} fontWeight="bold">
                                          Reçue(s)
                                        </Typography>
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              </Sheet>
                              <Sheet
                                variant="soft"
                                color="success"
                                sx={{
                                  height: 100,
                                  width: 100,
                                  minWidth: 100,
                                  overflow: 'hidden',
                                  borderRadius: 'sm',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Stack alignItems="center">
                                  <Tooltip title="Pieces jointes ajoutées par l'utilisateur" arrow>
                                    <IconButton
                                      style={{
                                        position: 'absolute',
                                        top: -3,
                                        right: -3,
                                        color: '#77b82b',
                                        width: 30,
                                        height: 30,
                                        zIndex: 1
                                      }}
                                      aria-label="info"
                                      color="#77b82b"
                                    >
                                      <Iconify icon="eva:info-outline" />
                                    </IconButton>
                                  </Tooltip>
                                  <InputLine
                                    type="file"
                                    label=""
                                    value={values.attachments.send}
                                    field="attachments.send"
                                    disabled={disabled}
                                    onChange={(field, value) =>
                                      setFieldValue(field, uniq([...values.attachments.send, ...value]))
                                    }
                                    fileView={
                                      <Stack>
                                        <AttachSendSvg />
                                        <Typography color="success.main" fontSize={10} fontWeight="bold">
                                          Envoyée(s)
                                        </Typography>
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              </Sheet>
                              <Stack direction="row" alignItems="center">
                                <CustomFileView
                                  attachments={values.attachments.send}
                                  isUploading={false}
                                  onRemove={(attachment) => {
                                    setFieldValue(
                                      'attachments.send',
                                      values.attachments.send.filter((one) => one.url !== attachment.url)
                                    );
                                  }}
                                  onUpdateFile={null}
                                  hideDelete={!canDelete}
                                  hideAdd={true}
                                  canDeleteBlob
                                  onChange={null}
                                  disabled={disabled}
                                  editable={true}
                                />
                              </Stack>
                            </Stack>
                          </Stack>
                          <Stack width={1} h={{ xs: 200 }}>
                            <InputLine
                              label="Notes"
                              type="textarea"
                              value={values.notes}
                              sxSheet={{ height: '100%' }}
                              field="notes"
                              onChange={setFieldValue}
                            />
                          </Stack>
                        </Stack>
                        <Box position="absolute" top={-10} left={20} bgcolor="#FFF" px={1}>
                          <Typography fontWeight="bold" fontSize={15} textTransform={'uppercase'}>
                            Information d'assignation
                          </Typography>
                        </Box>
                      </Sheet>
                    </Stack>
                  </Sheet>
                </Stack>
              </Stack>
            </Form>
          </DialogContent>
          <Stack
            direction="row"
            spacing={1}
            p={0.3}
            alignItems="center"
            sx={{
              position: 'absolute',
              top: '0px',
              right: '5px',
              borderRadius: 1,
              backgroundColor: 'transparent'
            }}
          >{
              isUploading && <CIconButton
                noBorder
                disabled={saving}
                title="Reduire"
                onClick={handleMinimize}
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  ':hover': { bgcolor: 'white' },
                  ':disabled': { bgcolor: 'white' }
                }}
              >
                <Iconify icon="ic:round-remove" />
              </CIconButton>
            }
            <CIconButton
              noBorder
              disabled={saving}
              title="Fermer"
              onClick={onClose}
              sx={{
                color: '#fff',
                bgcolor: 'red',
                ':hover': { bgcolor: 'red' },
                ':disabled': { bgcolor: 'error.light' }
              }}
            >
              <Iconify icon="eva:close-fill" />
            </CIconButton>
          </Stack>
        </Dialog>
      </FormikProvider>
      {visible && <RejectMessage open={Boolean(visible)} onClose={hide} onReject={handleReject} addFile />}
      {noteModal && (
        <NoteViewer
          open={Boolean(noteModal)}
          onClose={hideNote}
          note={affectation.validation.rejectNote}
          anchor={noteViewRef?.current}
        />
      )}
      {saveWithoutDocument && (
        <SaveWithoutDocument
          open={Boolean(saveWithoutDocument)}
          onClose={hideSaveWithoutDocument}
          onAccept={handleSaveWithoutDocument}
        />
      )}
      {isUploading && <LoadingScreen />}

      {saveWithoutUpdate && (
        <SaveWithoutUpdateModal
          open={Boolean(saveWithoutUpdate)}
          onClose={hidesaveWithoutUpdate}
          onAccept={handleSaveWithoutUpdate}
        />
      )}
    </>
  );
}

/**
 *
 * @param {{
 * user: firebase.default.UserInfo,
 * organigramme: Array<{label:string, users: Array}>,
 * assigne: Array<{label:string, users: Array}>,
 * onChange: (field, value) => void,
 * disabled: boolean
 * }} props
 */
const AssigneView = ({ user, organigramme, assigne, onChange, disabled = false }) => {
  const { users } = useSelector((state) => state.user);
  const myLevel = useMemo(() => {
    const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
    if (!assigne.length) {
      const _ass = organigramme.map((org) => ({ ...org, users: [] }));
      if (position !== -1) {
        return {
          assigne: _ass,
          position
        };
      }
      return { assigne: _ass, position: 0 };
    }
    return { assigne, position };
  }, [assigne, organigramme, user.uid]);


  // console.log({ myLevel, allUsers });

  const [assignationOrganigramme, setAssigneOrganigramme] = useState(myLevel.assigne);

  const handleAssigneTo = (currentLevel, newItem) => {
    const level = currentLevel;

    //console.log({ level: level, position: myLevel?.position, newItem });
    setAssigneOrganigramme((old) => {
      let newOrg = [...old];

      if (newOrg[level]) {
        newOrg = newOrg.map((item, index) => {
          if (index === level) {
            return { ...item, ...newItem };
          }
          return item;
        });

        onChange('assignationOrganigramme', [...newOrg]);
        return [...newOrg];
      }

      onChange('assignationOrganigramme', [...newOrg]);
      return [...newOrg];
    });
  };

  return (
    <Stack alignItems="start">
      {assignationOrganigramme?.map((org, index) => (
        <OrganigrammeItem
          key={org.label + index}
          item={org}
          isLast={index === assignationOrganigramme.length - 1}
          isFirst={index === 0}
          index={index}
          updateLevel={handleAssigneTo}
          assingation
          defaultPosition={myLevel.position}
          disabled={disabled}
        />
      ))}
    </Stack>
  );
};

const SaveWithoutUpdateModal = ({ open, onClose, onAccept }) => {
  return (
    <ActionModal
      title={'Enregistrer'}
      color="warning"
      open={open}
      onClose={onClose}
      moreAction={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>
            Vous n'avez pas apporté de modification à cette affectation. Voulez-vous l'enregistrer quand même ?
          </Typography>
        </Stack>
      }
      action={
        <Stack direction="row" spacing={1} alignItems="center">
          <Button color="warning" variant="contained" onClick={onAccept}>
            Enregistrer
          </Button>
        </Stack>
      }
    />
  );
};

const SaveWithoutDocument = ({ open, onClose, onAccept }) => {
  return (
    <>
      <ActionModal
        title={'Enregistrer sans pièce jointe'}
        color="warning"
        open={open}
        onClose={onClose}
        moreAction={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography textAlign={'center'}>
              Vous êtes sur le point d'enregistrer cette affectation sans y avoir ajouté de pièce jointe. Voulez-vous continuer ?
            </Typography>
          </Stack>
        }
        action={
          <Button color="warning" variant="contained" onClick={onAccept}>
            Continuer
          </Button>
        }
      />
    </>
  );
};

