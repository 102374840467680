import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
// material
import { Button, CircularProgress, ClickAwayListener, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MuiCustomInput } from 'src/components/CustomInput';
import { createColumn } from 'src/redux/slices/kanban';
import { dispatch } from 'src/redux/store';
import { useV4ProjectContext } from 'src/section/project/v4/content/project_content';

// ----------------------------------------------------------------------

/**
 * A React component that renders a button to add a new column to a Kanban board.
 * 
 * The component handles the state of the input field for the new column name, as well as the opening and closing of the input field.
 * When the user clicks the "Add Operation" button, the input field is displayed, and the user can enter a name for the new column.
 * When the user presses the "Enter" key, the new column is created and added to the Kanban board.
 * 
 * @param {boolean} loading - A boolean indicating whether the component is currently loading.
 * @param {string} projectKey - The key of the project that the Kanban board belongs to.
 * @returns {JSX.Element} - The rendered component.
 */
export default function V4ProjectContentKanbanColumnAdd({ loading, projectKey }) {
  const nameRef = useRef(null);
  //eslint-disable-next-line
  const theme = useTheme();
  const colomColor = '';
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const { taskRights: rigths } = useV4ProjectContext()

  //console.log({ rigths , canCreate});

  useEffect(() => {
    if (open) {
      if (nameRef !== null) {
        nameRef.current.focus();
      }
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleCreateColumn = async () => {
    try {
      handleClose();
      if (name) {
        const callback = () => {
          enqueueSnackbar('Opération créé avec succès', { variant: 'success' });
          setName('');
        };

        dispatch(createColumn({ name, color: colomColor, projectKey: projectKey }, callback));
      }
    } catch (error) {
      console.error(error);
    }
  };

  let counter = 0;
  const handleKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (counter < 1) {
        handleCreateColumn();
        counter += 1;
        return;
      }
    }
  };

  const handleToolbarKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <Paper sx={{ minWidth: 230, width: 230, p: 0.2 }}>
      {!open && (
        <Button
          fullWidth
          size="medium"
          color="inherit"
          variant="outlined"
          disabled={rigths.create ? false : true}
          startIcon={!loading ? <Icon icon={plusFill} width={20} height={20} /> : <CircularProgress size={25} />}
          onClick={handleOpen}
        >
          Ajouter une opération
        </Button>
      )}

      {open && (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <MuiCustomInput
            fullWidth
            placeholder="Nouvelle opération"
            label=" "
            size="small"
            multiline
            inputRef={nameRef}
            value={name}
            onKeyDown={handleToolbarKeyDown}
            onChange={handleChangeName}
            onKeyUp={handleKeyUp}
            sx={{ typography: 'overline', py: 0.5 }}
          />
        </ClickAwayListener>
      )}
    </Paper>
  );
}
