import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import TextMaxLine from 'src/components/TextMaxLine';
import { COL_WIDTH } from 'src/constants/task';

/**
 * Renders a task component for a project content list in the V4 version of the application.
 *
 * @param {object} props - The component props.
 * @param {string} props.name - The name of the task.
 * @param {string} props.intervenant - The name of the intervening party.
 * @param {string} props.priority - The priority of the task.
 * @param {string} props.due - The due date of the task.
 * @param {boolean} [props.header=false] - Whether the task is a header row.
 * @param {string} props.visibility - The visibility of the task.
 * @param {number} [props.customSize=COL_WIDTH] - The custom size of the task component.
 * @returns {JSX.Element} - The rendered task component.
 */
export default function V4ProjectContentTaskComponent({
  name,
  intervenant,
  priority,
  due,
  header = false,
  visibility,
  customSize = COL_WIDTH
}) {
  return (
    <Stack
      width={1}
      direction="row"
      alignItems="center"
      px={0.5}
      sx={{ borderBottom: '1px solid', borderColor: (t) => `${t.palette.divider}` }}
      divider={<Box height={30} width={1.01} bgcolor={(t) => t.palette.divider} mr={1} />}
    >
      {name && (
        <Stack width={1}>
          {header ? (
            <TextMaxLine variant="body1" fontSize={13} fontWeight="bold" line={1} maxLength={20}>
              {name}
            </TextMaxLine>
          ) : (

            name

          )}
        </Stack>
      )}

      {intervenant && (
        <Stack minWidth={customSize} alignItems="left">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {intervenant}
            </Typography>
          ) : (
            intervenant
          )}
        </Stack>
      )}

      {visibility && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              Visibilité
            </Typography>
          ) : (
            visibility
          )}
        </Stack>
      )}

      {priority && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {priority}
            </Typography>
          ) : (
            priority
          )}
        </Stack>
      )}

      {due && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {due}
            </Typography>
          ) : (
            due
          )}
        </Stack>
      )}
    </Stack>
  );
}
