import { useState, useMemo, useEffect } from 'react';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'src/redux/store';
import { getCurrentProject, getAllProjects } from 'src/redux/slices/kanban';
import { getProjectById } from 'src/utils/project';

/**
 * 
 * @param {{ id: string, status?: []}} props 
 * @returns {{ projects: [], loading: Boolean }}
 */
export default function useProject({ id, status=null }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${id}_My_Projects`]);
    const projects = useMemo(()=> isEmpty(getProjects) ? [] : getProjects, [getProjects]);


    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            ...(status ?{ 
                where:[['canAccessId', 'array-contains', id], ["state", "in", status]] 
            } : { 
                where:[['canAccessId', 'array-contains', id]]
            }), 
            orderBy:[['createdAt', 'desc']], 
            storeAs: `list_${id}_My_Projects`
        }
    ]);


    useEffect(() => {
        //console.log('getProjects', getProjects);
        setLoading(!isLoaded(getProjects));
        dispatch(getAllProjects(projects))
    },[getProjects, dispatch, projects]);

    return { loading, projects }
}

/**
 * A custom React hook that fetches and manages a list of projects that the user has access to.
 * 
 * @param {object} props - The props object.
 * @param {string} props.id - The user's ID.
 * @returns {object} An object containing the loading state and the list of projects.
 */
export const useAllProjects = ({ id }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${id}_All_Projects`]);
  const projects = useMemo(() => isEmpty(getProjects) ? [] : getProjects, [getProjects]);
  
  useFirestoreConnect(() => [
    {
      collection: 'project',
      where: [['canAccessId', 'array-contains', id]],
      orderBy: [['createdAt', 'desc']],
      storeAs: `list_${id}_All_Projects`
    }
  ]);

  useEffect(() => {
    setLoading(!isLoaded(getProjects));
    dispatch(getAllProjects(projects));
  }
    , [getProjects, dispatch, projects]);
  
  return { loading, projects };
}


/**
 * 
 * @param {{ id: string, status?: []}} props 
 * @returns {{ projects: [], loading: Boolean }}
 */
export function useProjectHome({ id }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${id}_My_Projects_Home`]);
    const projects = useMemo(()=> isEmpty(getProjects) ? [] : getProjects, [getProjects]);


    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            where:[['canAccessId', 'array-contains', id], ["state", "in", ['open']]],
            limit: 5,
            orderBy:[['lastOpen', 'desc']], 
            storeAs: `list_${id}_My_Projects_Home`
        }
    ]);


    useEffect(()=> {
        setLoading(!isLoaded(getProjects));
        dispatch(getAllProjects(projects))
    },[getProjects, dispatch, projects]);

    return { loading, projects }
}

export function useOneProject({ id }){
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`${id}_My_Project`]);
    const project = useMemo(()=> isEmpty(getProjects) ? {} : getProjects[0], [getProjects]);

    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            doc: id,
            storeAs: `${id}_My_Project`
        }
    ]);


    useEffect(()=> {
        setLoading(!isLoaded(getProjects));
        dispatch(getCurrentProject(project))
    },[getProjects, dispatch, project]);

    return { loading, project }
}


/**
 * Provides a custom React hook to fetch and manage a single project by its ID.
 *
 * @param {string} projectId - The ID of the project to fetch.
 * @returns {object} - An object containing the project data and a loading state.
 * @property {object} project - The fetched project data.
 * @property {boolean} loading - A flag indicating whether the project data is currently being fetched.
 */
export const useProjectData = (projectId) => {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getProjectById(projectId)
      .then((res) => {
        //console.log('res', res);
        if (isMounted) {
          setProject(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          //console.log('err', err);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [projectId]);

  return { project, loading };
};