import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { getFileExtensionByType, getFileFormat } from '../utils/getFileFormat';
import LightboxModal from './LightboxModal';
import VideoAndAudioPlayerModal from 'src/components/VideoAndAudioPlayerModal';
import DocumentReader from './only-office/DocumentReader';
import { isEmpty } from 'lodash';
import { resetFileUpload } from 'src/redux/slices/mailsDocument';
import { useDispatch, useSelector } from 'src/redux/store';
import { uploadMailAttachment } from 'src/redux/slices/mailAttachmentThunks';
import { DOCUMENT_TYPES, MEDIA_TYPES, SUPPORTED_FORMATS } from 'src/constants/mail';
import { useSnackbar } from 'notistack';

/**
 * Checks if the file type is supported by the reader.
 *
 * @param {Object} file - The file object containing the attachment details.
 * @returns {boolean} - True if the file type is supported, false otherwise.
 */
export const isFileHasReader = (file) => {
  if (file) {
    const fileType = getFileFormat(file?.type.replace('/', '.'));
    return SUPPORTED_FORMATS.includes(fileType);
  }
  return false;
};

/**
 * Renders a dialog that displays the content of an attachment based on its file type.
 * Supports image, document (Word, Excel, PDF), and video/audio file types.
 * 
 * @param {Object} file - The file object containing the attachment details.
 * @param {boolean} open - Indicates whether the dialog should be open.
 * @param {function} onClose - Callback function to be called when the dialog is closed.
 * @param {function} onUpdate - Callback function to be called when the attachment is updated.
 * @param {string?} mailId - The ID of the mail the attachment is associated with.
 * @returns {JSX.Element} - The rendered dialog component.
 */
export default function AttachmentReader({ file, open, onClose, onUpdate, mailId }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { progress, fileUrl, error } = useSelector((state) => state.mailsDocument);
  const [mediaUrl, setMediaUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdate = (metas) => {
    if (onUpdate) {
      onUpdate(metas);
    }
  };

  const fileType = getFileFormat(file?.type.replace('/', '.'));
  const isMedia = useMemo(() => MEDIA_TYPES.includes(fileType), [fileType]);
  const fileData = file?.url.split(',')[1];
  const fileExtension = getFileExtensionByType(fileType);
  let fileName = file?.name;

  if (!fileName?.includes('.')) {
    fileName = `${fileName}.${fileExtension}`;
  }

  useEffect(() => {
    if (file && open) {
      if (mailId && !isMedia) {
        dispatch(uploadMailAttachment({
          data: fileData,
          fileName: fileName,
          fileType: fileType,
          mailId: mailId
        }));
      } else {
        setMediaUrl(file?.url);
      }
    }

    return () => {
      dispatch(resetFileUpload());
    };
  }, [file, open, fileData, fileType, isMedia, dispatch, mailId, fileName]);

  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar('Une erreur s\'est produite lors du chargement du fichier', { variant: 'error' });
      // Display a notification or handle the error here
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (!open || !file) return null;

  const handleCloseView = (metas) => {
    onClose();
    handleUpdate(metas);
  };

  const renderContent = () => {
    if (fileType === 'image') {
      return <LightboxModal isOpen images={[mediaUrl]} photoIndex={0} onClose={onClose} setPhotoIndex={() => { }} />;
    }

    if (DOCUMENT_TYPES.includes(fileType)) {
      const documentUrl = mailId ? fileUrl : file?.url;
      if (progress < 100 || isEmpty(documentUrl)) {
        return null;
      }
      return (
        <DocumentReader
          onClose={onClose}
          open={Boolean(open)}
          document={{
            key: file?.id,
            url: documentUrl,
            title: fileName,
            fileType: fileType,
          }}
          uploadName={fileName}
          docKey={file?.docKey || file?.id}
          openMode={'view'}
          loading={false}
          editable={true}
        />
      );
    }

    if (fileType?.includes('video') || fileType?.includes('audio')) {
      return (
        <VideoAndAudioPlayerModal
          open
          file={{ ...file, url: mediaUrl }}
          email={user?.email}
          handleUpdate={handleUpdate}
          onClose={handleCloseView}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      {renderContent()}
    </Fragment>
  );
}
