import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { CustomMenuPopover } from '../MenuPopover';
import { Button, Divider, Stack } from '@mui/material';
import { format } from 'date-fns';
import { fr as LocalFr } from 'date-fns/locale';
import { DateRange, Calendar } from 'react-date-range';
import { Input } from '@mui/joy';
import { styled } from '@mui/styles';
import Iconify from '../Iconify';
import CIconButton from '../CIconButton';
import useToggle from 'src/hooks/useToggle';
import { baseColors } from 'src/constants/color';
import { gDate } from 'src/utils/formatTime';
import OKyTimeInput from './OKyTimeInput';
import { isArray, isEmpty } from 'lodash';

const StyleInput = styled(Input)(({ theme }) => ({
  width: 150,
  fontSize: 14
}));

export const OKY_CALENDAR_TYPE = {
  RANGE: 'RANGE',
  SOLO: 'SOLO'
};

/**
 * Composant de calendrier personnalisé utilisé pour la sélection de dates.
 *
 * @param {object} props - Les propriétés du composant.
 * @param {boolean} props.open - Indique si le calendrier est ouvert ou non.
 * @param {Function} props.onClose - Fonction de rappel appelée lorsque le calendrier est fermé.
 * @param {Element | (() => Element) | import('@mui/material').PopoverVirtualElement | (() => import('@mui/material').PopoverVirtualElement)} props.anchorEl - Élément d'ancrage pour positionner le calendrier.
 * @param {Date} [props.minDate] - Date minimale sélectionnable dans le calendrier.
 * @param {Date} [props.maxDate] - Date maximale sélectionnable dans le calendrier.
 * @param {boolean} [props.openStart] - Indique si le calendrier doit s'ouvrir à partir du début.
 * @param {boolean} [props.singleDate] - Indique si une seule date doit être sélectionnée.
 * @param {import('@mui/material').PopoverOrigin} [props.anchorOrigin] - Origine de l'ancre pour le positionnement du calendrier.
 * @param {import('@mui/material').PopoverOrigin} [props.transformOrigin] - Origine de transformation pour le positionnement du calendrier.
 * @param {{startDate: Date|string|null, endDate: Date|string|null} | [deb:Date|string| null, end:Date| string| null]} props.dates - Dates sélectionnées dans le calendrier.
 * @param {(dates:{startDate: Date|string|null, endDate: Date|string|null}|[deb:Date|string| null, end:Date| string| null]) =>{}} props.onChange - Fonction de rappel appelée lorsque les dates sélectionnées changent.
 */
export default function OkyCalendar({
  open,
  dates,
  maxDate,
  minDate,
  onClose,
  anchorEl,
  onChange,
  openStart = false,
  singleDate = false,
  anchorOrigin,
  transformOrigin
}) {
  return (
    <CustomMenuPopover
      open={open}
      disabledArrow
      onClose={onClose}
      width="max-content"
      anchorEl={anchorEl}
      anchorOrigin={{ ...(anchorOrigin || { vertical: 'center', horizontal: 'right' }) }}
      transformOrigin={{ ...(transformOrigin || { vertical: 'center', horizontal: 'left' }) }}
    >
      <OkyCalendarBody
        dates={dates}
        onChange={onChange}
        onClose={onClose}
        minDate={minDate}
        maxDate={maxDate}
        openStart={openStart}
        singleDate={singleDate}
      />
    </CustomMenuPopover>
  );
}

/**
 * Composant de calendrier personnalisé utilisé pour la sélection de dates.
 *
 * @param {object} props - Les propriétés du composant.
 * @param {Function} [props.onClose] - Fonction de rappel appelée lorsque le calendrier est fermé.
 * @param {Date} [props.minDate] - Date minimale sélectionnable dans le calendrier.
 * @param {Date} [props.maxDate] - Date maximale sélectionnable dans le calendrier.
 * @param {boolean} [props.openStart] - Indique si le calendrier doit s'ouvrir à partir du début.
 * @param {boolean} [props.singleDate] - Indique si une seule date doit être sélectionnée.
 * @param {{startDate: Date|string|null, endDate: Date|string|null} | [deb:Date|string| null, end:Date| string| null]} props.dates - Dates sélectionnées dans le calendrier.
 * @param {(dates:{startDate: Date|string|null, endDate: Date|string|null}|[deb:Date|string| null, end:Date| string| null]) =>{}} props.onChange - Fonction de rappel appelée lorsque les dates sélectionnées changent.
 */
export const OkyCalendarBody = ({ dates, onChange, maxDate, minDate, onClose, openStart, singleDate }) => {
  const [init, setInit] = useState(false);
  const [asDue, setAsDue] = useState(false);
  const { open: addTime, handleSwitch: changeAddTime, handleOpen } = useToggle(false);

  useEffect(() => {
    if (isArray(dates)) {
      setAsDue(true);
    }
    setInit(true);
  }, [dates]);

  useEffect(() => {
    if (
      dateHelpersConverter(dates).startDate?.getHours() ||
      dateHelpersConverter(dates).startDate?.getMinutes() ||
      dateHelpersConverter(dates).endDate?.getHours() ||
      dateHelpersConverter(dates).endDate?.getMinutes()
    ) {
      handleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  return (
    <Stack>
      {init && (
        <OkyCalendarDate
          asDue={asDue}
          addTime={addTime}
          dates={dates}
          onChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
          openStart={openStart}
          singleDate={singleDate}
        />
      )}
      <Divider light />
      <Stack direction="row" p={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <CIconButton
            color={addTime ? 'info' : 'inherit'}
            title={addTime ? "Retirer l'heure" : "Ajouter l'heure"}
            size="medium"
            noBorder
            onClick={changeAddTime}
            sx={{ ...(addTime && { color: '#FFF', bgcolor: baseColors.BLUE, ':hover': { bgcolor: baseColors.BLUE } }) }}
          >
            <Iconify icon="eva:clock-outline" />
          </CIconButton>
        </Stack>
        <Button variant="text" color="inherit" onClick={onClose}>
          Fermer
        </Button>
      </Stack>
    </Stack>
  );
};

const SELECT_RANGE = { first: [0, 0], end: [0, 1] };

/**
 *
 * @param {object} props - Les propriétés du composant.
 * @param {Date} [props.minDate] - Date minimale sélectionnable dans le calendrier.
 * @param {Date} [props.maxDate] - Date maximale sélectionnable dans le calendrier.
 * @param {boolean} [props.openStart] - Indique si le calendrier doit s'ouvrir à partir du début.
 * @param {boolean} [props.singleDate] - Indique si une seule date doit être sélectionnée.
 * @param {{startDate: Date|string|null, endDate: Date|string|null} | [deb:Date|string| null, end:Date| string| null]} props.dates - Dates sélectionnées dans le calendrier.
 * @param {(dates:{startDate: Date|string|null, endDate: Date|string|null}|[deb:Date|string| null, end:Date| string| null]) =>{}} props.onChange - Fonction de rappel appelée lorsque les dates sélectionnées changent.
 */
const OkyCalendarDate = ({ asDue, addTime, dates, onChange, minDate, maxDate, openStart, singleDate }) => {
  const [init, setInit] = useState(false);
  const [values, setValues] = useState({
    ...dateHelpersEnter(dates),
    key: 'selection'
  });
  const [calendarType, setCalendarType] = useState(OKY_CALENDAR_TYPE.SOLO);
  const [focusedRange, setFocusedRange] = useState(openStart ? SELECT_RANGE.first : SELECT_RANGE.end);

  const handleChangeCalendar = () => {
    if (calendarType === OKY_CALENDAR_TYPE.SOLO) {
      setCalendarType(OKY_CALENDAR_TYPE.RANGE);
      setValues((old) => ({ ...old, startDate: old.startDate || new Date() }));
      if (onChange) {
        onChange({
          startDate: toDateTime(values?.startDate || new Date(), values?.startTime),
          endDate: toDateTime(values?.endDate, values?.endTime)
        });
      }

      return;
    }
    return setCalendarType(OKY_CALENDAR_TYPE.SOLO);
  };

  const toDateTime = (date, time = '') => {
    if (isEmpty(time)) {
      return date;
    }

    if (gDate(date)) {
      const [hours, minutes] = time.split(':').map(Number);
      const updatedDate = new Date(gDate(date).getTime());
      updatedDate.setHours(hours);
      updatedDate.setMinutes(minutes);
      return updatedDate;
    }

    return date;
  };

  const handleDateChange = (item) => {
    if (calendarType === OKY_CALENDAR_TYPE.SOLO && !item?.selection) {
      setValues((old) => ({ ...old, endDate: item }));

      if (onChange) {
        if (asDue) {
          onChange(
            dateHelpersToDue({
              startDate: toDateTime(dateHelpersEnter(dates)?.startDate || null, values?.startTime),
              endDate: toDateTime(item, values.endTime)
            })
          );
          return;
        }

        onChange({
          startDate: toDateTime(dateHelpersEnter(dates)?.startDate || null, values?.startTime),
          endDate: toDateTime(item, values.endTime)
        });
      }

      return;
    }

    setValues(item?.selection);

    if (JSON.stringify(focusedRange) === JSON.stringify(SELECT_RANGE.first)) {
      if (init) {
        setFocusedRange(SELECT_RANGE.end);
      }
      setInit(true);
    }

    if (onChange) {
      if (asDue) {
        onChange(
          dateHelpersToDue({
            startDate: toDateTime(item?.selection?.startDate, values?.startTime),
            endDate: toDateTime(item?.selection?.endDate, values?.endTime)
          })
        );
        return;
      }

      onChange({
        startDate: toDateTime(item?.selection?.startDate, values?.startTime),
        endDate: toDateTime(item?.selection?.endDate, values?.endTime)
      });
    }
  };

  const handleUpdateTime = (field, val) => {
    setValues((old) => {
      const newDateValue = { ...old, [field]: val };

      if (onChange) {
        const startDateTime = toDateTime(newDateValue?.startDate, newDateValue?.startTime);
        const endDateTime = toDateTime(newDateValue?.endDate, newDateValue?.endTime);

        if (calendarType === OKY_CALENDAR_TYPE.SOLO) {
          if (asDue) {
            onChange(dateHelpersToDue({ startDate: startDateTime, endDate: endDateTime }));
          } else {
            onChange({ startDate: startDateTime, endDate: endDateTime });
          }
        } else {
          if (asDue) {
            onChange(dateHelpersToDue({ startDate: startDateTime, endDate: endDateTime }));
          } else {
            onChange({ startDate: startDateTime, endDate: endDateTime });
          }
        }
      }

      return newDateValue;
    });
  };

  useEffect(() => {
    if (!addTime) {
      setValues((old) => ({ ...old, startTime: '', endTime: '' }));
      return;
    }
  }, [addTime]);

  useEffect(() => {
    if (dateHelpersConverter(dates)?.startDate) {
      setCalendarType(OKY_CALENDAR_TYPE.RANGE);
    }
  }, [dates]);

  return (
    <Stack alignItems="center" spacing={1} p={2}>
      <Stack width={1} {...(singleDate && { direction: 'row', justifyContent: 'space-between' })} spacing={1}>
        <Stack direction="row" width={1} spacing={3} justifyContent="space-between" alignItems="center">
          {!singleDate && calendarType === OKY_CALENDAR_TYPE.SOLO && (
            <Button onClick={handleChangeCalendar} color="inherit" startIcon={<Iconify icon="eva:plus-fill" />}>
              Date début
            </Button>
          )}
          {!singleDate && calendarType === OKY_CALENDAR_TYPE.RANGE && (
            <StyleInput
              value={format(gDate(values?.startDate) || new Date(), 'dd MMM yyyy', { locale: LocalFr })}
              variant="outlined"
              size="lg"
              placeholder="Date début"
              onFocus={() => setFocusedRange(SELECT_RANGE.first)}
              sx={{
                ...(JSON.stringify(focusedRange) === JSON.stringify(SELECT_RANGE.first) && {
                  border: `1px solid ${baseColors.BLUE}`
                })
              }}
            />
          )}
          <StyleInput
            value={format(gDate(values?.endDate) || new Date(), 'dd MMM yyyy', { locale: LocalFr })}
            variant="outlined"
            size="lg"
            placeholder="Date échéance"
            onFocus={() => setFocusedRange(SELECT_RANGE.end)}
            sx={{
              ...(JSON.stringify(focusedRange) === JSON.stringify(SELECT_RANGE.end) && {
                border: `1px solid ${baseColors.BLUE}`
              })
            }}
          />
        </Stack>

        {addTime && (
          <Stack direction="row" width={1} spacing={3} justifyContent="space-between" alignItems="center">
            {!singleDate && calendarType === OKY_CALENDAR_TYPE.SOLO && (
              <Button onClick={handleChangeCalendar} color="inherit" startIcon={<Iconify icon="eva:plus-fill" />}>
                Heure début
              </Button>
            )}
            {!singleDate && calendarType === OKY_CALENDAR_TYPE.RANGE && (
              <OKyTimeInput
                variant="outlined"
                size="lg"
                placeholder="Heure début"
                value={values?.startTime}
                onChange={(val) => handleUpdateTime('startTime', val)}
              />
            )}
            <OKyTimeInput
              variant="outlined"
              size="lg"
              placeholder="Heure échéance"
              value={values?.endTime}
              onChange={(val) => handleUpdateTime('endTime', val)}
            />
          </Stack>
        )}
      </Stack>

      <>
        {calendarType === OKY_CALENDAR_TYPE.SOLO && (
          <Calendar
            locale={LocalFr}
            date={values.endDate}
            onChange={handleDateChange}
            {...(minDate && { minDate })}
            {...(maxDate && { maxDate })}
          />
        )}

        {calendarType === OKY_CALENDAR_TYPE.RANGE && (
          <DateRange
            ranges={[{ startDate: values?.startDate, endDate: values?.endDate, key: 'selection' }]}
            locale={LocalFr}
            focusedRange={focusedRange}
            showDateDisplay={false}
            onChange={handleDateChange}
            {...(minDate && { minDate })}
            {...(maxDate && { maxDate })}
          />
        )}
      </>
    </Stack>
  );
};

/**
 *
 * @param {{startDate: Date|string|null, endDate: Date|string|null}} dates
 * @returns {[deb:Date|string| null, end:Date| string| null]}
 */
const dateHelpersToDue = (dates) => {
  return [gDate(dates.startDate), gDate(dates.endDate)];
};

/**
 *
 * @param {[deb:Date|string| null, end:Date| string| null] | {startDate: Date|string|null, endDate: Date|string|null}} dates
 * @returns {{startDate: Date|string|null, endDate: Date|string|null}}
 */
const dateHelpersConverter = (dates) => {
  if (isArray(dates)) {
    return { startDate: gDate(dates?.at(0)), endDate: gDate(dates?.at(1)) };
  }

  return { startDate: gDate(dates?.startDate), endDate: gDate(dates?.endDate) };
};

/**
 *
 * @param {[deb:Date|string| null, end:Date| string| null] | {startDate: Date|string|null, endDate: Date|string|null}} dates
 * @returns {{startDate: Date|string|null, endDate: Date|string|null, startTime: string, endTime: string}}
 */
const dateHelpersEnter = (dates) => {
  const _date = dateHelpersConverter(dates);
  return {
    startDate: gDate(_date?.startDate),
    endDate: gDate(_date?.endDate) || new Date(),
    startTime: gDate(_date?.startDate) ? format(gDate(_date?.startDate), 'HH:mm', { locale: LocalFr }) : '',
    endTime: gDate(_date?.endDate) ? format(gDate(_date?.endDate), 'HH:mm', { locale: LocalFr }) : ''
  };
};
