import React, { useRef } from "react";
import { useToggleV2 } from "src/hooks/useToggle";
import { AUDIENCE_BASE_TYPE } from "src/constants";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import AudienceTableComment from "src/section/_dashboard/acceuil-v2/audience/AudienceTableComment";

const AcceuilFormAction = ({ selected, onChange, onSubmit, isEdit, loading, disabled }) => {
    const ref = useRef()
    const [visible, show, hide] = useToggleV2()

    const handleRejection = (comment) => {
        onChange('state', AUDIENCE_BASE_TYPE.REJECTED)
        onChange('comment', comment)
        hide()
    }

    return (
        <Stack direction={'row'} spacing={2} >



            {
                isEdit && (selected?.state === AUDIENCE_BASE_TYPE.REJECTED || selected?.state === AUDIENCE_BASE_TYPE.FINISHED) && (
                    <Tooltip title={'Mettre en attente'}>
                        <IconButton
                            size={'small'} sx={{ bgcolor: 'white' }} disabled={disabled}
                            onClick={() => onChange('state', AUDIENCE_BASE_TYPE.WAITING)}
                        >
                            <Icon icon={"ei:redo"} />
                        </IconButton>
                    </Tooltip>
                )
            }


            {
                isEdit && selected?.state === AUDIENCE_BASE_TYPE.REJECTED && (
                    <Stack

                        direction={'row'} spacing={1} px={1}
                        sx={{
                            bgcolor: 'white', borderColor: t => t?.palette?.error?.main,
                            borderRadius: 1, borderWidth: 1, borderStyle: 'solid'
                        }}
                    >
                        <Stack pt={.6}>
                            <Icon icon={'eva:close-outline'} />
                        </Stack>
                        Rejeté
                    </Stack>
                )
            }

            {
                isEdit && selected?.state === AUDIENCE_BASE_TYPE.FINISHED && (
                    <Stack
                        direction={'row'} spacing={1} px={1}
                        sx={{
                            bgcolor: 'white', borderColor: t => t?.palette?.success?.main,
                            borderRadius: 1, borderWidth: 1, borderStyle: 'solid'
                        }}
                    >
                        <Stack pt={.6}>
                            <Icon icon={'akar-icons:check'} />
                        </Stack>
                        Accepté
                    </Stack>
                )
            }


            {
                isEdit && selected?.state === AUDIENCE_BASE_TYPE.WAITING && (
                    <Stack direction={'row'}>

                        <Button color={'error'} variant={'contained'} size={'small'} sx={{ color: 'white' }} disabled={disabled}
                            onClick={show}
                        >
                            Rejeter
                        </Button>
                        <div ref={ref} />
                    </Stack>

                )
            }


            {
                isEdit && selected?.state === AUDIENCE_BASE_TYPE.WAITING && (
                    <Button color={'success'} variant={'contained'} size={'small'} sx={{ color: 'white' }} disabled={disabled}
                        onClick={() => onChange('state', AUDIENCE_BASE_TYPE.FINISHED)}
                    >
                        Accepter
                    </Button>
                )
            }


            <LoadingButton loading={loading} color={'secondary'} variant={'contained'} size={'small'} disabled={disabled}
                sx={{ color: 'white' }} onClick={onSubmit}>
                Enregistrer
            </LoadingButton>

            <AudienceTableComment
                anchor={ref}
                open={visible}
                onClose={hide}
                value={selected?.comment}
                onSave={handleRejection}
            />


        </Stack>
    )
}

export default AcceuilFormAction
