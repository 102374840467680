import React, { useEffect, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { baseColors } from 'src/constants/color';
import { formatDuration } from 'src/helpers/convertes';
import { Icon } from '@iconify/react';

const Widget = styled('div')(({ theme }) => ({
  padding: 10,
  paddingTop: 15,
  paddingBottom: 5,
  borderRadius: 16,
  width: 343,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  // zIndex: 1,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
  backdropFilter: 'blur(40px)'
}));

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2
});

export default function MusicPlayerSlider({ song, duration: _duration = 0, onListe = true, sx = {} }) {
  // console.log('song', song);
  const theme = useTheme();
  const [position, setPosition] = React.useState(0);
  const [volume, setVolume] = React.useState(1);
  const [paused, setPaused] = React.useState(true);
  const audioRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = React.useState(_duration);
  const audio = audioRef.current;

  //TODO :  auto get duration if is not set
  const play = () => {
    audio.volume = volume;
    if (paused) {
      setPaused(false);
      audio.play();
      return;
    }
    if (!paused) {
      setPaused(true);
      audio.pause();
    }
  };

  const getTimeUpdate = (e) => {
    setPosition(e.currentTarget.currentTime.toFixed(0));
  };

  const onPositionChange = (value) => {
    setPosition(value);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  };

  // console.log('duration', audioRef?.current?.duration);

  useEffect(() => {
    if (audioRef?.current?.duration != Infinity && _duration === 0) {
      setDuration(Math.floor(audioRef?.current?.duration));
    }
  }, [_duration, audioRef?.current?.duration]);

  return (
    <Box sx={{ width: 'fix-content', overflow: 'hidden', ...sx }}>
      <Widget sx={{ width: { xs: 1, md: 1 } }}>
        <Stack width={1} direction={onListe ? 'row' : 'column'} alignItems="center" spacing={2}>
          <Stack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: -1,
                ...(onListe && { width: '28%' })
              }}
            >
              {loading ? (
                <IconButton
                  aria-label={paused ? 'play' : 'pause'}
                  sx={{ bgcolor: baseColors.BLEU_N, ':hover': { bgcolor: baseColors.BLEU_N } }}
                  onClick={play}
                >
                  <Icon icon="line-md:downloading-loop" color="#FFF" height={19} width={19} />
                </IconButton>
              ) : (
                <IconButton
                  aria-label={paused ? 'play' : 'pause'}
                  sx={{ bgcolor: baseColors.BLEU_N, ':hover': { bgcolor: baseColors.BLEU_N } }}
                  onClick={play}
                >
                  {paused ? (
                    <PlayArrowRounded sx={{ fontSize: 19 }} htmlColor="#FFF" />
                  ) : (
                    <PauseRounded sx={{ fontSize: 19 }} htmlColor="#FFF" />
                  )}
                </IconButton>
              )}
            </Box>
          </Stack>

          <Stack width={1}>
            <Box sx={{ mt: -2 }}>
              <Slider
                aria-label="time-indicator"
                size="small"
                value={Number(position?.toString()) || 0}
                min={0}
                max={duration}
                onChange={(_, value) => onPositionChange(value)}
                sx={{
                  color: baseColors.BLEU_N,
                  height: 4,
                  '& .MuiSlider-thumb': {
                    width: 8,
                    height: 8,
                    transition: '0.5s cubic-bezier(.47,1.64,.41,.8)',
                    '&:before': {
                      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
                    },
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
                        }`
                    },
                    '&.Mui-active': {
                      width: 20,
                      height: 20
                    }
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.28
                  }
                }}
              />
              <audio
                ref={audioRef}
                src={song}
                onDurationChange={(data) => {
                  // console.log('onDurationChange', data.target.duration);

                }}
                onLoadStart={() => setLoading(true)}
                onLoadedMetadata={() => {
                  // console.log('onLoadedMetadata', audioRef.current.duration);
                }}
                onLoadedData={(data) => {
                  if (_duration === 0) {
                    // console.log({ data });
                    setDuration(audioRef.current.duration);
                    //setDuration(data.target.duration);
                  }
                  setLoading(false)
                }}
                onTimeUpdate={getTimeUpdate}
                onPause={() => {
                  setPaused(true);
                }}
                preload="auto"
              >
                <track default kind="captions" srcLang="fr" src="" />
              </audio>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: -2
                }}
              />
            </Box>
          </Stack>
          <Stack>
            <TinyText sx={{ mt: -1 }}> {formatDuration(duration - position)}</TinyText>
          </Stack>
        </Stack>
      </Widget>
    </Box>
  );
}
