import { createSlice, nanoid } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { NOTIFICATION_TYPES, TASK_STATE_VALIDATION } from 'src/constants';
import { auth } from 'src/contexts/FirebaseContext';
import { taskDefaultModel } from 'src/helpers/task';
import { changeOnObject } from 'src/utils/changeOnObject';
import firestore from 'src/utils/firestore';
import { serverTime } from 'src/utils/serverTime';
import { taskCreationNotification, taskUpdateNotification } from './notifications';
import { V4_TASK_HISTORY_ACTION } from 'src/constants/task';

const _createTaskHistory = ({
  actionType,
  task,
  values,
}) => {
    const { uid, displayName, email } = auth.currentUser;
    let history = {
      actionType,
      taskId : task?.id,
      values,
      createdBy: { id: uid, email, name: displayName },
      createdAt: serverTime(),
    };

  const _task = { ...task, metadata: {...task?.metadata, history: [history,...(task?.metadata?.history || [])] } };
  return _task;
}


export const createTaskHistory = ({ actionType, oldTask, values }) => {
  //TODO: fix why if is new task  the deadline is not updated
  return async (dispatch, getState) => { 
    try {
      const _task = _createTaskHistory({ actionType, oldTask, values });
    
      await firestore.collection('tasks').doc(_task.id).set(_task, { merge: true });
    }
    catch (error) {
      console.error(error);
    }
  }
}

const initialState = {
  tasks: []
};

const slice = createSlice({
  name: 'task_without_project',
  initialState,
  reducers: {
    addTask(state, action) {
      state.tasks.shift(action.payload);
    },
    addTasks(state, action) {
      state.tasks = action.payload;
    },
    updateTasks(state, action) {
      const task = action.payload;
      const position = state.tasks.findIndex((_one) => _one.id === task?.id);
      if (position !== -1) {
        state.tasks[position] = { ...state.tasks[position], ...task };
      }
    }
  }
});

export default slice.reducer;

export const { addTasks } = slice.actions;

export function createTaskWithoutProject(values, callback) {
  return async (dispatch, getState) => {
    const {
      kanban: { currentProject }
    } = getState();
    let id = values?.id || nanoid(26);
    const { uid, displayName, email } = auth.currentUser;

    let task = {
      id,
      ...taskDefaultModel,
      ...values,
      createdBy: { id: uid, email, name: displayName },
      projectState: currentProject?.state || 'open'
    };

    let observerIds = task.observers.map((pers) => pers.id);
    let canAccessId = task.assignee.map((pers) => pers.id);

    canAccessId.push(task.createdBy.id);
    canAccessId.concat(observerIds);

    const assigneIds = task?.assignee ? task?.assignee?.map((_one) => _one.id) : [];
    let assigneByIds = task?.assigneByIds || [];

    if (task.assignee?.length || task?.responsable !== null) {
      assigneByIds = uniq([uid, ...assigneByIds]);
    }

    const history = {
      actionType: V4_TASK_HISTORY_ACTION.CREATED,
      taskId: id,
      values: {},
      createdBy: { id: uid, email, name: displayName },
      createdAt: serverTime(),
    };

    task = {
      ...task,
      name: task?.name?.replace(/<[^>]+>/g, ''),
      state: TASK_STATE_VALIDATION.PENDING,
      isDeleted: false,
      canAccessId,
      assigneIds,
      assigneByIds,
      isDefaultTask: true,
      projectState: 'open',
      deadLine: task?.due?.at(1),
      createdAt: serverTime(),
      updatedAt: serverTime(),
      subTasksCount: 0,
      metadata: { history: [history] }
    };

    try {
      dispatch(slice.actions.addTask(task));
      await firestore
        .collection('tasks')
        .doc(id)
        .set({ ...task }, { merge: true });
      if (callback) {
        callback(id);
      }
      // console.log('task', task);
      dispatch(taskCreationNotification({ task }));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateTaskWithoutProject(oldTask, values, callback) {
  //TODO: fix why if is new task  the deadline is not updated
  return async (dispatch, getState) => {
    let temp = { ...values };
    // console.log('temp', temp);
    
    const { id } = oldTask;
    const isAssigne = values?.assignee || false;
    const oldDueDate = oldTask?.due
    const { uid, displayName, email } = auth.currentUser;
    const { kanban: { currentProject, sProject } } = getState();
    
    let project = currentProject || sProject;
    let metadata = { ...(oldTask?.metadata ?? {}) };

    try {
      if (temp?.due) {
        temp = { ...temp, deadLine: temp?.due?.at(1) };
      }
      if (id) {
        dispatch(slice.actions.updateTasks({ id, values: temp }));
        
        if (temp?.assignee) {
          const assigneIds = values?.assignee?.map((ass) => ass?.id);
          const canAccessId = uniq([oldTask?.createdBy?.id || '', ...(assigneIds || [])]);
          temp = { ...temp, canAccessId };
        }

        if (!oldTask?.completed && values?.completed) {
          metadata = {
            ...metadata,
            done: {
              doneDate: serverTime(),
              doneBy: {
                id: auth.currentUser?.uid
              }
            },
            history: [
             ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.DONE,
                taskId: id,
                values: {},
                createdBy:   { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.completed && !values?.completed) {
          metadata = {
            ...metadata,
            done: null,
            history: [
             ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.UNDONE,
                taskId: id,
                values: {  },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        
        }

        if (oldDueDate !== temp?.due && temp?.due) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.DUE_DATE_CHANGED,
                taskId: id,
                values: {
                  old: oldDueDate,
                  new: temp?.due
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.name !== temp?.name && temp?.name) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.NAME_CHANGED,
                taskId: id,
                values: { 
                  old: oldTask?.name,
                  new: temp?.name
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.priority !== temp?.priority && temp?.priority) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.PRIORITY_CHANGED,
                taskId: id,
                values: {
                  
                    old: oldTask?.priority,
                    new : temp?.priority
                  
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
                
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.desc !== temp?.desc && temp?.desc) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.DESC_CHANGED,
                taskId: id,
                values: {
                    old: oldTask?.desc,
                    new: temp?.desc 
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.state !== temp?.state && temp?.state) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.STATE_CHANGED,
                taskId: id,
                values: { 
                  old: oldTask?.state,
                  new: temp?.state
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        }

        if (oldTask?.assignee !== temp?.assignee && temp?.assignee) {
          metadata = {
            ...metadata,
            history: [
              ...(metadata?.history || []),
              {
                actionType: V4_TASK_HISTORY_ACTION.ASSIGNED,
                taskId: id,
                values: {
                  old : oldTask?.assignee,
                  new: temp?.assignee
                },
                createdBy: { id: uid, email, name: displayName },
                createdAt: serverTime(),
              }
            ]
          };
          temp = { ...temp, metadata };
        } 
        await firestore.collection('tasks').doc(id).set(temp, { merge: true });
        const change = changeOnObject(oldTask, values);
        dispatch(
          taskUpdateNotification({
            card: { ...oldTask, projectState: 'open', ...values },
            change,
            projectName: project?.name,
            theOld: oldTask
          })
        );
        if (callback) callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
}

