import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useMicrosoftMail } from 'src/contexts/MicrosoftMailContext';

import { isEmpty, keys, isEqual } from 'lodash';
import formatedFlags from 'src/utils/flags';

import MailLogin from 'src/section/mail/MailLogin';
import MailComposer from 'src/section/mail/MailComposer';
import MailAccountColor from 'src/section/mail/MailAccountColor';
import { igniteMsAccount, refreshLinkedAccount, refreshMsMails } from 'src/redux/slices/microsoftMail';
import { NotificationSnackbar } from 'src/components/NotificationBody';
import { useSnackbar } from 'notistack';
import MailV2DetailModal from 'src/section/mail_v2/MailV2DetailModal';
import { useCustomMail } from 'src/contexts/CustomMailContext';
import { getMails, refreshCustomMails } from 'src/redux/slices/customMail';
import { useSelector } from 'src/redux/store';
import UploadingFileView from '../doc/okydriver/annexes/UploadingFileView';
import { formatCorrectlyMailAdresse } from 'src/utils/email';

export const useMailContext = () => useContext(MailContext);

export default function MailProvider({ children, composerAnchor }) {
  const customMail = useCustomMail();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { progress } = useSelector((state) => state.mailsDocument);
  //#region PRIMAIRE STATE
  const [selectAccount, setSelectedAccount] = useState(null);
  const [uploadingFile, setUploadingFile] = useState({});
  const [showUpload, onShowUpload, onCloseUpload] = useToggleV2();

  //#endregion

  //#region STATE ACTIONS
  const [openSide, setOpenSide] = useState(false);

  const [folder, setFolder] = useState(MAIL_FOLDER.INBOX);
  const [account, setAccount] = useState(null);
  const [mails, setMails] = useState([]);
  const [readingMail, setReadingMail] = useState(null);
  const [selectedMails, setSelectedMails] = useState([]);
  const [isComposerOpen, onOpenComposer, onCloseComposer] = useToggleV2();

  const [isLoginBoxOpen, onOpenLoginBox, onCloseLoginBox] = useToggleV2();
  const [isMailDetailBoxOpen, onOpenMailDetailBox, onCloseMailDetailBox] = useToggleV2();

  const [readMail, setReadMail] = useState(null);
  const [readDraft, setReadDraft] = useState(null);
  const [isNewDraft, setIsNewDraft] = useState(false);

  const [prevLinkedAccount, setPrevLinkedAccount] = useState({});

  const [isColorsOpen, onOpenColors, onCloseColors] = useToggleV2();

  const microsoftMail = useMicrosoftMail();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { accounts } = useSelector((state) => state.customMail);

  //#endregion
  const initMail = {
    to: [],
    subject: '',
    cc: [],
    cci: [],
    replyTo: [],
    body: '',
    createdAt: new Date(),
    attachments: [],
  };

  const [mailComposer, setMailComposer] = useState(initMail);

  const handleClearMailComposer = () => {
    setMailComposer(initMail);
    setAccount(null);
  };

  const handleCloseUpload = () => {
    onCloseUpload();
    setUploadingFile({});
  };

  /**
   * Handles changes to a single field in the mail composer.
   *
   * @param {string} field - The name of the field to update.
   * @param {any} change - The new value for the field.
   */
  const handleChangeMailComposer = (field, change) => {
    //console.log('handleChangeMailComposer', field, change);
    setMailComposer((prevState) => ({ ...prevState, [field]: change }));
  };

  // handleChangeMailComposer qui prend en compte plusieurs champs simultanément
  /**
   * Handles changes to multiple fields in the mail composer.
   *
   * @param {Object} options - An object containing the updated fields for the mail composer.
   * @param {Array<string>} [options.to] - The updated list of recipient email addresses.
   * @param {Array<string>} [options.cc] - The updated list of CC email addresses.
   * @param {Array<string>} [options.cci] - The updated list of BCC email addresses.
   * @param {string} [options.subject] - The updated subject of the email.
   * @param {string} [options.body] - The updated body of the email.
   * @param {Array<string>} [options.replyTo] - The updated list of reply-to email addresses.
   * @param {Array<Object>} [options.attachments] - The updated list of email attachments.
   */
  const handleChangeMailComposerMulti = ({ to = [], cc = [], cci = [], subject = '', body = '', replyTo = [], attachments = [] }) => {
    console.log('handleChangeMailComposerMulti', { to, cc, cci, subject, body, replyTo, attachments });

    setMailComposer((prevState) => ({
      ...prevState,
      to: to.map(formatCorrectlyMailAdresse),
      cc: cc.map(formatCorrectlyMailAdresse),
      cci: cci.map(formatCorrectlyMailAdresse),
      replyTo: replyTo.map(formatCorrectlyMailAdresse),
      subject,
      body,
      attachments
    }));
  };

  const addFile = ({ file, mailId }) => {
    setUploadingFile({ ...file, progress, mailId });
    onShowUpload();
  };


  //#region USEMEMO

  //si une seul compte est disponible quel soit un custom mail ou un microsoft mail on le selectionne par defaut
  useEffect(() => {
    const customLength = keys(customMail.accounts).length;
    const microsoftLength = keys(microsoftMail.accounts).length;
    if (customLength + microsoftLength > 1) {
      setAccount(null);
      return;
    }
    if (customLength === 1 && microsoftLength === 0) {
      const account = customMail.accounts[keys(customMail.accounts)[0]];
      setAccount(account);
    }
    if (microsoftLength === 1 && customLength === 0) {
      const account = microsoftMail.accounts[keys(microsoftMail.accounts)[0]];
      setAccount(account);
    }

    // console.log({ accounts, microsoftMail });
  }, [accounts, microsoftMail, customMail.accounts]);

  useEffect(() => {
    if (!Object.entries(accounts).length) {
      return setIsFirstLoad(false);
    }

    let _AccountsContent = {};

    Object.entries(accounts).map(([_key, _]) => {
      if (!isEmpty(_key)) {
        const _content = customMail.mailsSnap[_key];

        if (_content) {
          _AccountsContent = { ..._AccountsContent, ..._content[folder] };
        }
      }
    });

    if (!Object.entries(_AccountsContent).length) {
      setIsFirstLoad(true);
      return;
    }

    if (
      Boolean(Object.entries(microsoftMail?.mailsSnap).length) ||
      Boolean(Object.entries(customMail?.mailsSnap).length)
    ) {
      // if mail is loaded for the first time , set the isFirstLoad
      setIsFirstLoad(false);
    } else {
      setIsFirstLoad(true);
    }
  }, [microsoftMail?.mailsSnap, customMail?.mailsSnap, accounts, folder]);

  const mailsLoading = useMemo(() => {
    return Boolean(microsoftMail.loadingSnap.length) || Boolean(customMail.loadingSnap.length);
  }, [microsoftMail.loadingSnap.length, customMail.loadingSnap.length]);

  const linkedMails = useMemo(
    () => [...microsoftMail.accounts, ...customMail.accounts],
    [microsoftMail.accounts, customMail.accounts]
  );

  const contentState = useMemo(
    () => getMailState(mailsLoading, linkedMails, account, mails, readingMail),
    [account, linkedMails, mails, mailsLoading, readingMail]
  );
  /**
   * Removes duplicate elements from an array based on the 'id' property of each element.
   *
   * @param {Array<Object>} arr - The input array of objects.
   * @returns {Array<Object>} - A new array with duplicate elements removed.
   */
  const deleteDuplicate = (arr) => {
    const unique = arr
      .map((e) => e['id'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  };

  /**
   * Retrieves a list of mails from the specified folder, optionally filtered by a flag or excluding a flag.
   *
   * @param {Object} options - The options object.
   * @param {string} options.folder - The folder to retrieve mails from.
   * @param {string|null} [options.flag] - The flag to filter mails by. If null, no filtering by flag is performed.
   * @param {string|null} [options.excludeFlag] - The flag to exclude mails by. If null, no exclusion by flag is performed.
   * @returns {Array<Object>} - An array of mail objects, with each object containing the mail data and additional properties.
   */
  const getMailsByFlag = ({ folder, flag = null, excludeFlag = MAIL_FLAG.DELETED }) => {
    const _current = [];
    console.log({ customMail: customMail.mailsSnap, linkedMails, folder, flag, excludeFlag });

    keys(customMail.mailsSnap).forEach((email) => {
      const snap = (customMail.mailsSnap[email] || {})[folder];
      const account = linkedMails?.find((el) => el?.email === email);
      keys(snap).forEach((id) => {
        if (flag === null && excludeFlag === null) {
          _current.push({ ...snap[id], account, folder, flags: formatedFlags(snap[id]?.flags) });
        } else if (flag === null && !snap[id]?.flags?.includes(excludeFlag)) {
          _current.push({ ...snap[id], account, folder, flags: formatedFlags(snap[id]?.flags) });
        } else if (excludeFlag === null && snap[id]?.flags?.includes(flag)) {
          _current.push({ ...snap[id], account, folder, flags: formatedFlags(snap[id]?.flags) });
        } else if (snap[id]?.flags?.includes(flag) && !snap[id]?.flags?.includes(excludeFlag)) {
          _current.push({ ...snap[id], account, folder, flags: formatedFlags(snap[id]?.flags) });
        }
      });
    });
    return _current;
  };

  /**
   * Retrieves the mails of the current folder, including both Microsoft and custom mail accounts.
   * The mails are filtered based on the current folder type (e.g. Inbox, Spam, Trash, etc.).
   * The mails are also enriched with additional properties such as the account and the sent date.
   * Duplicate mails are removed from the final result.
   *
   * @returns {Array<Object>} - An array of mail objects, with each object containing the mail data and additional properties.
   */
  const mailsOfCurrentFolder = useMemo(() => {
    let current = [];
    const spamCustomFlags = MAIL_FLAG.SPAM;
    const deleteCustomFlags = MAIL_FLAG.DELETED;
    const trashCustomFlags = MAIL_FLAG.TRASH;

    keys(microsoftMail.mailsSnap).forEach((email) => {
      //add date property to mail
      const snap = (microsoftMail.mailsSnap[email] || {})[folder];
      const account = linkedMails?.find((el) => el?.email === email);
      keys(snap).forEach((id) => {
        current.push({ ...snap[id], account, folder, date: snap[id]?.sentDate });
      });
    });

    if (folder.toLowerCase().includes(MAIL_FOLDER.TRASH.toLowerCase())) {
      // const mailByFlag = getMailsByFlag({ folder: MAIL_FOLDER.INBOX, flag: trashCustomFlags });
      const mailByFolder = getMailsByFlag({ folder });

      return [...mailByFolder];
    }

    else if (folder.toLowerCase().includes(MAIL_FOLDER.SPAM.toLowerCase())) {
      // const mailByFlag = getMailsByFlag({ folder: MAIL_FOLDER.INBOX, flag: spamCustomFlags });
      const mailByFolder = getMailsByFlag({ folder });

      return [...mailByFolder];
    }

    if (folder.toLowerCase().includes(MAIL_FOLDER.SENT.toLowerCase())) {
      // const mailByFlag = getMailsByFlag({ folder: MAIL_FOLDER.INBOX, flag: MAIL_FLAG.SENT });
      const mailByFolder = getMailsByFlag({ folder });

      return [...mailByFolder];
    }

    else if (folder.toLowerCase().includes(MAIL_FOLDER.DRAFTS.toLowerCase())) {
      // const mailByFlag = getMailsByFlag({ folder: MAIL_FOLDER.INBOX, flag: MAIL_FLAG.DRAFT });
      const mailByFolder = getMailsByFlag({ folder });

      return [...mailByFolder];
    }

    else {
      keys(customMail.mailsSnap).forEach((email) => {
        const snap = (customMail.mailsSnap[email] || {})[folder];
        const account = linkedMails?.find((el) => el?.email === email);
        keys(snap).forEach((id) => {
          if (snap[id]?.flags?.includes(deleteCustomFlags)) {
            return;
          }
          if (snap[id]?.flags?.includes(spamCustomFlags) || snap[id]?.flags?.includes(trashCustomFlags)) {
            return;
          }
          current.push({ ...snap[id], account, folder, flags: formatedFlags(snap[id]?.flags) });
        });
      });
    }

    return deleteDuplicate(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microsoftMail.mailsSnap, folder, linkedMails, customMail.mailsSnap]);


  /**
   * Calculates the total unread count across all Microsoft mail accounts.
   * @returns {number} The total unread count.
   */
  const unreadCount = useMemo(() => {
    let count = 0;
    keys(microsoftMail.folderSnap).forEach((email) => {
      const snap = (microsoftMail.folderSnap[email] || {})[MAIL_FOLDER.INBOX];
      count += snap?.unreadItemCount || 0;
    });

    return count;
  }, [microsoftMail.folderSnap]);

  //#endregion

  //#region SECOND ACTIONS
  /**
   * Opens and reads the mail for all accounts in the specified folder type.
   * @param {string} folderType - The type of folder to retrieve mails from, e.g. 'inbox', 'drafts', etc.
   * @returns {void}
   */
  const openAndReadBoxMail = (folderType) => {
    keys(accounts).forEach((_mail) => {
      if (!isEmpty(_mail)) {
        const _account = accounts[_mail];
        dispatch(getMails({ account: _account, folderType }));
      }
    });
  };
  //#endregion

  //#region FUNCTION MAIL

  /**
   * Logs in to a custom mail account and sets the account in the application state.
   * @param {Object} data - The login data, such as email and password.
   * @param {function} [onResolve] - An optional callback function to be called on successful login.
   * @param {function} [onReject] - An optional callback function to be called on login failure.
   * @returns {void}
   */
  const loginCustomMail = (data, onResolve, onReject) => {
    customMail.loginCustomMail(
      data,
      (account) => {
        onResolve && onResolve(account);
        setAccount(account);
        onOpenColors();
      },
      onReject
    );
  };

  /**
   * Retrieves a mail by its unique identifier, searching across both custom and Microsoft mail accounts.
   * @param {string} mailId - The unique identifier of the mail to retrieve.
   * @returns {object|null} - The mail object if found, or null if not found.
   */
  const getMailById = (mailId) => {
    //found mail one mail in custom mail with mail id search in all folder
    const customMail = keys(customMail.mailsSnap).find((email) => {
      const snap = customMail.mailsSnap[email];
      return keys(snap).find((id) => id === mailId);
    });

    if (customMail) {
      const snap = customMail.mailsSnap[customMail];
      const mail = keys(snap).find((id) => id === mailId);
      return { ...snap[mail], account: customMail, folder: snap[mail]?.folder };
    }

    //found mail one mail in microsoft mail with mail id search in all folder
    const microsoftMail = keys(microsoftMail.mailsSnap).find((email) => {
      const snap = microsoftMail.mailsSnap[email];
      return keys(snap).find((id) => id === mailId);
    });

    if (microsoftMail) {
      const snap = microsoftMail.mailsSnap[microsoftMail];
      const mail = keys(snap).find((id) => id === mailId);
      return { ...snap[mail], account: microsoftMail, folder: snap[mail]?.folder };
    }

    return null;
  };

  /**
   * Emits a new mail notification using the provided email object.
   * @param {Object} email - The email object containing the notification details.
   * @param {Object} email.from - The sender information, including name and email address.
   * @param {string} email.from.name - The name of the sender.
   * @param {string} email.from.email - The email address of the sender.
   * @param {string} email.subject - The subject of the email.
   * @returns {void}
   */
  const emitNewMailNotification = (email) => {
    enqueueSnackbar(
      <NotificationSnackbar
        open={true}
        title={'Vous avez reçu un email'}
        desc={`${email?.from?.name || email?.from?.email} <br/><br/> ${email.subject}`}
        url={null}
        action={{
          email,
          action: () => onOpenMailDetailBox(email)
        }}
        type={'email'}
        id={'emai'}
        onClose={() => { }}
      />,
      { persist: true }
    );
  };

  /**
   * Disconnects the specified email account from the application.
   * @param {Object} account - The email account to disconnect.
   * @param {string} account.platform - The platform of the email account (either "MICROSOFT" or "CUSTOM").
   * @returns {void}
   */
  const handleDisconnectAccount = (account) => {
    //console.log('disconnect', account);
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.logout(account);
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.logout(account);
    }
  };

  const handleLinkMail = () => {
    //console.log('handleLinkMail', account);
  };

  const openAccountColorPicker = (account) => {
    onOpenColors();
    setAccount(account);
  };
  /**
   * Updates the color of a given email account for Microsoft or Custom mail platforms.
   * @param {Object} account - The email account to update.
   * @param {string} account.email - The email address of the account.
   * @param {string} account.platform - The platform of the email account (either "MICROSOFT" or "CUSTOM").
   * @param {string} account.id - The unique identifier of the email account.
   * @param {string} color - The new color to set for the email account.
   */
  const handlePickAccountColor = (account, color) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateAccount(account.email, { ...account, color, id: account?.id });
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateAccount(account.email, { ...account, color, id: account?.id });
    }
  };

  /**
   * Adds a signature to the specified email account.
   * @param {Object} account - The email account to update.
   * @param {string} account.platform - The platform of the email account (either "MICROSOFT" or "CUSTOM").
   * @param {string} account.email - The email address of the account.
   * @param {string} signature - The signature to add to the account.
   * @returns {Promise<void>} - A Promise that resolves when the signature has been added successfully.
   */
  const handleAddSignatureToAccount = async (account, signature) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      await microsoftMail.updateAccount(account.email, { ...account, signature });
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      await customMail.updateAccount(account.email, { ...account, signature });
    }
    enqueueSnackbar('Signature ajoutée avec succès', { variant: 'info' });
  };

  /**
   * Sends an email using the specified email account and mail object.
   * @param {Object} account - The email account to use for sending the email.
   * @param {Object} mail - The mail object to send.
   * @param {Function} onResolve - The callback function to execute when the email is sent successfully.
   * @param {Function} onReject - The callback function to execute when the email fails to send.
   */
  const sendMail = (account, mail, onResolve, onReject) => {
    // mail.isRead = true by default
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.sendMail(
        account?.email,
        {
          ...mail,
          isRead: true
        },
        onResolve
      );
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.sendMail(
        account?.email,
        {
          ...mail,
          isRead: true
        },
        (data) => {
          onResolve(data);
          dispatch(getMails({
            account: account,
            folderType: folder,
            refreshDate: true
          }));
        },
        onReject
      );
    }

    // add sentflag to mail
    // customMail.updateMailFlag(account, mail,'', MAIL_FLAG.);
  };

  /**
   * Retrieves the attachments of a mail based on the account, folder type, and mail ID.
   * @param {Object} account - The mail account object.
   * @param {string} account.platform - The platform of the mail account (e.g. "MICROSOFT", "CUSTOM").
   * @param {string} folder - The folder containing the mail.
   * @param {string} mailId - The ID of the mail to retrieve attachments from.
   * @param {function} onResolve - The callback function to execute when the attachments are retrieved.
   */
  const getMailAttachments = (account, folder, mailId, onResolve) => {
    //console.log('level 1');
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.getMailAttachments(account, folder, mailId, onResolve);
    } else if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.getMailAttachments(account, folder, mailId, onResolve);
    }
  };

  /**
   * Deletes a mail from the specified account and folder.
   * @param {Object} account - The account object.
   * @param {string} account.platform - The platform of the account (e.g. "MICROSOFT", "CUSTOM").
   * @param {string} folder - The folder containing the mail.
   * @param {string} mailId - The ID of the mail to delete.
   * @param {Function} onResolve - The function to call when the operation is resolved.
   */
  const deleteMail = (account, folder, mail, mailUID, onResolve) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      //console.log('deleteMail', account, folder, mail?.id, mailUID);
      microsoftMail.deleteCompletyMail(account, folder, mail?.id, onResolve);
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateMailFlag(account, mail, '', MAIL_FLAG.DELETED, onResolve);
    }
  };

  /**
   * Update mail for a given account, folder and mail ID with the provided values.
   * @param {Object} account - The account object.
   * @param {string} account.platform - The platform of the account.
   * @param {string} folder - The folder of the mail.
   * @param {string} maiId - The ID of the mail.
   * @param {Object} values - The values to update the mail with.
   */
  const updateMail = (account, folder, maiId, values) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMail(account, folder, maiId, values);
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateMail(account, folder, maiId, values);
    }
  };

  /**
   * Toggles the importance of a mail for a given account and folder.
   * @param {Object} account - The account object.
   * @param {string} folder - The folder name.
   * @param {string} mailId - The ID of the mail to toggle importance for.
   * @param {boolean} isImportant - The new importance status of the mail.
   * @param {Function} onResolve - The callback function to execute after the operation is complete.
   */
  const toggleMailImportance = (account, folder, mailId, mailUID, isImportant, onResolve) => {
    //console.log('toggleMailImportance process', account, folder, mailId);
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.toggleMailImportance(account, folder, mailId, isImportant, onResolve);
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.toggleMailImportance(account, folder, mailId, mailUID, isImportant, onResolve);
    }
  };

  /**
   * Sets the selected mail as read and marks it as read in the corresponding mail platform if it's a Microsoft or custom account.
   * @param {Object} mail - The selected mail object.
   */
  const openMail = (mail) => {
    setReadMail(mail);
    if (!mail?.isRead) {
      if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT && !mail?.isRead) {
        microsoftMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail?.id);
      }
      if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM && !mail?.isRead) {
        if (mail?.isRead === false) {
          customMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail);
        }
      }
    }
  };

  /**
   * Checks if a mail has been seen/read.
   * @param {Object} mail - The mail object to check.
   * @returns {boolean} - True if the mail has been seen/read, false otherwise.
   */
  const mailIsSeen = (mail) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      return microsoftMail.mailsSnap?.[mail?.account?.email]?.[mail?.folder]?.[mail?.id]?.isRead;
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      return customMail.mailsSnap?.[mail?.account?.email]?.[mail?.folder]?.[mail?.id]?.isRead;
    }
  };

  /**
   * Checks if a given mail is in the spam folder.
   * @param {Object} mail - The mail object to check.
   * @returns {boolean} - True if the mail is in the spam folder, false otherwise.
   */
  const mailIsInSpam = (mail) => {
    if (mail.account.platform === MAIL_PLATFORM.MICROSOFT) {
      return microsoftMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.flags?.includes(MAIL_FLAG.SPAM);
    }
    if (mail.account.platform === MAIL_PLATFORM.CUSTOM) {
      return customMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.flags?.includes(MAIL_FLAG.SPAM);
    }
  };

  /**
   * Sets the selected mail as the read draft.
   * @param {Object} mail - The mail object to be set as the read draft.
   */
  const openDraft = (mail) => {
    setReadDraft(mail);
  };

  /**
   * Logs out the user from the mail account.
   * @param {Object} account - The mail account object.
   * @param {string} account.platform - The platform of the mail account.
   */
  const logout = (account) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.logout(account);
    }
    if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.logout(account);
    }
  };

  /**
   * Selects a mail and adds it to the selected mails state.
   * @param {Object} mail - The mail object to be selected.
   */
  const selectMail = (mail) => {
    setSelectedMails((prevState) => {
      const index = prevState.findIndex((el) => el?.id === mail?.id);
      if (index === -1) return [...prevState, mail];
      return prevState;
    });
  };

  /**
   * Unselects a mail from the selected mails list.
   * @param {Object} mail - The mail to be unselected.
   */
  const unselectMail = (mail) => {
    setSelectedMails((prevState) => {
      const index = prevState.findIndex((el) => el?.id === mail?.id);
      if (index === -1) return prevState;
      return prevState.filter((el) => el?.id !== mail?.id);
    });
  };

  /**
   * Toggles the selection of a mail and updates the selected mails state.
   * @param {Object} mail - The mail object to toggle selection for.
   */
  const toggleSelectMail = (mail) => {
    setSelectedMails((prevState) => {
      const index = prevState.findIndex((el) => el?.id === mail?.id);
      if (index === -1) return [...prevState, mail];
      return prevState.filter((el) => el?.id !== mail?.id);
    });
  };

  /**
   * Toggles the selection of all mails in the current folder.
   * If all mails are currently selected, this function will unselect all mails.
   * Otherwise, it will select all mails in the current folder.
   * @returns {void}
   */
  const toggleSelectAllMails = () => {
    setSelectedMails((prevState) => {
      if (prevState.length === mailsOfCurrentFolder.length) return [];
      return mailsOfCurrentFolder;
    });
  };

  /**
   * Sets the selected mails to be all the mails in the current folder.
   * This effectively selects all mails in the current folder.
   */
  const handleSelectAllMails = () => {
    setSelectedMails(mailsOfCurrentFolder);
  };

  /**
   * Unselects all mails by setting the selected mails state to an empty array.
   * This effectively deselects all mails in the current folder.
   */
  const handleUnselectAllMails = () => {
    setSelectedMails([]);
  };

  /**
   * Checks if all mails in the current folder are selected.
   * @returns {boolean} - True if all mails are selected, false otherwise.
   */
  const allMailsAreSelected = () => {
    return selectedMails.length === mailsOfCurrentFolder.length;
  };

  /**
   * Sets the selected mail as read and marks it as read in the corresponding mail platform if it's not already read.
   * @param {Object} mail - The selected mail object.
   */
  const handleReadMail = (mail) => {
    // setReadMail(mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail?.id);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail);
    }
  };

  /**
   * Sets the selected mail as read and marks it as unread in the corresponding mail platform if it is already read.
   * @param {Object} mail - The selected mail object.
   * @param {Object} mail.account - The mail account object.
   * @param {string} mail.account.platform - The mail platform (e.g. "MICROSOFT", "CUSTOM").
   * @param {Object} mail.account.user - The mail account user object.
   * @param {string} mail.account.user.email - The email address of the mail account user.
   * @param {string} mail.folder - The folder where the mail is located.
   * @param {string} mail.id - The ID of the selected mail.
   * @param {boolean} mail.isRead - The read status of the selected mail.
   * @returns {void}
   */
  const handleUnreadMail = (mail) => {
    // setReadMail(mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.markAsUnread(mail?.account?.user?.email, mail?.folder, mail?.id);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.markAsUnread(mail?.account?.user?.email, mail?.folder, mail);
    }
  };

  /**
   * Marks all unread mails in the current folder as read.
   * This effectively marks all mails in the current folder as read.
   */
  const handleReadAllMails = () => {
    const readMails = mailsOfCurrentFolder.filter((el) => !el?.isRead);
    readMails.forEach((mail) => {
      if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
        microsoftMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail?.id);
      } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
        customMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail);
      }
    });
  };

  /**
   * Marks all read mails in the current folder as unread.
   * This effectively marks all mails in the current folder as unread.
   */
  const handleUnreadAllMails = () => {
    const unreadMails = mailsOfCurrentFolder.filter((el) => el?.isRead);
    unreadMails.forEach((mail) => {
      if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
        microsoftMail.markAsUnread(mail?.account?.user?.email, mail?.folder, mail?.id);
      }
      if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
        customMail.markAsUnread(mail?.account?.user?.email, mail?.folder, mail?.id);
        //TODO : add custom mail mark as unread
      }
    });
  };

  /**
   * Checks if all mails in the current folder are read.
   * @returns {boolean} - True if all mails in the current folder are read, false otherwise.
   */
  const AllMailsAreRead = () => {
    return mailsOfCurrentFolder.filter((el) => !el?.isRead).length === 0;
  };

  /**
   * Checks if a given mail is read.
   * @param {Object} mail - The mail object to check.
   * @returns {boolean} - True if the mail is read, false otherwise.
   */
  const mailIsRead = (mail) => {
    //console.log('mailIsRead', mail.isRead);
    return mail?.isRead;
  };

  // foonction qui verifie si au moin un mail est selectionné
  /**
   * Checks if at least one mail is selected.
   * @returns {boolean} - True if at least one mail is selected, false otherwise.
   */
  const isAtLeastOneMailSelected = () => {
    return selectedMails.length > 0;
  };

  /**
   * Checks if a mail is selected.
   * @param {Object} mail - The mail object to check.
   * @returns {boolean} - True if the mail is selected, false otherwise.
   */
  const mailIsSelected = (mail) => {
    return selectedMails.findIndex((el) => el?.id === mail?.id) !== -1;
  };

  const moveMailToFolder = () => {
    // TODO : move mail to specific folder
  };

  useEffect(() => {
    const bc = new BroadcastChannel('mail_chanel');

    bc.onmessage = (ev) => {
      const account = {
        ...ev?.data,
        email: ev?.data?.user?.email
      };

      onCloseLoginBox();
      onOpenColors();
      setAccount(account);

      dispatch(refreshLinkedAccount({ emmitNotification: emitNewMailNotification }));

      //console.log('channel', account);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //TODO: fix many requests
    dispatch(refreshLinkedAccount({ emmitNotification: emitNewMailNotification }));
    dispatch(refreshCustomMails());
    dispatch(refreshMsMails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const linkedAccount = user.linkedAccount;

    if (!isEmpty(linkedAccount)) {
      if (isEqual(linkedAccount, prevLinkedAccount)) return;

      let microsoft = {};

      keys(linkedAccount).forEach((email) => {
        const data = linkedAccount[email];

        if (!data) return;

        if (data?.platform === MAIL_PLATFORM.MICROSOFT) {
          microsoft = {
            ...microsoft,
            [email]: data
          };
        }
      });

      dispatch(igniteMsAccount({ accountSnap: microsoft }));
      setPrevLinkedAccount(linkedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.linkedAccount]);

  const handleSetFolder = (folder) => {
    setFolder(folder);
    setReadMail(null);
    openAndReadBoxMail(folder);
  };

  /**
   * Saves the given mail to draft for the specified account.
   * @param {Object} account - The account object.
   * @param {Object} mail - The mail object to be saved.
   * @param {Function} onResolve - The callback function to be called on successful save.
   * @param {Function} onReject - The callback function to be called on failed save.
   */
  const saveToDraft = (account, mail, onResolve, onReject) => {
    if (account.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.saveToDraft(account, mail, onResolve);
    }
    if (account.platform === MAIL_PLATFORM.CUSTOM) {
      //TODO : add custom mail save to draft api call
      customMail.saveToDraft(account, mail, onResolve, onReject);
    }
  };

  /**
   * Updates the draft mail for a given account and mail object.
   * @param {Object} account - The account object.
   * @param {Object} mail - The mail object to be updated.
   * @param {Function} onResolve - The callback function to be called after the update is resolved.
   * @returns {void}
   */
  const updateDraft = (account, mail, onResolve) => {
    //TODO : add custom mail save to draft api call
    //console.log('updateDraft', account, mail);
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateDraftMail(account?.email, { ...mail, isRead: true }, onResolve);
    } else if (account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateDraftMail(account, mail, onResolve, () => {
        //console.log('updateDraft error');
      });
    }
  };

  /**
   * Deletes a draft mail from the specified account.
   * @param {Object} account - The account from which to delete the draft mail.
   * @param {Object} mail - The draft mail to delete.
   * @param {Function} onResolve - The function to call when the operation is resolved.
   */
  const deleteDraft = async (account, mail, onResolve) => {
    // console.log('deleteDraft', account, mail);
    //TODO : add custom mail save to draft api call
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      await microsoftMail.deleteDraft(account, mail, onResolve);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      await customMail.deleteDraftMail(account?.email, { ...mail, isRead: true }, onResolve);
    }
  };

  //move to spam
  /**
   * Handles adding a mail to the spam folder.
   * @param {Object} mail - The mail object to be added to spam folder.
   * @param {Function} onResolve - The function to be called when the operation is successful.
   * @param {Function} onReject - The function to be called when the operation fails.
   */
  const handleAddToSpam = async (mail, onResolve, onReject) => {
    //console.log('handleAddToSpam', mail);

    //changeFolder of mail
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.SPAM, onResolve, onReject);
      microsoftMail.updateMailFlag(mail.account, mail, '', MAIL_FLAG.SPAM, onResolve, onReject);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      //TODO : remove  updateCustomMailFolder
      customMail.updateMailFlag(mail?.account, mail, '', MAIL_FLAG.SPAM);
      customMail.updateCustomMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.SPAM, onResolve, onReject);
    }
  };

  //remove from spam
  /**
   * Handles removing a mail from spam folder.
   * @param {Object} mail - The mail object to remove from spam folder.
   * @param {Function} onResolve - The function to call when the operation is successful.
   * @param {Function} onReject - The function to call when the operation fails.
   */
  const handleRemoveFromSpam = async (mail, onResolve, onReject) => {
    //console.log('handleRemoveFromSpam', mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.INBOX, onResolve, onReject);
      microsoftMail.updateMailFlag(mail.account, mail, MAIL_FLAG.SPAM, '', onResolve, onReject);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      //TODO : remove  updateCustomMailFolder
      customMail.updateMailFlag(mail.account, mail, MAIL_FLAG.SPAM, '');
      customMail.updateCustomMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.INBOX, onResolve, onReject);
    }
  };

  /**
   * Handles adding a mail to the archive folder.
   * @param {Object} mail - The mail object to be added to the archive folder.
   * @param {Function} onResolve - The function to be called when the operation is successful.
   * @param {Function} onReject - The function to be called when the operation fails.
   */
  const handleAddToArchive = (mail, onResolve, onReject) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.ARCHIVE, onResolve, onReject);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateCustomMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.ARCHIVE, onResolve, onReject);
    }
  };

  /**
   * Handles removing a mail from archive folder and moving it to inbox folder.
   * @param {Object} mail - The mail object to be removed from archive.
   * @param {Function} onResolve - The function to be called when the operation is successful.
   * @param {Function} onReject - The function to be called when the operation fails.
   */
  const handleRemoveFromArchive = (mail, onResolve, onReject) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.INBOX, onResolve, onReject);
    }
    if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateCustomMailFolder(
        mail.account,
        mail,
        MAIL_FOLDER.ARCHIVE,
        MAIL_FOLDER.INBOX,
        onResolve,
        onReject
      );
    }
  };

  /**
   * Adds the given mail to the drafts folder of the corresponding mail platform.
   * @param {Object} mail - The mail object to be added to drafts.
   * @param {Function} onResolve - The function to be called when the operation is successful.
   * @param {Function} onReject - The function to be called when the operation fails.
   */
  const handleAddToDrafts = (mail, onResolve, onReject) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      //microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.DRAFTS, onResolve, onReject);
      microsoftMail.saveToDraft(mail.account, mail, onResolve, onReject);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      // customMail.updateCustomMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.DRAFTS, onResolve, onReject);
      customMail.saveToDraft(mail.account, mail, onResolve, onReject);
    }
  };

  /**
   * Removes a mail from drafts folder and moves it to inbox folder.
   * @param {Object} mail - The mail object to be removed from drafts.
   * @param {Function} onResolve - The function to be called when the operation is successful.
   * @param {Function} onReject - The function to be called when the operation fails.
   */
  const handleRemoveFromDrafts = (mail, onResolve, onReject) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      // microsoftMail.updateMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.INBOX, onResolve, onReject);
      microsoftMail.deleteDraft(mail.account, mail, onResolve, onReject);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateCustomMailFolder(mail.account, mail, MAIL_FOLDER.DRAFTS, MAIL_FOLDER.INBOX, onResolve, onReject);
      customMail.deleteMail(mail.account, mail.folder, mail.id, onResolve);
    }
  };

  /**
   * Moves the given mail to the trash folder of its account.
   * @param {Object} mail - The mail to move to trash.
   * @param {Function} onResolve - The function to call when the operation is successful.
   * @param {Function} onReject - The function to call when the operation fails.
   */
  const handleAddToTrash = (mail, onResolve, onReject) => {
    // console.log('handleAddToTrash', mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.deleteMail(mail.account, mail.folder, mail?.id, onResolve);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateMailFlag(mail.account, mail, '', MAIL_FLAG.TRASH, onResolve, onReject);
      customMail.updateCustomMailFolder(mail.account, mail, mail.folder, MAIL_FOLDER.TRASH, onResolve, onReject);
    }
  };

  /**
   * Handles removing a mail from the trash folder and moving it to the inbox folder.
   * @param {Object} mail - The mail object to be removed from the trash folder.
   * @param {Function} onResolve - The function to be called if the operation is successful.
   * @param {Function} onReject - The function to be called if the operation fails.
   */
  const handleRemoveFromTrash = (mail, onResolve, onReject) => {
    //console.log('handleRemoveFromTrash', mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, MAIL_FOLDER.TRASH, MAIL_FOLDER.INBOX, onResolve, onReject);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateMailFlag(mail.account, mail, MAIL_FLAG.TRASH, '', onResolve, onReject);
      customMail.updateCustomMailFolder(mail.account, mail, MAIL_FOLDER.TRASH, MAIL_FOLDER.INBOX, onResolve, onReject);
    }
  };

  /**
   * Handles restoring a mail from the trash folder to the inbox folder.
   * @param {Object} mail - The mail object to be restored from the trash folder.
   * @param {Function} onResolve - The function to be called if the operation is successful.
   * @param {Function} onReject - The function to be called if the operation fails.
   */
  const handleRestoreFromTrash = (mail, onResolve, onReject) => {
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMailFolder(mail.account, mail, MAIL_FOLDER.TRASH, MAIL_FOLDER.INBOX, onResolve, onReject);
    } else if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      customMail.updateMailFlag(mail.account, mail, MAIL_FLAG.TRASH, '', onResolve, onReject);
      customMail.updateCustomMailFolder(mail.account, mail, MAIL_FOLDER.TRASH, MAIL_FOLDER.INBOX, onResolve, onReject);
    }
  };

  /**
   * Determines if the given mail is marked as important.
   * @param {Object} mail - The mail object to check for importance.
   * @returns {boolean} - True if the mail is marked as important, false otherwise.
   */
  const mailIsImportant = (mail) => {
    if (mail.account.platform === MAIL_PLATFORM.MICROSOFT) {
      return microsoftMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.isImportant;
    }
    if (mail.account.platform === MAIL_PLATFORM.CUSTOM) {
      return customMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.flags?.includes(MAIL_FLAG.IMPORTANT);
    }
    //return mail?.isImportant;
  };

  const checkIfIsCurrentFolder = useCallback(
    (_folder) => {
      return folder.toLowerCase().includes(_folder.toLowerCase());
    },
    [folder]
  );

  /**
   * Checks if the given mail has the specified flag set.
   * @param {Object} mail - The mail object to check for the flag.
   * @param {string} flag - The flag to check for.
   * @returns {boolean} - True if the mail has the specified flag, false otherwise.
   */
  const checkHasCurrentFlag = useCallback(
    (mail, flag) => {
      if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
        return microsoftMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.flags?.includes(flag);
      }
      if (mail?.account?.platform === MAIL_PLATFORM.CUSTOM) {
        return customMail.mailsSnap[mail.account.email][mail.folder][mail.id]?.flags?.includes(flag);
      }
    },
    []
  );
  //#endregion

  const store = {
    handleAddSignatureToAccount,
    handlePickAccountColor,
    selectAccount,
    setSelectedAccount,
    openSide,
    onOpenSide: () => setOpenSide(true),
    onCloseSide: () => setOpenSide(false),
    folder,
    getMailById,
    setFolder: handleSetFolder,
    mailsLoading,
    account,
    setAccount,
    logout,
    emails: linkedMails,
    unreadCount,
    mailsOfCurrentFolder,
    disconnectAccount: handleDisconnectAccount,
    openComposer: onOpenComposer,
    openLoginBox: onOpenLoginBox,
    contentState,
    openAccountColorPicker,
    readMail,
    readDraft,
    isNewDraft,
    setIsNewDraft,
    setReadMail,
    openMail,
    openDraft,
    sendMail,
    deleteMail,
    toggleMailImportance,
    getMailAttachments,
    updateMail,
    emitNewMailNotification,
    openMailDetailBox: onOpenMailDetailBox,
    loginCustomMail,
    toggleSelectMail,
    toggleSelectAllMails,
    selectedMails,
    selectMail,
    unselectMail,
    isAtLeastOneMailSelected,
    mailIsSelected,
    allMailsAreSelected,
    handleSelectAllMails,
    handleUnselectAllMails,
    handleReadMail,
    handleUnreadMail,
    handleReadAllMails,
    handleUnreadAllMails,
    AllMailsAreRead,
    mailIsRead,
    moveMailToFolder,
    saveToDraft,
    updateDraft,
    deleteDraft,
    handleAddToSpam,
    handleRemoveFromSpam,
    handleAddToArchive,
    handleRemoveFromArchive,
    mailComposer,
    handleChangeMailComposer,
    handleChangeMailComposerMulti,
    clearMailComposer: handleClearMailComposer,
    handleAddToDrafts,
    handleRemoveFromDrafts,
    handleAddToTrash,
    handleRemoveFromTrash,
    handleRestoreFromTrash,
    mailIsSeen,
    mailIsInSpam,
    mailIsImportant,
    isFirstLoad,
    setIsFirstLoad: () => setIsFirstLoad(false),
    checkIfIsCurrentFolder,
    addFile,
  };

  // console.log(progress);

  return (
    <MailContext.Provider value={store}>
      {children}

      {(Boolean(!isEmpty(uploadingFile)) && Boolean(progress)) ? (
        <UploadingFileView
          uploadings={[{
            ...uploadingFile,
            progress: (progress / 100).toFixed(2)
          }]}
          onClose={handleCloseUpload}
          elementId='mail-uploading-file-view'
        />
      ) : null}

      {isComposerOpen && <MailComposer anchor={composerAnchor} open onClose={onCloseComposer} />}

      {isLoginBoxOpen && <MailLogin open onClose={onCloseLoginBox} onLink={handleLinkMail} />}

      {isColorsOpen && (
        <MailAccountColor account={account} onChoose={handlePickAccountColor} open onClose={onCloseColors} />
      )}

      {isMailDetailBoxOpen && <MailV2DetailModal open message={isMailDetailBoxOpen} onClose={onCloseMailDetailBox} />}
    </MailContext.Provider>
  );
}

const MailContext = createContext({
  handlePickAccountColor: () => { },
  handleAddSignatureToAccount: () => { },
  selectAccount: null,
  setSelectedAccount: () => { },
  openSide: false,
  onOpenSide: () => { },
  onCloseSide: () => { },
  emails: [],
  mailsOfCurrentFolder: [],
  mailsLoading: false,
  isNewDraft: false,
  setIsNewDraft: () => Promise.all(),
  unreadCount: 0,
  folder: null,
  getMailById: () => Promise.all(),
  setFolder: () => Promise.all(),
  account: null,
  setAccount: () => Promise.all(),
  disconnectAccount: () => Promise.all(),
  openComposer: () => Promise.all(),
  openLoginBox: () => Promise.all(),
  contentState: null,
  openAccountColorPicker: () => Promise.all(),
  readMail: null,
  readDraft: null,
  setReadMail: () => Promise.all(),
  openMail: () => Promise.all(),
  updateMail: () => Promise.all(),
  openDraft: () => Promise.all(),
  logout: () => Promise.all(),
  emitNewMailNotification: () => Promise.all(),
  openMailDetailBox: () => Promise.all(),
  loginCustomMail: () => Promise.all(),
  sendMail: () => Promise.all(),
  deleteMail: () => Promise.all(),
  toggleMailImportance: () => Promise.all(),
  getMailAttachments: () => Promise.all(),
  toggleSelectMail: () => Promise.all(),
  toggleSelectAllMails: () => Promise.all(),
  selectedMails: [],
  selectMail: () => Promise.all(),
  unselectMail: () => Promise.all(),
  isAtLeastOneMailSelected: () => Promise.all(),
  mailIsSelected: () => Promise.all(),
  allMailsAreSelected: () => Promise.all(),
  handleSelectAllMails: () => Promise.all(),
  handleUnselectAllMails: () => Promise.all(),
  handleReadMail: () => Promise.all(),
  handleUnreadMail: () => Promise.all(),
  handleReadAllMails: () => Promise.all(),
  handleUnreadAllMails: () => Promise.all(),
  AllMailsAreRead: () => Promise.all(),
  mailIsRead: () => Promise.all(),
  moveMailToFolder: () => Promise.all(),
  saveToDraft: () => Promise.all(),
  updateDraft: () => Promise.all(),
  deleteDraft: () => Promise.all(),
  handleAddToSpam: () => Promise.all(),
  handleRemoveFromSpam: () => Promise.all(),
  handleAddToArchive: () => Promise.all(),
  handleRemoveFromArchive: () => Promise.all(),
  mailComposer: null,
  handleChangeMailComposer: () => Promise.all(),
  handleChangeMailComposerMulti: () =>
    Promise.all(),
  clearMailComposer: () => Promise.all(),
  handleAddToDrafts: () => Promise.all(),
  handleRemoveFromDrafts: () => Promise.all(),
  handleAddToTrash: () => Promise.all(),
  handleRemoveFromTrash: () => Promise.all(),
  handleRestoreFromTrash: () => Promise.all(),
  mailIsSeen: () => Promise.all(),
  mailIsInSpam: () => Promise.all(),
  mailIsImportant: () => Promise.all(),
  isFirstLoad: true,
  setIsFirstLoad: () => Promise.all(),
  checkIfIsCurrentFolder: (_folder) => Promise.all(),
  addFile: () => Promise.all()
});


//#region folder
export const MAIL_FOLDER = {
  SENT: 'Sent',
  INBOX: 'INBOX',
  SPAM: 'Junk',
  TRASH: 'Trash',
  DRAFTS: 'Drafts',
  ARCHIVE: 'Archive'
};
//Seen, Deleted, Draft, Flagged, Answered,

// export const MAIL_FLAG = {
//   UNSEEN: '\\UNSEEN',
//   SEEN: '\\Seen',
//   DRAFT: '\\DRAFT',
//   SENT: '\\SENT',
//   TRASH: '\\TRASH',
//   SPAM: '\\JUNK',
//   DELETED: '\\Deleted',
//   FLAGGED: '\\Flagged',
//   ANSWERED: '\\Answered',
//   ARCHIVE: '\\Archive',
//   IMPORTANT: '\\Important'
// };

export const MAIL_FLAG = {
  UNSEEN: 'UNSEEN',
  SEEN: 'Seen',
  DRAFT: 'DRAFT',
  SENT: 'SENT',
  TRASH: 'TRASH',
  SPAM: 'JUNK',
  DELETED: 'Deleted',
  FLAGGED: 'Flagged',
  ANSWERED: 'Answered',
  ARCHIVE: 'Archive',
  IMPORTANT: 'Important'
};

export const MAIL_PLATFORM = {
  MICROSOFT: 'microsoft',
  CUSTOM: 'custom'
};

export const mailFolderOptions = [
  {
    label: 'Boite de réception',
    icon: 'solar:inbox-bold',
    value: MAIL_FOLDER.INBOX
  },
  {
    label: 'Envoyés',
    icon: 'iconamoon:send-fill',
    value: MAIL_FOLDER.SENT
  },
  {
    label: 'Brouillons',
    icon: 'fluent:text-bullet-list-square-edit-24-filled',
    value: MAIL_FOLDER.DRAFTS
  },
  {
    label: 'Spam',
    icon: 'solar:danger-bold',
    value: MAIL_FOLDER.SPAM
  },
  {
    label: 'Corbeille',
    icon: 'solar:trash-bin-minimalistic-bold',
    value: MAIL_FOLDER.TRASH
  }
];

//#endregion


/**
 * Determines the current state of the mail content based on the provided parameters.
 *
 * @param {boolean} mailsLoading - Indicates whether the mails are currently being loaded.
 * @param {any[]} [linkedMails=[]] - The list of linked mails.
 * @param {any} currentAccount - The current account.
 * @param {any[]} [mails=[]] - The list of mails.
 * @param {boolean} readingMail - Indicates whether a mail is currently being read.
 * @returns {string} The current state of the mail content.
 */
const getMailState = (mailsLoading, linkedMails = [], currentAccount, mails, readingMail) => {
  if (mailsLoading) return CONTENT_STATE.LOADING;
  if (linkedMails?.length === 0) return CONTENT_STATE.NO_LINKED_ACCOUNT;
  if (mails?.length === 0) return CONTENT_STATE.NO_EMAILS;
  if (readingMail) return CONTENT_STATE.READING_MAILS;

  return CONTENT_STATE.LIST_MAILS;
};

const CONTENT_STATE = {
  LOADING: 'LOADING',
  NO_LINKED_ACCOUNT: 'NO_LINKED_ACCOUNT',
  NO_EMAILS: 'NO_EMAILS',
  LIST_MAILS: 'LIST_MAILS',
  READING_MAILS: 'READING_MAILS'
};
