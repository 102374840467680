import { useState, useEffect, useMemo} from 'react';
import {  dispatch, useSelector } from 'src/redux/store';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { addTasks } from 'src/redux/slices/task';


/**
 * 
 * @param {{userId:string, name : string, projectState: string, taskState: boolean, limit: number }} props 
 * @returns {{ loading: boolean, tasks: Array}}
 */
export default function useGetTasks({userId, name, projectState='open', taskState=[], limit= 10, }) {
    const [loading, setLoading] = useState(false);
    
    const getTasks = useSelector((state) => state.firestore.ordered[`tasks_state_${name}`]);

    const tasks = useMemo(() => isEmpty(getTasks) ? [] : getTasks,[getTasks]);

    useFirestoreConnect(() =>[
        {
            collection: 'tasks',
            limit: limit,
            where: [
                ['projectState', '==', projectState], 
                ['canAccessId', 'array-contains', userId ], 
                ['completed', '==', taskState],
                ['isDeleted', '==', false],
            ],
            orderBy: ['updatedAt', 'desc'],
            storeAs: `tasks_state_${name}`
        }
    ])

    useEffect(() =>{
        setLoading(!isLoaded(getTasks));
        dispatch(addTasks(tasks));
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[getTasks])

  return {loading, tasks}
}

export const useGetTaskById = (taskId) => {
    const [loading, setLoading] = useState(false);
    const getTask = useSelector((state) => state.firestore.data.tasks);
    const task = useMemo(() => getTask ? getTask[taskId] : null, [getTask, taskId]);

    useFirestoreConnect(() =>[
        {
            collection: 'tasks',
            doc: taskId
        }
    ])

    useEffect(() =>{
        setLoading(!isLoaded(getTask));
    },[getTask])

  return {loading, task}
}
