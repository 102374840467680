

const MAIL_COMPOSER_URL = 'mailComposerUrl';
/**
 * Saves a new mail composer URL in the browser's local storage.
 *
 * @param {Object} param0 - An object containing the name and URL of the mail composer.
 * @param {string} param0.name - The name of the mail composer.
 * @param {string} param0.url - The URL of the mail composer.
 */
export const setMailComposerUrl = ({ name, url }) => {
  const _urls = JSON.parse(localStorage.getItem(MAIL_COMPOSER_URL)) || [];
  _urls.push({ name, url });
  localStorage.setItem(MAIL_COMPOSER_URL, JSON.stringify(_urls));

}

/**
 * Retrieves the mail composer URL associated with the given name from the browser's local storage.
 *
 * @param {string} name - The name of the mail composer to retrieve the URL for.
 * @returns {Object|undefined} - The mail composer object with the given name, or undefined if not found.
 */
export const getMailComposerUrl = (name) => {
  const _urls = JSON.parse(localStorage.getItem(MAIL_COMPOSER_URL)) || [];
  return _urls.find(el => el.name === name);
}