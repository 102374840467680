import React, { useRef } from 'react';
import { Dialog, Paper, Container, Button } from '@mui/material';
import Center from 'src/components/Center';
import MailV2MessageDetail from 'src/section/mail_v2/MailV2MessageDetail';

/**
 * Renders a modal dialog that displays detailed information about a mail message.
 *
 * @param {Object} message - The mail message object to display.
 * @param {boolean} open - Whether the modal is currently open.
 * @param {function} onClose - Callback function to close the modal.
 * @returns {JSX.Element} - The rendered modal dialog.
 */
export default function MailV2DetailModal({ message, open, onClose }) {
  //console.log({ message });
  const ref = useRef(null);
  return (
    <Dialog
      ref={ref}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      fullScreen
      PaperProps={{ sx: { bgcolor: 'transparent' } }}
    >
      <Center>
        <Container maxWidth={'md'}>
          <Paper sx={{ borderRadius: 1 }} variant="outlined">
            <MailV2MessageDetail message={message} isModal onCloseModal={onClose} modalRef={ref} open={open} />
          </Paper>
        </Container>
      </Center>
    </Dialog>
  );
}
