import { useEffect, useState, useMemo } from 'react';
import useToggle from 'src/hooks/useToggle';

import { humanFileSize } from 'src/utils/formatNumber';
import { getFileFormat, getFileThumbUrl } from 'src/utils/getFileFormat';

import { Stack, Typography, Box, Grid, styled, Skeleton } from '@mui/material';
import { MenuPopoverCenter } from 'src/components/MenuPopover';
import { MenuLine } from 'src/components/MenuLine';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import Iconify from 'src/components/Iconify';

import AttachmentReader from 'src/components/AttachmentReader';
import { downloadFile } from 'src/helpers/downloadFile';
import { nanoid } from '@reduxjs/toolkit';
import TextMaxLine from 'src/components/TextMaxLine';
import FileIcon from 'src/components/FileIcon';
import CIconButton from 'src/components/CIconButton';
import { useDriverContext } from '../doc/okydriver/context/DriverContext';
import { useMailContext } from './MailContext';

const MailAttachBox = styled(Box)(({ theme, color }) => ({
  width: 200,
  height: 120,
  borderRadius: 10,
  position: 'relative',
  border: `0.5px solid #e0e0e0`,
  clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%)',
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '20px 20px 0 0',
    borderColor: `${color || '#e74c3c'} transparent transparent transparent`
  }
}));

const MailAttachBoxTitleContainer = styled(Stack)(({ theme }) => ({
  height: 30,
  padding: theme.spacing(0.5, 1),
  borderTop: '1px solid #ccc',
  backgroundColor: '#f5f5f5',
  borderRadius: '0 0 10px 10px'
}));

const MailAttachBoxSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 200,
  height: 120,
  borderRadius: 10
}));

const getMailBoxColor = (type) => {
  const fileType = getFileFormat(type);
  if (fileType === 'image' || fileType === 'video') {
    return '#ccc';
  } else if (fileType === 'pdf') {
    return '#e74c3c';
  } else if (fileType === 'word') {
    return '#3498db';
  } else if (fileType === 'excel') {
    return '#28740A';
  } else if (fileType === 'powerpoint') {
    return '#f1c40f';
  } else if (fileType === 'zip') {
    return '#9b59b6';
  } else if (fileType === 'audio') {
    return '#e67e22';
  } else {
    return '#ccc';
  }
};

/**
 * Renders a preview component for an attachment file, including the file name, size, and buttons to preview or download the file.
 *
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the file.
 * @param {string} props.fileName - The name of the file.
 * @param {string} props.fileSize - The size of the file in a human-readable format.
 * @param {function} props.handleReadFile - A function to handle reading the file for preview.
 * @param {function} props.handleDownload - A function to handle downloading the file.
 * @param {string} props.thumbnail - The URL of the file thumbnail, if available.
 * @param {boolean} props.canPreview - A flag indicating whether the file can be previewed.
 * @param {boolean} props.canDownload - A flag indicating whether the file can be downloaded.
 * @param {string} props.objectFit - The object-fit CSS property for the thumbnail image.
 * @returns {JSX.Element} The attachment preview component.
 */
export const AttachmentPreview = ({
  type,
  fileName,
  fileSize,
  handleReadFile,
  handleDownload,
  thumbnail,
  canPreview,
  canDownload,
  objectFit = 'cover'
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Stack
      sx={{
        cursor: 'pointer',
      }}
    >
      <MailAttachBox
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleReadFile}
        flex={1}
        color={getMailBoxColor(type)}
      >
        {hover ? (
          <Stack width={1}>
            <Stack direction="row" alignItems="flex-start" height={60} justifyContent="flex-start" width={1} p={1}>
              {thumbnail ? (
                <Box
                  component="img"
                  src={thumbnail}
                  alt={fileName}
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    objectFit: 'contain'
                  }}
                />
              ) : (
                <InsertDriveFileIcon fontSize="small" sx={{ color: 'grey.500', alignSelf: 'center' }} />
              )}

              <Box sx={{ flexGrow: 1, ml: 1 }}>
                <TextMaxLine variant="caption" line={2} fontSize={10} fontWeight="700" color="#000">
                  {fileName}
                </TextMaxLine>
                <Typography variant="caption" fontSize={10} color="#000">{`${fileSize}`}</Typography>
              </Box>
            </Stack>

            <Stack height={60} direction="row" alignItems="center" justifyContent={'start'} width={'100%'} px={1}>
              <CIconButton
                title="Télécharger"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload();
                }}
              >
                <Iconify icon={'ic:baseline-get-app'} />
              </CIconButton>
            </Stack>
          </Stack>
        ) : (
          <>
            <Box
              component="img"
              src={thumbnail}
              alt={fileName}
              height={90}
              sx={{
                objectFit,
                width: 1,
                backgroundColor: '#fff'
              }}
            />
            <MailAttachBoxTitleContainer>
              <Stack direction="row" width="calc(100% - 20px)" height={30} alignItems="center">
                <FileIcon type={type} url={thumbnail} size={20} />
                <TextMaxLine variant="caption" line={1} fontWeight="700" color="#000" fontSize={12} sx={{ ml: 1 }}>
                  {fileName}
                </TextMaxLine>
              </Stack>
            </MailAttachBoxTitleContainer>
          </>
        )}
      </MailAttachBox>
    </Stack>
  );
};

export default function MailDetailAttachment({ mail }) {
  const { addFile } = useMailContext();
  const { open, handleOpen, handleClose } = useToggle();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([]);
    setLoading(false);

    if (mail?.hasAttachment && mail?.attachments?.length > 0) {
      setLoading(true);
      const formattedAttachments = mail.attachments.map((att) => {
        return {
          id: nanoid(),
          name: att.filename,
          type: att.mimetype,
          size: att.size || att.length,
          url: `data:${att.mimetype};base64,${att.data}`,
          format: getFileFormat(att.filename)
        };
      });
      setFiles(formattedAttachments);
      setLoading(false);
    }
  }, [mail]);

  const { big, mini, isManyFile, filesSize } = useMemo(() => {
    const buff = files?.map((file) => ({
      file,
      name: file?.name,
      type: file?.type,
      size: file?.size,
      id: file?.id || nanoid(),
      format: getFileFormat(file?.name)
    }));
    return {
      big: buff?.filter((file) => ['image', 'video'].includes(file?.format)),
      mini: buff?.filter((file) => !['image', 'video'].includes(file?.format)),
      isManyFile: Boolean(buff?.length > 1),
      filesSize: humanFileSize(files?.reduce((acc, file) => acc + file?.size, 0))
    };
  }, [files]);

  // const folderId = 'root';

  const handleFileLoad = (file) => {
    console.log({ file });
    if (files.length) {
      addFile({
        file: file,
        mailId: mail?.id,
        onSuccess: () => { },
        onError: () => { }
      });
    }
  };

  const handleReadFile = (file) => {
    setSelectedFile(file);

    handleFileLoad(file);
    handleOpen();
  };

  const handleClosing = () => {
    handleClose();
    setSelectedFile(null);
  };

  const handleDownload = (file) => {
    // console.log({ file });
    downloadFile(file?.url, file?.name);
  };

  if (!mail?.hasAttachment || mail?.attachments?.length === 0) {
    return null;
  }

  return (
    <Stack spacing={1} pt={2} mt={2} borderTop={(t) => `1.5px dashed ${t.palette.divider}`}>
      {loading && (
        <Grid container spacing={2}>
          {[...Array(mail?.attachments?.length || 1)]?.map((_, index) => (
            <Grid item key={`big-${index}`}>
              <MailAttachBoxSkeleton variant="rectangular" width={200} height={120} />
            </Grid>
          ))}
        </Grid>
      )}

      <Stack width={1} direction="row" spacing={0.5} alignItems="center">
        <Typography variant="body2" fontWeight="bold">
          {`${big?.length + mini?.length} Pièce${isManyFile ? 's' : ''} jointe${isManyFile ? 's' : ''} `}
        </Typography>

        <Typography>-</Typography>

        <Typography variant="body2" fontSize={12}>
          ({`${filesSize}`})
        </Typography>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" columnGap={3} rowGap={3}>
        {[...big, ...mini]?.map((el) => (
          <Stack width={200} key={el?.id}>
            <ItemView file={el} onRead={handleReadFile} onDownload={handleDownload} />
          </Stack>
        ))}
      </Stack>

      <AttachmentReader file={selectedFile} open={open} onClose={handleClosing} mailId={mail?.id} />
    </Stack>
  );
}

const ItemView = ({ file, onRead, onDownload }) => {
  const _file = file?.file;

  const isValidFileOrBlob = (_file) => {
    if (!(_file instanceof Blob) && !(_file instanceof File)) {
      return false;
    }
    const supportedContentTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];
    if (!supportedContentTypes.includes(_file.type)) {
      return false;
    }
    if (!_file.name || !_file.type) {
      return false;
    }
    return true;
  };

  let preview = null;
  try {
    preview = isValidFileOrBlob(_file) ? URL.createObjectURL(_file) : getFileThumbUrl(_file?.type, _file?.url);
  } catch (e) {
    console.error(e);
  }

  const thumbnail = getFileThumbUrl(_file?.type, _file?.url);

  return (
    <AttachmentPreview
      type={_file.type}
      fileName={_file?.name}
      fileSize={humanFileSize(_file?.size)}
      file={_file}
      handleDownload={() => onDownload(_file)}
      handleReadFile={() => onRead(_file)}
      thumbnail={thumbnail}
      canPreview={_file?.type?.includes('image') || _file?.type?.includes('video')}
      canDownload={true}
      objectFit={_file?.type?.includes('image') ? 'cover' : 'contain'}
    />
  );
};

/**
 * Renders a menu popover with options to read or download a file.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.file - The file object.
 * @param {React.RefObject} props.anchor - The anchor element for the menu popover.
 * @param {boolean} props.open - Whether the menu popover is open.
 * @param {Function} props.onClose - Callback function to close the menu popover.
 * @param {Function} props.onRead - Callback function to handle reading the file.
 * @param {Function} props.onDownload - Callback function to handle downloading the file.
 * @returns {React.ReactElement} - The OptionsMenu component.
 */
const OptionsMenu = ({ file, anchor, open, onClose, onRead, onDownload }) => {
  return (
    <MenuPopoverCenter anchorEl={anchor.current} open={open} onClose={onClose} disabledArrow width={'fit-content'}>
      <MenuLine icon={'material-symbols:download'} title={'Télécharger'} onClick={onDownload} />
    </MenuPopoverCenter>
  );
};
