import { isObject, isEmpty, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { gDate } from 'src/utils/formatTime';

/**
 * Checks if the provided `element` is an instance of the `File` class.
 *
 * @param {any} element - The value to check.
 * @returns {boolean} `true` if the `element` is a `File`, `false` otherwise.
 */
export const isFile = (element) => {
  return element instanceof File
}

/**
 * Checks if the provided `value` is a falsy value.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is a falsy value, `false` otherwise.
 */
export const isNot = (value) => {
  return Boolean(value) === false;
};

/**
 * Checks if the provided `value` is not an empty value.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is not empty, `false` otherwise.
 */
export const isNotEmpty = (value) => {
  return isNot(isEmpty(value));
};

/**
 * Checks if the provided `value` is not undefined.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is not undefined, `false` otherwise.
 */
export const isNotUndefined = (value) => {
  return isNot(isUndefined(value));
};

/**
 * Checks if the provided `timestamp` is a Firebase Timestamp object.
 *
 * @param {any} timestamp - The value to check.
 * @returns {boolean} `true` if the `timestamp` is a Firebase Timestamp object, `false` otherwise.
 */
export const isFirebaseTimestamp = (timestamp) => {
  return isObject(timestamp) && ('toDate' in timestamp);
};

/**
 * Checks if the provided `person` object has the required properties for a person.
 *
 * @param {object} person - The person object to check.
 * @param {string} person.id - The unique identifier for the person.
 * @param {string} person.displayName - The display name for the person.
 * @param {string} person.email - The email address for the person.
 * @returns {boolean} `true` if the `person` object has the required properties, `false` otherwise.
 */
export const isPerson = person => {
  return isObject(person) && 'id' in person && 'displayName' in person && 'email' in person
    && isNotEmpty(person.id) && isNotEmpty(person.displayName) && isNotEmpty(person.email);
};

/**
 * Checks if a given deadline has passed compared to a start date.
 *
 * @param {Date} deadline - The deadline date to check.
 * @param {Date} [startDate=new Date()] - The start date to compare the deadline against. Defaults to the current date.
 * @returns {boolean} `true` if the deadline has passed, `false` otherwise.
 */
export const isLate = (deadline, startDate = new Date()) => {
  const end = DateTime.fromJSDate(gDate(deadline));
  const start = DateTime.fromJSDate(gDate(startDate));

  return end > start;
};

//#region pdf renderer
/**
 * Checks if the provided `value` is a table object with the required properties.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is a table object with `type`, `columns`, and `rows` properties, `false` otherwise.
 */
export const isTable = value => {
  return 'type' in value && value.type === 'table' && 'columns' in value && 'rows' in value;
};

/**
 * Checks if the provided `value` is a title object with the required properties.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is a title object with `type` and `text` properties, `false` otherwise.
 */
export const isTitle = value => {
  return 'type' in value && value.type === 'title' && 'text' in value;
};

/**
 * Checks if the provided `value` is a text object with the required properties.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the `value` is a text object with `type` and `text` properties, `false` otherwise.
 */
export const isText = value => {
  return 'type' in value && value.type === 'text' && 'text' in value;
};

//#endregion