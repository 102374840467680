export const NOTIFICATION_TYPES = {
  BROADCAST: 'broadcast',
  PROJECT_CREATE: 'projet_creation',
  PROJECT_ADD_USER: 'projet_add_user',
  PROJECT_UPDATE: 'project_update',
  PROJECT_MANAGER: 'project_manager',
  TASK: 'task',
  TASK_MENTION: 'TASK_MENTION',
  TASK_VALIDATION_DONE: 'task_validation_done',
  TASK_VALIDATION_DEMANDE: 'task_validation_demande',
  TASK_VALIDATION_ACCEPTED: 'task_validation_accepted',
  TASK_BEGINING: 'task_begining',
  TASK_DELETE: 'task_delete',
  TASK_CREATE: 'task_create',
  TASK_VALIDATION_REJECT: 'task_validation_reject',
  TASK_VALIDATION_ASSIGN_USER: 'task_assign_user',
  SUB_TASK_VALIDATION_ASSIGN_USER: 'sub_task_assign_user',
  SUB_TASK_USER_ADDED: 'sub_task_user_added',
  SUB_TASK_ADD: 'sub_task_add',
  TASK_RETRIEVE: 'task_retreive',
  TASK_DUE_CHANGE: 'task_due_change',
  TASK_DUE_ADD: 'task_due_add',
  TASK_PIECE_JOINTES: 'task_piece_joints',
  TASK_PIECE_JOINTE : 'task_piece_jointe',
  TASK_COMMENT_ADD: 'task_comment_add',
  RELANCE_TASK: 'relance_task',
  BLOG: 'blog',
  RAPPEL: 'Rappel',
  OPPORTUNITIES: 'opportunities',
  AUDIENCE: 'audience',
  STAGE: 'stage',
  CHAT: 'chat',
  CHAT_MENTION: 'chat_mention',
  PROJECT_CHAT: 'project_chat',
  PROJECT_DEADLINE_EXTEND: 'project_deadline_extend',
  PROJECT_DEADLINE_EXTEND_PERMISSION: 'project_deadline_extend_permission',
  LOAN: 'loan',
  GOUTI_TASK: 'Tâche',
  GOUTI_ACTION: 'Action',
  GOUTI_PROBLEM: 'Probleme',
  GOUTI_CHANGE: 'Changement',
  GOUTI_MILESTONE: 'Jalon',
  GOUTI_DELIVERABLE: 'Livrable',
  GOUTI_PROJECT: 'Projet',
  GOUTI_ORGANIZATION: 'Organisation',
  GOUTI_RISK: 'Risque',
  GOUTI_GOAL: 'Objectif',
  ARCHIVE: 'archive',
  
  //
  DOCUMENT_SHARED: 'document_shared',
  FILES_SHARED: 'files_shared',
  DOCUMENT_SHARED_USER_LEAVE: 'document_shared_user_leave',
  SEND_AUTORIZATION_FOR_ELEMENT: 'send_autorization_for_element',
  RESEND_AUTORIZATION_FOR_ELEMENT: 'resend_autorization_for_element',
  ACCEPTED_AUTORIZATION_FOR_ELEMENT: 'accepted_autorization_for_element',
  REJECTED_AUTORIZATION_FOR_ELEMENT: 'rejected_autorization_for_element',
  ADD_FILE_TO_FOLDER: 'add_file_to_folder',
  ADD_FOLDER_TO_FOLDER: 'add_folder_to_folder',

  //
  AFFECT_ADD: 'affect_add',
  AFFECT_UPDATE: 'affect_update',
  AFFECT_DONE: 'affect_done',
  AFFECT_VALIDATE: 'affect_validate',
  AFFECT_REJECT: 'affect_reject',
  AFFECT_PRINT: 'affect_print',
  AFFECT_COMMENT: 'affect_comment',

  AFFECTATION: 'affectation',
  AFFECTATION_STATE_CHANGE: 'affectation_state_change',
  AFFECTATION_REVOKE_USER: 'affectation_revoke_user',
  AFFECTATION_ASSIGN_USER: 'affectation_assign_user',
  AFFECTATION_OTHER_CHANGE: 'affectation_other_change',

  // Affectation v4 (couriel)
  COURIEL_ADD: 'couriel.add',
  COURIEL_ASSIGN: 'couriel.assign_users',
  COURIEL_VALIDATE: 'couriel.validate',
  
  // Affectation v4 for notification
  AFFECTATION_ASSIGN: 'affectation.assign',
  COURIEL_TO_DO: 'couriel.to_do',
  COURIEL_DELETE: 'couriel.delete',
  COURIEL_CREATION: 'couriel.creation',
  COURIEL_ADD_ATTACHMENT: 'couriel.add_attachment',
  COURIEL_TO_REVIEW: 'couriel.to_review',
  COURIEL_CO_RECEIVER: 'couriel.co_receiver',
  COURIEL_DEFAULT_RECEIVER: 'couriel.default_receiver',
  COURIEL_HISTORY: 'couriel.history',
  COURIEL_ARCHIVE: 'couriel.archive',
  COURIEL_UN_ARCHIVE: 'couriel.un_archive',
  COURIEL_TRASH: 'couriel.trash',
  COURIEL_UN_TRASH: 'couriel.un_trash',
  COURIEL_ASSIGNED: 'couriel.assigned',
  COURIEL_REJECT: 'couriel.reject',

  // COMMUNICATION
  COMMUNICATION: 'communication',
  COMMUNICATION_ADD_TO_CHANNEL: 'communication_add_to_channel',
  COMMUNICATION_REMOVE_FROM_CHANNEL: 'communication_remove_from_channel',
  COMMUNICATION_YOU_ARE_NEW_ADMIN: 'communication_you_are_new_admin',
  COMMUNICATION_NEW_MESSAGE: 'communication_new_message',
  COMMUNICATION_NEW_ANSWER_MESSAGE: 'communication_new_answer_message'
};

export const NOTIFICATION_DEFAULT_ICON = '/static/icons/ic_notification_mail.svg';

export const NOTIFICATION_ICONS = {
  // [NOTIFICATION_TYPES.BROADCAST]: "/static/icons/ic_notification_mail.svg",
  [NOTIFICATION_TYPES.TASK]: '/static/icons/ic_task.svg',
  [NOTIFICATION_TYPES.BLOG]: '/static/icons/navbar/ic_blog.svg',
  [NOTIFICATION_TYPES.OPPORTUNITIES]: '/static/faqs/ic_refund.svg',
  // [NOTIFICATION_TYPES.AUDIENCE]: "/static/icons/ic_notification_shipping.svg",
  // [NOTIFICATION_TYPES.STAGE]: "/static/icons/ic_notification_shipping.svg",
  [NOTIFICATION_TYPES.CHAT]: '/static/icons/ic_notification_chat.svg',
  [NOTIFICATION_TYPES.CHAT_MENTION]: '/static/icons/ic_notification_chat.svg',
  [NOTIFICATION_TYPES.PROJECT_CHAT]: '/static/icons/ic_notification_chat.svg',

  ///
  [NOTIFICATION_TYPES.GOUTI_PROJECT]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath d="M18 2H6c-1.103 0-2 .897-2 2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm0 18H6V4h12v16z" fill="currentColor"%2F%3E%3Cpath d="M8 6h3v2H8zm5 0h3v2h-3zm-5 4h3v2H8zm5 .031h3V12h-3zM8 14h3v2H8zm5 0h3v2h-3z" fill="currentColor"%2F%3E%3C%2Fsvg%3E',

  [NOTIFICATION_TYPES.GOUTI_TASK]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"%3E%3Cg fill="currentColor"%3E%3Cpath fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"%2F%3E%3Cpath d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z"%2F%3E%3Cpath fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_ACTION]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"%3E%3Cpath d="M0 972.138V556.629c2.192-43.973 37.788-75.724 76.898-76.252h186.106c53.196-40.854 90.897-97.554 142.165-138.611c18.094-14.432 32.095-30.479 42.003-48.142c32.214-63.281 12.695-136.954 58.481-187.399c92.008-39.482 202.231 15.751 233.279 102.423c24.405 70.78 8.052 141.366-22.294 203.877c109.856-.182 219.71.708 329.563 1.292c89.434 6.678 153.202 66.892 153.797 152.504c-.244 86.275-74.623 149.017-153.797 150.565h-129.24c-4.308 25.417-12.709 48.465-25.202 69.144c7.239 53.145-9.327 105.247-41.356 142.812c-17.576 306.75-419.443 124.761-569.952 120.193H76.898C32.617 1046.939.528 1011.445 0 972.138zm277.221 0c120.425 2.591 531.909 184.658 492.407-76.252c43.546-23.471 60.301-86.286 33.603-126.01c40.566-40.005 52.118-90.265 12.925-129.887c38.771 0 77.113-.216 115.023-.646c37.911-.431 76.253-.646 115.024-.646c44.371-.933 75.122-33.487 75.606-72.374c-1.014-45.976-35.914-75.137-75.606-75.606c-150.385-.008-298.632-1.276-438.126-1.292c12.555-100.763 132.769-237.585 10.017-316.963c-19.652-9.652-35.367-13.749-55.896-10.017c-3.446 1.723-5.385 3.447-5.816 5.17c-.431 1.723-1.076 4.523-1.938 8.4c-13.044 79.87-25.221 159.73-87.237 212.601c-68.263 52.343-108.514 134.749-186.752 168.014h-3.231l-.003 415.508z" fill="blue"%2F%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_RISK]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"%3E%3Cpath d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43z" class="clr-i-outline clr-i-outline-path-1" fill="red"%2F%3E%3Cpath d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89z" class="clr-i-outline clr-i-outline-path-2" fill="red"%2F%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_PROBLEM]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath d="M14.8 4.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.213 3.213 0 0 1-1.743.516H5.298C3.477 21 2 19.47 2 17.581c0-.639.173-1.264.498-1.807L9.2 4.613c.962-1.603 2.996-2.094 4.543-1.096c.428.276.79.651 1.057 1.096zm-2.22.839a1.077 1.077 0 0 0-1.514.365L4.365 16.98a1.17 1.17 0 0 0-.166.602c0 .63.492 1.14 1.1 1.14H18.7c.206 0 .407-.06.581-.172a1.164 1.164 0 0 0 .353-1.57L12.933 5.817a1.12 1.12 0 0 0-.352-.365zM12 17a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z" fill="red"%2F%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_CHANGE]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath d="M9 20c0 .55.45 1 1 1s1-.45 1-1v-3c.73-2.58 3.07-3.47 5.17-3l-.88.88a.996.996 0 1 0 1.41 1.41l2.59-2.59a.996.996 0 0 0 0-1.41L16.7 9.7a.996.996 0 1 0-1.41 1.41l.88.89c-1.51-.33-3.73.08-5.17 1.36V6.83l.88.88a.996.996 0 1 0 1.41-1.41L10.7 3.71a.996.996 0 0 0-1.41 0L6.71 6.29A.996.996 0 1 0 8.12 7.7L9 6.83V20z" fill="currentColor"%2F%3E%3C%2Fsvg%3E',

  [NOTIFICATION_TYPES.GOUTI_DELIVERABLE]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cg fill="none"%3E%3Cpath d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.748l-7 3.89a2 2 0 0 1-1.942 0l-7-3.89A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874L11.03 2.54z" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%2F%3E%3Cpath d="M3 7l9 5m0 0l9-5m-9 5v10" stroke="green" stroke-width="2" stroke-linejoin="round"%2F%3E%3Cpath d="M7.5 9.5l9-5" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%2F%3E%3Cpath d="M6 12.328L9 14" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_GOAL]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath d="M19.07 4.93l-1.41 1.41A8.014 8.014 0 0 1 20 12c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02C8.16 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41C15.55 9.9 16 10.9 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72c0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V2h-1C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z" fill="green"%2F%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_ORGANIZATION]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"%3E%3Cg fill="currentColor"%3E%3Cpath d="M7 14s-1 0-1-1s1-4 5-4s5 3 5 4s-1 1-1 1H7zm4-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6z"%2F%3E%3Cpath fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"%2F%3E%3Cpath d="M4.5 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5z"%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E',
  [NOTIFICATION_TYPES.GOUTI_MILESTONE]:
    'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"%3E%3Cpath d="M573.19 402.67l-139.79-320C428.43 71.29 417.6 64 405.68 64h-97.59l2.45 23.16c.5 4.72-3.21 8.84-7.96 8.84h-29.16c-4.75 0-8.46-4.12-7.96-8.84L267.91 64h-97.59c-11.93 0-22.76 7.29-27.73 18.67L2.8 402.67C-6.45 423.86 8.31 448 30.54 448h196.84l10.31-97.68c.86-8.14 7.72-14.32 15.91-14.32h68.8c8.19 0 15.05 6.18 15.91 14.32L348.62 448h196.84c22.23 0 36.99-24.14 27.73-45.33zM260.4 135.16a8 8 0 0 1 7.96-7.16h39.29c4.09 0 7.53 3.09 7.96 7.16l4.6 43.58c.75 7.09-4.81 13.26-11.93 13.26h-40.54c-7.13 0-12.68-6.17-11.93-13.26l4.59-43.58zM315.64 304h-55.29c-9.5 0-16.91-8.23-15.91-17.68l5.07-48c.86-8.14 7.72-14.32 15.91-14.32h45.15c8.19 0 15.05 6.18 15.91 14.32l5.07 48c1 9.45-6.41 17.68-15.91 17.68z" fill="currentColor"%2F%3E%3C%2Fsvg%3E'
};

export const getNotificationIcon = (type) => (type && NOTIFICATION_ICONS[type]) || NOTIFICATION_DEFAULT_ICON;
