

/**
 * Splits a display name string into its first and last name components.
 *
 * @param {string} displayName - The full display name to split.
 * @returns {object} - An object with `lastName` and `firstName` properties.
 */
export const displayNameSplite = (displayName) =>{
    const tab = displayName.split(' ');
    const lastName = tab[0];
    const firstName = tab?.at(1) || '';

    return { lastName, firstName };
}