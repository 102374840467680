import axios from 'axios';
import { DateTime } from 'luxon';
import { isDate, isString, min, max, ceil, uniq, keys, orderBy } from 'lodash';
import { fr as LocalFr } from 'date-fns/locale';
import { format, formatDistanceToNow } from 'date-fns';
import { isFirebaseTimestamp } from './type_check';
// ----------------------------------------------------------------------

/**
 * Formats a date or timestamp to a string representation.
 *
 * @param {Date|string|number} date - The date or timestamp to format.
 * @param {string} [_format='dd MMMM yyyy'] - The format string to use for formatting the date.
 * @returns {string} The formatted date string, or an empty string if the input is invalid.
 */
export function fDate(date, _format = 'dd MMMM yyyy') {
  const convert = gDate(date);

  if (convert === null) return '';

  return format(convert, _format, {
    locale: LocalFr
  });
}

/**
 * Formats a date or timestamp to a string representation in the format "dd MMM yyyy HH:mm".
 *
 * @param {Date|string|number} date - The date or timestamp to format.
 * @returns {string} The formatted date and time string, or an empty string if the input is invalid.
 */
export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm', {
    locale: LocalFr
  });
}

/**
 * Formats a date or timestamp to a string representation in the format "HH:mm".
 *
 * @param {Date|string|number} date - The date or timestamp to format.
 * @returns {string} The formatted time string, or an empty string if the input is invalid.
 */
export function fTime(date) {
  return format(new Date(date), 'HH:mm', {
    locale: LocalFr
  });
}

/**
 * Formats a date or timestamp to a string representation in the format "dd/MM/yyyy HH:mm".
 *
 * @param {Date|string|number} date - The date or timestamp to format.
 * @returns {string} The formatted date and time string, or an empty string if the input is invalid.
 */
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

/**
 * Formats a date or timestamp to a string representation of the time elapsed since that date.
 *
 * @param {Date|string|number} date - The date or timestamp to format.
 * @returns {string} The formatted time elapsed string, or an empty string if the input is invalid.
 */
export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: LocalFr
  });
}

/**
 * Converts an epoch timestamp to a formatted date string in the format "dd MMM yyyy".
 *
 * @param {number} epoch - The epoch timestamp to convert.
 * @returns {string} The formatted date string.
 */
export function epochToDate(epoch) {
  return DateTime.fromJSDate(epoch.toDate()).toFormat('dd MMM yyyy');
}

/**
 * Converts an epoch timestamp to a formatted date and time string in the format "dd MMM yyyy HH:mm".
 *
 * @param {number} epoch - The epoch timestamp to convert.
 * @returns {string} The formatted date and time string.
 */
export function epochToDateTime(epoch) {
  return DateTime.fromJSDate(epoch.toDate()).toFormat('dd MMM yyyy HH:mm');
}

/**
 * @deprecated use [gfDate] instead
 * @param date
 * @returns {string|*}
 */
export const getDate = (date) => {
  return isFirebaseTimestamp(date) ? fDate(date.toDate()) : fDate(date);
};

/**
 * @deprecated use [gfDateTime] instead
 * @param date
 * @returns {string|*}
 */
export const getDateTime = (date) => {
  return isFirebaseTimestamp(date) ? fDateTime(date.toDate()) : fDateTime(date);
};

/**
 *
 * @param {*} date
 * @returns {Date?}
 */
export const gDate = (date) => {
  if (isFirebaseTimestamp(date)) return date.toDate();
  else if (isDate(date)) return date;
  else if (isString(date)) return new Date(date);
  return null;
};

/**
 * Converts a date to a formatted date string in the format "dd MMM yyyy".
 *
 * @param {Date|string|FirebaseTimestamp} date - The date to format.
 * @returns {string|null} The formatted date string, or null if the input date is invalid.
 */
export const gfDate = (date) => {
  return date ? fDate(gDate(date)) : null;
};

/**
 * Converts a date to a formatted date and time string in the format "dd MMM yyyy HH:mm".
 *
 * @param {Date|string|FirebaseTimestamp} date - The date to format.
 * @returns {string|null} The formatted date and time string, or null if the input date is invalid.
 */
export const gfDateTime = (date) => {
  return date ? fDateTime(gDate(date)) : null;
};

/**
 * Converts a date to a formatted time string in the format "HH:mm".
 *
 * @param {Date|string|FirebaseTimestamp} date - The date to format.
 * @returns {string|null} The formatted time string, or null if the input date is invalid.
 */
export const gfTime = (date) => {
  return date ? fTime(gDate(date)) : null;
};

/**
 * Generates a formatted date range string based on the provided start and end dates.
 *
 * The format of the output string depends on the year, month, and day differences between the start and end dates:
 * - If the start and end dates are in the same year, month, and day, the output will be in the format "dd MMMM".
 * - If the start and end dates are in the same year and month, but different days, the output will be in the format "dd - dd MMMM".
 * - If the start and end dates are in the same year but different months, the output will be in the format "dd MMMM - dd MMMM".
 * - If the start and end dates are in different years, the output will be in the format "dd MMM yyyy - dd MMM yyyy".
 *
 * @param {Date|string|FirebaseTimestamp} start - The start date of the range.
 * @param {Date|string|FirebaseTimestamp} end - The end date of the range.
 * @returns {string|null} The formatted date range string, or null if the input dates are invalid.
 */
export const gfRange = (start, end) => {
  const a = gDate(start);
  const b = gDate(end);

  if (a === null || b === null) return null;
  const _start = DateTime.fromJSDate(a);
  const _end = DateTime.fromJSDate(b);
  const today = DateTime.fromJSDate(new Date());

  const _format = (date, formattext) => {
    return format(gDate(date), formattext, {
      locale: LocalFr
    });
  };

  if (_start.year === _end.year && _start.year === today.year) {
    if (_start.month === _end.month && _start.day === _end.day) {
      return _format(start, 'dd MMMM');
    }
    if (_start.month === _end.month && _start.day !== _end.day) {
      return `${_format(start, 'dd')} - ${_format(end, 'dd MMMM')}`;
    }

    return `${_format(start, 'dd MMMM')} - ${_format(end, 'dd MMMM')}`;
  }

  return `${gfDate(start)} - ${gfDate(end)}`;
};

/**
 * Calculates the difference between two dates in terms of days, years, hours, minutes, and milliseconds.
 *
 * @param {Date|string|FirebaseTimestamp} startDate - The start date.
 * @param {Date|string|FirebaseTimestamp} endDate - The end date.
 * @returns {object|null} An object containing the difference in days, years, hours, minutes, and milliseconds, or null if either the start or end date is invalid.
 */
export const diffDate = (startDate, endDate) => {
  if (!startDate || !endDate) return null;

  const start = DateTime.fromJSDate(gDate(startDate));
  const end = DateTime.fromJSDate(gDate(endDate));
  const { days, years, hours, minutes, milliseconds } = end.diff(start, [
    'days',
    'years',
    'hours',
    'minutes',
    'milliseconds'
  ]);

  return { days, years, hours, minutes, milliseconds };
};

/**
 * Calculates the dates between a start and end date, inclusive.
 *
 * @param {Date|string|FirebaseTimestamp} startDate - The start date.
 * @param {Date|string|FirebaseTimestamp} endDate - The end date.
 * @returns {Date[]} An array of dates between the start and end date, inclusive.
 */
export const datesBetween = (startDate, endDate) => {
  const collection = [gDate(startDate), gDate(endDate)].filter((date) => isDate(date));
  const start = min(collection);
  const end = max(collection);
  const days = ceil(diffDate(start, end)?.days || 0);

  const dates = [...Array(days)].map((_, index) => {
    return DateTime.fromJSDate(start).plus({ days: index }).toJSDate();
  });

  return uniq(dates);
};

/**
 * Calculates the unique weeks between a start and end date, inclusive.
 *
 * @param {Date|string|FirebaseTimestamp} startDate - The start date.
 * @param {Date|string|FirebaseTimestamp} endDate - The end date.
 * @returns {number[]} An array of unique week numbers between the start and end date, inclusive.
 */
export const weeksBetween = (startDate, endDate) => {
  const dates = datesBetween(startDate, endDate);

  const weeks = dates.map((date) => {
    return DateTime.fromJSDate(date).weekNumber;
  });

  return uniq(weeks);
};

/**
 * Calculates the unique years between a start and end date, inclusive.
 *
 * @param {Date|string|FirebaseTimestamp} startDate - The start date.
 * @param {Date|string|FirebaseTimestamp} endDate - The end date.
 * @returns {number[]} An array of unique years between the start and end date, inclusive.
 */
export const yearsBetween = (startDate, endDate) => {
  const dates = datesBetween(startDate, endDate);

  const years = dates.map((date) => {
    return DateTime.fromJSDate(date).year;
  });

  return uniq(years);
};

/**
 * Returns an array of dates representing the days of the week, starting from the first day of the week that the provided date falls in.
 *
 * @param {Date} [date=new Date()] - The date to use as the reference point for the week.
 * @returns {Date[]} An array of 7 dates representing the days of the week.
 */
export const daysOfWeek = (date = new Date()) => {
  const current = DateTime.fromJSDate(gDate(date));
  const firstDay = current.startOf('week');

  return [...Array(7)].map((_, idx) => {
    return firstDay.plus({ days: idx }).toJSDate();
  });
};

export const onlineTime = async () => {
  try {
    const { data } = await axios.get('http://worldtimeapi.org/api/ip');

    return new Date(data?.utc_datetime);
  } catch (e) {
    console.error(e);
    return new Date();
  }
};

/**
 * Returns a new `Date` instance with only the date portion (year, month, day) of the provided `date` parameter.
 *
 * @param {Date|string|number} date - The date to extract the date portion from.
 * @returns {Date|null} A new `Date` instance with only the date portion, or `null` if the provided `date` parameter is invalid.
 */
export function getOnlyDate(date) {
  const parsed = gDate(date);

  if (parsed === null) return null;

  const year = parsed.getFullYear();
  const month = parsed.getMonth();
  const day = parsed.getDate();

  return new Date(year, month, day);
}

/**
 * Returns a new `Date` instance with the same date and time as the provided `date` parameter.
 *
 * @param {Date|string|number} date - The date to create a new `Date` instance from.
 * @returns {Date|null} A new `Date` instance with the same date and time, or `null` if the provided `date` parameter is invalid.
 */
export function getRappelLikeDate(date) {
  const parsed = gDate(date);

  if (parsed === null) return null;

  const year = parsed.getFullYear();
  const month = parsed.getMonth();
  const day = parsed.getDate();
  const hour = parsed.getHours();
  const minutes = parsed.getMinutes();

  return new Date(year, month, day, hour, minutes);
}

/**
 * Checks if the provided date is the same as today's date.
 *
 * @param {Date|string|number} date - The date to check.
 * @returns {boolean} `true` if the provided date is the same as today's date, `false` otherwise.
 */
export const isToday = (date) => {
  const today = getOnlyDate(new Date());
  const _date = getOnlyDate(date);

  return today?.toString() === _date?.toString();
};
/**
 * Groups a list of items by their date, using the provided date key.
 *
 * @param {Array} list - The list of items to group.
 * @param {string} [dateKey='createdAt'] - The key to use for the date value in each item.
 * @returns {Array} An array of objects, where each object has a `date` property (the unique date) and a `data` property (an array of items for that date).
 */
export const groupByDate = (list = [], dateKey = 'createdAt') => {
  const map = {};

  list.forEach((el) => {
    const cursor = getOnlyDate(el.createdAt);
    const isIncluded = Boolean(keys(map).filter((key) => key.toString() === cursor.toString()).length);

    if (isIncluded) {
      map[cursor] = [...map[cursor], el];
    } else {
      map[cursor] = [el];
    }
  });

  const result = keys(map).map((date) => ({
    date: gDate(date),
    data: map[date]
  }));

  return orderBy(result, dateKey, 'desc');
};

/**
 * Calculates the start and end dates for a given day.
 *
 * @param {Date} [date=new Date()] - The date to calculate the interval for. Defaults to the current date.
 * @returns {Object} An object with `start` and `end` properties, representing the start and end of the day for the given date.
 */
export const getDayInterval = (date = new Date()) => {
  const _date = gDate(date);

  if (_date === null) return null;

  const instance = DateTime.fromJSDate(_date);
  const start = new Date(instance.year, instance.month - 1, instance.day, 0, 0, 0);
  const end = new Date(instance.year, instance.month - 1, instance.day, 23, 59, 0);

  return { start, end };
};

/**
 * Calculates the start and end dates for the week containing the given date.
 *
 * @param {Date} [date=new Date()] - The date to calculate the week interval for. Defaults to the current date.
 * @returns {Object} An object with `start` and `end` properties, representing the start and end of the week for the given date.
 */
export const getWeekInterval = (date = new Date()) => {
  const _date = gDate(date);

  if (_date === null) return null;

  const instance = DateTime.fromJSDate(_date);
  const start = instance.set({ weekday: 1, hour: 0, minute: 0, second: 0 }).toJSDate();
  const end = instance.set({ weekday: 7, hour: 23, minute: 59, second: 0 }).toJSDate();

  return { start, end };
};

/**
 * Calculates the start and end dates for the month containing the given date.
 *
 * @param {Date} [date=new Date()] - The date to calculate the month interval for. Defaults to the current date.
 * @returns {Object} An object with `start` and `end` properties, representing the start and end of the month for the given date.
 */
export const getMonthInterval = (date = new Date()) => {
  const _date = gDate(date);

  if (_date === null) return null;

  const instance = DateTime.fromJSDate(_date);
  const start = instance.set({ day: 1, hour: 0, minute: 0, second: 0 }).toJSDate();
  const end = instance
    .set({
      day: 1,
      hour: 0,
      minute: 0,
      second: 0
    })
    .plus({ day: 33 })
    .set({ day: 1 })
    .minus({ day: 1 })
    .toJSDate();

  return { start, end };
};
/**
 * Orders a collection of dates in ascending or descending order.
 *
 * @param {Array<Date|string>} [collection=[]] - The collection of dates to order. Dates can be provided as Date objects or strings.
 * @param {boolean} [desc=false] - If true, the dates will be ordered in descending order. Otherwise, they will be ordered in ascending order.
 * @returns {Array<Date>} - The ordered collection of dates.
 */
export const orderDates = (collection = [], desc = false) => {
  return collection
    ?.map((date) => gDate(date))
    ?.sort((a, b) => {
      if (desc) return new Date(a).getTime() < new Date(b).getTime() ? 1 : -1;

      return new Date(a).getTime() > new Date(b).getTime() ? 1 : -1;
    });
};

/**
 * Finds the earliest date from a collection of dates.
 *
 * @param {Array<Date|string>} dates - The collection of dates to search.
 * @param {boolean} [eliminerDatesPassees=false] - If true, any dates in the past will be excluded from the result.
 * @returns {Date|null} - The earliest date in the collection, or null if the input is invalid or empty.
 */
export function findEarliestDate(dates, eliminerDatesPassees = false) {

  if (!Array.isArray(dates) || dates.length === 0) {
    return null; // Return null for invalid input or an empty list
  }

  let list = [...dates.map(el=>gDate(el))];

  // Si l'option d'élimination des dates passées est activée
  if (eliminerDatesPassees) {
    const dateActuelle = new Date();
    list = list.filter(date => date >= dateActuelle);
  }

  // Triez les dates par ordre croissant
  list.sort((a, b) => a - b);

  // La première date dans le tableau trié est la date la plus proche
  if (dates.length > 0) {
    return dates[0];
  }

  // Si aucune date valide n'a été trouvée
  return null;
}
