/**
 * Converts a Unix timestamp to a date string in the format "DD / MM / YYYY".
 *
 * @param {number} unix - The Unix timestamp to convert.
 * @returns {string} The date string in the format "DD / MM / YYYY". If the input `unix` is falsy, an empty string is returned.
 */
export function unixToDateString(unix) {
  if (unix) {
    const date = new Date(unix * 1000);
    return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
  } else {
    return '';
  }
}

/**
 * Converts a Unix timestamp to a date string in the format "YYYY / MM / DD".
 *
 * @param {number} unix - The Unix timestamp to convert.
 * @returns {string} The date string in the format "YYYY / MM / DD". If the input `unix` is falsy, an empty string is returned.
 */
export function unixReverseToDateString(unix) {
  const date = new Date(unix * 1000);
  return `${date.getFullYear()} / ${date.getMonth() + 1} / ${date.getDate()}`;
}

/**
 * Converts a Unix timestamp to a JavaScript Date object.
 *
 * @param {number} secs - The Unix timestamp (in seconds) to convert.
 * @returns {Date} The JavaScript Date object representing the given Unix timestamp.
 */
export function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
