// utils/dateUtils.js

/**
 * Converts a date or timestamp to a timestamp with the time portion set to 00:00:00.
 *
 * @param {Date|string|{seconds: number}} date - The date or timestamp to convert.
 * @returns {number|null} The timestamp with the time portion set to 00:00:00, or null if the input is invalid.
 */
export function convertToTimestamp(date) {
  let timestamp;

  if (date instanceof Date) {
    timestamp = date.getTime();
  } else if (typeof date === 'string') {
    timestamp = Date.parse(date);
  } else if (typeof date === 'object' && date?.seconds && typeof date?.seconds === 'number') {
    timestamp = date?.seconds * 1000; // Convert seconds to milliseconds
  } else {
    return null;
  }

  // Create a new Date object from the timestamp
  let dateObject = new Date(timestamp);

  // Set the hours and minutes to 00
  dateObject.setHours(0, 0, 0, 0);

  // Get the new timestamp
  timestamp = dateObject.getTime();

  return timestamp;
}

/**
 * Converts a timestamp to a human-readable date string in the French locale.
 *
 * @param {number} timestamp - The timestamp to convert.
 * @returns {string} The human-readable date string in the format "MMMM d, yyyy".
 */
export function convertTimestampToHumanReadable(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('fr-FR', options);
}
