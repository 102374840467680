// src/hooks/useTaskDueDate.js
import { useState, useMemo, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useDatePicker } from 'src/section/tasks/kanban/KanbanTaskAdd';
import { getCurrentUserAccess } from 'src/helpers/user';
import { dispatch } from 'src/redux/store';
import { updateProject } from 'src/redux/slices/kanban';
import {
  ProjectDeadlineExtendNotification,
  ProjectDeadlineExtendsPermissionNotification
} from 'src/redux/slices/notifications';
import { convertToTimestamp, convertTimestampToHumanReadable } from 'src/utils/dateUtils';
import { dateConverter } from 'src/helpers/dueDateConverter';
import { useOneProject } from '../useProject';

/**
 * A React hook that manages the due date functionality for a task.
 *
 * @param {object} task - The task object.
 * @param {object} enabledDataInterval - The enabled data interval for the task.
 * @param {function} onUpdate - A callback function to update the task.
 */
export const useTaskDueDate = (task, enabledDataInterval, onUpdate) => {
  const {
    startTime,
    endTime,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker,
    dueDate,
    isSameDays,
    isSameMonths
  } = useDatePicker({
    date: dateConverter(task?.due)
  });

  const { enqueueSnackbar } = useSnackbar();
  const [openExtend, showExtend, HideExtend] = useToggleV2();
  const projectKey = task?.projectKey || task?.projectId;
  const { project } = useOneProject({ id: projectKey });
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(null);
  const [tempDate, setTempDate] = useState(null);
  const iniTaskDate = useMemo(() => task?.due, [task]);
  const [isLoading, setIsLoading] = useState(false);
  const anchorEl = useRef(null);
  const currentUser = getCurrentUserAccess();
  const disabled = useMemo(() => !currentUser?.rights?.edit, [currentUser]);

  const currentUserRights = useMemo(() => {
    if (project?.createBy?.id === currentUser.id) return { delete: true, edit: true, create: true };

    if (project?.canAccessId?.includes(currentUser?.id)) {
      return (
        project?.canAccess?.find((user) => user?.id === currentUser?.id)?.rights ||
        project?.managers?.find((user) => user?.id === currentUser?.id)?.rights
      );
    }

    return { delete: false, edit: false, create: true };
  }, [project, currentUser]);

  const extendDate = (onClose = null) => {
    let start = tempDate?.isStartTimeSelected ? tempDate?.dates[0] : enabledDataInterval?.start || new Date();
    let end = tempDate?.isStartTimeSelected ? enabledDataInterval?.end || new Date() : tempDate?.dates[1];

    if (!currentUserRights.edit) {
      enqueueSnackbar("Vous n'avez pas les droits pour modifier la date du projet", { variant: 'error' });
      dispatch(ProjectDeadlineExtendsPermissionNotification({ project, deadline: tempDate?.date }));
      return;
    }

    setIsLoading(true);
    dispatch(
      updateProject(
        {
          ...project,
          dataInterval: {
            start,
            end
          }
        },
        project,
        () => {
          enqueueSnackbar(
            `La date a été prolongée au ${convertTimestampToHumanReadable(convertToTimestamp(tempDate?.date))}`,
            { variant: 'success' }
          );
          HideExtend();
          onSave();
          if (onClose) onClose();
          onChangeDueDate(tempDate?.dates);
          dispatch(ProjectDeadlineExtendNotification({ project, deadline: tempDate?.date }));
          dispatch(onUpdate(task, { due: tempDate?.dates }));
          setIsLoading(false);
        }
      )
    );
  };

  const handleDueDateChange = (_date) => {
    const date = _date.startDate && _date.endDate ? [_date.startDate, _date.endDate] : _date;
    if (date[0] === null && date[1] === null) return;
    const isStartTimeSelected = convertToTimestamp(date[0]) !== convertToTimestamp(startTime);
    const dateIsInInterval =
      convertToTimestamp(date[1]) <= convertToTimestamp(enabledDataInterval?.end) &&
      convertToTimestamp(date[1]) >= convertToTimestamp(enabledDataInterval?.start);
    console.log({ dateIsInInterval, project, date, enabledDataInterval });
    if (!dateIsInInterval) {
      if (project?.dataInterval?.start === null && project?.dataInterval?.end === null) {
        if (!currentUserRights.edit) {
          enqueueSnackbar("Vous n'avez pas les droits pour etendre la date du projet", { variant: 'error' });
          return;
        } else {
          const _date = isStartTimeSelected ? date[0] : date[1];
          dispatch(
            updateProject(
              {
                ...project,
                dataInterval: {
                  start: date[0] === null ? new Date() : date[0],
                  end: date[1] === null ? new Date() : date[1]
                }
              },
              project,
              () => {}
            )
          );
          onChangeDueDate(date);
          dispatch(onUpdate(task, { due: date }));
          enqueueSnackbar(`La date a été changée au ${convertTimestampToHumanReadable(convertToTimestamp(_date))}`, {
            variant: 'success'
          });
          return;
        }
      } else {
        setTempDate({
          date: isStartTimeSelected ? date[0] : date[1],
          dates: date,
          isStartTimeSelected: isStartTimeSelected
        });
        showExtend();
        return;
      }
    }
    onChangeDueDate(date);
    dispatch(onUpdate(task, { due: date }));
  };

  const onSave = () => {
    const _startTime = new Date(startTime).toISOString();
    const _endTime = new Date(endTime).toISOString();
    const values = [new Date(_startTime), new Date(_endTime)];
    dispatch(onUpdate(task, { due: values }));
  };

  const handleClosePicker = () => {
    onClosePicker();
  };

  const handleCloseExtend = () => {
    HideExtend();
  };

  return {
    startTime,
    endTime,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker,
    dueDate,
    isSameDays,
    isSameMonths,
    enqueueSnackbar,
    openExtend,
    showExtend,
    HideExtend,
    projectKey,
    project,
    isStartTimeSelected,
    setIsStartTimeSelected,
    tempDate,
    setTempDate,
    iniTaskDate,
    isLoading,
    setIsLoading,
    anchorEl,
    currentUser,
    currentUserRights,
    extendDate,
    handleDueDateChange,
    onSave,
    handleClosePicker,
    handleCloseExtend,
    onOpenPickerDisabled: (disabled) => {
      if (disabled) {
        return;
      }
      onOpenPicker();
    }
  };
};
