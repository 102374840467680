import { createSlice } from '@reduxjs/toolkit';
import { uploadMailAttachment } from './mailAttachmentThunks';

/**
 * Slice for handling file upload state.
 */
const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: {
    /** 
     * @property {number} progress - Upload progress percentage.
     */
    progress: 0,
    /** 
     * @property {string} fileUrl - URL of the uploaded file.
     */
    fileUrl: '',
    /** 
     * @property {string|null} error - Error message, if any.
     */
    error: null,
  },
  reducers: {
    /**
     * Resets the file upload state.
     * @param {Object} state - Current state of the slice.
     */
    resetFileUpload: (state) => {
      state.progress = 0;
      state.fileUrl = '';
      state.error = null;
    },

    /**
     * Sets the upload progress.
     * @param {Object} state - Current state of the slice.
     * @param {Object} action - Action dispatched.
     * @param {number} action.payload - Progress percentage.
     */

    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadMailAttachment.pending, (state) => {
        state.progress = 0;
        state.fileUrl = '';
        state.error = null;
      })
      .addCase(uploadMailAttachment.fulfilled, (state, action) => {
        state.progress = action.payload.progress;
        state.fileUrl = action.payload.url;
      })
      .addCase(uploadMailAttachment.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

/** 
 * Action to reset the file upload state.
 * @returns {Object} Action object.
 */
export const { resetFileUpload,setProgress } = fileUploadSlice.actions;

/** 
 * Reducer for the file upload slice.
 * @param {Object} state - Current state of the slice.
 * @param {Object} action - Action dispatched.
 * @returns {Object} New state of the slice.
 */
export default fileUploadSlice.reducer;
