import { PDFViewer, Page, View, Text, Image, Document } from '@react-pdf/renderer';
import styles from '../../assets/css/pdfStyle';
import { Box } from '@mui/material';
import { AFFECT_ANNOTATION } from 'src/constants/affectation';
import { gDate } from 'src/utils/formatTime';
import { format } from 'date-fns';
import { isObject, isString } from 'lodash';
import { toDateTime } from 'src/utils/unixDateTime';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { AFFECTATION_PRINT_HEADER } from 'src/config';

export default function AffectPDF({ affect }) {
  const [annotations, setAnnotation] = useState([]);
  const users = useSelector((state) => state.user.users);

  const convertAnnotation = () => {
    let position = 0;
    const list = [];
    while (position < AFFECT_ANNOTATION.length) {
      list.push(AFFECT_ANNOTATION.slice(position, position + 3));
      position += 3;
    }
    setAnnotation(list);
  };

  useEffect(() => {
    convertAnnotation();
  }, [affect]);

  const existAnnotation = useMemo(() => {
    const findSelections = AFFECT_ANNOTATION.filter((_one) => {
      if (isString(affect?.annotation)) {
        return _one === affect?.annotation;
      }
      return affect?.annotation.includes(_one);
    });
    return findSelections;
  }, [affect]);

  const notExistAnnotation = useMemo(() => {
    if (isString(affect?.annotation)) {
      const exist = AFFECT_ANNOTATION.find((_one) => _one === affect?.annotation);
      if (exist) {
        return;
      }
      return [affect?.annotation];
    }
    const custom = affect?.annotation.filter((_one) => !AFFECT_ANNOTATION.includes(_one));
    return custom;
  }, [affect]);

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
      <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={[styles.gridContainer, styles.mb8]}>
              <View style={{ alignItems: 'center', flexDirection: 'column' }}>
                <Text style={styles.h5}> {AFFECTATION_PRINT_HEADER.header_1} </Text>
                {AFFECTATION_PRINT_HEADER.header_2 && (
                  <Text style={styles.h5}> {AFFECTATION_PRINT_HEADER.header_2} </Text>
                )}
                <Text Text style={[styles.h4, styles.mt]}>
                  ...................
                </Text>
              </View>
              {AFFECTATION_PRINT_HEADER.use_motto && (
                <View style={{ alignItems: 'center', flexDirection: 'column' }}>
                  <Text style={styles.h4}> REPUBLIQUE TOGOLAISE </Text>
                  <Text style={styles.bold}> Travail - Liberté - Patrie </Text>
                  <Text style={[styles.h4, styles.mt]}> ................. </Text>
                </View>
              )}
            </View>

            <View style={{ alignItems: 'center', flexDirection: 'column' }}>
              <Text style={[styles.h3, styles.underline]}>FICHE D'AFFECTATION DU COURRIER ARRIVÉE </Text>
            </View>

            <View style={[styles.table, styles.mb10]}>
              <View style={styles.tableHeader}>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCell_2]}>
                    <Text style={styles.subtitle2}> Numéro d'enregistrement </Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}> Date d'arrivée </Text>
                  </View>

                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}> Date du courrier </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableBody}>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}> {affect?.save_reference || ''} </Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>
                      {affect?.correspondance?.dateArr
                        ? format(
                          isObject(affect?.correspondance?.dateArr)
                            ? toDateTime(affect?.correspondance?.dateArr?.seconds)
                            : gDate(affect?.correspondance?.dateArr),
                          'dd/MM/yyyy'
                        )
                        : affect?.courriel_date || ' '}
                    </Text>
                  </View>

                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>
                      {affect?.assignedAt?.date
                        ? format(
                          isObject(affect.assignedAt.date)
                            ? toDateTime(affect.assignedAt.date?.seconds)
                            : gDate(affect.assignedAt.date),
                          'dd/MM/yyyy'
                        )
                        : affect?.save_date || ' '}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.table, styles.mb10]}>
              <View style={styles.tableHeader}>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>Type d'opération</Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>Expéditeur</Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>Prioritaire</Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableBody}>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}> {affect?.operation_type || ''} </Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>
                      {affect?.expediteur?.titre || affect?.correspondant?.title || ''}
                    </Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.center]}>
                    <Text style={styles.subtitle2}>{affect?.isPriority ? 'Oui' : 'Non'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginBottom: 15,
                alignItems: 'flex-start',
                padding: 0
              }}
            >
              <Text style={[styles.h5, styles.underline]}>Objet</Text>
              <Text style={[styles.body1]}>{affect?.correspondance?.objet || affect?.courriel_object || ''} </Text>
            </View>

            <View style={{ flexDirection: 'column' }}>
              <Text style={[styles.h5, styles.underline]}>Assignation</Text>
            </View>

            <View style={{ ...styles.table, marginBottom: 25, minHeight: 100 }}>
              {affect?.assignationOrganigramme?.map((item, index) => {
                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Text style={styles.subtitle2}> {item.label} : </Text>
                      {item.users?.map((id, index) => {
                        const user = users?.filter((_user) => _user.id === id);
                        return (
                          <View key={id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Text>
                              {' '}
                              {user[0].displayName}
                              {index < item.users.length - 1 ? ',' : ''}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>

            <View style={{ flexDirection: 'column' }}>
              <Text style={[styles.h5, styles.underline]}>Annotation</Text>
            </View>

            <View style={[styles.table, styles.mb30]}>
              <View style={{ marginTop: 10 }}>
                {!!notExistAnnotation?.length
                  ? notExistAnnotation.map((_one) => (
                    <View style={[styles.tableRow, styles.tableBody]}>
                      <View style={[styles.tableCell_2, { width: '101%' }]}>
                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ width: '95%' }}>
                            <Text style={styles.subtitle2}> {_one} </Text>
                          </View>
                          <View style={{ width: '3%' }}>
                            <Image source="/static/done.png" style={{ height: 11, width: 11 }} />
                          </View>
                        </View>
                      </View>
                    </View>
                  ))
                  : null}

                {!!annotations?.length
                  ? annotations.map((_one, idx) => (
                    <View style={[styles.tableRow, styles.tableBody]} key={`ann-${idx}`}>
                      {_one.map((ann, udx) => (
                        <View style={[styles.tableCell_2]} key={`sub-${udx}`}>
                          <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: '84%' }}>
                              <Text style={styles.subtitle2}> {ann} </Text>
                            </View>
                            {existAnnotation.includes(ann) ? (
                              <View style={{ width: '15%' }}>
                                <Image source="/static/done.png" style={{ height: 11, width: 11 }} />
                              </View>
                            ) : null}
                          </View>
                        </View>
                      ))}
                    </View>
                  ))
                  : null}
              </View>
            </View>

            <View style={styles.gridContainer}>
              <View style={{ alignItems: 'center', flexDirection: 'column' }}>
                <Text Text style={styles.h4}>
                  Date:{' '}
                  <Text style={styles.body1}>
                    {affect?.assignedAt?.date
                      ? format(
                        isObject(affect?.assignedAt?.date)
                          ? toDateTime(affect?.assignedAt?.date?.seconds)
                          : gDate(affect?.assignedAt?.date),
                        'dd/MM/yyyy'
                      )
                      : format(
                        isObject(affect?.createdAt)
                          ? toDateTime(affect?.createdAt?.seconds)
                          : gDate(affect?.createdAt),
                        'dd/MM/yyyy'
                      )}
                  </Text>
                </Text>
              </View>
              <View style={{ alignItems: 'center', flexDirection: 'column' }}>
                <Text style={styles.h3_2}> Signature: ........................ </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}
