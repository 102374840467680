import {firestore} from "src/contexts/FirebaseContext";
import {getCurrentUserAccess} from "src/helpers/user";
import {isPlainObject} from 'lodash'
//TODO: use to task
/**
 * Determines the base project rights for a given user based on their role or roles.
 *
 * @param {Object} user - The user object containing the `role` and `roles` properties.
 * @returns {Object} An object containing the user's read, create, edit, and delete rights for the project.
 */
export const getBaseProjectRights = user => {
    const roles = user?.role || user?.roles
    if (roles?.admin || roles?.manager) {
        return {
            read: true,
            create: true,
            edit: true,
            delete: true
        }
    } else {
        return {
            read: roles?.task?.read || false,
            create: roles?.task?.create || false,
            edit: roles?.task?.edit || false,
            delete: roles?.task?.delete || false
        }
    }

}

/**
 * Determines the selected project rights for a given user based on the project's access and manager lists.
 *
 * @param {Object} project - The project object containing the `canAccess` and `managers` properties.
 * @param {Object} user - The user object containing the `id`, `role`, and `roles` properties.
 * @returns {Object} An object containing the user's read, create, edit, and delete rights for the project, as well as a `validate` flag indicating whether the rights were successfully determined.
 */
export const getSelectedProjectRights = (project, user) => {


    if (isPlainObject(project) && isPlainObject(user) && 'canAccess' in project && 'managers' in project) {
        const base = getBaseProjectRights(user)

        const people = [
            ...project?.canAccess,
            ...project?.managers,
        ];

        const reads = people?.map(el => el?.id)?.includes(user?.id);
        const creates = people?.filter(el => el?.rights?.create)?.map(el => el?.id)?.includes(user?.id);
        const edits = people?.filter(el => el?.rights?.edit)?.map(el => el?.id)?.includes(user?.id);
        const remove = people?.filter(el => el?.rights?.delete)?.map(el => el?.id)?.includes(user?.id);

        const isOldModel = !Boolean(people?.find(el => el?.id === user?.id)?.rights)


        return {
            read: reads && base?.read || isOldModel || false,
            create: creates && base?.create || isOldModel || false,
            edit: edits && base?.edit || isOldModel || false,
            delete: remove && base?.delete || isOldModel || false,
            validate: true
        }
    } else {
        return {
            read: true,
            create: true,
            edit: true,
            delete: true,
            validate: true
        }
    }


}

/**
 * Retrieves a project by its ID, first checking the store and then fetching it from Firestore if not found.
 *
 * @param {string} projectId - The ID of the project to retrieve.
 * @param {object} [store=null] - An optional store object that may contain the project data.
 * @returns {Promise<object|null>} The project object if found, or null if not found.
 */
export const getProjectById = async (projectId, store = null) => {
    if (store) {
        const user = getCurrentUserAccess();

        const storeKey = `${projectId}_My_Project`;
        const secondKey = `list_${user?.id}_My_Projects`

        const list = [
            ...(store?.firestore?.ordered[storeKey] || []),
            ...(store?.firestore?.ordered[secondKey] || []),
        ];

        const project = list?.find(el => el?.id === projectId);

        if (project) return project;
    }

    const snap = await firestore.collection('project').doc(projectId).get();

    if (snap.exists) return {...snap.data(), id: snap.id};

    return null

}

