import { Icon } from '@iconify/react';
import { Button, CircularProgress, Paper, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useV4ProjectContext } from '..';
import V4ProjectCotentKanbanCard, { V4ProjectContentKanbanNewCard } from './V4ProjectCotentKanbanCard';
import { Droppable } from 'react-beautiful-dnd';
import Scrollbar from 'src/components/Scrollbar';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

const DEFAULT_PER_PAGE = 10;

/**
 * Renders the Kanban board body, including the list of Kanban cards and the "Add Task" button.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.column - The current Kanban column.
 * @param {function} props.onOpenTasknew - A function to open the task creation modal.
 * @param {boolean} props.openTasknew - A flag indicating whether the task creation modal is open.
 * @param {function} props.onCloseTasknew - A function to close the task creation modal.
 * @param {Object} props.rights - The user's permissions for the current project.
 * @returns {JSX.Element} - The rendered Kanban board body.
 */
export default function V4ProjectCotentKanbanBody({ column, onOpenTasknew, openTasknew, onCloseTasknew, rights }) {
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showLength, setShowLength] = useState(DEFAULT_PER_PAGE);

  const {
    board: { cards }
  } = useV4ProjectContext();

  const handleNext = async (direction) => {
    console.log('handleNext', direction);
    try {
      setIsLoading(true);
      if (direction === 'down' && canLoadMore) {
        setShowLength((prev) => prev + DEFAULT_PER_PAGE);
      }

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (column?.cardIds?.length <= showLength) {
      setCanLoadMore(false);
    }
    else if (column?.cardIds?.length > showLength) {
      setCanLoadMore(true);
    }
  }, [column?.cardIds, showLength]);

  const scrollRef = useInfiniteScroll({
    next: handleNext,
    rowCount: column?.cardIds?.length || 0,
    hasMore: { down: canLoadMore },
  });

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    if (openTasknew) {
      scrollMessagesToBottom();
    }
  }, [openTasknew, scrollRef]);

  const handleOpen = () => {
    onOpenTasknew();
  };

  const cardIds = useMemo(() => {
    return [...(column?.cardIds?.slice(0, showLength) || [])].filter((cardId) => cards[cardId]);
  }, [cards, column?.cardIds, showLength]);
  const canCreate = rights?.create;

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        height: 'calc(100vh - 150px)',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch'
      }}
    >
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        <Droppable droppableId={column?.id} type="task" >
          {(provided, snapshot) => (
            <Stack
              {...provided.droppableProps}
              ref={provided.innerRef}
              component={Paper}
              height="calc(100vh - 150px)"
              spacing={1}
              sx={{
                p: 0.5,
                transition: 'background-color 0.3s ease',
                ...(snapshot.isDraggingOver
                  ? { bgcolor: 'rgba(233, 255, 90, 0.2)' }
                  : cardIds?.length && { bgcolor: 'transparent' })
              }}
            >

              <V4ProjectContentKanbanCardList
                cardIds={cardIds}
                cards={cards}
                scrollRef={scrollRef}
              />

              {provided.placeholder}

              {openTasknew && (
                <V4ProjectContentKanbanNewCard operation={column} open={openTasknew} onClose={onCloseTasknew} rigths={rights} />
              )}
              {isLoading && <CircularProgress />}
              <Button
                fullWidth
                color="inherit"
                startIcon={<Icon icon="eva:plus-fill" height={15} width={15} />}
                onClick={canCreate ? handleOpen : () => { }}
                disabled={!canCreate}
              >
                Ajouter une tâche
              </Button>
            </Stack>
          )}
        </Droppable>
      </Scrollbar>
    </Stack>
  );
}

/**
 * Renders a list of Kanban cards within a scrollable container.
 *
 * @param {Object} props - The component props.
 * @param {string[]} props.cardIds - An array of card IDs to be displayed.
 * @param {Object} props.cards - An object containing the card data, keyed by card ID.
 * @param {React.RefObject<HTMLDivElement>} props.scrollRef - A ref to the scrollable container element.
 * @returns {JSX.Element} - The rendered Kanban card list.
 */
const V4ProjectContentKanbanCardList = ({ cardIds, cards, scrollRef }) => {
  return (
    <Stack
      spacing={1}
      ref={scrollRef}
    >
      {cardIds?.map((cardId, index) => (
        <V4ProjectCotentKanbanCard key={cardId} task={cards[cardId]} index={index} />
      ))}
    </Stack>
  );
};
