import { v4 as uuidv4 } from 'uuid';
import { filter, indexOf, isArray, isFunction, uniq, uniqBy } from 'lodash';
import { createSlice, nanoid } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import storage from '../../utils/storage';
import { auth } from '../../contexts/FirebaseContext';
import { defaultDb } from 'src/contexts/FirebaseContext';
import { docDriverCollectionPaths, FileType, FolderType, ShareType } from 'src/section/doc/types';
import { serverTime } from 'src/utils/serverTime';
import { UserType } from 'src/helpers/types';
import axiosRequest from 'src/utils/axiosRequest';

const initialState = {
  isLoading: false,
  error: false,
  documents: [],
  myFolders: [],
  myFoldersChilds: [],
  notifications: null
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    //AUDIENCE ADD AND UPDATE SUCCESS
    addSuccess(state, action) {
      state.isLoading = false;
      state.documents.push(action.payload);
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      const toUpdate = indexOf(state.documents, (docuemnt) => docuemnt.id === action.payload);
      let newAudience = [...state.documents];
      newAudience.splice(toUpdate, 1, action.payload);
      state.documents = newAudience;
    },

    //GET AUDIENCE SUCCESS
    getDocumentSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },

    deleteDocumentSuccess(state, action) {
      state.isLoading = false;
      state.documents = filter(state.documents, (docuemnt) => docuemnt.id !== action.payload);
    },

    getMyFolders(state, action) {
      const { folders, parentId, isToplevel } = action.payload;
      if (isToplevel) {
        state.myFolders = [...folders];
        state.myFoldersChilds = [...folders];
        return;
      }
      state.myFoldersChilds = [...state.myFoldersChilds, ...folders];
    }
  }
});

export default slice.reducer;

const getUserId = () => {
  const userId = auth.currentUser.uid;
  if (!userId) throw Error('The user is not connected! ');

  return userId;
};

//#region save on dir ------------------------------------------------------------------------------

/**
 * Saves a set of files in the specified directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {string} dir - The name of the directory to save the files in.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function saveFilesOnRootDir(files, dir, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const userId = getUserId();

      let folderId = null;
      const rootDocs = await firestore
        .collection('documents')
        .where('userId', 'array-contains', userId)
        .where('name', '==', dir)
        .get();

      if (rootDocs.empty) {
        const isCreate = await firestore.collection('documents').add({
          name: dir,
          parentId: userId,
          path: [],
          userId: [userId],
          createAt: new Date(),
          updateAt: new Date()
        });

        folderId = isCreate.id;
      } else {
        folderId = rootDocs.docs[0].id;
      }

      const docBatch = firestore.batch();

      isArray(files) &&
        files.forEach((file) => {
          const docRef = firestore.collection('files').doc();
          docBatch.set(docRef, {
            url: file.url,
            name: file.name,
            fileType: file.type,
            createAt: new Date(),
            folderId,
            userId: [userId]
          });
        });

      await docBatch.commit();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Saves a set of files in the 'Stages' directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function SaveStageDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Stages', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Saves a set of files in the 'Blog' directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function SaveBlogDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Blog', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Saves a set of files in the 'Task' directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function SaveTaskDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Task', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Saves a set of files in the 'Affectation' directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function SaveAffectDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Affectation', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Saves a set of files in the 'Chat' directory on the root directory.
 *
 * @param {Array<File>} files - An array of files to be saved.
 * @param {?function} callback - An optional callback function to be executed after the files have been saved.
 * @returns {function} - A thunk function that can be dispatched to save the files.
 */
export function SaveChatDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Chat', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

//#endregion

//#region SAVING EDITING AND DELETING FILE AND FOLDER

/**
 * Deletes a file from the Firestore 'files' collection.
 *
 * @param {string} fileId - The ID of the file to be deleted.
 * @param {function} [callback] - An optional callback function to be executed after the deletion is complete.
 * @returns {function} - A thunk function that can be dispatched to delete the file.
 */
export function deleteFile(fileId, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('files').doc(fileId).delete();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Updates the name of a file in the Firestore 'files' collection.
 *
 * @param {string} fileId - The ID of the file to be updated.
 * @param {string} newName - The new name to be set for the file.
 * @param {function} [callback] - An optional callback function to be executed after the update is complete.
 * @returns {function} - A thunk function that can be dispatched to update the file name.
 */
export function updateFilename(fileId, newName, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('files').doc(fileId).set({ name: newName }, { merge: true });

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Deletes a folder from the Firestore 'documents' collection.
 *
 * @param {string} folderId - The ID of the folder to be deleted.
 * @param {function} [callback] - An optional callback function to be executed after the deletion is complete.
 * @returns {function} - A thunk function that can be dispatched to delete the folder.
 */
export function deleteFolder(folderId, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('documents').doc(folderId).delete();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Updates the name of a folder in the Firestore 'documents' collection.
 *
 * @param {string} folderId - The ID of the folder to be updated.
 * @param {string} newName - The new name to be set for the folder.
 * @param {function} [callback] - An optional callback function to be executed after the update is complete.
 * @returns {function} - A thunk function that can be dispatched to update the folder name.
 */
export function updateFolderName(folderId, newName, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('documents').doc(folderId).set({ name: newName }, { merge: true });

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * Uploads multiple files to the Firebase storage and returns the download URLs.
 *
 * @param {File[]} files - An array of files to be uploaded.
 * @param {function} onSave - A callback function that will be called with the uploaded file information.
 * @param {function} [callback] - An optional callback function that will be called after all files have been uploaded.
 * @param {function} [setUploading] - An optional callback function that will be called with the upload progress for each file.
 * @param {string} [filesPathRoot] - An optional path prefix for the uploaded files.
 * @param {function} [onEveryFileUpload] - An optional callback function that will be called after each file has been uploaded.
 * @param {boolean} [noRetrieveDoneUplaod] - An optional flag to disable removing uploaded files from the `setUploading` callback.
 * @returns {Promise<void>} - A Promise that resolves when all files have been uploaded.
 */
export async function multipleFilesSave(
  files,
  onSave,
  callback = null,
  setUploading = null,
  filesPathRoot = null,
  onEveryFileUpload = null,
  noRetrieveDoneUplaod = false
) {
  try {
    const data = [];
    let totalLenght = 0;

    if (files?.length) {
      if (setUploading) {
        const startUpload = async () => {
          await asyncForEach(files, async (file) => {
            const id = file?.id || nanoid(30);

            setUploading((prevs) => [
              ...prevs,
              { id: id, progress: 0, error: false, name: file.name, url: file?.url || '', type: file?.type }
            ]);

            const uploadTask = storage
              .ref()
              .child(`${filesPathRoot || ''}${file?.id || id}/${file.name}`)
              .put(file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes;

                setUploading((prevUploadingFiles) => {
                  return prevUploadingFiles.map((uploadFile) => {
                    if (uploadFile.id === id) {
                      return { ...uploadFile, progress: progress };
                    }

                    return uploadFile;
                  });
                });
              },
              (error) => {
                setUploading((prevUploadingFiles) => {
                  return prevUploadingFiles.map((uploadFile) => {
                    if (uploadFile.id === id) {
                      return { ...uploadFile, error };
                    }
                    return uploadFile;
                  });
                });
              },
              () => {
                if (!noRetrieveDoneUplaod) {
                  setUploading((prevUploadingFiles) => {
                    return prevUploadingFiles.filter((uploadFile) => {
                      return uploadFile.id !== id;
                    });
                  });
                }

                uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                  const _completeFile = { name: file.name, url: url, type: file.type, id: id, size: file?.size };
                  data.push(_completeFile);
                  onEveryFileUpload && onEveryFileUpload(_completeFile);

                  totalLenght += 1;
                  if (totalLenght === files.length) {
                    onSave(data);
                    if (callback) return callback(data);
                  }
                });
              }
            );
          });
        };

        startUpload();
      } else {
        await Promise.all(
          files.map(async (file) => {
            const id = nanoid();
            let fileSnap = await storage
              .ref()
              .child(`${filesPathRoot || ''}${file?.id || id}/${file.name}`)
              .put(file);
            let url = await fileSnap.ref.getDownloadURL();
            let _file_ = { name: file.name, url: url, type: file.type, id: id, size: file?.size };
            data.push(_file_);
          })
        );
        onSave(data);
        if (callback) return callback(data);
      }
      return;
    }

    onSave(data);
    if (callback) return callback(data);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Uploads a file to the Firebase storage and returns the download URL.
 *
 * @param {File} file - The file to be uploaded.
 * @param {function} onSave - A callback function that will be called with the uploaded file information.
 * @param {function} [callback] - An optional callback function that will be called after the file has been uploaded.
 * @param {function} [uploading] - An optional callback function that will be called with the upload progress.
 * @param {string} [fileId] - An optional file ID to use for the upload.
 * @returns {Promise<void>} - A Promise that resolves when the file has been uploaded.
 */
export async function saveFile(file, onSave, callback = null, uploading = null, fileId = null) {
  try {
    let fileSnap = storage
      .ref()
      .child(`${fileId || ''}/${file.name}`)
      .put(file);
    fileSnap.on(
      'state_changed',
      (snap) => {
        var progress = (snap.bytesTransferred / snap.totalBytes) * 100;

        //eslint-disable-next-line
        switch (snap.state) {
          case defaultDb.storage.TaskState.PAUSED: // or 'paused'
            uploading && uploading({ progress, name: file?.name, state: 'En Pause' });
            break;
          case defaultDb.storage.TaskState.RUNNING: // or 'running'
            uploading && uploading({ progress, name: file?.name, state: 'En cours' });
            break;
        }
      },
      (error) => {
        uploading &&
          uploading((prev) => {
            return { progress: prev.progress, name: file?.name, state: 'error', error: error };
          });
      },
      () => {
        fileSnap.snapshot.ref.getDownloadURL().then((url) => {
          const _file = { name: file.name, url: url, type: file.type };

          isFunction(onSave) && onSave(_file);
          if (callback) return callback();
        });
      }
    );
  } catch (error) {
    console.error(error);
  }
}

//#endregion

//#region DOCRVIDER --------------------------------*****************------------------------

/**
 * Retrieves a document from the 'myFolders' collection in Firestore by its ID.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.docId - The ID of the document to retrieve.
 * @param {function} [params.callback] - An optional callback function that will be called with the retrieved document data.
 * @returns {Promise<void>} - A Promise that resolves when the document has been retrieved.
 */
export function getElementById({ docId, callback }) {
  return async (dispatch) => {
    try {
      const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(docId);
      const docSnap = await docRef.get();
      // console.log(docSnap.data());
      if (docSnap.exists) {
        const docData = docSnap.data();
        if (callback) return callback(docData);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Retrieves the user's folders from the 'myFolders' collection in Firestore.
 *
 * @param {Object} params - The parameters object.
 * @param {Array<FolderType>} params.folders - The array of folders to retrieve.
 * @param {string} params.parentId - The ID of the parent folder.
 * @param {boolean} params.isToplevel - Indicates whether the folders are top-level folders.
 * @returns {Promise<void>} - A Promise that resolves when the folders have been retrieved.
 */
export function docDrivergetMyFolders({ folders, parentId, isToplevel }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getMyFolders({ folders, parentId, isToplevel }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Creates a new folder in the 'myFolders' collection in Firestore.
 *
 * @param {Object} params - The parameters object.
 * @param {FolderType} params.folder - The folder object to create.
 * @param {UserType} params.user - The user creating the folder.
 * @param {function} [params.callback] - An optional callback function to be called after the folder is created.
 * @returns {Promise<void>} - A Promise that resolves when the folder has been created.
 */
export function docDriverCreateMyFolder({ folder, user, callback = null }) {
  return async (dispatch) => {
    try {
      const docRef = await firestore.collection(docDriverCollectionPaths.myFolders).add({
        ...folder,
        type: 'folder',
        isDelete: false,
        access: 'private',
        createdBy: user,
        canAccessId: [user.id],
        createdAt: serverTime(),
        updatedAt: serverTime(),
        lastUpdatedBy: user
      });
      if (callback) return callback(docRef.id);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
/**
 * Creates one or more files in the 'myFolders' collection in Firestore.
 *
 * @param {Object} params - The parameters object.
 * @param {Array<FileType>} params.files - The array of files to create.
 * @param {UserType} params.user - The user creating the files.
 * @param {Function} [params.callback] - An optional callback function to be called after the files are created.
 * @returns {Promise<void>} - A Promise that resolves when the files have been created.
 */
export function docDriverCreateFile({ files, user, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      files.forEach((file) => {
        const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(file.id);
        batch.set(docRef, {
          ...file,
          type: 'file',
          acess: 'private',
          isDelete: false,
          createdBy: user,
          canAccessId: [user.id],
          createdAt: serverTime(),
          updatedAt: serverTime(),
          lastUpdatedBy: user
        });
      });

      await batch.commit();

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Updates the value of a field in a document in the 'myFolders' collection.
 *
 * @param {Object} params - The parameters object.
 * @param {string|string[]} params.id - The ID or IDs of the document(s) to update.
 * @param {string} params.field - The name of the field to update.
 * @param {any} params.value - The new value for the field.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export function updateDocDriverFileOrFolderField({ id, field, value }) {
  return async (dispatch) => {
    try {
      if (isArray(id)) {
        const batch = firestore.batch();
        id.forEach((_id) => {
          const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(_id);
          batch.update(docRef, { [field]: value });
        });
        await batch.commit();
        return;
      }

      await firestore
        .collection(docDriverCollectionPaths.myFolders)
        .doc(id)
        .set({ [field]: value }, { merge: true });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Emits a new comment for a document in the 'myFolders' collection.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.docId - The ID of the document to add the comment to.
 * @param {Object} params.comment - The comment object to add.
 * @param {Function} [params.callback] - An optional callback function to be called after the comment is added.
 * @returns {Promise<void>} - A promise that resolves when the comment is added.
 */
export function emmitDocComment({ docId, comment, callback }) {
  return async (dispatch) => {
    try {
      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(docId);

        return transaction.get(ref).then((snap) => {
          if (snap.exists) {
            const commentsCount = (snap.data()?.commentsCount || 0) + 1;

            const { id, ...rest } = comment;

            transaction.set(ref, { commentsCount }, { merge: true });

            transaction.set(ref.collection('comments').doc(id), rest, { merge: true });
          }
        });
      });

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Deletes a comment from a document in the 'myFolders' collection.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.docId - The ID of the document to delete the comment from.
 * @param {string} params.commentId - The ID of the comment to delete.
 * @param {Function} [params.callback] - An optional callback function to be called after the comment is deleted.
 * @returns {Promise<void>} - A promise that resolves when the comment is deleted.
 */
export function deleteDocComment({ docId, commentId, callback }) {
  return async (dispatch) => {
    try {
      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(docId);

        return transaction.get(ref).then((snap) => {
          if (snap.exists) {
            const commentsCount = (snap.data()?.commentsCount || 0) - 1;

            transaction.set(ref, { commentsCount }, { merge: true });

            transaction.delete(ref.collection('comments').doc(commentId));
          }
        });
      });
      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Shares a file with a set of email addresses.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.file - The file object to be shared.
 * @param {Array} params.members - An array of member objects containing email addresses to share the file with.
 * @param {Function} [params.callback] - An optional callback function to be called on successful completion.
 * @param {Function} [params.onError] - An optional callback function to be called on error.
 * @returns {Function} - An async function that performs the file sharing operation.
 */
export function shareFileWithEmail({ file, members, callback, onError }) {
  return async () => {
    try {
      const memberIds = members.map((_one) => _one.id);

      const { uid } = auth.currentUser;

      const oldShared = file?.sharedBy || {};
      const myOldShared = oldShared[uid];

      const newSahredMembers = [...(myOldShared?.to || []), ...memberIds];

      await firestore
        .collection(docDriverCollectionPaths.myFolders)
        .doc(file?.id)
        .set(
          {
            canAccessId: uniq([...(file.canAccessId || []), ...memberIds]),
            shareWith: uniq([...(file.members || []), ...members]),
            sharedAt: serverTime(),
            sharedById: uniq([...(file.sharedById || []), uid]),
            sharedBy: {
              ...(file?.sharedBy || {}),
              [uid]: { to: uniq(newSahredMembers), at: serverTime() }
            }
          },
          { merge: true }
        );

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error(error);
    }
  };
}

/**
 * Restores a set of deleted documents in the firestore database.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.docs - An array of document objects to be restored.
 * @param {Function} [params.callback] - An optional callback function to be called on successful completion.
 * @param {Function} [params.onError] - An optional callback function to be called on error.
 * @returns {Function} - An async function that performs the document restore operation.
 */
export function restoreDeleteDocDrive({ docs, callback, onError }) {
  return async () => {
    try {
      const batch = firestore.batch();

      docs.forEach((doc) => {
        const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(doc?.id);
        batch.update(docRef, { isDelete: false });
      });

      await batch.commit();

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

/**
 * Moves a set of documents to a new parent folder in the firestore database.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.docs - An array of document objects to be moved.
 * @param {string} params.parentId - The ID of the new parent folder.
 * @param {Function} [params.onResolve] - An optional callback function to be called on successful completion.
 * @param {Function} [params.onReject] - An optional callback function to be called on error.
 * @returns {Function} - An async function that performs the document move operation.
 */
export function moveDocs({ docs = [], parentId, onResolve, onReject }) {
  return async () => {
    try {
      const batch = firestore.batch();

      docs?.forEach((el) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(el?.id);
        batch.set(ref, { parentId }, { merge: true });
      });

      await batch.commit();

      onResolve && onResolve();
    } catch (e) {
      //console.log(e);
      onReject && onReject(e);
    }
  };
}

export function getOnlyOfficeToken({ id, callback, onError: onError }) {
  return async (dispatch) => {
    try {
      const { uid } = auth.currentUser;

      const tokenObject = await axiosRequest.post('/onlyoffice-token', { userId: uid, docuemntId: id });

      if (callback) {
        callback(tokenObject?.token);
      }
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
}

//#endregion
