import { Stack, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import ChannelActionModal from '../../channels/ChannelActionModal';
import Iconify from 'src/components/Iconify';

/**
 * Renders the Admin Selection Modal component.
 *
 * @param {boolean} open - Indicates if the modal is open
 * @param {function} onClose - Function to handle modal close
 * @param {function} onAdminSelect - Function to handle admin selection
 * @param {Array} members - Array of members
 * @param {boolean} isLoading - Indicates if data is loading
 * @return {JSX.Element} The rendered Admin Selection Modal component
 */
export const AdminSelectionModal = ({ open, onClose, onAdminSelect, members, isLoading = false }) => {
  const { user } = useAuth();
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [list, setList] = useState([...(members || [])]);

  // console.log({ list });

  React.useEffect(() => setList([...(members || [])]), [members]);

  const handleAdminSelection = (admin) => {
    setSelectedAdmin(admin);
  };

  const handleSetNewAdminAndLeaveChannel = () => {
    onAdminSelect(selectedAdmin);
  };



  return (
    <Stack>
      <ChannelActionModal
        open={open}
        onClose={onClose}
        action={handleSetNewAdminAndLeaveChannel}
        actionTitle="Choisir Et Quitter"
        title={'Sélectionnez un nouvel administrateur'}
        message="Sélectionnez un nouvel administrateur pour ce canal"
        disabledAction={!selectedAdmin || isLoading}
        isLoading={isLoading}
      // sx={{ maxHeight: '80vh', alignItems: 'center' }}
      >
        <Stack>
          {
            list.filter((_user) => _user?.id !== user?.id).map((__user) => (
              <Stack
                key={__user.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                my={1}
                borderRadius={2}
                bgcolor={selectedAdmin?.id === __user.id ? 'primary.light' : 'transparent'}
                onClick={() => isLoading ? null : handleAdminSelection(__user)}
                sx={{ cursor: 'pointer', ':hover': { bgcolor: selectedAdmin?.id === __user.id ? 'primary.light' : 'grey.100' } }}

              >
                <Stack direction="row" alignItems="center">
                  <Stack>
                    <Typography fontWeight="bold">
                      {__user.name || __user.displayName || __user.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {__user.email}
                    </Typography>
                  </Stack>
                </Stack>
                {selectedAdmin?.id === __user.id && (
                  <IconButton onClick={() => handleAdminSelection(null)}>
                    <Iconify icon="eva:checkmark-circle-2-fill" color="primary" />
                  </IconButton>
                )}
              </Stack>
            ))}
        </Stack>
      </ChannelActionModal>
    </Stack>
  );
}
