/**
 * Asynchronously iterates over an array, calling the provided callback function
 * for each element.
 *
 * @param {Array} array - The array to iterate over.
 * @param {Function} callback - The function to call for each element. The
 *   callback function should have the signature `(element, index, array)`.
 * @returns {Promise} A promise that resolves when the iteration is complete.
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
