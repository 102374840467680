import _, {isObject, take, values, keys, isEqual, uniq, differenceWith, intersectionWith, merge, orderBy} from 'lodash';
import {gDate, getOnlyDate} from "src/utils/formatTime";

/**
 * Updates an item in the given collection based on the provided change object and data index.
 *
 * @param {Array} collection - The collection of items to update.
 * @param {Object} change - The change object containing the updated data.
 * @param {string} [dataIndex='id'] - The property name to use as the data index.
 * @returns {Array} - The updated collection.
 */
export const updateBy = (collection = [], change = {}, dataIndex = 'id') => {
    return collection?.map(one => (change[dataIndex] === one[dataIndex]) ? merge({}, one, change) : one);
};

/**
 * Updates a list of items in the given destination collection based on the provided source collection and data index.
 *
 * @param {Array} destination - The collection of items to update.
 * @param {Array} source - The collection of items containing the updated data.
 * @param {string} [dataIndex='id'] - The property name to use as the data index.
 * @returns {Array} - The updated destination collection.
 */
export const updateListBy = (destination = [], source = [], dataIndex = 'id') => {
    let result = [...destination];

    source?.forEach(record => {
        result = updateBy(result, record, dataIndex);
    });

    return result;
};

/**
 * Updates an item in the given collection based on the provided change object and data index.
 *
 * @param {Array} collection - The collection of items to update.
 * @param {Object} change - The change object containing the updated data.
 * @param {string} [dataIndex='id'] - The property name to use as the data index.
 * @returns {Array} - The updated collection.
 */
export const setsBy = (collection = [], change = {}, dataIndex = 'id') => {
    const isEdit = collection?.find(one => one[dataIndex]);

    if (isEdit) {
        return updateBy(collection, change, dataIndex);
    } else {
        return [
            ...collection,
            change
        ];
    }

};

/**
 * Updates a list of items in the given destination collection based on the provided source collection and data index.
 *
 * @param {Array} destination - The collection of items to update.
 * @param {Array} source - The collection of items containing the updated data.
 * @param {string} [dataIndex='id'] - The property name to use as the data index.
 * @returns {Array} - The updated destination collection.
 */
export const setsListBy = (destination = [], source = [], dataIndex = 'id') => {
    let result = [...destination];

    source?.forEach(record => {
        result = setsBy(result, record, dataIndex);
    });

    return result;
};

/**
 * Transforms a collection of objects into a tree-like data structure.
 *
 * @param {Array} collection - The collection of objects to transform.
 * @param {Object} [options] - The options object.
 * @param {string} [options.index='name'] - The property name to use as the index.
 * @param {string} [options.parentIndex='parent'] - The property name to use as the parent index.
 * @param {string} [options.childrenIndex='children'] - The property name to use for the children.
 * @param {string} [options.cardIds='cardIds'] - The property name to use for the card IDs.
 * @returns {Array} - The transformed tree-like data structure.
 */
export const transformToTree = (collection, {
    index = 'name',
    parentIndex = 'parent',
    childrenIndex = 'children',
    cardIds = 'cardIds'
} = {}) => {
    let nodes = {};
    return collection.filter((obj) => {
        let id = obj[index];
        let parentId = obj[parentIndex];

        nodes[id] = _.defaults(obj, nodes[id], {[childrenIndex]: [], [cardIds]: []});
        parentId && (nodes[parentId] = (nodes[parentId] || {[childrenIndex]: []}))[childrenIndex].push(obj);
        parentId && (nodes[parentId] = (nodes[parentId] || {[cardIds]: []}))[cardIds].push(obj[index]);

        return !parentId;
    });
};

/**
 * Accumulates the values of a specified property across duplicate items in a collection.
 *
 * @param {Array} collection - The collection of items to accumulate.
 * @param {string} accumulatorIndex - The property name to accumulate.
 * @returns {Array} - The updated collection with accumulated values.
 */
export const accumulate = (collection, accumulatorIndex) => {
    let first = [...collection];

    let second = [];


    while (first.length !== 0) {
        const selected = first[0];
        const duplicate = first.filter(one => one?.id === selected?.id);
        first = first.filter(one => one?.id !== selected?.id);

        const countList = duplicate.map(one => Number(one[accumulatorIndex] || 0));
        const count = (countList.length === 0) ? [0] : countList.reduce((a, b) => a + b);

        second.push({
            ...selected,
            [accumulatorIndex]: count
        });
    }

    return second;
};

/**
 *
 * @param text {string}
 * @param collection {string[]}
 * @returns {{fail: number, match: number}}
 */
export const includesEither = (text, collection) => {
    const _text = text?.trim()?.toLowerCase();

    let result = {
        match: 0,
        fail: 0
    };

    collection.forEach(keyword => {

        const _keyword = keyword?.trim()?.toLowerCase();

        if (_text.includes(_keyword))
            result.match += 1;
        else result.fail += 1;

    });

    return result;
};

/**
 *
 * @param array {*[]}
 * @param keyword {string}
 * @param key {string}
 * @returns {*}
 */
export const searchList = (array = [], keyword = '', key = '_helper') => {
    return array
        ?.map(item => ({
            ...item,
            ...includesEither(String(item[key]), keyword.split(' '))
        }))
        ?.filter(item => item.match >= 1)
        ?.sort((a, b) => b.match - a.match)
        ?.map(({match, fail, ...rest}) => ({...rest}));
};

export const paginate = (collection, limit = 400) => {

    let list = [...collection];
    let result = [];

    while (list.length !== 0) {

        const taken = take(list, limit);
        result = [...result, taken];
        list = list.filter(one => !taken.includes(one));

    }

    return result;
};

export const sum = collection => {
    let result = 0;
    collection?.forEach(val => result += Number(val || 0));

    return result;
};

/**
 * Checks if the provided `element` is equal to any of the values in the `collection`.
 *
 * @param {*} element - The element to check for equality.
 * @param {*[]} collection - The collection of values to check against.
 * @returns {boolean} - `true` if the `element` is equal to any value in the `collection`, `false` otherwise.
 */
export const equalsEither = (element, collection) => {
    let flag = false;

    collection?.forEach(val => {
        if (element === val) {
            flag = true;
        }
    });

    return flag;
};

/**
 * Checks if the provided `element` contains any of the values in the `collection`.
 *
 * @param {*} element - The element to check for inclusion.
 * @param {*[]} collection - The collection of values to check against.
 * @returns {boolean} - `true` if the `element` contains any value in the `collection`, `false` otherwise.
 */
export const containsEither = (element, collection) => {
    let flag = false;

    collection?.forEach(val => {
        if (String(element).toLowerCase().includes(String(val).toLowerCase())) {
            flag = true;
        }
    });
    //console.log(String(element).toLowerCase(), collection)
    return flag;
};


/**
 * Ensures the provided `collection` is an array. If `collection` is an object, it returns the values of that object as an array. Otherwise, it returns the `collection` as-is or an empty array if `collection` is falsy.
 *
 * @param {*} collection - The collection to be fixed.
 * @returns {Array} - The fixed collection as an array.
 */
export const fixCollection = (collection) => {
    return isObject(collection) ? values(collection) : collection || []
}

/**
 * Compares two arrays and returns an object with the newest, missing, and common elements.
 *
 * @param {Array} previous - The previous array to compare against.
 * @param {Array} current - The current array to compare.
 * @returns {Object} - An object with the following properties:
 *   - `newest`: An array of elements that are in the `current` array but not in the `previous` array.
 *   - `missing`: An array of elements that are in the `previous` array but not in the `current` array.
 *   - `common`: An array of elements that are common to both the `previous` and `current` arrays.
 */
export const arrayDifference = (previous = [], current = []) => {

    return {
        newest: differenceWith(current, previous, isEqual),
        missing: differenceWith(previous, current, isEqual),
        common: intersectionWith(previous, current, isEqual)
    }
}

export const collectIds = collection => uniq(collection?.map(one => one?.id))


/**
 * Groups a collection of elements by their date, using a specified date key.
 *
 * @param {Array} collection - The collection of elements to group.
 * @param {string} [dateKey='date'] - The key in each element that contains the date value.
 * @param {boolean} [desc=true] - Whether to sort the groups in descending order.
 * @returns {Array} - An array of objects, where each object has a `cursor` property (the date) and a `list` property (the elements for that date).
 */
export const groupByDate = (collection = [], dateKey = 'date', desc = true) => {
    const formated = collection?.map(el => ({...el, [dateKey]: gDate(el[dateKey])}));
    const ordered = orderBy(formated, dateKey, desc ? 'desc' : 'asc');

    let data = {};
    let result = [];

    for (const element of ordered) {
        const cursor = getOnlyDate(element[dateKey]).toDateString();

        if (Boolean(data[cursor])) {
            data[cursor] = [...data[cursor], element]
        } else {
            data[cursor] = [element]
        }
    }

    keys(data).forEach(key => {
        result.push({
            cursor: gDate(key),
            list: data[key]
        })
    })

    return orderBy(result,'cursor',desc ? 'desc' : 'asc');
}
