
/**
 * Formats an email address by removing any angle brackets (`<` or `>`) from the input string.
 *
 * @param {string} mail - The email address to format.
 * @returns {string} The formatted email address with angle brackets removed.
 */
export const formatCorrectlyMailAdresse = (mail) => {
      const mailWithout = mail.replace(/<|>/g, '')
    return mailWithout;
  };
