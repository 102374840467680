import { CircularProgress, Sheet, Tooltip } from '@mui/joy';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Iconify from 'src/components/Iconify';
import TextMaxLine from 'src/components/TextMaxLine';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { IOkyDriverFile, IOkyDriverFolder } from 'src/models/IOkyDriver';
import { DRIVER_TYPE_FOLDER, ZIPPING_STATES } from './helpers';
import { checkIsOne } from 'src/helpers/checkIsOne';
import { useDriverContext } from '../context/DriverContext';

/**
 *
 * @param {{
 * selection: {[id:string]: {
 * zip: null,
 * state: 'start' | 'loading' | 'done' | 'error',
 * items: Array<IOkyDriverFile | IOkyDriverFolder>,
 * }},
 * onClose: ()=>{},
 * onDownload: () =>{},
 * onError: () =>{}
 * }} props
 */
export default function ZippingFilesView({ selection, onClose }) {
  const { open, handleSwitch } = useToggle(true);
  const container = document.getElementById('oky_driver');

  return ReactDOM.createPortal(
    <Box
      sx={{
        zIndex: (t) => t.zIndex.tooltip - 1,
        position: 'absolute',
        bottom: 0,
        right: 55,
        p: 3,
        overflow: 'hidden',
        display: container ? 'initial' : 'none'
      }}
    >
      <Paper variant="elevation" elevation={2} sx={{ width: 400 }}>
        <Stack>
          <Sheet variant="soft" color="neutral" sx={{ borderRadius: 'sm' }}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" p={1}>
              <TextMaxLine fontSize={13} fontWeight="bold" noTooltip>
                Préparation de téléchargement
              </TextMaxLine>
              <Stack direction="row" spacing={1}>
                <Tooltip title={!open ? 'Ouvrir' : 'Reduire'} placement="top">
                  <IconButton size="small" onClick={handleSwitch}>
                    <Iconify icon="eva:arrow-ios-downward-fill" rotate={open ? 0 : 2} />
                  </IconButton>
                </Tooltip>
                <IconButton size="small" title="Fermer" onClick={onClose}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Stack>
            </Stack>
          </Sheet>
          {Object.entries(selection).map(([id, value], index) => (
            <UploadingLine key={index + '_zipping_view'} id={id} element={value} />
          ))}
        </Stack>
      </Paper>
    </Box>,
    document.body
  );
}

/**
 *
 * @param {{
 * id:string,
 * element: {
 * zip: null,
 * state: 'start' | 'loading' | 'done' | 'error',
 * items: Array<IOkyDriverFile | IOkyDriverFolder>,
 * },
 * }} props
 */
const UploadingLine = ({ id, element }) => {
  const { handleDownloadElement, updateDownloading } = useDriverContext();

  const _items = useMemo(() => {
    const files = element.items.filter((one) => one.type !== DRIVER_TYPE_FOLDER);
    const folders = element.items.filter((one) => one.type === DRIVER_TYPE_FOLDER);
    return { files, folders };
  }, [element]);

  useEffect(() => {
    if (element.state === ZIPPING_STATES.START) {
      updateDownloading(id, { ...element, state: ZIPPING_STATES.LOADING });
      handleDownloadElement({
        element: element.items,
        onSuccess: (blob) => updateDownloading(id, { ...element, state: ZIPPING_STATES.DONE, zip: blob }),
        onError: () => updateDownloading(id, { ...element, state: ZIPPING_STATES.ERROR })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  const onManuelDownload = () => {
    if (element.zip) {
      const url = window.URL.createObjectURL(element.zip);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Okydook Driver.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
      <Stack width={1} direction="row" alignItems="center" spacing={1}>
        <Iconify
          icon={!_items.folders.length && _items.files.length === 1 ? 'fluent:folder-16-filled' : 'bi:file-zip-fill'}
        />
        <TextMaxLine noTooltip fontSize={12}>
          Téléchargement
          {_items.folders.length ? ` de ${_items.folders.length} dossier${checkIsOne(_items.folders) ? '' : 's'}` : ''}
          {_items.files.length && _items.folders.length ? ',' : ''}{' '}
          {_items.files.length ? ` de ${_items.files.length} fichier${checkIsOne(_items.files) ? '' : 's'}` : ''}
        </TextMaxLine>
      </Stack>
      {element.state === ZIPPING_STATES.ERROR ? (
        <IconButton size="small" title="Une erreur s'est produite">
          <Iconify icon="eva:info-fill" color="red" />
        </IconButton>
      ) : element.state === ZIPPING_STATES.LOADING ? (
        <CircularProgress variant="plain" size="sm" />
      ) : (
        element.state === ZIPPING_STATES.DONE && <DownloadButton onManuelDownload={onManuelDownload} />
      )}
    </Stack>
  );
};

const DownloadButton = ({ onManuelDownload }) => {
  const [hover, onHover, endHover] = useToggleV2();

  return (
    <Tooltip title="Télécharger" arrow placement="left-start">
      <IconButton
        onMouseEnter={onHover}
        onMouseLeave={endHover}
        title="Télécharger"
        size="small"
        onClick={onManuelDownload}
      >
        <Iconify icon={hover ? 'ic:round-download' : 'lets-icons:done-ring-round-fill'} color="green" />
      </IconButton>
    </Tooltip>
  );
};
