import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { useProjectContext } from 'src/contexts/ProjectContext';



/**
 * A React hook that manages the assignee for a task.
 *
 * @param {object} task - The task object.
 * @param {function} onUpdate - A callback function to update the task.
 * @param {boolean} [disabled=false] - Whether the assignee selection is disabled.
 * @returns {object} An object with the following properties:
 *   - `assignee`: The current assignee(s) for the task.
 *   - `onAssigne`: A function to update the assignee(s) for the task.
 *   - `_contacts`: The list of users that can be assigned to the task.
 */
export function useTaskAssignee(task, onUpdate, disabled = false) {
  const [assignee, setAssigne] = useState(task?.assignee || []);
  const { handleOpenAddToProjectConfirmation, currentProject, handleCloseAddToProjectConfirmation, handleOnValidateAddUserToProject } = useProjectContext();
  const { users: _contacts } = useSelector((state) => state.user);

  const onAssigne = async (_assigne) => {

    if (disabled) {
      return;
    }

    setAssigne(_assigne);

    const isNotInTheProject = [];
    _assigne.forEach((_user) => {
      if (!currentProject?.canAccessId.find((_id) => _id === _user.id)) {
        isNotInTheProject.push({ ..._user });
      }
    });
    if (isNotInTheProject.length) {
      handleOpenAddToProjectConfirmation({
        task,
        usersToAdd: isNotInTheProject,
        values: { assignee: _assigne },
        callback: () => {
          // if(callback) callback();
        }
      });
      return;
    } else {
      handleOpenAddToProjectConfirmation({
        task,
        options: {
          disable: true,
        },
        usersToAdd: _assigne?.filter((_user) => !currentProject?.canAccessId.find((_id) => _id === _user.id)),
        values: { assignee: _assigne },
        callback: () => {
          // if(callback) callback();
        },
      });
    }
    dispatch(onUpdate(task, { assignee: _assigne }));
  }

  const chandleRejectAddUserToProject = () => {
    handleCloseAddToProjectConfirmation();
    handleOnValidateAddUserToProject();
  }

  useEffect(() => {
    setAssigne(task?.assignee || []);
  }, [task]);

  return { assignee, onAssigne, _contacts, chandleRejectAddUserToProject };
}
