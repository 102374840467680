import ReactDOM from 'react-dom';
import { useState, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { varFadeInLeft } from 'src/components/animate/variants';
import { filter, isArray, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Alert,
  Stack,
  Dialog,
  Drawer,
  Button,
  Tooltip,
  Divider,
  Typography,
  DialogContent,
  DialogActions,
  LinearProgress,
  DialogContentText,
  Checkbox,
  Card
} from '@mui/material';
//
import { MIconButton, MHidden } from '../../../components/@material-extend';
import useToggle from '../../../hooks/useToggle';
import { makeStyles } from '@mui/styles';
import { multipleFilesSave, SaveTaskDocs } from 'src/redux/slices/document';
import { useDispatch } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import usePersons from '../../../hooks/usePersons';
import { useOneProject } from '../../../hooks/useProject';
import KanbanTaskDetailsInfo from './KanbanTaskDetailsInfo';
import KanbanDetailsAttach from './KanbanDetailsAttach';
import KanbanTaskDetailsComment from './KanbanTaskDetailsComment';
import { TASK_STATE_VALIDATION } from 'src/constants';
import { deleteCard } from '../../../redux/slices/kanban';
import { useSnackbar } from 'notistack';
import Relance from 'src/section/relance/Relance';
import { sendTaskRelanceNotification } from 'src/redux/slices/notifications';
import ActionModal from 'src/components/ActionModal';
import { LoadingButton } from '@mui/lab';
import { getSelectedProjectRights } from '../../../utils/project';
import TaskDetailsHeaderScrumb from '../TaskDetailsHeaderScrumb';
import CIconButton from 'src/components/CIconButton';
import { PATH_DASHBOARD } from 'src/routes/paths';
import DependencyRenderListOnDelete from '../dependency/DependencyRenderListOnDelete';
import KanbanTaskActivity from './KanbanTaskActivity';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}));

// const PRIORITIZES = ['BASE', 'NORMALE', 'HAUTE',  'URGENTE'];

KanbanTaskDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func
};

export const LabelStyle = styled(Typography)(({ theme, ...rest }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  fontWeight: 'bold',
  flexShrink: 0,
  // color: theme.palette.text.secondary,
  ...rest
}));

/**
 *
 * @param {{  card: any, isOpen: boolean, onClose:Function, onDeleteTask: Function, onModal: boolean, currentTab: number? }} props
 * @returns
 */
export default function KanbanTaskDetails(props) {
  const { card, isOpen, onClose, onDeleteTask, onModal = false, currentTab = null } = props;

  const { user } = useAuth();

  const { project, loading: projectLoading } = useOneProject({ id: card?.projectKey || card?.idProject || '' });
  const rigths = useMemo(() => getSelectedProjectRights(project, user), [project, user]);

  //#region STATE
  const classes = useStyles();
  const [taskCompleted, setTaskCompleted] = useState(card.completed);
  const [progress, setProgress] = useState(card.progress);
  const [uploadings, setUploadingFiles] = useState([]);
  const [details, setDetails] = useState({ ...card });

  // console.log('details', details);
  // eslint-disable-next-line
  const { open: show, handleOpen: handleOpenShow, handleClose: handleCloseShow } = useToggle();
  const { open: openRelance, handleOpen: onOpenRelance, handleClose: closeRelance } = useToggle();

  const [isUploading, setUploading] = useState(false);
  // const observersRef = useRef(null);
  const dispatch = useDispatch();

  const {
    user: { email, id, displayName, role, photoURL }
  } = useAuth();
  const { persons, loading: userLoading } = usePersons({ email: email });

  //#endregion

  const [deleting, setDeleting] = useState(false);
  const { open: openDel, handleClose: closeDelete, handleOpen: openDelete } = useToggle();

  const [deleteOk, setDeleteOK] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  //#region FUNCTION
  const canEndTask = useMemo(() => {
    if (details?.observers?.find((_one) => _one.email === email || _one.id === id)) {
      return true;
    }

    if (!details?.observers?.length && (details?.createdBy?.email === email || details?.createdBy?.id === id)) {
      return true;
    }

    return false;
  }, [details?.observers, details?.createdBy, email, id]);

  const users = useMemo(() => {
    if (role?.isGuest) {
      return filter(project?.canAccess || [], (_user) => persons.find((_pers) => _pers.email === _user.email));
    }
    return persons;
  }, [persons, role, project?.canAccess]);

  const handleDetailChange = (name, value) => {
    let _newDetailsValues = { ...details };

    if (isArray(name)) {
      name.forEach((_n, index) => (_newDetailsValues = { ..._newDetailsValues, [_n]: value[index] }));
    } else {
      _newDetailsValues = { ..._newDetailsValues, [name]: value };
    }

    if (name === 'state') {
      if (value === TASK_STATE_VALIDATION.ACCEPTED) {
        _newDetailsValues = { ..._newDetailsValues, completed: true };
      }
      if (value !== TASK_STATE_VALIDATION.ACCEPTED) {
        _newDetailsValues = { ..._newDetailsValues, completed: false };
      }
    }

    setDetails({ ..._newDetailsValues });
  };

  const handleManganer = (managers, _state, _completed) => {
    setDetails({ ...details, managers: managers, state: _state, completed: _completed });
  };

  //eslint-disable-next-line
  const handleToggleCompleted = () => {
    setTaskCompleted((prev) => !prev);
    handleDetailChange('completed', !taskCompleted);
    if (taskCompleted) {
      return setProgress(card.progress);
    }
    return setProgress(100);
  };

  const handleCloseDetails = () => {
    onClose({ ...details, progress, completed: taskCompleted });
  };

  const handleUploadAttachement = (files) => {
    setUploading(true);

    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          id: _file?.id || uuidv4(),
          displayName,
          createAt: new Date()
        };
      });

      setUploading(false);

      let newAttachs = details.attachments.map((_att) => {
        if (!_att?.id) {
          return { ..._att, id: uuidv4() };
        }
        return _att;
      });

      handleDetailChange('attachments', [...newAttachs, ...user_to_files]);

      dispatch(SaveTaskDocs(newfiles));
    };
    multipleFilesSave(files, handleFiles, null, setUploadingFiles);
  };

  const handleCommentChange = (comment) => {
    handleDetailChange('comments', [...details.comments, { ...comment }]);
  };

  const onRemoveFile = (file) => {
    const rest = details.attachments.filter((att) => {
      if (att?.id) {
        return att.id !== file.id;
      }
      return att !== file;
    });
    handleDetailChange('attachments', [...rest]);
  };

  const onUpdateFile = (oldFile, newFile) => {
    const rest = details.attachments.map((att) => {
      if (oldFile?.id) {
        if (att?.id === oldFile?.id) {
          return newFile;
        }
        return att;
      }

      if (att === oldFile) {
        return newFile;
      }
      return att;
    });
    handleDetailChange('attachments', [...rest]);
  };

  //#endregion

  const handleDeletion = () => {
    setDeleting(true);

    dispatch(
      deleteCard(card, () => {
        closeDelete();
        setDeleting(false);
        enqueueSnackbar('Suppression effectuée avec succès', { variant: 'warning' });
        onClose && onClose();
      })
    );
  };

  const onRelanceValidate = (sendTo) => {
    dispatch(
      sendTaskRelanceNotification({
        sendTo,
        projectId: project?.id,
        projectName: project?.name,
        taskId: details?.id,
        taskName: details?.name,
        callback: () => {
          enqueueSnackbar('Relance envoyée avec succès', { variant: 'success' });
        }
      })
    );
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleCloseDetails}
        anchor="right"
        ModalProps={{ sx: { ...(onModal && { zIndex: (t) => t.zIndex.modal + 2 }) } }}
        transitionDuration={1000}
        PaperProps={{
          sx: { width: { xs: 1, md: 1 }, bgcolor: 'transparent' }
        }}
      >
        <AnimatePresence>
          <Card component={motion.div} {...varFadeInLeft} sx={{ m: { xs: 1, sm: 3 }, height: { xs: 1, md: '99vh' } }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              width={1}
              minHeight={45}
              bgcolor={(t) => t.palette.action.selected}
            >
              <Stack direction="row" width={0.7} alignItems="center" pl={2} pt={{ xs: 1 }}>
                <TaskDetailsHeaderScrumb
                  path={[{ name: project?.name, href: PATH_DASHBOARD.general.tasks, path: [] }]}
                />
              </Stack>

              <Stack width={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <Stack>
                  <Button variant="contained" size="small" color="info" onClick={handleCloseDetails}>
                    Enregistrer
                  </Button>
                </Stack>
                <Stack>
                  <Button variant="outlined" size="small" onClick={onOpenRelance}>
                    Relance
                  </Button>
                </Stack>
                <Stack>
                  {canEndTask && rigths?.delete && (
                    <Tooltip title="Supprimer">
                      <CIconButton size="small" color="error" onClick={openDelete}>
                        <Icon icon={trash2Outline} color="red" width={20} height={20} />
                      </CIconButton>
                    </Tooltip>
                  )}
                </Stack>
                <Stack>
                  <MHidden width="smUp">
                    <Tooltip title="Back">
                      <MIconButton onClick={handleCloseDetails} sx={{ mr: 1 }}>
                        <Icon icon={arrowIosBackFill} width={20} height={20} />
                      </MIconButton>
                    </Tooltip>
                  </MHidden>

                  <MHidden width="smDown">
                    <Tooltip title="Back">
                      <CIconButton onClick={handleCloseDetails} sx={{ mr: 1 }}>
                        <Icon icon="eva:close-fill" height={20} />
                      </CIconButton>
                    </Tooltip>
                  </MHidden>
                </Stack>
              </Stack>
            </Stack>
            <Divider />

            <Stack width={1} height={1} direction="row">
              <Stack height={1} width={{ xs: 1, md: 0.6 }}>
                <Stack height={`calc(100vmin - 120px)`}>
                  <KanbanTaskDetailsInfo
                    details={details}
                    handleDetailChange={handleDetailChange}
                    users={users}
                    userId={id}
                    handleManganer={handleManganer}
                    project={project}
                    userLoading={userLoading}
                    classes={classes}
                    rigths={rigths}
                  />
                </Stack>
                <Stack
                  height={95}
                  maxHeight={95}
                  position="absolute"
                  bottom={10}
                  width={{ xs: 1, md: 0.6 }}
                  maxWidth={{ xs: 1, md: 0.6 }}
                  bgcolor="background.default"
                  px={2}
                >
                  <KanbanDetailsAttach
                    rigths={rigths}
                    userId={id}
                    details={details}
                    userName={displayName}
                    userEmail={email}
                    userAvatar={photoURL}
                    isUploading={isUploading}
                    onRemoveFile={onRemoveFile}
                    onUpdateFile={onUpdateFile}
                    handleUploadAttachement={handleUploadAttachement}
                  />
                </Stack>
              </Stack>

              <Divider orientation="vertical" light />

              <Stack display={{ xs: 'none', md: 'block' }} width={{ xs: 0, md: 0.4 }} maxHeight="95vmin">
                <KanbanTaskActivity id={details?.id} />

                <KanbanTaskDetailsComment
                  idQ="details"
                  details={details}
                  users={users}
                  userId={id}
                  name={displayName}
                  handleCommentChange={handleCommentChange}
                />
              </Stack>
            </Stack>
            {openRelance && (
              <Relance
                onRelanceValidate={(sendTo) => onRelanceValidate(sendTo)}
                show={openRelance}
                onClose={closeRelance}
              />
            )}
            {onDeleteTask && canEndTask && openDel && rigths?.delete && (
              <ActionModal
                title="Supprimer la tâche ?"
                desc="Cette action ne peut pas être annulée"
                color="error"
                open={openDel}
                onClose={closeDelete}
                body={<DependencyRenderListOnDelete card={details} />}
                moreAction={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                      size="small"
                      color="error"
                      checked={deleteOk}
                      onChange={(e) => setDeleteOK(e.currentTarget.checked)}
                    />
                    <Typography fontSize={14} fontWeight={120}>
                      {' '}
                      Je comprends qu'une fois supprimé, cette tâche ne peut pas être récupérée.
                    </Typography>
                  </Stack>
                }
                action={
                  <LoadingButton
                    loading={deleting}
                    color="error"
                    variant="contained"
                    disabled={!deleteOk}
                    onClick={handleDeletion}
                  >
                    Supprimer
                  </LoadingButton>
                }
              />
            )}
          </Card>
        </AnimatePresence>
      </Drawer>

      {show && (
        <Dialog sx={{ zIndex: (theme) => theme.zIndex.modal + 5 }} open={show} onClose={handleCloseShow}>
          <DialogContent sx={{ align: 'center' }}>
            <DialogContentText align="center">
              Vous ne pouvez pas éditer un projet suspendu ou cloturé.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShow}> OK </Button>
          </DialogActions>
        </Dialog>
      )}

      {uploadings.length > 0 &&
        ReactDOM.createPortal(
          <Box
            sx={{
              zIndex: (theme) => theme.zIndex.modal + 10,
              position: 'absolute',
              bottom: '70px',
              right: '1rem',
              maxWidth: '350px'
            }}
          >
            {uploadings.map((file) => (
              <Alert
                key={file.id}
                severity="success"
                color={file.error ? 'error' : 'info'}
                sx={{ m: 1, minWidth: 300, maxWidth: 350 }}
                onClose={() => {
                  setUploadingFiles((prevUploadingFiles) => {
                    return prevUploadingFiles.filter((uploadFile) => {
                      return uploadFile.id !== file.id;
                    });
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Tooltip title={file.name} placement="top">
                      <Typography noWrap> {file.name.substring(0, 25)} </Typography>
                    </Tooltip>
                  </Grid>
                  {file.error && (
                    <Grid item xs={3}>
                      <Typography noWrap> Erreur </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <LinearProgress
                      sx={{ minWidth: 220, maxWidth: 350 }}
                      variant="buffer"
                      color={file.error ? 'error' : 'info'}
                      value={file.error ? 100 : file.progress * 100}
                      valueBuffer={10}
                    />
                  </Grid>
                </Grid>
              </Alert>
            ))}
          </Box>,
          document.body
        )}
    </>
  );
}
